/* -------------------------------------------------------------------------- */
/* EMPLOYEE.ACTIONS.JS                                                        */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {employeeConstants}                             from '../_constants';
import {employeeService}                               from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const employeeActions = {
    _addEmployee,
    _editEmployee,
    _filterEmployee,
    _chart0Employee,
    get_employee_report_excel_000,
    get_employee_report_pdf_000,
    get_employee_report_html_000,
    get_employee_report_excel_001,
    get_employee_report_pdf_001,
    get_employee_report_html_001,
    _viewEmployee,
    _listEmployee,
    _removeEmployee,
    _lookupEmployee,
    _showEmployeeModalForm,
    _sendEmployeeFormParam,
    _activateAddEmployee,
    _activateEditEmployee,
    _activateFilterEmployee,
    _onEmployeeStateChange,
    _onEmployeeFormFieldChange,
    action_to_import_employee,
    action_to_export_employee,
    action_to_get_sample_employee,
    action_to_count_employee,
};

export function _addEmployee(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  employeeService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupEmployee());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_EMPLOYEE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: employeeConstants.EMPLOYEE_ADD_REQUEST         }}
    function success(result)  { return { type: employeeConstants.EMPLOYEE_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: employeeConstants.EMPLOYEE_ADD_FAILURE}}
}

export function _editEmployee(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  employeeService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupEmployee());
          dispatch(_viewEmployee(data.employee_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_EMPLOYEE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: employeeConstants.EMPLOYEE_CHANGE_REQUEST         }}
    function success(result)  { return { type: employeeConstants.EMPLOYEE_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: employeeConstants.EMPLOYEE_CHANGE_FAILURE, payload: error }}
}

export function _filterEmployee(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  employeeService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_EMPLOYEE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: employeeConstants.EMPLOYEE_FILTER_REQUEST         }}
    function success(result, page)  { return { type: employeeConstants.EMPLOYEE_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: employeeConstants.EMPLOYEE_FILTER_FAILURE}}
}

export function _chart0Employee(chart_type) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  employeeService._chart0(chart_type)
    .then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.CHART_EMPLOYEE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: employeeConstants.EMPLOYEE_CHART0_REQUEST         }}
    function success(result)  { return { type: employeeConstants.EMPLOYEE_CHART0_SUCCESS, payload: result }}
    function failure()   { return { type: employeeConstants.EMPLOYEE_CHART0_FAILURE}}
}


export function get_employee_report_excel_000(data) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        employeeService.get_employee_report_excel_000(data)
            .then(
                response => {
                  if(response[0].status){
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.REPORT_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_EXCEL_REQUEST         }}
    function success(result) { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_EXCEL_SUCCESS,  payload: result }}
    function failure()  { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_EXCEL_FAILURE}}
}


export function get_employee_report_pdf_000(data) {
    let path = ''
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        employeeService.get_employee_report_pdf_000(data)
            .then(
                response => {
                  if(response[0].status){
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.PDF_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_PDF_REQUEST}}
    function success(result) { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_PDF_SUCCESS,  payload: result }}
    function failure()  { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_PDF_FAILURE}}
}


export function get_employee_report_html_000(data) {
    let path = ''
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        employeeService.get_employee_report_html_000(data)
            .then(
                response => {
                  if(response.status){
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.PDF_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_HTML_REQUEST}}
    function success(result) { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_HTML_SUCCESS,  payload: result }}
    function failure()  { return { type: employeeConstants.EMPLOYEE_REPORT_000_TO_HTML_FAILURE}}
}


export function get_employee_report_excel_001(data) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        employeeService.get_employee_report_excel_001(data)
            .then(
                response => {
                  if(response[0].status){
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.REPORT_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_EXCEL_REQUEST         }}
    function success(result) { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_EXCEL_SUCCESS,  payload: result }}
    function failure()  { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_EXCEL_FAILURE}}
}


export function get_employee_report_pdf_001(data) {
    let path = ''
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        employeeService.get_employee_report_pdf_001(data)
            .then(
                response => {
                  if(response[0].status){
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.PDF_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_PDF_REQUEST}}
    function success(result) { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_PDF_SUCCESS,  payload: result }}
    function failure()  { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_PDF_FAILURE}}
}


export function get_employee_report_html_001(data) {
    let path = ''
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        employeeService.get_employee_report_html_001(data)
            .then(
                response => {
                  if(response.status){
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.PDF_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_HTML_REQUEST}}
    function success(result) { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_HTML_SUCCESS,  payload: result }}
    function failure()  { return { type: employeeConstants.EMPLOYEE_REPORT_001_TO_HTML_FAILURE}}
}


export function _removeEmployee(id) {
      const data = {
        employee_id                               : '', 
        employee_name                             : '', 
        complex_id                                : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'employee_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        employeeService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listEmployee(data));
          dispatch(_lookupEmployee());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_EMPLOYEE_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: employeeConstants.EMPLOYEE_REMOVE_REQUEST        }}
    function success(result) { return { type: employeeConstants.EMPLOYEE_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: employeeConstants.EMPLOYEE_REMOVE_FAILURE}}
}

export function _initEmployee() {
  return dispatch => {dispatch(success());};
  function success() { return { type: employeeConstants.EMPLOYEE_INIT_SUCCESS}}
}

export function _viewEmployee(id) {
  return dispatch => {
      dispatch(request({id}));

      employeeService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: employeeConstants.EMPLOYEE_VIEW_REQUEST} }
  function success(result) { return { type: employeeConstants.EMPLOYEE_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: employeeConstants.EMPLOYEE_VIEW_FAILURE}}
}

export function action_to_count_employee() {
  return dispatch => {
      dispatch(request());
      employeeService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: employeeConstants.EMPLOYEE_COUNT_REQUEST} }
  function success(result) { return { type: employeeConstants.EMPLOYEE_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: employeeConstants.EMPLOYEE_COUNT_FAILURE}}
}

export function _listEmployee(data) {
    return dispatch => {
        dispatch(request(data));
        employeeService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_EMPLOYEE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: employeeConstants.EMPLOYEE_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: employeeConstants.EMPLOYEE_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: employeeConstants.EMPLOYEE_GET_LIST_FAILURE}}
}
export function _lookupEmployee() {
  return dispatch => {
    dispatch(request());
    employeeService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_EMPLOYEE_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: employeeConstants.EMPLOYEE_LOOKUP_REQUEST         }}
    function success(result) { return { type: employeeConstants.EMPLOYEE_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: employeeConstants.EMPLOYEE_LOOKUP_FAILURE}}
}

export function _activateEditEmployee(data) {
  console.log('[employee.action][_activateEditEmployee][started]');
  const param = [{
      employee_id        : data[0].employee_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showEmployeeModalForm('show'))
    dispatch(_sendEmployeeFormParam(param));
  }
}

export function _activateAddEmployee() {
  console.log('[employee.action][_activateAddEmployee][started]');
  const param = [{
      employee_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initEmployee());
    dispatch(_showEmployeeModalForm('show'));
    dispatch(_sendEmployeeFormParam(param));
  }
}

export function _activateFilterEmployee() {
  console.log('[employee.action][_activateFilterEmployee][started]');
  const param = [{
      employee_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initEmployee());
    dispatch(_showEmployeeModalForm('show'));
    dispatch(_sendEmployeeFormParam(param));
  }
}

export function _onEmployeeFormFieldChange (field, value) {
  console.log('[employee.action][onEmployeeFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: employeeConstants.ON_EMPLOYEE_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onEmployeeStateChange (field, value) {
  return {
    type: employeeConstants.ON_EMPLOYEE_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showEmployeeModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:employeeConstants.EMPLOYEE_SHOW_MODAL, payload: showModal}}
}

export function _sendEmployeeFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: employeeConstants.EMPLOYEE_SEND_PARAM, payload: param}}
}

export function _showEmployeeLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:employeeConstants.EMPLOYEE_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_employee(data) {
      const param = {
        employee_id                               : '', 
        employee_name                             : '', 
        complex_id                                : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'employee_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  employeeService.service_to_import_employee(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listEmployee(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_EMPLOYEE_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_EMPLOYEE_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_EMPLOYEE_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: employeeConstants.EMPLOYEE_IMPORT_REQUEST         }}
    function success(result)  { return { type: employeeConstants.EMPLOYEE_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: employeeConstants.EMPLOYEE_IMPORT_FAILURE}}
}

export function action_to_export_employee() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  employeeService.service_to_export_employee()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_EMPLOYEE_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_EMPLOYEE_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: employeeConstants.EMPLOYEE_EXPORT_REQUEST         }}
    function success(result)  { return { type: employeeConstants.EMPLOYEE_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: employeeConstants.EMPLOYEE_EXPORT_FAILURE}}
}

export function action_to_get_sample_employee() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  employeeService.service_to_get_sample_employee()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_EMPLOYEE_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_EMPLOYEE_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_EMPLOYEE_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: employeeConstants.EMPLOYEE_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: employeeConstants.EMPLOYEE_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: employeeConstants.EMPLOYEE_GET_SAMPLE_FILE_FAILURE}}
}

