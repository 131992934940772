export const userlevelConstants = {
    ON_USERLEVEL_FORM_FIELD_CHANGE                : 'ON_USERLEVEL_FORM_FIELD_CHANGE',
    CHANGE_USERLEVEL_FIELD                        : 'CHANGE_USERLEVEL_FIELD',
    ON_USERLEVEL_STATE_CHANGE                     : 'ON_USERLEVEL_STATE_CHANGE',

    USERLEVEL_GET_LIST_REQUEST                    : 'USERLEVEL_GET_LIST_REQUEST',
    USERLEVEL_GET_LIST_SUCCESS                    : 'USERLEVEL_GET_LIST_SUCCESS',
    USERLEVEL_GET_LIST_FAILURE                    : 'USERLEVEL_GET_LIST_FAILURE',

    USERLEVEL_VIEW_REQUEST                        : 'USERLEVEL_VIEW_REQUEST',
    USERLEVEL_VIEW_SUCCESS                        : 'USERLEVEL_VIEW_SUCCESS',
    USERLEVEL_VIEW_FAILURE                        : 'USERLEVEL_VIEW_FAILURE',

    USERLEVEL_ADD_REQUEST                         : 'USERLEVEL_ADD_REQUEST',
    USERLEVEL_ADD_SUCCESS                         : 'USERLEVEL_ADD_SUCCESS',
    USERLEVEL_ADD_FAILURE                         : 'USERLEVEL_ADD_FAILURE',

    USERLEVEL_CHANGE_REQUEST                      : 'USERLEVEL_CHANGE_REQUEST',
    USERLEVEL_CHANGE_SUCCESS                      : 'USERLEVEL_CHANGE_SUCCESS',
    USERLEVEL_CHANGE_FAILURE                      : 'USERLEVEL_CHANGE_FAILURE',

    USERLEVEL_FILTER_REQUEST                      : 'USERLEVEL_FILTER_REQUEST',
    USERLEVEL_FILTER_SUCCESS                      : 'USERLEVEL_FILTER_SUCCESS',
    USERLEVEL_FILTER_FAILURE                      : 'USERLEVEL_FILTER_FAILURE',

    USERLEVEL_REMOVE_REQUEST                      : 'USERLEVEL_REMOVE_REQUEST',
    USERLEVEL_REMOVE_SUCCESS                      : 'USERLEVEL_REMOVE_SUCCESS',
    USERLEVEL_REMOVE_FAILURE                      : 'USERLEVEL_REMOVE_FAILURE',

    USERLEVEL_LOOKUP_REQUEST                      : 'USERLEVEL_LOOKUP_REQUEST',
    USERLEVEL_LOOKUP_SUCCESS                      : 'USERLEVEL_LOOKUP_SUCCESS',
    USERLEVEL_LOOKUP_FAILURE                      : 'USERLEVEL_LOOKUP_FAILURE',

    USERLEVEL_SHOW_MODAL                          : 'USERLEVEL_SHOW_MODAL',
    USERLEVEL_SEND_PARAM                          : 'USERLEVEL_SEND_PARAM',
    USERLEVEL_INITIALIZE_DATA                     : 'USERLEVEL_INITIALIZE_DATA',
    USERLEVEL_SHOW_LOOKUP_MODAL                   : 'USERLEVEL_SHOW_LOOKUP_MODAL',
    USERLEVEL_INIT_SUCCESS                        : 'USERLEVEL_INIT_SUCCESS',

    USERLEVEL_IMPORT_REQUEST                      : 'USERLEVEL_IMPORT_REQUEST',
    USERLEVEL_IMPORT_SUCCESS                      : 'USERLEVEL_IMPORT_SUCCESS',
    USERLEVEL_IMPORT_FAILURE                      : 'USERLEVEL_IMPORT_FAILURE',

    USERLEVEL_EXPORT_REQUEST                      : 'USERLEVEL_EXPORT_REQUEST',
    USERLEVEL_EXPORT_SUCCESS                      : 'USERLEVEL_EXPORT_SUCCESS',
    USERLEVEL_EXPORT_FAILURE                      : 'USERLEVEL_EXPORT_FAILURE',

    USERLEVEL_COUNT_REQUEST                       : 'USERLEVEL_COUNT_REQUEST',
    USERLEVEL_COUNT_SUCCESS                       : 'USERLEVEL_COUNT_SUCCESS',
    USERLEVEL_COUNT_FAILURE                       : 'USERLEVEL_COUNT_FAILURE',

    USERLEVEL_GET_SAMPLE_FILE_REQUEST             : 'USERLEVEL_GET_SAMPLE_FILE_REQUEST',
    USERLEVEL_GET_SAMPLE_FILE_SUCCESS             : 'USERLEVEL_GET_SAMPLE_FILE_SUCCESS',
    USERLEVEL_GET_SAMPLE_FILE_FAILURE             : 'USERLEVEL_GET_SAMPLE_FILE_FAILURE',

};

