/* -------------------------------------------------------------------------- */
/* LEASE_DETAIL.ACTIONS.JS                                                    */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {lease_detailConstants}                         from '../_constants';
import {lease_detailService}                           from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const lease_detailActions = {
    _addLease_detail,
    _editLease_detail,
    _filterLease_detail,
    _viewLease_detail,
    _listLease_detail,
    _removeLease_detail,
    _lookupLease_detail,
    _showLease_detailModalForm,
    _sendLease_detailFormParam,
    _activateAddLease_detail,
    _activateEditLease_detail,
    _activateFilterLease_detail,
    _onLease_detailStateChange,
    _onLease_detailFormFieldChange,
    action_to_import_lease_detail,
    action_to_export_lease_detail,
    action_to_get_sample_lease_detail,
    action_to_count_lease_detail,
};

export function _addLease_detail(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  lease_detailService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupLease_detail());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_LEASE_DETAIL_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: lease_detailConstants.LEASE_DETAIL_ADD_REQUEST         }}
    function success(result)  { return { type: lease_detailConstants.LEASE_DETAIL_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: lease_detailConstants.LEASE_DETAIL_ADD_FAILURE}}
}

export function _editLease_detail(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  lease_detailService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupLease_detail());
          dispatch(_viewLease_detail(data.lease_detail_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_LEASE_DETAIL_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: lease_detailConstants.LEASE_DETAIL_CHANGE_REQUEST         }}
    function success(result)  { return { type: lease_detailConstants.LEASE_DETAIL_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: lease_detailConstants.LEASE_DETAIL_CHANGE_FAILURE, payload: error }}
}

export function _filterLease_detail(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  lease_detailService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_LEASE_DETAIL_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: lease_detailConstants.LEASE_DETAIL_FILTER_REQUEST         }}
    function success(result, page)  { return { type: lease_detailConstants.LEASE_DETAIL_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: lease_detailConstants.LEASE_DETAIL_FILTER_FAILURE}}
}

export function _removeLease_detail(id) {
      const data = {
        lease_detail_id                           : '', 
        description                               : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'lease_detail_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        lease_detailService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listLease_detail(data));
          dispatch(_lookupLease_detail());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_LEASE_DETAIL_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: lease_detailConstants.LEASE_DETAIL_REMOVE_REQUEST        }}
    function success(result) { return { type: lease_detailConstants.LEASE_DETAIL_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: lease_detailConstants.LEASE_DETAIL_REMOVE_FAILURE}}
}

export function _initLease_detail() {
  return dispatch => {dispatch(success());};
  function success() { return { type: lease_detailConstants.LEASE_DETAIL_INIT_SUCCESS}}
}

export function _viewLease_detail(id) {
  return dispatch => {
      dispatch(request({id}));

      lease_detailService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_LEASE_DETAIL_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: lease_detailConstants.LEASE_DETAIL_VIEW_REQUEST} }
  function success(result) { return { type: lease_detailConstants.LEASE_DETAIL_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: lease_detailConstants.LEASE_DETAIL_VIEW_FAILURE}}
}

export function action_to_count_lease_detail() {
  return dispatch => {
      dispatch(request());
      lease_detailService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_LEASE_DETAIL_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: lease_detailConstants.LEASE_DETAIL_COUNT_REQUEST} }
  function success(result) { return { type: lease_detailConstants.LEASE_DETAIL_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: lease_detailConstants.LEASE_DETAIL_COUNT_FAILURE}}
}

export function _listLease_detail(data) {
    return dispatch => {
        dispatch(request(data));
        lease_detailService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_LEASE_DETAIL_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: lease_detailConstants.LEASE_DETAIL_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: lease_detailConstants.LEASE_DETAIL_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: lease_detailConstants.LEASE_DETAIL_GET_LIST_FAILURE}}
}
export function _lookupLease_detail() {
  return dispatch => {
    dispatch(request());
    lease_detailService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_LEASE_DETAIL_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: lease_detailConstants.LEASE_DETAIL_LOOKUP_REQUEST         }}
    function success(result) { return { type: lease_detailConstants.LEASE_DETAIL_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: lease_detailConstants.LEASE_DETAIL_LOOKUP_FAILURE}}
}

export function _activateEditLease_detail(data) {
  console.log('[lease_detail.action][_activateEditLease_detail][started]');
  const param = [{
      lease_detail_id        : data[0].lease_detail_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showLease_detailModalForm('show'))
    dispatch(_sendLease_detailFormParam(param));
  }
}

export function _activateAddLease_detail() {
  console.log('[lease_detail.action][_activateAddLease_detail][started]');
  const param = [{
      lease_detail_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initLease_detail());
    dispatch(_showLease_detailModalForm('show'));
    dispatch(_sendLease_detailFormParam(param));
  }
}

export function _activateFilterLease_detail() {
  console.log('[lease_detail.action][_activateFilterLease_detail][started]');
  const param = [{
      lease_detail_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initLease_detail());
    dispatch(_showLease_detailModalForm('show'));
    dispatch(_sendLease_detailFormParam(param));
  }
}

export function _onLease_detailFormFieldChange (field, value) {
  console.log('[lease_detail.action][onLease_detailFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: lease_detailConstants.ON_LEASE_DETAIL_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onLease_detailStateChange (field, value) {
  return {
    type: lease_detailConstants.ON_LEASE_DETAIL_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showLease_detailModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:lease_detailConstants.LEASE_DETAIL_SHOW_MODAL, payload: showModal}}
}

export function _sendLease_detailFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: lease_detailConstants.LEASE_DETAIL_SEND_PARAM, payload: param}}
}

export function _showLease_detailLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:lease_detailConstants.LEASE_DETAIL_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_lease_detail(data) {
      const param = {
        lease_detail_id                           : '', 
        description                               : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'lease_detail_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  lease_detailService.service_to_import_lease_detail(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listLease_detail(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_LEASE_DETAIL_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_LEASE_DETAIL_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_LEASE_DETAIL_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: lease_detailConstants.LEASE_DETAIL_IMPORT_REQUEST         }}
    function success(result)  { return { type: lease_detailConstants.LEASE_DETAIL_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: lease_detailConstants.LEASE_DETAIL_IMPORT_FAILURE}}
}

export function action_to_export_lease_detail() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  lease_detailService.service_to_export_lease_detail()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_LEASE_DETAIL_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_LEASE_DETAIL_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: lease_detailConstants.LEASE_DETAIL_EXPORT_REQUEST         }}
    function success(result)  { return { type: lease_detailConstants.LEASE_DETAIL_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: lease_detailConstants.LEASE_DETAIL_EXPORT_FAILURE}}
}

export function action_to_get_sample_lease_detail() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  lease_detailService.service_to_get_sample_lease_detail()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_LEASE_DETAIL_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_LEASE_DETAIL_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_LEASE_DETAIL_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: lease_detailConstants.LEASE_DETAIL_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: lease_detailConstants.LEASE_DETAIL_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: lease_detailConstants.LEASE_DETAIL_GET_SAMPLE_FILE_FAILURE}}
}

