'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('apartment_id'): {
        let apartment_id    = value
        let isValid = true

      if (apartment_id.length > 0) {
        if (apartment_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'apartment_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'apartment_id_error_msg'], 'ID Apartment perlu di isi')
        }

        if (apartment_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'apartment_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'apartment_id_error_msg'], 'ID Apartment panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(apartment_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'apartment_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'apartment_id_error_msg'], 'ID Apartment tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'apartment_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'apartment_id_error_msg'], '')
        }
    }

    case ('apartment_name'): {
        let apartment_name    = value
        let isValid = true

        if (apartment_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'apartment_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'apartment_name_error_msg'], 'Nama Apartment perlu di isi')
        }

        if (apartment_name.length > 40) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'apartment_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'apartment_name_error_msg'], 'Nama Apartment panjang maksimumnya 40 karakter.')
        }

        if (!check.validateAlpha(apartment_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'apartment_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'apartment_name_error_msg'], 'Nama Apartment tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'apartment_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'apartment_name_error_msg'], '')
        }
    }

    case ('total_bedroom'): {
        let total_bedroom    = value
        let isValid = true

        if (total_bedroom.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'total_bedroom_has_error'], true)
            .setIn(['form', 'gpDataFields', 'total_bedroom_error_msg'], 'Jumlah Kamar Tidur perlu di isi')
        }

        if (total_bedroom.length > 10) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'total_bedroom_has_error'], true)
            .setIn(['form', 'gpDataFields', 'total_bedroom_error_msg'], 'Jumlah Kamar Tidur panjang maksimumnya 10 karakter.')
        }

        if (!check.validateNumber(total_bedroom)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'total_bedroom_has_error'], true)
            .setIn(['form', 'gpDataFields', 'total_bedroom_error_msg'], 'Jumlah Kamar Tidur tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'total_bedroom_has_error'], false)
            .setIn(['form', 'gpDataFields', 'total_bedroom_error_msg'], '')
        }
    }

    case ('rent_cost'): {
        let rent_cost    = value
        let isValid = true

        if (rent_cost.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_cost_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_cost_error_msg'], 'Biaya Sewa perlu di isi')
        }

        if (rent_cost.length > 10) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_cost_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_cost_error_msg'], 'Biaya Sewa panjang maksimumnya 10 karakter.')
        }

        if (!check.validateMoney(rent_cost)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_cost_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_cost_error_msg'], 'Biaya Sewa tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'rent_cost_has_error'], false)
            .setIn(['form', 'gpDataFields', 'rent_cost_error_msg'], '')
        }
    }

  }
  return state
}
