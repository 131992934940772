'use strict'
import {usersConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/users.initialstate'
import fieldValidation                              from './../_validation/users.field.validation'
import formValidation                               from './../_validation/users.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case usersConstants.USERS_GET_LIST_REQUEST:
    case usersConstants.USERS_LOOKUP_REQUEST:
    case usersConstants.USERS_ADD_REQUEST:
    case usersConstants.USERS_CHANGE_REQUEST:
    case usersConstants.USERS_FILTER_REQUEST:
    case usersConstants.USERS_VIEW_REQUEST:
    case usersConstants.USERS_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpUsersIsFetching'],  true)

    case usersConstants.USERS_CHANGE_SUCCESS:
    case usersConstants.USERS_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpUsersIsFetching'],  false)

    case usersConstants.USERS_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpUsersIsFetching'],  false)
          .setIn(['form', 'users_count'],  action.payload)

    case usersConstants.USERS_FILTER_SUCCESS:
    case usersConstants.USERS_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpUsersDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpUsersDataList'], data.rows)
          .setIn(['form', 'gpUsersDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpUsersIsFetching'],  false)


  case usersConstants.USERS_LOOKUP_SUCCESS:
    console.log('[users.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpUsersLookupList'], action.payload)
        .setIn(['form', 'gpUsersIsFetching'], false)


    case usersConstants.USERS_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'id_user'],'')
          .setIn(['form', 'gpDataFields',                                                           'fullname'],'')
          .setIn(['form', 'gpDataFields',                                                           'phone'],'')
          .setIn(['form', 'gpDataFields',                                                           'email'],'')
          .setIn(['form', 'gpDataFields',                                                           'passwd'],'')
          .setIn(['form', 'gpDataFields',                                                           'active'],'')
          .setIn(['form', 'gpDataFields',                                                           'administrator'],'')
          .setIn(['form', 'gpDataFields',                                                           'id_userlevel'],'')
          .setIn(['form', 'gpDataFields',                                                           'last_login_date'],'')
          .setIn(['form', 'gpDataFields',                                                           'login_attempt'],'')
          .setIn(['form', 'gpDataFields',                                                           'expired_date'],'')

    case usersConstants.USERS_SHOW_MODAL:
      return state
        .setIn(['form', 'gpUsersShowModal'],  action.payload)


    case usersConstants.USERS_SEND_PARAM:
      return state
        .setIn(['form', 'gpUsersModalParam'],  action.payload[0])


    case usersConstants.USERS_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'id_user'],                                           action.payload[0].id_user)
          .setIn(['form', 'gpOriginalFields', 'id_user'],                                           action.payload[0].id_user)
          .setIn(['form', 'gpDataFields',     'fullname'],                                          action.payload[0].fullname)
          .setIn(['form', 'gpOriginalFields', 'fullname'],                                          action.payload[0].fullname)
          .setIn(['form', 'gpDataFields',     'phone'],                                             action.payload[0].phone)
          .setIn(['form', 'gpOriginalFields', 'phone'],                                             action.payload[0].phone)
          .setIn(['form', 'gpDataFields',     'email'],                                             action.payload[0].email)
          .setIn(['form', 'gpOriginalFields', 'email'],                                             action.payload[0].email)
          .setIn(['form', 'gpDataFields',     'passwd'],                                            action.payload[0].passwd)
          .setIn(['form', 'gpOriginalFields', 'passwd'],                                            action.payload[0].passwd)
          .setIn(['form', 'gpDataFields',     'active'],                                            action.payload[0].active)
          .setIn(['form', 'gpOriginalFields', 'active'],                                            action.payload[0].active)
          .setIn(['form', 'gpDataFields',     'administrator'],                                     action.payload[0].administrator)
          .setIn(['form', 'gpOriginalFields', 'administrator'],                                     action.payload[0].administrator)
          .setIn(['form', 'gpDataFields',     'id_userlevel'],                                      action.payload[0].id_userlevel)
          .setIn(['form', 'gpOriginalFields', 'id_userlevel'],                                      action.payload[0].id_userlevel)
          .setIn(['form', 'gpDataFields',     'last_login_date'],                                   action.payload[0].last_login_date)
          .setIn(['form', 'gpOriginalFields', 'last_login_date'],                                   action.payload[0].last_login_date)
          .setIn(['form', 'gpDataFields',     'login_attempt'],                                     action.payload[0].login_attempt)
          .setIn(['form', 'gpOriginalFields', 'login_attempt'],                                     action.payload[0].login_attempt)
          .setIn(['form', 'gpDataFields',     'expired_date'],                                      action.payload[0].expired_date)
          .setIn(['form', 'gpOriginalFields', 'expired_date'],                                      action.payload[0].expired_date)
          .setIn(['form', 'gpUsersIsFetching'],                                                     false)
          return nextRecordState


    case usersConstants.USERS_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'id_user'],                                               '')
          .setIn(['form', 'gpDataFields', 'fullname'],                                              '')
          .setIn(['form', 'gpDataFields', 'phone'],                                                 '')
          .setIn(['form', 'gpDataFields', 'email'],                                                 '')
          .setIn(['form', 'gpDataFields', 'passwd'],                                                '')
          .setIn(['form', 'gpDataFields', 'active'],                                                '')
          .setIn(['form', 'gpDataFields', 'administrator'],                                         '')
          .setIn(['form', 'gpDataFields', 'id_userlevel'],                                          '')
          .setIn(['form', 'gpDataFields', 'last_login_date'],                                       '')
          .setIn(['form', 'gpDataFields', 'login_attempt'],                                         '')
          .setIn(['form', 'gpDataFields', 'expired_date'],                                          '')
          .setIn(['form', 'gpDataFields', 'id_user_has_error'],                                     false)
          .setIn(['form', 'gpDataFields', 'fullname_has_error'],                                    false)
          .setIn(['form', 'gpDataFields', 'phone_has_error'],                                       false)
          .setIn(['form', 'gpDataFields', 'email_has_error'],                                       false)
          .setIn(['form', 'gpDataFields', 'passwd_has_error'],                                      false)
          .setIn(['form', 'gpDataFields', 'active_has_error'],                                      false)
          .setIn(['form', 'gpDataFields', 'administrator_has_error'],                               false)
          .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'],                                false)
          .setIn(['form', 'gpDataFields', 'last_login_date_has_error'],                             false)
          .setIn(['form', 'gpDataFields', 'login_attempt_has_error'],                               false)
          .setIn(['form', 'gpDataFields', 'expired_date_has_error'],                                false)

          .setIn(['form', 'gpUsersIsValid'],                                                        false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('usersReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'id_user'],                                               '')
          .setIn(['form', 'gpDataFields', 'fullname'],                                              '')
          .setIn(['form', 'gpDataFields', 'phone'],                                                 '')
          .setIn(['form', 'gpDataFields', 'email'],                                                 '')
          .setIn(['form', 'gpDataFields', 'passwd'],                                                '')
          .setIn(['form', 'gpDataFields', 'active'],                                                '')
          .setIn(['form', 'gpDataFields', 'administrator'],                                         '')
          .setIn(['form', 'gpDataFields', 'id_userlevel'],                                          '')
          .setIn(['form', 'gpDataFields', 'last_login_date'],                                       '')
          .setIn(['form', 'gpDataFields', 'login_attempt'],                                         '')
          .setIn(['form', 'gpDataFields', 'expired_date'],                                          '')
          .setIn(['form', 'gpUsersError'],                                                          null)
          return formValidation(nextRecordState, action)


    case usersConstants.USERS_GET_LIST_FAILURE:
    case usersConstants.USERS_LOOKUP_FAILURE:
    case usersConstants.USERS_ADD_FAILURE:
    case usersConstants.USERS_CHANGE_FAILURE:
    case usersConstants.USERS_FILTER_FAILURE:
    case usersConstants.USERS_VIEW_FAILURE:
    case usersConstants.USERS_COUNT_FAILURE:
      console.log('users.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpUsersIsFetching'], false)
          .setIn(['form', 'gpUsersError'], action.payload)


   case usersConstants.ON_USERS_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case usersConstants.ON_USERS_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case usersConstants.CHANGE_USERS_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpUsersError'], null)
      return nextState}

    case usersConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'id_user'],                                               record.id_user)
          .setIn(['form', 'gpOriginalFields', 'id_user'],                                           record.id_user)
          .setIn(['form', 'gpDataFields', 'fullname'],                                              record.fullname)
          .setIn(['form', 'gpOriginalFields', 'fullname'],                                          record.fullname)
          .setIn(['form', 'gpDataFields', 'phone'],                                                 record.phone)
          .setIn(['form', 'gpOriginalFields', 'phone'],                                             record.phone)
          .setIn(['form', 'gpDataFields', 'email'],                                                 record.email)
          .setIn(['form', 'gpOriginalFields', 'email'],                                             record.email)
          .setIn(['form', 'gpDataFields', 'passwd'],                                                record.passwd)
          .setIn(['form', 'gpOriginalFields', 'passwd'],                                            record.passwd)
          .setIn(['form', 'gpDataFields', 'active'],                                                record.active)
          .setIn(['form', 'gpOriginalFields', 'active'],                                            record.active)
          .setIn(['form', 'gpDataFields', 'administrator'],                                         record.administrator)
          .setIn(['form', 'gpOriginalFields', 'administrator'],                                     record.administrator)
          .setIn(['form', 'gpDataFields', 'id_userlevel'],                                          record.id_userlevel)
          .setIn(['form', 'gpOriginalFields', 'id_userlevel'],                                      record.id_userlevel)
          .setIn(['form', 'gpDataFields', 'last_login_date'],                                       record.last_login_date)
          .setIn(['form', 'gpOriginalFields', 'last_login_date'],                                   record.last_login_date)
          .setIn(['form', 'gpDataFields', 'login_attempt'],                                         record.login_attempt)
          .setIn(['form', 'gpOriginalFields', 'login_attempt'],                                     record.login_attempt)
          .setIn(['form', 'gpDataFields', 'expired_date'],                                          record.expired_date)
          .setIn(['form', 'gpOriginalFields', 'expired_date'],                                      record.expired_date)

          .setIn(['form', 'gpDataFields', 'id_user_has_error'],                                     record.id_user_has_error)
          .setIn(['form', 'gpDataFields', 'fullname_has_error'],                                    record.fullname_has_error)
          .setIn(['form', 'gpDataFields', 'phone_has_error'],                                       record.phone_has_error)
          .setIn(['form', 'gpDataFields', 'email_has_error'],                                       record.email_has_error)
          .setIn(['form', 'gpDataFields', 'passwd_has_error'],                                      record.passwd_has_error)
          .setIn(['form', 'gpDataFields', 'active_has_error'],                                      record.active_has_error)
          .setIn(['form', 'gpDataFields', 'administrator_has_error'],                               record.administrator_has_error)
          .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'],                                record.id_userlevel_has_error)
          .setIn(['form', 'gpDataFields', 'last_login_date_has_error'],                             record.last_login_date_has_error)
          .setIn(['form', 'gpDataFields', 'login_attempt_has_error'],                               record.login_attempt_has_error)
          .setIn(['form', 'gpDataFields', 'expired_date_has_error'],                                record.expired_date_has_error)



          .setIn(['form', 'gpUsersDataList'],                                                       record.gpUsersDataList)
          .setIn(['form', 'gpUsersIsDisabled'],                                                     record.gpUsersIsDisabled)
          .setIn(['form', 'gpUsersError'],                                                          record.gpUsersError)
          .setIn(['form', 'gpUsersIsValid'],                                                        record.gpUsersIsValid)
          .setIn(['form', 'gpUsersIsFetching'],                                                     record.gpUsersIsFetching)
          .setIn(['form', 'users_count'],                                                           record.users_count)
      return next
case usersConstants.USERS_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpUsersLookupShowModal'],  action.payload)


  }
  return state
}
