import { combineReducers } from 'redux';
import alert from './alert.reducer';
import apartment from './apartment.reducer';
import authenticate from './authenticate.reducer';
import building from './building.reducer';
import company from './company.reducer';
import complex from './complex.reducer';
import employee from './employee.reducer';
import global from './global.reducer';
import lease from './lease.reducer';
import lease_detail from './lease_detail.reducer';
import payment from './payment.reducer';
import rent from './rent.reducer';
import service from './service.reducer';
import tenant from './tenant.reducer';
import userlevel from './userlevel.reducer';
import users from './users.reducer';

const rootReducer = combineReducers({
alert,
apartment,
authenticate,
building,
company,
complex,
employee,
global,
lease,
lease_detail,
payment,
rent,
service,
tenant,
userlevel,
users,
});

export default rootReducer;
