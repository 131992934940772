import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import PaymentEdit                                     from './edit';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  paymentActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...paymentActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    payment: {
      gpDataFields                                : state.payment.form.gpDataFields,
      gpPaymentIsFetching                         : state.payment.form.gpPaymentIsFetching,
      payment_active_tab                          : state.payment.form.payment_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsPaymentIsFetching                         : true,
      payment_id                                  : '',
      payment_date                                : '',
      Payment_amount                              : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        payment_id                                : nextProps.payment.gpDataFields.payment_id,
        payment_date                              : nextProps.payment.gpDataFields.payment_date,
        Payment_amount                            : nextProps.payment.gpDataFields.Payment_amount,
        date_upd                                  : nextProps.payment.gpDataFields.date_upd,
        date_add                                  : nextProps.payment.gpDataFields.date_add,
        lsPaymentIsFetching                       : nextProps.payment.gpPaymentIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewPayment(this.props.match.params.payment_id);
      this.setState({
        lsPaymentIsFetching                       : this.props.payment.gpPaymentIsFetching,
        payment_id                                : this.props.payment.gpDataFields.payment_id,
        payment_date                              : this.props.payment.gpDataFields.payment_date,
        Payment_amount                            : this.props.payment.gpDataFields.Payment_amount,
      });
    }


    _editPayment(id) {
      const data = [{
          payment_id      : id,
      }];
      this.props.actions._activateEditPayment(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onPaymentStateChange('payment_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
      ]


      const {
        payment_active_tab
      } = this.props.payment;


      const {
        rent_id,
        rent_description,
        payment_id,
        payment_date,
        Payment_amount,
        date_add,
        date_upd
      } = this.props.payment.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Payment
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/payment/edit/' + payment_id}
                onClick = {() => this._editPayment(payment_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Penyewaan</span><br />
                  {rent_id} - {rent_description}</Col>

                  <Col md={8} className='p-2'><span className='font-bold'>ID Pembayaran</span><br />
                  {payment_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Tanggal Bayar</span><br />
                  {payment_date}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Jumlah Bayar</span><br />
            <NumericFormat
              value={Payment_amount}
              thousandSeparator='.'
              prefix='Rp '
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

