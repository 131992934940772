'use strict'
import {apartmentConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/apartment.initialstate'
import fieldValidation                              from './../_validation/apartment.field.validation'
import formValidation                               from './../_validation/apartment.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case apartmentConstants.APARTMENT_GET_LIST_REQUEST:
    case apartmentConstants.APARTMENT_LOOKUP_REQUEST:
    case apartmentConstants.APARTMENT_ADD_REQUEST:
    case apartmentConstants.APARTMENT_CHANGE_REQUEST:
    case apartmentConstants.APARTMENT_FILTER_REQUEST:
    case apartmentConstants.APARTMENT_VIEW_REQUEST:
    case apartmentConstants.APARTMENT_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpApartmentIsFetching'],  true)

    case apartmentConstants.APARTMENT_CHART0_REQUEST:
      return state
        .setIn(['form', 'gpApartmentChart0IsFetching'],  true)

    case apartmentConstants.APARTMENT_REPORT_000_TO_EXCEL_REQUEST:
    case apartmentConstants.APARTMENT_REPORT_000_TO_PDF_REQUEST:
      return state
        .setIn(['form', 'gpApartmentReport000IsFetching'],  true)
    
    case apartmentConstants.APARTMENT_REPORT_000_TO_EXCEL_SUCCESS:
    case apartmentConstants.APARTMENT_REPORT_000_TO_PDF_SUCCESS:
      return state
        .setIn(['form', 'gpApartmentReport000Path'],  action.payload)
        .setIn(['form', 'gpApartmentReport000IsFetching'],  false)
    
    case apartmentConstants.APARTMENT_REPORT_000_TO_EXCEL_FAILURE:
    case apartmentConstants.APARTMENT_REPORT_000_TO_PDF_FAILURE:
      return state
        .setIn(['form', 'gpApartmentReport000IsFetching'],  false)

    case apartmentConstants.APARTMENT_CHANGE_SUCCESS:
    case apartmentConstants.APARTMENT_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpApartmentIsFetching'],  false)

    case apartmentConstants.APARTMENT_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpApartmentIsFetching'],  false)
          .setIn(['form', 'apartment_count'],  action.payload)

    case apartmentConstants.APARTMENT_FILTER_SUCCESS:
    case apartmentConstants.APARTMENT_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpApartmentDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpApartmentDataList'], data.rows)
          .setIn(['form', 'gpApartmentDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpApartmentIsFetching'],  false)


case apartmentConstants.APARTMENT_CHART0_SUCCESS:
  console.log('APARTMENT_CHART0_SUCCESS.payload:' + JSON.stringify(action.payload));
  for(let i = 0; i < action.payload.length; i++) {
    action.payload[i].sum = parseInt(action.payload[i].sum);
  }
  return state
      .setIn(['form', 'gpApartmentChart0DataList'], action.payload)
      .setIn(['form', 'gpApartmentChart0IsFetching'], false)

  case apartmentConstants.APARTMENT_LOOKUP_SUCCESS:
    console.log('[apartment.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpApartmentLookupList'], action.payload)
        .setIn(['form', 'gpApartmentIsFetching'], false)


    case apartmentConstants.APARTMENT_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'apartment_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'apartment_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'total_bedroom'],'')
          .setIn(['form', 'gpDataFields',                                                           'rent_cost'],'')
          .setIn(['form', 'gpDataFields',                                                           'building_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'building_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'building_building_id'],'')

    case apartmentConstants.APARTMENT_SHOW_MODAL:
      return state
        .setIn(['form', 'gpApartmentShowModal'],  action.payload)


    case apartmentConstants.APARTMENT_SEND_PARAM:
      return state
        .setIn(['form', 'gpApartmentModalParam'],  action.payload[0])


    case apartmentConstants.APARTMENT_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'apartment_id'],                                      action.payload[0].apartment_id)
          .setIn(['form', 'gpOriginalFields', 'apartment_id'],                                      action.payload[0].apartment_id)
          .setIn(['form', 'gpDataFields',     'apartment_name'],                                    action.payload[0].apartment_name)
          .setIn(['form', 'gpOriginalFields', 'apartment_name'],                                    action.payload[0].apartment_name)
          .setIn(['form', 'gpDataFields',     'total_bedroom'],                                     action.payload[0].total_bedroom)
          .setIn(['form', 'gpOriginalFields', 'total_bedroom'],                                     action.payload[0].total_bedroom)
          .setIn(['form', 'gpDataFields',     'rent_cost'],                                         action.payload[0].rent_cost)
          .setIn(['form', 'gpOriginalFields', 'rent_cost'],                                         action.payload[0].rent_cost)
          .setIn(['form', 'gpDataFields',     'building_id'],                                       action.payload[0].building_id)
          .setIn(['form', 'gpOriginalFields', 'building_id'],                                       action.payload[0].building_id)
          .setIn(['form', 'gpDataFields',     'building_name'],                                     action.payload[0].building_name)
          .setIn(['form', 'gpOriginalFields', 'building_name'],                                     action.payload[0].building_name)
          .setIn(['form', 'gpDataFields', 'building_building_id'],                                  action.payload[0].building_building_id)
          .setIn(['form', 'gpOriginalFields', 'building_building_id'],                              action.payload[0].building_building_id)
          .setIn(['form', 'gpApartmentIsFetching'],                                                 false)
          return nextRecordState


    case apartmentConstants.APARTMENT_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'apartment_id'],                                          '')
          .setIn(['form', 'gpDataFields', 'apartment_name'],                                        '')
          .setIn(['form', 'gpDataFields', 'total_bedroom'],                                         '')
          .setIn(['form', 'gpDataFields', 'rent_cost'],                                             '')
          .setIn(['form', 'gpDataFields', 'apartment_id_has_error'],                                false)
          .setIn(['form', 'gpDataFields', 'apartment_name_has_error'],                              false)
          .setIn(['form', 'gpDataFields', 'total_bedroom_has_error'],                               false)
          .setIn(['form', 'gpDataFields', 'rent_cost_has_error'],                                   false)

          .setIn(['form', 'gpApartmentIsValid'],                                                    false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('apartmentReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'apartment_id'],                                          '')
          .setIn(['form', 'gpDataFields', 'apartment_name'],                                        '')
          .setIn(['form', 'gpDataFields', 'total_bedroom'],                                         '')
          .setIn(['form', 'gpDataFields', 'rent_cost'],                                             '')
          .setIn(['form', 'gpDataFields', 'building_id'],                                           '')
          .setIn(['form', 'gpDataFields', 'building_name'],                                         '')
          .setIn(['form', 'gpDataFields', 'building_id'],                                           '')
          .setIn(['form', 'gpApartmentError'],                                                      null)
          return formValidation(nextRecordState, action)


    case apartmentConstants.APARTMENT_GET_LIST_FAILURE:
    case apartmentConstants.APARTMENT_LOOKUP_FAILURE:
    case apartmentConstants.APARTMENT_ADD_FAILURE:
    case apartmentConstants.APARTMENT_CHANGE_FAILURE:
    case apartmentConstants.APARTMENT_FILTER_FAILURE:
    case apartmentConstants.APARTMENT_VIEW_FAILURE:
    case apartmentConstants.APARTMENT_COUNT_FAILURE:
      console.log('apartment.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpApartmentIsFetching'], false)
          .setIn(['form', 'gpApartmentError'], action.payload)


    case apartmentConstants.APARTMENT_CHART0_FAILURE:
      return state
          .setIn(['form', 'gpApartmentChart0IsFetching'], false)
          .setIn(['form', 'gpApartmentChart0Error'], action.payload)


   case apartmentConstants.ON_APARTMENT_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case apartmentConstants.ON_APARTMENT_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case apartmentConstants.CHANGE_APARTMENT_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpApartmentError'], null)
      return nextState}

    case apartmentConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'apartment_id'],                                          record.apartment_id)
          .setIn(['form', 'gpOriginalFields', 'apartment_id'],                                      record.apartment_id)
          .setIn(['form', 'gpDataFields', 'apartment_name'],                                        record.apartment_name)
          .setIn(['form', 'gpOriginalFields', 'apartment_name'],                                    record.apartment_name)
          .setIn(['form', 'gpDataFields', 'total_bedroom'],                                         record.total_bedroom)
          .setIn(['form', 'gpOriginalFields', 'total_bedroom'],                                     record.total_bedroom)
          .setIn(['form', 'gpDataFields', 'rent_cost'],                                             record.rent_cost)
          .setIn(['form', 'gpOriginalFields', 'rent_cost'],                                         record.rent_cost)
          .setIn(['form', 'gpDataFields', 'building_id'],                                           record.building_id)
          .setIn(['form', 'gpOriginalFields', 'building_id'],                                       record.building_id)
          .setIn(['form', 'gpDataFields', 'building_id_has_error'],                                 record.building_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'building_id_has_error'],                             record.building_id_has_error)
          .setIn(['form', 'gpDataFields', 'building_name'],                                         record.building_name)
          .setIn(['form', 'gpOriginalFields', 'building_name'],                                     record.building_name)
          .setIn(['form', 'gpDataFields', 'building_name_has_error'],                               record.building_name_has_error)
          .setIn(['form', 'gpOriginalFields', 'building_name_has_error'],                           record.building_name_has_error)
          .setIn(['form', 'gpDataFields', 'building_building_id'],                                  record.building_building_id)
          .setIn(['form', 'gpOriginalFields', 'building_building_id'],                              record.building_building_id)

          .setIn(['form', 'gpDataFields', 'apartment_id_has_error'],                                record.apartment_id_has_error)
          .setIn(['form', 'gpDataFields', 'apartment_name_has_error'],                              record.apartment_name_has_error)
          .setIn(['form', 'gpDataFields', 'total_bedroom_has_error'],                               record.total_bedroom_has_error)
          .setIn(['form', 'gpDataFields', 'rent_cost_has_error'],                                   record.rent_cost_has_error)

          .setIn(['form', 'gpDataFields', 'building_building_id_has_error'],                        action.payload.building_building_id_has_error)

          .setIn(['form', 'gpApartmentChart0DataList']     ,                                        record.gpApartmentChart0DataList)
          .setIn(['form', 'gpApartmentChart0Error']        ,                                        record.gpApartmentChart0Error)
          .setIn(['form', 'gpApartmentChart0IsFetching']   ,                                        record.gpApartmentChart0IsFetching)

          .setIn(['form', 'gpApartmentDataList'],                                                   record.gpApartmentDataList)
          .setIn(['form', 'gpApartmentIsDisabled'],                                                 record.gpApartmentIsDisabled)
          .setIn(['form', 'gpApartmentError'],                                                      record.gpApartmentError)
          .setIn(['form', 'gpApartmentIsValid'],                                                    record.gpApartmentIsValid)
          .setIn(['form', 'gpApartmentIsFetching'],                                                 record.gpApartmentIsFetching)
          .setIn(['form', 'apartment_count'],                                                       record.apartment_count)
      return next
case apartmentConstants.APARTMENT_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpApartmentLookupShowModal'],  action.payload)


  }
  return state
}
