const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        tenant_id                                 : '',
        tenant_name                               : '',
        email                                     : '',
        phone                                     : '',
        ktp                                       : '',
        search_tenant_id                          : '',
        search_tenant_name                        : '',
        search_email                              : '',
        search_phone                              : '',
        search_ktp                                : '',
   }))(),
   gpTenantDataList                               : [],
   gpTenantDataListNumRows                        : 0,
   gpTenantLookupList                             : [],
   gpTenantIsDisabled                             : false,
   gpTenantError                                  : null,
   gpTenantIsValid                                : true,
   gpTenantIsFetching                             : false,
   tenant_count                                   : 0,
   tenant_active_tab                              : 0,
   gpTenantShowModal                              : 'hide',
   gpTenantModalParam                             : [],
   gpTenantLookupShowModal                        : 'hide',
   gpDataFields: new (Record({
        tenant_id                                 : '',
        tenant_name                               : '',
        email                                     : '',
        phone                                     : '',
        ktp                                       : '',
        search_tenant_id                          : '',
        search_tenant_name                        : '',
        search_email                              : '',
        search_phone                              : '',
        search_ktp                                : '',
        tenant_id_has_error                       : false,
        tenant_name_has_error                     : false,
        email_has_error                           : false,
        phone_has_error                           : false,
        ktp_has_error                             : false,
        search_tenant_id_has_error                : false,
        search_tenant_name_has_error              : false,
        search_email_has_error                    : false,
        search_phone_has_error                    : false,
        search_ktp_has_error                      : false,
        tenant_id_error_msg                       : false,
        tenant_name_error_msg                     : false,
        email_error_msg                           : false,
        phone_error_msg                           : false,
        ktp_error_msg                             : false,
        search_tenant_id_error_msg                : false,
        search_tenant_name_error_msg              : false,
        search_email_error_msg                    : false,
        search_phone_error_msg                    : false,
        search_ktp_error_msg                      : false,
   }))(),
 })
 var tenantInitialState = Record({
   form: new Form()
 })
export default tenantInitialState
