export const serviceConstants = {
    ON_SERVICE_FORM_FIELD_CHANGE                  : 'ON_SERVICE_FORM_FIELD_CHANGE',
    CHANGE_SERVICE_FIELD                          : 'CHANGE_SERVICE_FIELD',
    ON_SERVICE_STATE_CHANGE                       : 'ON_SERVICE_STATE_CHANGE',

    SERVICE_GET_LIST_REQUEST                      : 'SERVICE_GET_LIST_REQUEST',
    SERVICE_GET_LIST_SUCCESS                      : 'SERVICE_GET_LIST_SUCCESS',
    SERVICE_GET_LIST_FAILURE                      : 'SERVICE_GET_LIST_FAILURE',

    SERVICE_VIEW_REQUEST                          : 'SERVICE_VIEW_REQUEST',
    SERVICE_VIEW_SUCCESS                          : 'SERVICE_VIEW_SUCCESS',
    SERVICE_VIEW_FAILURE                          : 'SERVICE_VIEW_FAILURE',

    SERVICE_ADD_REQUEST                           : 'SERVICE_ADD_REQUEST',
    SERVICE_ADD_SUCCESS                           : 'SERVICE_ADD_SUCCESS',
    SERVICE_ADD_FAILURE                           : 'SERVICE_ADD_FAILURE',

    SERVICE_CHANGE_REQUEST                        : 'SERVICE_CHANGE_REQUEST',
    SERVICE_CHANGE_SUCCESS                        : 'SERVICE_CHANGE_SUCCESS',
    SERVICE_CHANGE_FAILURE                        : 'SERVICE_CHANGE_FAILURE',

    SERVICE_FILTER_REQUEST                        : 'SERVICE_FILTER_REQUEST',
    SERVICE_FILTER_SUCCESS                        : 'SERVICE_FILTER_SUCCESS',
    SERVICE_FILTER_FAILURE                        : 'SERVICE_FILTER_FAILURE',

    SERVICE_REMOVE_REQUEST                        : 'SERVICE_REMOVE_REQUEST',
    SERVICE_REMOVE_SUCCESS                        : 'SERVICE_REMOVE_SUCCESS',
    SERVICE_REMOVE_FAILURE                        : 'SERVICE_REMOVE_FAILURE',

    SERVICE_LOOKUP_REQUEST                        : 'SERVICE_LOOKUP_REQUEST',
    SERVICE_LOOKUP_SUCCESS                        : 'SERVICE_LOOKUP_SUCCESS',
    SERVICE_LOOKUP_FAILURE                        : 'SERVICE_LOOKUP_FAILURE',

    SERVICE_SHOW_MODAL                            : 'SERVICE_SHOW_MODAL',
    SERVICE_SEND_PARAM                            : 'SERVICE_SEND_PARAM',
    SERVICE_INITIALIZE_DATA                       : 'SERVICE_INITIALIZE_DATA',
    SERVICE_SHOW_LOOKUP_MODAL                     : 'SERVICE_SHOW_LOOKUP_MODAL',
    SERVICE_INIT_SUCCESS                          : 'SERVICE_INIT_SUCCESS',

    SERVICE_IMPORT_REQUEST                        : 'SERVICE_IMPORT_REQUEST',
    SERVICE_IMPORT_SUCCESS                        : 'SERVICE_IMPORT_SUCCESS',
    SERVICE_IMPORT_FAILURE                        : 'SERVICE_IMPORT_FAILURE',

    SERVICE_EXPORT_REQUEST                        : 'SERVICE_EXPORT_REQUEST',
    SERVICE_EXPORT_SUCCESS                        : 'SERVICE_EXPORT_SUCCESS',
    SERVICE_EXPORT_FAILURE                        : 'SERVICE_EXPORT_FAILURE',

    SERVICE_COUNT_REQUEST                         : 'SERVICE_COUNT_REQUEST',
    SERVICE_COUNT_SUCCESS                         : 'SERVICE_COUNT_SUCCESS',
    SERVICE_COUNT_FAILURE                         : 'SERVICE_COUNT_FAILURE',

    SERVICE_GET_SAMPLE_FILE_REQUEST               : 'SERVICE_GET_SAMPLE_FILE_REQUEST',
    SERVICE_GET_SAMPLE_FILE_SUCCESS               : 'SERVICE_GET_SAMPLE_FILE_SUCCESS',
    SERVICE_GET_SAMPLE_FILE_FAILURE               : 'SERVICE_GET_SAMPLE_FILE_FAILURE',

};

