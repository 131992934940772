const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        complex_id                                : '',
        complex_name                              : '',
        address                                   : '',
        search_complex_id                         : '',
        search_complex_name                       : '',
        search_address                            : '',
   }))(),
   gpComplexDataList                              : [],
   gpComplexDataListNumRows                       : 0,
   gpComplexLookupList                            : [],
   gpComplexIsDisabled                            : false,
   gpComplexError                                 : null,
   gpComplexIsValid                               : true,
   gpComplexIsFetching                            : false,
   complex_count                                  : 0,
   complex_active_tab                             : 0,
   gpComplexShowModal                             : 'hide',
   gpComplexModalParam                            : [],
   gpComplexLookupShowModal                       : 'hide',
   gpDataFields: new (Record({
        complex_id                                : '',
        complex_name                              : '',
        address                                   : '',
        search_complex_id                         : '',
        search_complex_name                       : '',
        search_address                            : '',
        complex_id_has_error                      : false,
        complex_name_has_error                    : false,
        address_has_error                         : false,
        search_complex_id_has_error               : false,
        search_complex_name_has_error             : false,
        search_address_has_error                  : false,
        complex_id_error_msg                      : false,
        complex_name_error_msg                    : false,
        address_error_msg                         : false,
        search_complex_id_error_msg               : false,
        search_complex_name_error_msg             : false,
        search_address_error_msg                  : false,
   }))(),
 })
 var complexInitialState = Record({
   form: new Form()
 })
export default complexInitialState
