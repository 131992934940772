'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('service_id'): {
        let service_id    = value
        let isValid = true

      if (service_id.length > 0) {
        if (service_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'service_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'service_id_error_msg'], 'ID Perbaikan perlu di isi')
        }

        if (service_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'service_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'service_id_error_msg'], 'ID Perbaikan panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(service_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'service_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'service_id_error_msg'], 'ID Perbaikan tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'service_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'service_id_error_msg'], '')
        }
    }

    case ('service_name'): {
        let service_name    = value
        let isValid = true

        if (service_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'service_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'service_name_error_msg'], 'Jenis Perbaikan perlu di isi')
        }

        if (service_name.length > 40) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'service_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'service_name_error_msg'], 'Jenis Perbaikan panjang maksimumnya 40 karakter.')
        }

        if (!check.validateAlpha(service_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'service_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'service_name_error_msg'], 'Jenis Perbaikan tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'service_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'service_name_error_msg'], '')
        }
    }

    case ('problem'): {
        let problem    = value
        let isValid = true

        if (problem.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'problem_has_error'], true)
            .setIn(['form', 'gpDataFields', 'problem_error_msg'], 'Permasalahan perlu di isi')
        }

        if (problem.length > 200) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'problem_has_error'], true)
            .setIn(['form', 'gpDataFields', 'problem_error_msg'], 'Permasalahan panjang maksimumnya 200 karakter.')
        }

        if (!check.validateAlpha(problem)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'problem_has_error'], true)
            .setIn(['form', 'gpDataFields', 'problem_error_msg'], 'Permasalahan tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'problem_has_error'], false)
            .setIn(['form', 'gpDataFields', 'problem_error_msg'], '')
        }
    }

  }
  return state
}
