import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import ServiceEdit                                     from './edit';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  serviceActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...serviceActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    service: {
      gpDataFields                                : state.service.form.gpDataFields,
      gpServiceIsFetching                         : state.service.form.gpServiceIsFetching,
      service_active_tab                          : state.service.form.service_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsServiceIsFetching                         : true,
      service_id                                  : '',
      service_name                                : '',
      problem                                     : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        service_id                                : nextProps.service.gpDataFields.service_id,
        service_name                              : nextProps.service.gpDataFields.service_name,
        problem                                   : nextProps.service.gpDataFields.problem,
        date_upd                                  : nextProps.service.gpDataFields.date_upd,
        date_add                                  : nextProps.service.gpDataFields.date_add,
        lsServiceIsFetching                       : nextProps.service.gpServiceIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewService(this.props.match.params.service_id);
      this.setState({
        lsServiceIsFetching                       : this.props.service.gpServiceIsFetching,
        service_id                                : this.props.service.gpDataFields.service_id,
        service_name                              : this.props.service.gpDataFields.service_name,
        problem                                   : this.props.service.gpDataFields.problem,
      });
    }


    _editService(id) {
      const data = [{
          service_id      : id,
      }];
      this.props.actions._activateEditService(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onServiceStateChange('service_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
      ]


      const {
        service_active_tab
      } = this.props.service;


      const {
        apartment_id,
        apartment_name,
        employee_id,
        employee_name,
        service_id,
        service_name,
        problem,
        date_add,
        date_upd
      } = this.props.service.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Jasa Perbaikan
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/service/edit/' + service_id}
                onClick = {() => this._editService(service_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Apartment</span><br />
                  {apartment_id} - {apartment_name}</Col>

                  <Col md={8} className='p-2'><span className='font-bold'>ID Pekerja</span><br />
                  {employee_id} - {employee_name}</Col>

                  <Col md={8} className='p-2'><span className='font-bold'>ID Perbaikan</span><br />
                  {service_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Jenis Perbaikan</span><br />
                  {service_name}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Permasalahan</span><br />
                  {problem}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

