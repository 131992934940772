import React                                           from 'react';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import DashboardList                                   from './list';
import {
  Row,
  Icon,
  Breadcrumb,
  Col,
}                                                      from 'antd';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */


class Dashboard extends React.Component {

  render() {
    return (
      <div>
        <div className='header-middle' >
          Dashboard
        </div>
        <div className='content' >
        <Row>
          <Col span={24}>
            <DashboardList />
          </Col>
        </Row>
       </div>
      </div>
    );
  }
}



function mapStateToProps(state) {

}
const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };
