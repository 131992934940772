import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import PropTypes                                       from 'prop-types';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import { NumericFormat }                               from 'react-number-format';
import * as employeeActions                            from './../../_actions/employee.actions';
import * as apartmentActions                           from './../../_actions/apartment.actions';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  RadialBarChart,
  RadialBar,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell
}                                                       from 'recharts';

import {
  Row,
  Card,
  Col,
  Input,
  Button,
  Table,
  Popconfirm,
  Spin
}                                                      from 'antd';

import {
  Icon,
  Grid
}                                                      from 'semantic-ui-react';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#c6d421', '#965df5', '#c25113'];
const RADIAN = Math.PI / 180;

function mapStateToProps(state) {
    return {
     employee: {
       gpEmployeeChart0DataList           : state.employee.form.gpEmployeeChart0DataList,
       gpEmployeeChart0IsFetching         : state.employee.form.gpEmployeeChart0IsFetching,
     },
     apartment: {
       gpApartmentChart0DataList           : state.apartment.form.gpApartmentChart0DataList,
       gpApartmentChart0IsFetching         : state.apartment.form.gpApartmentChart0IsFetching,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...employeeActions,
      ...apartmentActions,
      }, dispatch)
  }
}

  const getPath = (x, y, width, height) => {
    return `M${x},${y + height}
      C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
      C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
      Z`;
  };

  const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke='none' fill={fill}/>;
  };

  TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
  };

  const style = {
    top: 0,
    left: 350,
    lineHeight: '24px'
  };

class DashboardList extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        sEmployeeChart0DataList     : [],
        sEmployeeChart0IsFetching   : true,
        sApartmentChart0DataList     : [],
        sApartmentChart0IsFetching   : true,
    }

    componentWillReceiveProps (nextProps) {
      if (nextProps.employee.gpEmployeeChart0DataList){
        if (nextProps.employee.gpEmployeeChart0DataList !== this.props.employee.gpEmployeeChart0DataList) {
          this.setState({
            sEmployeeChart0DataList     : nextProps.employee.gpEmployeeChart0DataList,
            sEmployeeChart0IsFetching   : nextProps.employee.gpEmployeeChart0IsFetching,
          }, this._fillEmployeeChart0Colors);
        };
      };

      if(nextProps.employee.gpEmployeeChart0IsFetching) {
        if (nextProps.employee.gpEmployeeChart0IsFetching !== this.props.employee.gpEmployeeChart0IsFetching) {
          this.setState({
            sEmployeeChart0IsFetching  : nextProps.employee.gpEmployeeChart0IsFetching,
          });
        };
      };
      if (nextProps.apartment.gpApartmentChart0DataList){
        if (nextProps.apartment.gpApartmentChart0DataList !== this.props.apartment.gpApartmentChart0DataList) {
          this.setState({
            sApartmentChart0DataList     : nextProps.apartment.gpApartmentChart0DataList,
            sApartmentChart0IsFetching   : nextProps.apartment.gpApartmentChart0IsFetching,
          }, this._fillApartmentChart0Colors);
        };
      };

      if(nextProps.apartment.gpApartmentChart0IsFetching) {
        if (nextProps.apartment.gpApartmentChart0IsFetching !== this.props.apartment.gpApartmentChart0IsFetching) {
          this.setState({
            sApartmentChart0IsFetching  : nextProps.apartment.gpApartmentChart0IsFetching,
          });
        };
      };
    };

    /* -------------------------------------------------------------------------- */
    /*  componentdidmount                                                         */
    /* -------------------------------------------------------------------------- */
    componentDidMount(){
      this.props.actions._chart0Employee('count')
      this.props.actions._chart0Apartment('sum','rent_cost')
    }

  _fillEmployeeChart0Colors(){
    var data = this.state.sEmployeeChart0DataList
    var i

    for(i=0; i<data.length; i++){
      data[i]['fill'] = COLORS[i]
    }

    this.setState({
      sEmployeeChart0DataList: data
    })
  }

  _fillApartmentChart0Colors(){
    var data = this.state.sApartmentChart0DataList
    var i

    for(i=0; i<data.length; i++){
      data[i]['fill'] = COLORS[i]
    }

    this.setState({
      sApartmentChart0DataList: data
    })
  }

    render() {
        return (
            <div>
                <div className='dashboard-card-container'>
                  <Row gutter={8}>
                    <Col span={6}>
                      <div className='dashboard-card'>
                        <Grid columns={2}>
                          <Grid.Column>
                            <div style={{fontSize:'14px'}}><b>TOTAL PEKERJA</b></div>
                            <span style={{fontSize:'36px', margin:'0px'}}>
                              {this.state.sEmployeeChart0DataList[0]?.data_result}
                            </span>
                          </Grid.Column>
                          <Grid.Column textAlign='center'>
                            <Icon size='big' color='teal' inverted circular bordered name='writer' />
                          </Grid.Column>
                        </Grid>
                      </div>
                    </Col>

                    <Col span={6}>
                      <div className='dashboard-card'>
                        <Grid columns={2}>
                          <Grid.Column>
                            <div style={{fontSize:'14px'}}><b>TOTAL BIAYA</b></div>
                            <span style={{fontSize:'36px', margin:'0px'}}>
                              <NumericFormat
                                value={this.state.sApartmentChart0DataList[0]?.data_result}
                                thousandSeparator='.'
                                prefix='Rp '
                                decimalSeparator=','
                                decimalScale={2}
                                displayType='text'
                                renderText={(value) =><span style={{fontSize:'22px'}}>{value}</span>}
                              />
                            </span>
                          </Grid.Column>
                          <Grid.Column textAlign='center'>
                            <Icon size='big' color='teal' inverted circular bordered name='book' />
                          </Grid.Column>
                        </Grid>
                      </div>
                    </Col>

                   </Row>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardList);

