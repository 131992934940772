export const rentConstants = {
    ON_RENT_FORM_FIELD_CHANGE                     : 'ON_RENT_FORM_FIELD_CHANGE',
    CHANGE_RENT_FIELD                             : 'CHANGE_RENT_FIELD',
    ON_RENT_STATE_CHANGE                          : 'ON_RENT_STATE_CHANGE',

    RENT_GET_LIST_REQUEST                         : 'RENT_GET_LIST_REQUEST',
    RENT_GET_LIST_SUCCESS                         : 'RENT_GET_LIST_SUCCESS',
    RENT_GET_LIST_FAILURE                         : 'RENT_GET_LIST_FAILURE',

    RENT_VIEW_REQUEST                             : 'RENT_VIEW_REQUEST',
    RENT_VIEW_SUCCESS                             : 'RENT_VIEW_SUCCESS',
    RENT_VIEW_FAILURE                             : 'RENT_VIEW_FAILURE',

    RENT_ADD_REQUEST                              : 'RENT_ADD_REQUEST',
    RENT_ADD_SUCCESS                              : 'RENT_ADD_SUCCESS',
    RENT_ADD_FAILURE                              : 'RENT_ADD_FAILURE',

    RENT_CHANGE_REQUEST                           : 'RENT_CHANGE_REQUEST',
    RENT_CHANGE_SUCCESS                           : 'RENT_CHANGE_SUCCESS',
    RENT_CHANGE_FAILURE                           : 'RENT_CHANGE_FAILURE',

    RENT_FILTER_REQUEST                           : 'RENT_FILTER_REQUEST',
    RENT_FILTER_SUCCESS                           : 'RENT_FILTER_SUCCESS',
    RENT_FILTER_FAILURE                           : 'RENT_FILTER_FAILURE',

    RENT_REMOVE_REQUEST                           : 'RENT_REMOVE_REQUEST',
    RENT_REMOVE_SUCCESS                           : 'RENT_REMOVE_SUCCESS',
    RENT_REMOVE_FAILURE                           : 'RENT_REMOVE_FAILURE',

    RENT_LOOKUP_REQUEST                           : 'RENT_LOOKUP_REQUEST',
    RENT_LOOKUP_SUCCESS                           : 'RENT_LOOKUP_SUCCESS',
    RENT_LOOKUP_FAILURE                           : 'RENT_LOOKUP_FAILURE',

    RENT_SHOW_MODAL                               : 'RENT_SHOW_MODAL',
    RENT_SEND_PARAM                               : 'RENT_SEND_PARAM',
    RENT_INITIALIZE_DATA                          : 'RENT_INITIALIZE_DATA',
    RENT_SHOW_LOOKUP_MODAL                        : 'RENT_SHOW_LOOKUP_MODAL',
    RENT_INIT_SUCCESS                             : 'RENT_INIT_SUCCESS',

    RENT_IMPORT_REQUEST                           : 'RENT_IMPORT_REQUEST',
    RENT_IMPORT_SUCCESS                           : 'RENT_IMPORT_SUCCESS',
    RENT_IMPORT_FAILURE                           : 'RENT_IMPORT_FAILURE',

    RENT_EXPORT_REQUEST                           : 'RENT_EXPORT_REQUEST',
    RENT_EXPORT_SUCCESS                           : 'RENT_EXPORT_SUCCESS',
    RENT_EXPORT_FAILURE                           : 'RENT_EXPORT_FAILURE',

    RENT_COUNT_REQUEST                            : 'RENT_COUNT_REQUEST',
    RENT_COUNT_SUCCESS                            : 'RENT_COUNT_SUCCESS',
    RENT_COUNT_FAILURE                            : 'RENT_COUNT_FAILURE',

    RENT_GET_SAMPLE_FILE_REQUEST                  : 'RENT_GET_SAMPLE_FILE_REQUEST',
    RENT_GET_SAMPLE_FILE_SUCCESS                  : 'RENT_GET_SAMPLE_FILE_SUCCESS',
    RENT_GET_SAMPLE_FILE_FAILURE                  : 'RENT_GET_SAMPLE_FILE_FAILURE',

};

