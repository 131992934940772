import React, { Component }                           from 'react';
import { connect }                                    from 'react-redux';
import { bindActionCreators }                         from 'redux';
import { Link, Redirect }                             from 'react-router-dom';
import { authenticateActions }                        from './../_actions';


function mapStateToProps (state) {
  return {
    authenticate: {
      form                      : state.authenticate.form,
      fields                    : state.authenticate.form.fields,
      isFetching                : state.authenticate.form.isFetching,
      isValid                   : state.authenticate.form.isValid,
    },
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
       ...authenticateActions },
       dispatch)
  }
}


let renderedState = -1;
let renderPasses  = 0;

class Logout extends Component {
    state = {value: 0};

    constructor(props) {
        super(props);
        renderPasses++;
        renderedState = this.state.value;
    }

    componentDidMount(){
      this.props.actions.logout('Log Out');
    }

    render() {
      if (this.state.value === 0) {
        this.setState({value: 1});
      }
      return <div />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)
