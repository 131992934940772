export const employeeConstants = {
    ON_EMPLOYEE_FORM_FIELD_CHANGE                 : 'ON_EMPLOYEE_FORM_FIELD_CHANGE',
    CHANGE_EMPLOYEE_FIELD                         : 'CHANGE_EMPLOYEE_FIELD',
    ON_EMPLOYEE_STATE_CHANGE                      : 'ON_EMPLOYEE_STATE_CHANGE',

    EMPLOYEE_GET_LIST_REQUEST                     : 'EMPLOYEE_GET_LIST_REQUEST',
    EMPLOYEE_GET_LIST_SUCCESS                     : 'EMPLOYEE_GET_LIST_SUCCESS',
    EMPLOYEE_GET_LIST_FAILURE                     : 'EMPLOYEE_GET_LIST_FAILURE',

    EMPLOYEE_VIEW_REQUEST                         : 'EMPLOYEE_VIEW_REQUEST',
    EMPLOYEE_VIEW_SUCCESS                         : 'EMPLOYEE_VIEW_SUCCESS',
    EMPLOYEE_VIEW_FAILURE                         : 'EMPLOYEE_VIEW_FAILURE',

    EMPLOYEE_ADD_REQUEST                          : 'EMPLOYEE_ADD_REQUEST',
    EMPLOYEE_ADD_SUCCESS                          : 'EMPLOYEE_ADD_SUCCESS',
    EMPLOYEE_ADD_FAILURE                          : 'EMPLOYEE_ADD_FAILURE',

    EMPLOYEE_CHANGE_REQUEST                       : 'EMPLOYEE_CHANGE_REQUEST',
    EMPLOYEE_CHANGE_SUCCESS                       : 'EMPLOYEE_CHANGE_SUCCESS',
    EMPLOYEE_CHANGE_FAILURE                       : 'EMPLOYEE_CHANGE_FAILURE',

    EMPLOYEE_FILTER_REQUEST                       : 'EMPLOYEE_FILTER_REQUEST',
    EMPLOYEE_FILTER_SUCCESS                       : 'EMPLOYEE_FILTER_SUCCESS',
    EMPLOYEE_FILTER_FAILURE                       : 'EMPLOYEE_FILTER_FAILURE',

    EMPLOYEE_CHART0_REQUEST                       : 'EMPLOYEE_CHART0_REQUEST',
    EMPLOYEE_CHART0_SUCCESS                       : 'EMPLOYEE_CHART0_SUCCESS',
    EMPLOYEE_CHART0_FAILURE                       : 'EMPLOYEE_CHART0_FAILURE',

    EMPLOYEE_REPORT_000_TO_EXCEL_REQUEST          : 'EMPLOYEE_REPORT000_TO_EXCEL_REQUEST',
    EMPLOYEE_REPORT_000_TO_EXCEL_SUCCESS          : 'EMPLOYEE_REPORT000_TO_EXCEL_SUCCESS',
    EMPLOYEE_REPORT_000_TO_EXCEL_FAILURE          : 'EMPLOYEE_REPORT000_TO_EXCEL_FAILURE',

    EMPLOYEE_REPORT_000_TO_PDF_REQUEST            : 'EMPLOYEE_REPORT000_TO_PDF_REQUEST',
    EMPLOYEE_REPORT_000_TO_PDF_SUCCESS            : 'EMPLOYEE_REPORT000_TO_PDF_SUCCESS',
    EMPLOYEE_REPORT_000_TO_PDF_FAILURE            : 'EMPLOYEE_REPORT000_TO_PDF_FAILURE',

    EMPLOYEE_REPORT_000_TO_HTML_REQUEST           : 'EMPLOYEE_REPORT000_TO_HTML_REQUEST',
    EMPLOYEE_REPORT_000_TO_HTML_SUCCESS           : 'EMPLOYEE_REPORT000_TO_HTML_SUCCESS',
    EMPLOYEE_REPORT_000_TO_HTML_FAILURE           : 'EMPLOYEE_REPORT000_TO_HTML_FAILURE',

    EMPLOYEE_REPORT_001_TO_EXCEL_REQUEST          : 'EMPLOYEE_REPORT001_TO_EXCEL_REQUEST',
    EMPLOYEE_REPORT_001_TO_EXCEL_SUCCESS          : 'EMPLOYEE_REPORT001_TO_EXCEL_SUCCESS',
    EMPLOYEE_REPORT_001_TO_EXCEL_FAILURE          : 'EMPLOYEE_REPORT001_TO_EXCEL_FAILURE',

    EMPLOYEE_REPORT_001_TO_PDF_REQUEST            : 'EMPLOYEE_REPORT001_TO_PDF_REQUEST',
    EMPLOYEE_REPORT_001_TO_PDF_SUCCESS            : 'EMPLOYEE_REPORT001_TO_PDF_SUCCESS',
    EMPLOYEE_REPORT_001_TO_PDF_FAILURE            : 'EMPLOYEE_REPORT001_TO_PDF_FAILURE',

    EMPLOYEE_REPORT_001_TO_HTML_REQUEST           : 'EMPLOYEE_REPORT001_TO_HTML_REQUEST',
    EMPLOYEE_REPORT_001_TO_HTML_SUCCESS           : 'EMPLOYEE_REPORT001_TO_HTML_SUCCESS',
    EMPLOYEE_REPORT_001_TO_HTML_FAILURE           : 'EMPLOYEE_REPORT001_TO_HTML_FAILURE',

    EMPLOYEE_REMOVE_REQUEST                       : 'EMPLOYEE_REMOVE_REQUEST',
    EMPLOYEE_REMOVE_SUCCESS                       : 'EMPLOYEE_REMOVE_SUCCESS',
    EMPLOYEE_REMOVE_FAILURE                       : 'EMPLOYEE_REMOVE_FAILURE',

    EMPLOYEE_LOOKUP_REQUEST                       : 'EMPLOYEE_LOOKUP_REQUEST',
    EMPLOYEE_LOOKUP_SUCCESS                       : 'EMPLOYEE_LOOKUP_SUCCESS',
    EMPLOYEE_LOOKUP_FAILURE                       : 'EMPLOYEE_LOOKUP_FAILURE',

    EMPLOYEE_SHOW_MODAL                           : 'EMPLOYEE_SHOW_MODAL',
    EMPLOYEE_SEND_PARAM                           : 'EMPLOYEE_SEND_PARAM',
    EMPLOYEE_INITIALIZE_DATA                      : 'EMPLOYEE_INITIALIZE_DATA',
    EMPLOYEE_SHOW_LOOKUP_MODAL                    : 'EMPLOYEE_SHOW_LOOKUP_MODAL',
    EMPLOYEE_INIT_SUCCESS                         : 'EMPLOYEE_INIT_SUCCESS',

    EMPLOYEE_IMPORT_REQUEST                       : 'EMPLOYEE_IMPORT_REQUEST',
    EMPLOYEE_IMPORT_SUCCESS                       : 'EMPLOYEE_IMPORT_SUCCESS',
    EMPLOYEE_IMPORT_FAILURE                       : 'EMPLOYEE_IMPORT_FAILURE',

    EMPLOYEE_EXPORT_REQUEST                       : 'EMPLOYEE_EXPORT_REQUEST',
    EMPLOYEE_EXPORT_SUCCESS                       : 'EMPLOYEE_EXPORT_SUCCESS',
    EMPLOYEE_EXPORT_FAILURE                       : 'EMPLOYEE_EXPORT_FAILURE',

    EMPLOYEE_COUNT_REQUEST                        : 'EMPLOYEE_COUNT_REQUEST',
    EMPLOYEE_COUNT_SUCCESS                        : 'EMPLOYEE_COUNT_SUCCESS',
    EMPLOYEE_COUNT_FAILURE                        : 'EMPLOYEE_COUNT_FAILURE',

    EMPLOYEE_GET_SAMPLE_FILE_REQUEST              : 'EMPLOYEE_GET_SAMPLE_FILE_REQUEST',
    EMPLOYEE_GET_SAMPLE_FILE_SUCCESS              : 'EMPLOYEE_GET_SAMPLE_FILE_SUCCESS',
    EMPLOYEE_GET_SAMPLE_FILE_FAILURE              : 'EMPLOYEE_GET_SAMPLE_FILE_FAILURE',

};

