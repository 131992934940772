'use strict'
import {buildingConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/building.initialstate'
import fieldValidation                              from './../_validation/building.field.validation'
import formValidation                               from './../_validation/building.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case buildingConstants.BUILDING_GET_LIST_REQUEST:
    case buildingConstants.BUILDING_LOOKUP_REQUEST:
    case buildingConstants.BUILDING_ADD_REQUEST:
    case buildingConstants.BUILDING_CHANGE_REQUEST:
    case buildingConstants.BUILDING_FILTER_REQUEST:
    case buildingConstants.BUILDING_VIEW_REQUEST:
    case buildingConstants.BUILDING_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpBuildingIsFetching'],  true)

    case buildingConstants.BUILDING_CHANGE_SUCCESS:
    case buildingConstants.BUILDING_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpBuildingIsFetching'],  false)

    case buildingConstants.BUILDING_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpBuildingIsFetching'],  false)
          .setIn(['form', 'building_count'],  action.payload)

    case buildingConstants.BUILDING_FILTER_SUCCESS:
    case buildingConstants.BUILDING_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpBuildingDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpBuildingDataList'], data.rows)
          .setIn(['form', 'gpBuildingDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpBuildingIsFetching'],  false)


  case buildingConstants.BUILDING_LOOKUP_SUCCESS:
    console.log('[building.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpBuildingLookupList'], action.payload)
        .setIn(['form', 'gpBuildingIsFetching'], false)


    case buildingConstants.BUILDING_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'building_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'building_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'total_apartement'],'')
          .setIn(['form', 'gpDataFields',                                                           'complex_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'complex_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'complex_complex_id'],'')

    case buildingConstants.BUILDING_SHOW_MODAL:
      return state
        .setIn(['form', 'gpBuildingShowModal'],  action.payload)


    case buildingConstants.BUILDING_SEND_PARAM:
      return state
        .setIn(['form', 'gpBuildingModalParam'],  action.payload[0])


    case buildingConstants.BUILDING_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'building_id'],                                       action.payload[0].building_id)
          .setIn(['form', 'gpOriginalFields', 'building_id'],                                       action.payload[0].building_id)
          .setIn(['form', 'gpDataFields',     'building_name'],                                     action.payload[0].building_name)
          .setIn(['form', 'gpOriginalFields', 'building_name'],                                     action.payload[0].building_name)
          .setIn(['form', 'gpDataFields',     'total_apartement'],                                  action.payload[0].total_apartement)
          .setIn(['form', 'gpOriginalFields', 'total_apartement'],                                  action.payload[0].total_apartement)
          .setIn(['form', 'gpDataFields',     'complex_id'],                                        action.payload[0].complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_id'],                                        action.payload[0].complex_id)
          .setIn(['form', 'gpDataFields',     'complex_name'],                                      action.payload[0].complex_name)
          .setIn(['form', 'gpOriginalFields', 'complex_name'],                                      action.payload[0].complex_name)
          .setIn(['form', 'gpDataFields', 'complex_complex_id'],                                    action.payload[0].complex_complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_complex_id'],                                action.payload[0].complex_complex_id)
          .setIn(['form', 'gpBuildingIsFetching'],                                                  false)
          return nextRecordState


    case buildingConstants.BUILDING_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'building_id'],                                           '')
          .setIn(['form', 'gpDataFields', 'building_name'],                                         '')
          .setIn(['form', 'gpDataFields', 'total_apartement'],                                      '')
          .setIn(['form', 'gpDataFields', 'building_id_has_error'],                                 false)
          .setIn(['form', 'gpDataFields', 'building_name_has_error'],                               false)
          .setIn(['form', 'gpDataFields', 'total_apartement_has_error'],                            false)

          .setIn(['form', 'gpBuildingIsValid'],                                                     false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('buildingReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'building_id'],                                           '')
          .setIn(['form', 'gpDataFields', 'building_name'],                                         '')
          .setIn(['form', 'gpDataFields', 'total_apartement'],                                      '')
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'complex_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            '')
          .setIn(['form', 'gpBuildingError'],                                                       null)
          return formValidation(nextRecordState, action)


    case buildingConstants.BUILDING_GET_LIST_FAILURE:
    case buildingConstants.BUILDING_LOOKUP_FAILURE:
    case buildingConstants.BUILDING_ADD_FAILURE:
    case buildingConstants.BUILDING_CHANGE_FAILURE:
    case buildingConstants.BUILDING_FILTER_FAILURE:
    case buildingConstants.BUILDING_VIEW_FAILURE:
    case buildingConstants.BUILDING_COUNT_FAILURE:
      console.log('building.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpBuildingIsFetching'], false)
          .setIn(['form', 'gpBuildingError'], action.payload)


   case buildingConstants.ON_BUILDING_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case buildingConstants.ON_BUILDING_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case buildingConstants.CHANGE_BUILDING_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpBuildingError'], null)
      return nextState}

    case buildingConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'building_id'],                                           record.building_id)
          .setIn(['form', 'gpOriginalFields', 'building_id'],                                       record.building_id)
          .setIn(['form', 'gpDataFields', 'building_name'],                                         record.building_name)
          .setIn(['form', 'gpOriginalFields', 'building_name'],                                     record.building_name)
          .setIn(['form', 'gpDataFields', 'total_apartement'],                                      record.total_apartement)
          .setIn(['form', 'gpOriginalFields', 'total_apartement'],                                  record.total_apartement)
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            record.complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_id'],                                        record.complex_id)
          .setIn(['form', 'gpDataFields', 'complex_id_has_error'],                                  record.complex_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'complex_id_has_error'],                              record.complex_id_has_error)
          .setIn(['form', 'gpDataFields', 'complex_name'],                                          record.complex_name)
          .setIn(['form', 'gpOriginalFields', 'complex_name'],                                      record.complex_name)
          .setIn(['form', 'gpDataFields', 'complex_name_has_error'],                                record.complex_name_has_error)
          .setIn(['form', 'gpOriginalFields', 'complex_name_has_error'],                            record.complex_name_has_error)
          .setIn(['form', 'gpDataFields', 'complex_complex_id'],                                    record.complex_complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_complex_id'],                                record.complex_complex_id)

          .setIn(['form', 'gpDataFields', 'building_id_has_error'],                                 record.building_id_has_error)
          .setIn(['form', 'gpDataFields', 'building_name_has_error'],                               record.building_name_has_error)
          .setIn(['form', 'gpDataFields', 'total_apartement_has_error'],                            record.total_apartement_has_error)

          .setIn(['form', 'gpDataFields', 'complex_complex_id_has_error'],                          action.payload.complex_complex_id_has_error)


          .setIn(['form', 'gpBuildingDataList'],                                                    record.gpBuildingDataList)
          .setIn(['form', 'gpBuildingIsDisabled'],                                                  record.gpBuildingIsDisabled)
          .setIn(['form', 'gpBuildingError'],                                                       record.gpBuildingError)
          .setIn(['form', 'gpBuildingIsValid'],                                                     record.gpBuildingIsValid)
          .setIn(['form', 'gpBuildingIsFetching'],                                                  record.gpBuildingIsFetching)
          .setIn(['form', 'building_count'],                                                        record.building_count)
      return next
case buildingConstants.BUILDING_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpBuildingLookupShowModal'],  action.payload)


  }
  return state
}
