'use strict'

import { authenticateConstants }                            from './../_constants';

export default function formValidation (state) {
  console.log('FormValidation >> state.form.state: ' + state.form.state);
  switch (state.form.state) {

    case authenticateConstants.LOGOUT:
      return state.setIn(['form', 'isValid'], true)
    case authenticateConstants.REGISTER:
      if (state.form.fields.fullname !== '' &&
          state.form.fields.email !== '' &&
          state.form.fields.phone !== '' &&
          state.form.fields.password !== '' &&
          !state.form.fields.fullname_has_error &&
          !state.form.fields.email_has_error &&
          !state.form.fields.phone_has_error &&
          !state.form.fields.password_has_error) {
        return state.setIn(['form', 'isValid'], true)
      } else {
        return state.setIn(['form', 'isValid'], false)
      }

    case authenticateConstants.LOGIN:
      if (state.form.fields.email !== '' &&
          state.form.fields.password !== '' &&
          !state.form.fields.email_has_error &&
          !state.form.fields.password_has_error) {
        return state.setIn(['form', 'isValid'], true)
      } else {
        return state.setIn(['form', 'isValid'], false)
      }

    case authenticateConstants.FORGOT_PASSWORD:
      if (state.form.fields.email !== '' &&
        !state.form.fields.email_has_error) {
        return state.setIn(['form', 'isValid'], true)
      } else {
        return state.setIn(['form', 'isValid'], false)
      }

  }

  return state
}
