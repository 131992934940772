'use strict'
import {paymentConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/payment.initialstate'
import fieldValidation                              from './../_validation/payment.field.validation'
import formValidation                               from './../_validation/payment.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case paymentConstants.PAYMENT_GET_LIST_REQUEST:
    case paymentConstants.PAYMENT_LOOKUP_REQUEST:
    case paymentConstants.PAYMENT_ADD_REQUEST:
    case paymentConstants.PAYMENT_CHANGE_REQUEST:
    case paymentConstants.PAYMENT_FILTER_REQUEST:
    case paymentConstants.PAYMENT_VIEW_REQUEST:
    case paymentConstants.PAYMENT_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpPaymentIsFetching'],  true)

    case paymentConstants.PAYMENT_CHANGE_SUCCESS:
    case paymentConstants.PAYMENT_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpPaymentIsFetching'],  false)

    case paymentConstants.PAYMENT_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpPaymentIsFetching'],  false)
          .setIn(['form', 'payment_count'],  action.payload)

    case paymentConstants.PAYMENT_FILTER_SUCCESS:
    case paymentConstants.PAYMENT_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpPaymentDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpPaymentDataList'], data.rows)
          .setIn(['form', 'gpPaymentDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpPaymentIsFetching'],  false)


  case paymentConstants.PAYMENT_LOOKUP_SUCCESS:
    console.log('[payment.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpPaymentLookupList'], action.payload)
        .setIn(['form', 'gpPaymentIsFetching'], false)


    case paymentConstants.PAYMENT_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'payment_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'payment_date'],'')
          .setIn(['form', 'gpDataFields',                                                           'Payment_amount'],'')
          .setIn(['form', 'gpDataFields',                                                           'rent_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'rent_description'],'')
          .setIn(['form', 'gpDataFields',                                                           'rent_rent_id'],'')

    case paymentConstants.PAYMENT_SHOW_MODAL:
      return state
        .setIn(['form', 'gpPaymentShowModal'],  action.payload)


    case paymentConstants.PAYMENT_SEND_PARAM:
      return state
        .setIn(['form', 'gpPaymentModalParam'],  action.payload[0])


    case paymentConstants.PAYMENT_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'payment_id'],                                        action.payload[0].payment_id)
          .setIn(['form', 'gpOriginalFields', 'payment_id'],                                        action.payload[0].payment_id)
          .setIn(['form', 'gpDataFields',     'payment_date'],                                      action.payload[0].payment_date)
          .setIn(['form', 'gpOriginalFields', 'payment_date'],                                      action.payload[0].payment_date)
          .setIn(['form', 'gpDataFields',     'Payment_amount'],                                    action.payload[0].Payment_amount)
          .setIn(['form', 'gpOriginalFields', 'Payment_amount'],                                    action.payload[0].Payment_amount)
          .setIn(['form', 'gpDataFields',     'rent_id'],                                           action.payload[0].rent_id)
          .setIn(['form', 'gpOriginalFields', 'rent_id'],                                           action.payload[0].rent_id)
          .setIn(['form', 'gpDataFields',     'rent_description'],                                  action.payload[0].rent_description)
          .setIn(['form', 'gpOriginalFields', 'rent_description'],                                  action.payload[0].rent_description)
          .setIn(['form', 'gpDataFields', 'rent_rent_id'],                                          action.payload[0].rent_rent_id)
          .setIn(['form', 'gpOriginalFields', 'rent_rent_id'],                                      action.payload[0].rent_rent_id)
          .setIn(['form', 'gpPaymentIsFetching'],                                                   false)
          return nextRecordState


    case paymentConstants.PAYMENT_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'payment_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'payment_date'],                                          '')
          .setIn(['form', 'gpDataFields', 'Payment_amount'],                                        '')
          .setIn(['form', 'gpDataFields', 'payment_id_has_error'],                                  false)
          .setIn(['form', 'gpDataFields', 'payment_date_has_error'],                                false)
          .setIn(['form', 'gpDataFields', 'Payment_amount_has_error'],                              false)

          .setIn(['form', 'gpPaymentIsValid'],                                                      false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('paymentReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'payment_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'payment_date'],                                          '')
          .setIn(['form', 'gpDataFields', 'Payment_amount'],                                        '')
          .setIn(['form', 'gpDataFields', 'rent_id'],                                               '')
          .setIn(['form', 'gpDataFields', 'rent_description'],                                      '')
          .setIn(['form', 'gpDataFields', 'rent_id'],                                               '')
          .setIn(['form', 'gpPaymentError'],                                                        null)
          return formValidation(nextRecordState, action)


    case paymentConstants.PAYMENT_GET_LIST_FAILURE:
    case paymentConstants.PAYMENT_LOOKUP_FAILURE:
    case paymentConstants.PAYMENT_ADD_FAILURE:
    case paymentConstants.PAYMENT_CHANGE_FAILURE:
    case paymentConstants.PAYMENT_FILTER_FAILURE:
    case paymentConstants.PAYMENT_VIEW_FAILURE:
    case paymentConstants.PAYMENT_COUNT_FAILURE:
      console.log('payment.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpPaymentIsFetching'], false)
          .setIn(['form', 'gpPaymentError'], action.payload)


   case paymentConstants.ON_PAYMENT_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case paymentConstants.ON_PAYMENT_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case paymentConstants.CHANGE_PAYMENT_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpPaymentError'], null)
      return nextState}

    case paymentConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'payment_id'],                                            record.payment_id)
          .setIn(['form', 'gpOriginalFields', 'payment_id'],                                        record.payment_id)
          .setIn(['form', 'gpDataFields', 'payment_date'],                                          record.payment_date)
          .setIn(['form', 'gpOriginalFields', 'payment_date'],                                      record.payment_date)
          .setIn(['form', 'gpDataFields', 'Payment_amount'],                                        record.Payment_amount)
          .setIn(['form', 'gpOriginalFields', 'Payment_amount'],                                    record.Payment_amount)
          .setIn(['form', 'gpDataFields', 'rent_id'],                                               record.rent_id)
          .setIn(['form', 'gpOriginalFields', 'rent_id'],                                           record.rent_id)
          .setIn(['form', 'gpDataFields', 'rent_id_has_error'],                                     record.rent_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'rent_id_has_error'],                                 record.rent_id_has_error)
          .setIn(['form', 'gpDataFields', 'rent_description'],                                      record.rent_description)
          .setIn(['form', 'gpOriginalFields', 'rent_description'],                                  record.rent_description)
          .setIn(['form', 'gpDataFields', 'rent_description_has_error'],                            record.rent_description_has_error)
          .setIn(['form', 'gpOriginalFields', 'rent_description_has_error'],                        record.rent_description_has_error)
          .setIn(['form', 'gpDataFields', 'rent_rent_id'],                                          record.rent_rent_id)
          .setIn(['form', 'gpOriginalFields', 'rent_rent_id'],                                      record.rent_rent_id)

          .setIn(['form', 'gpDataFields', 'payment_id_has_error'],                                  record.payment_id_has_error)
          .setIn(['form', 'gpDataFields', 'payment_date_has_error'],                                record.payment_date_has_error)
          .setIn(['form', 'gpDataFields', 'Payment_amount_has_error'],                              record.Payment_amount_has_error)

          .setIn(['form', 'gpDataFields', 'rent_rent_id_has_error'],                                action.payload.rent_rent_id_has_error)


          .setIn(['form', 'gpPaymentDataList'],                                                     record.gpPaymentDataList)
          .setIn(['form', 'gpPaymentIsDisabled'],                                                   record.gpPaymentIsDisabled)
          .setIn(['form', 'gpPaymentError'],                                                        record.gpPaymentError)
          .setIn(['form', 'gpPaymentIsValid'],                                                      record.gpPaymentIsValid)
          .setIn(['form', 'gpPaymentIsFetching'],                                                   record.gpPaymentIsFetching)
          .setIn(['form', 'payment_count'],                                                         record.payment_count)
      return next
case paymentConstants.PAYMENT_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpPaymentLookupShowModal'],  action.payload)


  }
  return state
}
