/* -------------------------------------------------------------------------- */
/* COMPLEX.ACTIONS.JS                                                         */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {complexConstants}                              from '../_constants';
import {complexService}                                from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const complexActions = {
    _addComplex,
    _editComplex,
    _filterComplex,
    _viewComplex,
    _listComplex,
    _removeComplex,
    _lookupComplex,
    _showComplexModalForm,
    _sendComplexFormParam,
    _activateAddComplex,
    _activateEditComplex,
    _activateFilterComplex,
    _onComplexStateChange,
    _onComplexFormFieldChange,
    action_to_import_complex,
    action_to_export_complex,
    action_to_get_sample_complex,
    action_to_count_complex,
};

export function _addComplex(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  complexService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupComplex());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_COMPLEX_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: complexConstants.COMPLEX_ADD_REQUEST         }}
    function success(result)  { return { type: complexConstants.COMPLEX_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: complexConstants.COMPLEX_ADD_FAILURE}}
}

export function _editComplex(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  complexService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupComplex());
          dispatch(_viewComplex(data.complex_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_COMPLEX_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: complexConstants.COMPLEX_CHANGE_REQUEST         }}
    function success(result)  { return { type: complexConstants.COMPLEX_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: complexConstants.COMPLEX_CHANGE_FAILURE, payload: error }}
}

export function _filterComplex(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  complexService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_COMPLEX_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: complexConstants.COMPLEX_FILTER_REQUEST         }}
    function success(result, page)  { return { type: complexConstants.COMPLEX_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: complexConstants.COMPLEX_FILTER_FAILURE}}
}

export function _removeComplex(id) {
      const data = {
        complex_id                                : '', 
        complex_name                              : '', 
        address                                   : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'complex_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        complexService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listComplex(data));
          dispatch(_lookupComplex());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_COMPLEX_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: complexConstants.COMPLEX_REMOVE_REQUEST        }}
    function success(result) { return { type: complexConstants.COMPLEX_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: complexConstants.COMPLEX_REMOVE_FAILURE}}
}

export function _initComplex() {
  return dispatch => {dispatch(success());};
  function success() { return { type: complexConstants.COMPLEX_INIT_SUCCESS}}
}

export function _viewComplex(id) {
  return dispatch => {
      dispatch(request({id}));

      complexService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_COMPLEX_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: complexConstants.COMPLEX_VIEW_REQUEST} }
  function success(result) { return { type: complexConstants.COMPLEX_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: complexConstants.COMPLEX_VIEW_FAILURE}}
}

export function action_to_count_complex() {
  return dispatch => {
      dispatch(request());
      complexService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_COMPLEX_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: complexConstants.COMPLEX_COUNT_REQUEST} }
  function success(result) { return { type: complexConstants.COMPLEX_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: complexConstants.COMPLEX_COUNT_FAILURE}}
}

export function _listComplex(data) {
    return dispatch => {
        dispatch(request(data));
        complexService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_COMPLEX_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: complexConstants.COMPLEX_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: complexConstants.COMPLEX_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: complexConstants.COMPLEX_GET_LIST_FAILURE}}
}
export function _lookupComplex() {
  return dispatch => {
    dispatch(request());
    complexService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_COMPLEX_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: complexConstants.COMPLEX_LOOKUP_REQUEST         }}
    function success(result) { return { type: complexConstants.COMPLEX_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: complexConstants.COMPLEX_LOOKUP_FAILURE}}
}

export function _activateEditComplex(data) {
  console.log('[complex.action][_activateEditComplex][started]');
  const param = [{
      complex_id        : data[0].complex_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showComplexModalForm('show'))
    dispatch(_sendComplexFormParam(param));
  }
}

export function _activateAddComplex() {
  console.log('[complex.action][_activateAddComplex][started]');
  const param = [{
      complex_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initComplex());
    dispatch(_showComplexModalForm('show'));
    dispatch(_sendComplexFormParam(param));
  }
}

export function _activateFilterComplex() {
  console.log('[complex.action][_activateFilterComplex][started]');
  const param = [{
      complex_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initComplex());
    dispatch(_showComplexModalForm('show'));
    dispatch(_sendComplexFormParam(param));
  }
}

export function _onComplexFormFieldChange (field, value) {
  console.log('[complex.action][onComplexFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: complexConstants.ON_COMPLEX_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onComplexStateChange (field, value) {
  return {
    type: complexConstants.ON_COMPLEX_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showComplexModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:complexConstants.COMPLEX_SHOW_MODAL, payload: showModal}}
}

export function _sendComplexFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: complexConstants.COMPLEX_SEND_PARAM, payload: param}}
}

export function _showComplexLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:complexConstants.COMPLEX_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_complex(data) {
      const param = {
        complex_id                                : '', 
        complex_name                              : '', 
        address                                   : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'complex_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  complexService.service_to_import_complex(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listComplex(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_COMPLEX_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_COMPLEX_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_COMPLEX_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: complexConstants.COMPLEX_IMPORT_REQUEST         }}
    function success(result)  { return { type: complexConstants.COMPLEX_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: complexConstants.COMPLEX_IMPORT_FAILURE}}
}

export function action_to_export_complex() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  complexService.service_to_export_complex()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_COMPLEX_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_COMPLEX_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: complexConstants.COMPLEX_EXPORT_REQUEST         }}
    function success(result)  { return { type: complexConstants.COMPLEX_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: complexConstants.COMPLEX_EXPORT_FAILURE}}
}

export function action_to_get_sample_complex() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  complexService.service_to_get_sample_complex()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_COMPLEX_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_COMPLEX_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_COMPLEX_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: complexConstants.COMPLEX_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: complexConstants.COMPLEX_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: complexConstants.COMPLEX_GET_SAMPLE_FILE_FAILURE}}
}

