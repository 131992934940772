import React, { Component }                            from 'react';                                                                      
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import {
  apartmentActions,
}                                                      from './../../_actions';

import {
  Row,
  Card,
  Col,
  Input,
  Button,
  Table,
  Popconfirm,
  Spin,
  Icon,
  Breadcrumb
}                                                      from 'antd';

import {
       FaHome,
}                                                      from 'react-icons/fa';

import styled                                          from 'styled-components'
import ReactToPrint                                    from 'react-to-print';

const Title = styled.h1`
  font-weight: bold;
  font-size: 1.5em;
  text-align: left;
  color: #2b2b2b;
`;

const Description = styled.h2`
  font-size: 1em;
  text-align: left;
  color: #4e4f4f;
`;

const Rectangle = styled.h2`
  padding: 0px;
  min-height: 1em;
  font-size: 1em;
  font-weight: bold;
  color: #000000;
`;

const RecordLine = styled.h2`
  min-height: 1em;
  font-size: 0.8em;
  color: #000000;
`;
const ReportBoldLine = styled.hr`
  border: 1px solid #000000;
  border-collapse: collapse;
  margin: 0px;
`;

const ReportLightLine = styled.hr`
  border: 1px solid #cccccc;
  border-collapse: collapse;
  margin: 0px;
`;

const PageBreak = styled.div`
  page-break-before: always;
`;



function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...apartmentActions,
      }
      , dispatch)
  }
}


function mapStateToProps(state) {
    return {
      apartment: {
          gpApartmentDataList        : state.apartment.form.gpApartmentDataList,
          gpApartmentDataListNumRows : state.apartment.form.gpApartmentDataListNumRows,
          gpApartmentIsFetching      : state.apartment.form.gpApartmentIsFetching,
          gpApartmentShowModal       : state.apartment.form.ggpApartmentShowModal,
        },
      global: {
        currentUser : state.global.currentUser
      }
    };
}


class Report01 extends Component {
  constructor(props) {
      super(props);

  }


        state = {
        search_apartment_id                       : '',
        search_apartment_name                     : '',
        search_total_bedroom                      : '',
        search_rent_cost                          : '',
        lsApartmentIsFetching                     : true,
        lsApartmentDataList                       : [],
        lsPagination                              : {},
        lsPageSize                                : 10,
        lsCurrentPage                             : 1,
        lsSortedBy                                : 'apartment_id',
        lsSortOrder                               : 'descend',
        lsTotalPage                               : 0,
        lsNumberOfRows                            : 0,
        lsSearchText                              : '',
        lsLoadingApartment                        : true,
        direction                                 : null,
        building_id                               : '',
        features                                  : {
          width                                   : 1200,
          height                                  : 800,
        }
        }


  componentWillReceiveProps (nextProps) {

    if (nextProps.apartment.gpApartmentDataList){
      if (nextProps.apartment.gpApartmentDataList !== this.props.apartment.gpApartmentDataList) {
        this.setState({
          lsApartmentDataList              : nextProps.apartment.gpApartmentDataList,
          lsNumberOfRows              : nextProps.apartment.gpApartmentDataListNumRows,
        });

        const lsPagination = { ...this.state.pagination };
        lsPagination.total = nextProps.apartment.gpApartmentDataListNumRows*1;
        this.setState({
          pagination: lsPagination,
        });
      };
    };
  };


  componentDidMount(){
    this._loadApartmentDataSets();
  }


/* -------------------------------------------------------------------------- */
/*  load apartment list                                      */
/* -------------------------------------------------------------------------- */
    _loadApartmentDataSets() {

      const data = {
        apartment_id                              : this.state.search_apartment_id,
        apartment_name                            : this.state.search_apartment_name,
        total_bedroom                             : this.state.search_total_bedroom,
        rent_cost                                 : this.state.search_rent_cost,
        building_id                               : this.state.building_id, 
        page_size                                 : this.state.lsPageSize,
        current_page                              : 1,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
  }

      this.setState({
        lsCurrentPage                             : 1,
      }, this.props.actions._listApartment(data));
  }


  render() {
    return (
      <div  style={{paddingTop:'5px', backgroundColor:'#fff', border:'1px #000000'}}>
      <div className='content' >
        <Row gutter={16}>
          <Col span={21}>
            <Title>PT AMEEMO TECHNOLOGIES</Title>
            <Description>Jl. Menteng Unik, No 109 RT 12/30, Gunung Agung</Description>
            <Description>Phone: 0867888900, Email: adam_yuhuu@yahoo.com</Description>
          </Col>
        </Row>
        <br />
        <Rectangle>
          <Row gutter={16}>
            <Col span={1}>No. </Col>
            <Col span={6}>Kode</Col>
            <Col span={6}>Nama Apartemen</Col>
            <Col span={6}>Jumlah Kamar</Col>
            <Col span={6}>Biaya Sewa</Col>
          </Row>
        </Rectangle>
<ReportBoldLine />
        {
          this.state.lsApartmentDataList.map((e, i) =>
            <div>

            <RecordLine>
              <Row gutter={16}>
                <Col span={1}>{i+1}.</Col>
            <Col span={6}>{e.apartment_id}</Col>
            <Col span={6}>{e.apartment_name}</Col>
            <Col span={6}>{e.total_bedroom}</Col>
            <Col span={6}>{e.rent_cost}</Col>
              </Row>
            </RecordLine>
              <ReportLightLine />
            </div>
          )
        }
        <ReportBoldLine />
        <br />
        <br />
      </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report01);
