'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.lease_detail_id_has_error &&
        state.form.gpDataFields.description !== '' &&
        !state.form.gpDataFields.description_has_error &&
        state.form.gpDataFields.quantity !== '' &&
        !state.form.gpDataFields.quantity_has_error &&
        state.form.gpDataFields.unit_price !== '' &&
        !state.form.gpDataFields.unit_price_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpLease_detailIsValid'], true)
  } else {
    return state.setIn(['form', 'gpLease_detailIsValid'], false)
  }
}
