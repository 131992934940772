export function today() {
    var d     = new Date();
    var month = '' + (d.getMonth() + 1);
    var year  = d.getFullYear();
    var day   = '' + d.getDate();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day     = '0' + day;

    return [day, month, year].join('.');
}

export function create_id(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export function validate_code_reponse(data) {
  if(data=="ERR_401"){
    console.log('::auth.list.err_401');
    throw 'login failure';
  }

  if(data=="ERR_403"){
    console.log('::auth.list.err_401');
    throw 'unauthorized access';
  }
}

