export const leaseConstants = {
    ON_LEASE_FORM_FIELD_CHANGE                    : 'ON_LEASE_FORM_FIELD_CHANGE',
    CHANGE_LEASE_FIELD                            : 'CHANGE_LEASE_FIELD',
    ON_LEASE_STATE_CHANGE                         : 'ON_LEASE_STATE_CHANGE',

    LEASE_GET_LIST_REQUEST                        : 'LEASE_GET_LIST_REQUEST',
    LEASE_GET_LIST_SUCCESS                        : 'LEASE_GET_LIST_SUCCESS',
    LEASE_GET_LIST_FAILURE                        : 'LEASE_GET_LIST_FAILURE',

    LEASE_VIEW_REQUEST                            : 'LEASE_VIEW_REQUEST',
    LEASE_VIEW_SUCCESS                            : 'LEASE_VIEW_SUCCESS',
    LEASE_VIEW_FAILURE                            : 'LEASE_VIEW_FAILURE',

    LEASE_ADD_REQUEST                             : 'LEASE_ADD_REQUEST',
    LEASE_ADD_SUCCESS                             : 'LEASE_ADD_SUCCESS',
    LEASE_ADD_FAILURE                             : 'LEASE_ADD_FAILURE',

    LEASE_CHANGE_REQUEST                          : 'LEASE_CHANGE_REQUEST',
    LEASE_CHANGE_SUCCESS                          : 'LEASE_CHANGE_SUCCESS',
    LEASE_CHANGE_FAILURE                          : 'LEASE_CHANGE_FAILURE',

    LEASE_FILTER_REQUEST                          : 'LEASE_FILTER_REQUEST',
    LEASE_FILTER_SUCCESS                          : 'LEASE_FILTER_SUCCESS',
    LEASE_FILTER_FAILURE                          : 'LEASE_FILTER_FAILURE',

    LEASE_REMOVE_REQUEST                          : 'LEASE_REMOVE_REQUEST',
    LEASE_REMOVE_SUCCESS                          : 'LEASE_REMOVE_SUCCESS',
    LEASE_REMOVE_FAILURE                          : 'LEASE_REMOVE_FAILURE',

    LEASE_LOOKUP_REQUEST                          : 'LEASE_LOOKUP_REQUEST',
    LEASE_LOOKUP_SUCCESS                          : 'LEASE_LOOKUP_SUCCESS',
    LEASE_LOOKUP_FAILURE                          : 'LEASE_LOOKUP_FAILURE',

    LEASE_SHOW_MODAL                              : 'LEASE_SHOW_MODAL',
    LEASE_SEND_PARAM                              : 'LEASE_SEND_PARAM',
    LEASE_INITIALIZE_DATA                         : 'LEASE_INITIALIZE_DATA',
    LEASE_SHOW_LOOKUP_MODAL                       : 'LEASE_SHOW_LOOKUP_MODAL',
    LEASE_INIT_SUCCESS                            : 'LEASE_INIT_SUCCESS',

    LEASE_IMPORT_REQUEST                          : 'LEASE_IMPORT_REQUEST',
    LEASE_IMPORT_SUCCESS                          : 'LEASE_IMPORT_SUCCESS',
    LEASE_IMPORT_FAILURE                          : 'LEASE_IMPORT_FAILURE',

    LEASE_EXPORT_REQUEST                          : 'LEASE_EXPORT_REQUEST',
    LEASE_EXPORT_SUCCESS                          : 'LEASE_EXPORT_SUCCESS',
    LEASE_EXPORT_FAILURE                          : 'LEASE_EXPORT_FAILURE',

    LEASE_COUNT_REQUEST                           : 'LEASE_COUNT_REQUEST',
    LEASE_COUNT_SUCCESS                           : 'LEASE_COUNT_SUCCESS',
    LEASE_COUNT_FAILURE                           : 'LEASE_COUNT_FAILURE',

    LEASE_GET_SAMPLE_FILE_REQUEST                 : 'LEASE_GET_SAMPLE_FILE_REQUEST',
    LEASE_GET_SAMPLE_FILE_SUCCESS                 : 'LEASE_GET_SAMPLE_FILE_SUCCESS',
    LEASE_GET_SAMPLE_FILE_FAILURE                 : 'LEASE_GET_SAMPLE_FILE_FAILURE',

};

