export const apartmentConstants = {
    ON_APARTMENT_FORM_FIELD_CHANGE                : 'ON_APARTMENT_FORM_FIELD_CHANGE',
    CHANGE_APARTMENT_FIELD                        : 'CHANGE_APARTMENT_FIELD',
    ON_APARTMENT_STATE_CHANGE                     : 'ON_APARTMENT_STATE_CHANGE',

    APARTMENT_GET_LIST_REQUEST                    : 'APARTMENT_GET_LIST_REQUEST',
    APARTMENT_GET_LIST_SUCCESS                    : 'APARTMENT_GET_LIST_SUCCESS',
    APARTMENT_GET_LIST_FAILURE                    : 'APARTMENT_GET_LIST_FAILURE',

    APARTMENT_VIEW_REQUEST                        : 'APARTMENT_VIEW_REQUEST',
    APARTMENT_VIEW_SUCCESS                        : 'APARTMENT_VIEW_SUCCESS',
    APARTMENT_VIEW_FAILURE                        : 'APARTMENT_VIEW_FAILURE',

    APARTMENT_ADD_REQUEST                         : 'APARTMENT_ADD_REQUEST',
    APARTMENT_ADD_SUCCESS                         : 'APARTMENT_ADD_SUCCESS',
    APARTMENT_ADD_FAILURE                         : 'APARTMENT_ADD_FAILURE',

    APARTMENT_CHANGE_REQUEST                      : 'APARTMENT_CHANGE_REQUEST',
    APARTMENT_CHANGE_SUCCESS                      : 'APARTMENT_CHANGE_SUCCESS',
    APARTMENT_CHANGE_FAILURE                      : 'APARTMENT_CHANGE_FAILURE',

    APARTMENT_FILTER_REQUEST                      : 'APARTMENT_FILTER_REQUEST',
    APARTMENT_FILTER_SUCCESS                      : 'APARTMENT_FILTER_SUCCESS',
    APARTMENT_FILTER_FAILURE                      : 'APARTMENT_FILTER_FAILURE',

    APARTMENT_CHART0_REQUEST                      : 'APARTMENT_CHART0_REQUEST',
    APARTMENT_CHART0_SUCCESS                      : 'APARTMENT_CHART0_SUCCESS',
    APARTMENT_CHART0_FAILURE                      : 'APARTMENT_CHART0_FAILURE',

    APARTMENT_REPORT_000_TO_EXCEL_REQUEST         : 'APARTMENT_REPORT000_TO_EXCEL_REQUEST',
    APARTMENT_REPORT_000_TO_EXCEL_SUCCESS         : 'APARTMENT_REPORT000_TO_EXCEL_SUCCESS',
    APARTMENT_REPORT_000_TO_EXCEL_FAILURE         : 'APARTMENT_REPORT000_TO_EXCEL_FAILURE',

    APARTMENT_REPORT_000_TO_PDF_REQUEST           : 'APARTMENT_REPORT000_TO_PDF_REQUEST',
    APARTMENT_REPORT_000_TO_PDF_SUCCESS           : 'APARTMENT_REPORT000_TO_PDF_SUCCESS',
    APARTMENT_REPORT_000_TO_PDF_FAILURE           : 'APARTMENT_REPORT000_TO_PDF_FAILURE',

    APARTMENT_REPORT_000_TO_HTML_REQUEST          : 'APARTMENT_REPORT000_TO_HTML_REQUEST',
    APARTMENT_REPORT_000_TO_HTML_SUCCESS          : 'APARTMENT_REPORT000_TO_HTML_SUCCESS',
    APARTMENT_REPORT_000_TO_HTML_FAILURE          : 'APARTMENT_REPORT000_TO_HTML_FAILURE',

    APARTMENT_REMOVE_REQUEST                      : 'APARTMENT_REMOVE_REQUEST',
    APARTMENT_REMOVE_SUCCESS                      : 'APARTMENT_REMOVE_SUCCESS',
    APARTMENT_REMOVE_FAILURE                      : 'APARTMENT_REMOVE_FAILURE',

    APARTMENT_LOOKUP_REQUEST                      : 'APARTMENT_LOOKUP_REQUEST',
    APARTMENT_LOOKUP_SUCCESS                      : 'APARTMENT_LOOKUP_SUCCESS',
    APARTMENT_LOOKUP_FAILURE                      : 'APARTMENT_LOOKUP_FAILURE',

    APARTMENT_SHOW_MODAL                          : 'APARTMENT_SHOW_MODAL',
    APARTMENT_SEND_PARAM                          : 'APARTMENT_SEND_PARAM',
    APARTMENT_INITIALIZE_DATA                     : 'APARTMENT_INITIALIZE_DATA',
    APARTMENT_SHOW_LOOKUP_MODAL                   : 'APARTMENT_SHOW_LOOKUP_MODAL',
    APARTMENT_INIT_SUCCESS                        : 'APARTMENT_INIT_SUCCESS',

    APARTMENT_IMPORT_REQUEST                      : 'APARTMENT_IMPORT_REQUEST',
    APARTMENT_IMPORT_SUCCESS                      : 'APARTMENT_IMPORT_SUCCESS',
    APARTMENT_IMPORT_FAILURE                      : 'APARTMENT_IMPORT_FAILURE',

    APARTMENT_EXPORT_REQUEST                      : 'APARTMENT_EXPORT_REQUEST',
    APARTMENT_EXPORT_SUCCESS                      : 'APARTMENT_EXPORT_SUCCESS',
    APARTMENT_EXPORT_FAILURE                      : 'APARTMENT_EXPORT_FAILURE',

    APARTMENT_COUNT_REQUEST                       : 'APARTMENT_COUNT_REQUEST',
    APARTMENT_COUNT_SUCCESS                       : 'APARTMENT_COUNT_SUCCESS',
    APARTMENT_COUNT_FAILURE                       : 'APARTMENT_COUNT_FAILURE',

    APARTMENT_GET_SAMPLE_FILE_REQUEST             : 'APARTMENT_GET_SAMPLE_FILE_REQUEST',
    APARTMENT_GET_SAMPLE_FILE_SUCCESS             : 'APARTMENT_GET_SAMPLE_FILE_SUCCESS',
    APARTMENT_GET_SAMPLE_FILE_FAILURE             : 'APARTMENT_GET_SAMPLE_FILE_FAILURE',

};

