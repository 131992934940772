'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('tenant_id'): {
        let tenant_id    = value
        let isValid = true

      if (tenant_id.length > 0) {
        if (tenant_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'tenant_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'tenant_id_error_msg'], 'ID Penyewa perlu di isi')
        }

        if (tenant_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'tenant_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'tenant_id_error_msg'], 'ID Penyewa panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(tenant_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'tenant_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'tenant_id_error_msg'], 'ID Penyewa tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'tenant_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'tenant_id_error_msg'], '')
        }
    }

    case ('tenant_name'): {
        let tenant_name    = value
        let isValid = true

        if (tenant_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'tenant_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'tenant_name_error_msg'], 'Nama Tenant perlu di isi')
        }

        if (tenant_name.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'tenant_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'tenant_name_error_msg'], 'Nama Tenant panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(tenant_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'tenant_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'tenant_name_error_msg'], 'Nama Tenant tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'tenant_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'tenant_name_error_msg'], '')
        }
    }

    case ('email'): {
        let email    = value
        let isValid = true

        if (email.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email perlu di isi')
        }

        if (email.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email panjang maksimumnya 50 karakter.')
        }

        if (!check.validateEmail(email)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], false)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], '')
        }
    }

    case ('phone'): {
        let phone    = value
        let isValid = true

        if (phone.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'Phone perlu di isi')
        }

        if (phone.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'Phone panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(phone)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'Phone tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], false)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], '')
        }
    }

    case ('ktp'): {
        let ktp    = value
        let isValid = true

        if (ktp.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'ktp_has_error'], true)
            .setIn(['form', 'gpDataFields', 'ktp_error_msg'], 'Nomor KTP perlu di isi')
        }

        if (ktp.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'ktp_has_error'], true)
            .setIn(['form', 'gpDataFields', 'ktp_error_msg'], 'Nomor KTP panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(ktp)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'ktp_has_error'], true)
            .setIn(['form', 'gpDataFields', 'ktp_error_msg'], 'Nomor KTP tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'ktp_has_error'], false)
            .setIn(['form', 'gpDataFields', 'ktp_error_msg'], '')
        }
    }

  }
  return state
}
