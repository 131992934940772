const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        payment_id                                : '',
        payment_date                              : '',
        Payment_amount                            : '',
        rent_id                                   : '',
        rent_description                          : '',
        search_payment_id                         : '',
        search_Payment_amount                     : '',
        search_rent_id                            : '',
        rent_rent_id                              : '',
   }))(),
   gpPaymentDataList                              : [],
   gpPaymentDataListNumRows                       : 0,
   gpPaymentLookupList                            : [],
   gpPaymentIsDisabled                            : false,
   gpPaymentError                                 : null,
   gpPaymentIsValid                               : true,
   gpPaymentIsFetching                            : false,
   payment_count                                  : 0,
   payment_active_tab                             : 0,
   gpPaymentShowModal                             : 'hide',
   gpPaymentModalParam                            : [],
   gpPaymentLookupShowModal                       : 'hide',
   gpDataFields: new (Record({
        rent_id                                   : '',
        rent_description                          : '',
        payment_id                                : '',
        payment_date                              : '',
        Payment_amount                            : '',
        search_payment_id                         : '',
        search_Payment_amount                     : '',
        rent_rent_id                              : '',
        payment_id_has_error                      : false,
        payment_date_has_error                    : false,
        Payment_amount_has_error                  : false,
        search_payment_id_has_error               : false,
        search_Payment_amount_has_error           : false,
        rent_rent_id_has_error                    : false,
        payment_id_error_msg                      : false,
        payment_date_error_msg                    : false,
        Payment_amount_error_msg                  : false,
        search_payment_id_error_msg               : false,
        search_Payment_amount_error_msg           : false,
        rent_rent_id_error_msg                    : false,
        rent_id_has_error                         : false,
        rent_id_error_msg                         : false,
        search_rent_id                            : '',
        rent_id                                   : '',
   }))(),
 })
 var paymentInitialState = Record({
   form: new Form()
 })
export default paymentInitialState
