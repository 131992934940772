/* -------------------------------------------------------------------------- */
/* USERS.ACTIONS.JS                                                           */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {usersConstants}                                from '../_constants';
import {usersService}                                  from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const usersActions = {
    _addUsers,
    _editUsers,
    _filterUsers,
    _viewUsers,
    _listUsers,
    _removeUsers,
    _lookupUsers,
    _showUsersModalForm,
    _sendUsersFormParam,
    _activateAddUsers,
    _activateEditUsers,
    _activateFilterUsers,
    _onUsersStateChange,
    _onUsersFormFieldChange,
    action_to_import_users,
    action_to_export_users,
    action_to_get_sample_users,
    action_to_count_users,
};

export function _addUsers(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  usersService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupUsers());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_USERS_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: usersConstants.USERS_ADD_REQUEST         }}
    function success(result)  { return { type: usersConstants.USERS_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: usersConstants.USERS_ADD_FAILURE}}
}

export function _editUsers(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  usersService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupUsers());
          dispatch(_viewUsers(data.id_user));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_USERS_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: usersConstants.USERS_CHANGE_REQUEST         }}
    function success(result)  { return { type: usersConstants.USERS_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: usersConstants.USERS_CHANGE_FAILURE, payload: error }}
}

export function _filterUsers(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  usersService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_USERS_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: usersConstants.USERS_FILTER_REQUEST         }}
    function success(result, page)  { return { type: usersConstants.USERS_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: usersConstants.USERS_FILTER_FAILURE}}
}

export function _removeUsers(id) {
      const data = {
        id_user                                   : '', 
        fullname                                  : '', 
        phone                                     : '', 
        email                                     : '', 
        passwd                                    : '', 
        id_userlevel                              : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'id_user',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        usersService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listUsers(data));
          dispatch(_lookupUsers());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_USERS_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: usersConstants.USERS_REMOVE_REQUEST        }}
    function success(result) { return { type: usersConstants.USERS_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: usersConstants.USERS_REMOVE_FAILURE}}
}

export function _initUsers() {
  return dispatch => {dispatch(success());};
  function success() { return { type: usersConstants.USERS_INIT_SUCCESS}}
}

export function _viewUsers(id) {
  return dispatch => {
      dispatch(request({id}));

      usersService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_USERS_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: usersConstants.USERS_VIEW_REQUEST} }
  function success(result) { return { type: usersConstants.USERS_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: usersConstants.USERS_VIEW_FAILURE}}
}

export function action_to_count_users() {
  return dispatch => {
      dispatch(request());
      usersService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_USERS_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: usersConstants.USERS_COUNT_REQUEST} }
  function success(result) { return { type: usersConstants.USERS_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: usersConstants.USERS_COUNT_FAILURE}}
}

export function _listUsers(data) {
    return dispatch => {
        dispatch(request(data));
        usersService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_USERS_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: usersConstants.USERS_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: usersConstants.USERS_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: usersConstants.USERS_GET_LIST_FAILURE}}
}
export function _lookupUsers() {
  return dispatch => {
    dispatch(request());
    usersService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_USERS_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: usersConstants.USERS_LOOKUP_REQUEST         }}
    function success(result) { return { type: usersConstants.USERS_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: usersConstants.USERS_LOOKUP_FAILURE}}
}

export function _activateEditUsers(data) {
  console.log('[users.action][_activateEditUsers][started]');
  const param = [{
      id_user        : data[0].id_user,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showUsersModalForm('show'))
    dispatch(_sendUsersFormParam(param));
  }
}

export function _activateAddUsers() {
  console.log('[users.action][_activateAddUsers][started]');
  const param = [{
      id_user        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initUsers());
    dispatch(_showUsersModalForm('show'));
    dispatch(_sendUsersFormParam(param));
  }
}

export function _activateFilterUsers() {
  console.log('[users.action][_activateFilterUsers][started]');
  const param = [{
      id_user        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initUsers());
    dispatch(_showUsersModalForm('show'));
    dispatch(_sendUsersFormParam(param));
  }
}

export function _onUsersFormFieldChange (field, value) {
  console.log('[users.action][onUsersFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: usersConstants.ON_USERS_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onUsersStateChange (field, value) {
  return {
    type: usersConstants.ON_USERS_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showUsersModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:usersConstants.USERS_SHOW_MODAL, payload: showModal}}
}

export function _sendUsersFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: usersConstants.USERS_SEND_PARAM, payload: param}}
}

export function _showUsersLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:usersConstants.USERS_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_users(data) {
      const param = {
        id_user                                   : '', 
        fullname                                  : '', 
        phone                                     : '', 
        email                                     : '', 
        passwd                                    : '', 
        id_userlevel                              : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'id_user',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  usersService.service_to_import_users(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listUsers(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_USERS_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_USERS_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_USERS_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: usersConstants.USERS_IMPORT_REQUEST         }}
    function success(result)  { return { type: usersConstants.USERS_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: usersConstants.USERS_IMPORT_FAILURE}}
}

export function action_to_export_users() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  usersService.service_to_export_users()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_USERS_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_USERS_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: usersConstants.USERS_EXPORT_REQUEST         }}
    function success(result)  { return { type: usersConstants.USERS_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: usersConstants.USERS_EXPORT_FAILURE}}
}

export function action_to_get_sample_users() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  usersService.service_to_get_sample_users()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_USERS_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_USERS_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_USERS_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: usersConstants.USERS_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: usersConstants.USERS_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: usersConstants.USERS_GET_SAMPLE_FILE_FAILURE}}
}

