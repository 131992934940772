'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('employee_id'): {
        let employee_id    = value
        let isValid = true

      if (employee_id.length > 0) {
        if (employee_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'employee_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'employee_id_error_msg'], 'ID Pekerja perlu di isi')
        }

        if (employee_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'employee_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'employee_id_error_msg'], 'ID Pekerja panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(employee_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'employee_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'employee_id_error_msg'], 'ID Pekerja tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'employee_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'employee_id_error_msg'], '')
        }
    }

    case ('employee_name'): {
        let employee_name    = value
        let isValid = true

        if (employee_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'employee_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'employee_name_error_msg'], 'Nama Pekerja perlu di isi')
        }

        if (employee_name.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'employee_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'employee_name_error_msg'], 'Nama Pekerja panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(employee_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'employee_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'employee_name_error_msg'], 'Nama Pekerja tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'employee_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'employee_name_error_msg'], '')
        }
    }

  }
  return state
}
