import { authHeader,
         authHeaderNoContentType
       } from '../_applib/_helpers';
import * as CONFIG                                    from './../_applib/_settings/config';
import {
  authenticateActions
}                                                     from '../_actions';

export const buildingService = {
   _add,
   _edit,
   _filter,
   _view,
   _count,
   _list,
   _lookup,
   _remove,
   service_to_export_building,
   service_to_import_building,
   service_to_get_sample_building,
}

const targetURL       = CONFIG.URL + 'building';
const download        = require('downloadjs');

function _add(data) {
  const options = {
    method    : 'POST',
    headers   : { ...authHeader()},
    body      : JSON.stringify(data)
  };
  return fetch(targetURL, options)
    .then(_handleResponse)
    .then(_returnResponse);
};

function _edit(data) {
  const options = {
    method    : 'PATCH',
    headers   : { ...authHeader()},
    body      : JSON.stringify(data)
  };
  return fetch(targetURL +
      '/' + data.building_id, options)
    .then(_handleResponse)
    .then(_returnResponse);
};

function _filter(data) {
  const options = {
    method    : 'POST',
    headers   : { ...authHeader()},
    body      : JSON.stringify(data)
  };
  return fetch(targetURL, options)
    .then(_handleResponse)
    .then(_returnResponse);
};

function _remove(id) {
  const options = _requestHeader('DELETE');
  return fetch(targetURL  + '/' + id, options)
    .then(_handleResponse)
    .then(_returnResponse);
}


function _view(id) {
  const options = _requestHeader('GET');
  let path = targetURL + '/' + id;
  return fetch(path, options)
    .then(_handleResponse)
    .then(_returnResponse);
}

function _count() {
  const options = _requestHeader('GET');
  return fetch(targetURL +
      '?type=count', options)
  .then(_handleResponse)
  .then(_returnResponse);
}

function _list (data) {
  const options = _requestHeader('GET');
  let searchText = ''; //searchText.replace(/ /g,'_');
       console.log('building.service.building_id: ' + JSON.stringify(data.building_id));
       console.log('building.service.building_name: ' + JSON.stringify(data.building_name));
       console.log('building.service.total_apartement: ' + JSON.stringify(data.total_apartement));
  return fetch(targetURL +
      '?type=list' +
      '&pagesize=' + data.page_size +
      '&currentpage=' + data.current_page +
      '&sortfield=' + data.sort_field +
      '&building_id=' + data.building_id + 
      '&building_name=' + data.building_name + 
      '&total_apartement=' + data.total_apartement + 
      '&complex_id=' + data.complex_id + 
      '&sortorder=' + data.sort_order, options)
  .then(_handleResponse)
  .then(_returnResponse);
}

function _lookup() {
  const options = _requestHeader('GET');
  return fetch(targetURL + '_lookup', options)
    .then(_handleResponse)
    .then(_returnResponse);
  }

async function  service_to_export_building(){
  const options = _requestHeader('GET');
  const res = await fetch(targetURL + '_export', options);
  const blob = await res.blob();
  const today = new Date();

  const file_date = [
    today.getDate().toString().padStart(2,'0'),
    (today.getMonth() + 1).toString().padStart(2,'0'),
    today.getFullYear(),
  ].join('_');

  const file_name = 'building_' + file_date + '.xlsx';
  download(blob, file_name);
  return [{'status':true}];
}

function service_to_import_building(data) {
  const options = {
    method    : 'POST',
    headers   : { ...authHeaderNoContentType()},
    body      : data
  };
  return fetch(targetURL + '_import', options)
    .then(_handleResponse)
    .then(_returnResponse);
}

async function service_to_get_sample_building(){
  const options = _requestHeader('GET');
  const res = await fetch(targetURL + '_get_sample_building', options);
  const blob = await res.blob();

  const file_name = 'building.csv';
  download(blob, file_name);
  return [{'status':true}];
}

function _requestHeader(type) {
  const header = {
    method  : type,
    headers : { ...authHeader()},
  };

  return header;
}

function _handleResponse(response) {
  return response.json();
}

function _returnResponse(response) {
  if(response.code=='E.AUT.001'){
    console.log('[DEBUG] Login Expired');
    console.log('[DEBUG] Response: ' + JSON.stringify(response));
    authenticateActions.logout('Session Expired');
  } else {
    return response;
  }
}
