/* -------------------------------------------------------------------------- */
/* AUTHENTICATE.SERVICE.JS                                                    */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import { authenticateConstants }                       from './../_constants';
import { authHeader }                                  from './../_applib/_helpers';
import * as CONFIG                                     from './../_applib/_settings/config';


export const authenticateService = {
    login,
    forgot,
    register,
    getAll,
    getById,
    update,
    delete: _delete
};

function login(email, passwd) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, passwd })
    };

    return fetch(CONFIG.URL + 'login', requestOptions)
        .then(response => {
            CONFIG.LOG('CONFIG.NOTIFICATION[001]: ' + CONFIG.NOTIFICATION['LOGIN_FAILED'])
            console.log('login::response:' + JSON.stringify(response));
            if (!response.ok) {
                console.log('test 1');
                return Promise.reject(response.statusText);
            }
            return response.json();
        })
        .then(user => {
            console.log('CONFIG.NOTIFICATION[001]: ' + CONFIG.NOTIFICATION['LOGIN_SUCCESS'])
            if(CONFIG.NOTIFICATION['LOGIN_SUCCESS']){
              CONFIG.LOG('made it');
            }

            // login successful if there's a jwt token in the response
            console.log('user:' + user + ', token:' + user.token);
            if (user && user.token) {
                console.log('test 2');
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }
            console.log('login::user:'+JSON.stringify(user));
            return user;
        });
}

function forgot(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(CONFIG.URL + 'forgot/', requestOptions).then(_handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('users.service::getAll:' + JSON.stringify(requestOptions));
    return fetch(CONFIG.URL + 'users/users', requestOptions).then(_handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(CONFIG.URL + 'users/users/id/' + id, requestOptions).then(_handleResponse);
}

function register(data) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    return fetch(CONFIG.URL + 'register/', requestOptions).then(_handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch('/users/' + user.id, requestOptions).then(_handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(CONFIG.URL + 'users/users/id/' + id, requestOptions).then(_handleResponse);
}

function _handleResponse(response) {
    if (!response.status) {
        return Promise.reject(response.statusText);
    }
    return response.json();
}

