// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as usersActions                               from '../_actions/users.actions';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     users: {
       gpUsersShowModal                           : state.users.form.gpUsersShowModal,
       gpUsersModalParam                          : state.users.form.gpUsersModalParam,
       gpUsersIsFetching                          : state.users.form.gpUsersIsFetching,
       gpDataFields                               : state.users.form.gpDataFields,
       gpUsersIsValid                             : state.users.form.gpUsersIsValid,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...usersActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editUsers(this.props.match.params.id_user));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addUsers());
      }

    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.users.gpUsersModalParam) {
        if (nextProps.users.gpUsersModalParam !== this.props.users.gpUsersModalParam) {
          console.log('Information: gpUsersModalParam props changed');
          if(nextProps.users.gpUsersModalParam['trxType']==='edt') {
            this.setState({
              id_user      : nextProps.users.gpUsersModalParam['id_user'],
              trxType          : nextProps.users.gpUsersModalParam['trxType'],
            }, this._editUsers(nextProps.users.gpUsersModalParam['id_user']));
          };

          if(nextProps.users.gpUsersModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.users.gpUsersModalParam['trxType'],
            }, this._addUsers());
          };

          if(nextProps.users.gpUsersModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.users.gpUsersModalParam['trxType'],
            }, this._filterUsers());
          };

        };
      };

      if(nextProps.users.gpUsersShowModal) {
        if (nextProps.users.gpUsersShowModal !== this.props.users.gpUsersShowModal) {
          console.log('Information: gpUsersShowModal props changed');
          if(nextProps.users.gpUsersShowModal==='show'){
            this.setState({
              lsUsersShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.users.gpUsersShowModal==='hide'){
            this.setState({
              lsUsersShowModal: false,
            });
          }
        };
      };

      this.setState({
        id_user                                   : nextProps.users.gpDataFields.id_user,
        fullname                                  : nextProps.users.gpDataFields.fullname,
        phone                                     : nextProps.users.gpDataFields.phone,
        email                                     : nextProps.users.gpDataFields.email,
        passwd                                    : nextProps.users.gpDataFields.passwd,
        active                                    : nextProps.users.gpDataFields.active,
        administrator                             : nextProps.users.gpDataFields.administrator,
        id_userlevel                              : nextProps.users.gpDataFields.id_userlevel,
        last_login_date                           : nextProps.users.gpDataFields.last_login_date,
        login_attempt                             : nextProps.users.gpDataFields.login_attempt,
        expired_date                              : nextProps.users.gpDataFields.expired_date,
        date_add                                  : nextProps.users.gpDataFields.date_add,
        date_upd                                  : nextProps.users.gpDataFields.date_upd,
      });

}
_addUsersOnServer() {
    var id1 = this.state.id_user.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(100);
    } else {
      id3 = this.state.id_user;
    }
    const data = {
        id_user                                   : id3,
        fullname                                  : this.state.fullname,
        phone                                     : this.state.phone,
        email                                     : this.state.email,
        passwd                                    : this.state.passwd,
        active                                    : this.state.active,
        administrator                             : this.state.administrator,
        id_userlevel                              : this.state.id_userlevel,
        last_login_date                           : this.state.last_login_date,
        login_attempt                             : this.state.login_attempt,
        expired_date                              : this.state.expired_date,
    }
    this.props.actions._addUsers(data);
    this.goBack();
}

_editUsersOnServer() {
    const data = {
        id_user                                   : this.state.id_user,
        fullname                                  : this.state.fullname,
        phone                                     : this.state.phone,
        email                                     : this.state.email,
        passwd                                    : this.state.passwd,
        active                                    : this.state.active,
        administrator                             : this.state.administrator,
        id_userlevel                              : this.state.id_userlevel,
        last_login_date                           : this.state.last_login_date,
        login_attempt                             : this.state.login_attempt,
        expired_date                              : this.state.expired_date,
    }
    this.props.actions._editUsers(data);
    this.goBack();
}

_filterUsersOnServer() {
    const data = {
        id_user                                   : this.state.id_user,
        fullname                                  : this.state.fullname,
        phone                                     : this.state.phone,
        email                                     : this.state.email,
        passwd                                    : this.state.passwd,
        active                                    : this.state.active,
        administrator                             : this.state.administrator,
        id_userlevel                              : this.state.id_userlevel,
        last_login_date                           : this.state.last_login_date,
        login_attempt                             : this.state.login_attempt,
        expired_date                              : this.state.expired_date,
    }
    this.props.actions._filterUsers(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit users                                                              */
/* -------------------------------------------------------------------------- */
_editUsers(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewUsers(id);
}

/* -------------------------------------------------------------------------- */
/*  add new users                                     
/* -------------------------------------------------------------------------- */
_addUsers() {
    let today = GF.today();
    this.props.actions._onUsersFormFieldChange('id_user', '')
    this.props.actions._onUsersFormFieldChange('fullname', '')
    this.props.actions._onUsersFormFieldChange('phone', '')
    this.props.actions._onUsersFormFieldChange('email', '')
    this.props.actions._onUsersFormFieldChange('passwd', '')
    this.props.actions._onUsersFormFieldChange('active', 0)
    this.props.actions._onUsersFormFieldChange('administrator', 0)
    this.props.actions._onUsersFormFieldChange('id_userlevel', '')
    this.props.actions._onUsersFormFieldChange('last_login_date', '')
    this.props.actions._onUsersFormFieldChange('login_attempt', 0)
    this.props.actions._onUsersFormFieldChange('expired_date', '')
    this.props.actions._onUsersFormFieldChange('last_login_date', today)
    this.props.actions._onUsersFormFieldChange('expired_date', today)
    this.props.actions._initUsers();

    this.setState({
         id_user                                  : '',
         fullname                                 : '',
         phone                                    : '',
         email                                    : '',
         passwd                                   : '',
         active                                   : 0,
         administrator                            : 0,
         id_userlevel                             : '',
         last_login_date                          : today,
         login_attempt                            : 0,
         expired_date                             : today,
         isIDDisabled                             : false,
         lsUsersDetail                            : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
}

/* -------------------------------------------------------------------------- */
/*  filter users                                     
/* -------------------------------------------------------------------------- */
_filterUsers() {

    let today = GF.today();
    this.setState({
         id_user                                  : '',
         fullname                                 : '',
         phone                                    : '',
         email                                    : '',
         passwd                                   : '',
         active                                   : 0,
         administrator                            : 0,
         id_userlevel                             : '',
         last_login_date                          : today,
         login_attempt                            : 0,
         expired_date                             : today,
         lsUsersDetail                            : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showUsersModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addUsersOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editUsersOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterUsersOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showUsersModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[users.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onUsersFormFieldChange(field, value)
}

onLast_login_dateChange = (value) => {
  const lvValue   = moment(value, dateFormat).format(dateFormat);
  const lvField   = 'last_login_date';
     if (lvValue !== '') {
       this.props.actions._onUsersFormFieldChange(lvField, lvValue)
     }
}

onLogin_attemptChange = (value) => {
  const lvValue   = value;
  const lvField   = 'login_attempt';
     if (lvValue !== '') {
       this.props.actions._onUsersFormFieldChange(lvField, lvValue)
     }
}

onExpired_dateChange = (value) => {
  const lvValue   = moment(value, dateFormat).format(dateFormat);
  const lvField   = 'expired_date';
     if (lvValue !== '') {
       this.props.actions._onUsersFormFieldChange(lvField, lvValue)
     }
}

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsUsersShowModal,
    } = this.state;


     const {
       id_user,
       fullname,
       phone,
       email,
       passwd,
       active,
       administrator,
       id_userlevel,
       last_login_date,
       login_attempt,
       expired_date,
       date_add,
       date_upd,
       id_user_has_error,
       fullname_has_error,
       phone_has_error,
       email_has_error,
       passwd_has_error,
       active_has_error,
       administrator_has_error,
       id_userlevel_has_error,
       last_login_date_has_error,
       login_attempt_has_error,
       expired_date_has_error,
       date_add_has_error,
       date_upd_has_error,
       id_user_error_msg,
       fullname_error_msg,
       phone_error_msg,
       email_error_msg,
       passwd_error_msg,
       active_error_msg,
       administrator_error_msg,
       id_userlevel_error_msg,
       last_login_date_error_msg,
       login_attempt_error_msg,
       expired_date_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.users.gpDataFields;


const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Users'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add users start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add users body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Pengguna
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={8}>
          <b>ID User</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='id_user'
              placeholder='[AUTO]'
              value={id_user}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {id_user_has_error?<div style={{color:'lightcoral'}}>{id_user_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Nama Lengkap</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='fullname'
              placeholder='Masukan Nama Lengkap'
              value={fullname}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {fullname_has_error?<div style={{color:'lightcoral'}}>{fullname_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>No. Telepon</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='phone'
              placeholder='Masukan No. Telepon'
              value={phone}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {phone_has_error?<div style={{color:'lightcoral'}}>{phone_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Email</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='email'
              placeholder='Masukan Email'
              value={email}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {email_has_error?<div style={{color:'lightcoral'}}>{email_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Kata Kunci</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='passwd'
              placeholder='Masukan Kata Kunci'
              value={passwd}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {passwd_has_error?<div style={{color:'lightcoral'}}>{passwd_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Aktif</b><br />
          <div className='spacer' />
          <NumericFormat
            value={active}
            customInput={Input}
            name = 'active'
            disabled={false}
            placeholder='Masukan Aktif'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onUsersFormFieldChange('active', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {active_has_error?<div style={{color:'lightcoral'}}>{active_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Admin</b><br />
          <div className='spacer' />
          <NumericFormat
            value={administrator}
            customInput={Input}
            name = 'administrator'
            disabled={false}
            placeholder='Masukan Admin'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onUsersFormFieldChange('administrator', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {administrator_has_error?<div style={{color:'lightcoral'}}>{administrator_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>ID UserLevel</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='id_userlevel'
              placeholder='Masukan ID UserLevel'
              value={id_userlevel}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {id_userlevel_has_error?<div style={{color:'lightcoral'}}>{id_userlevel_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Tanggal Login Terakhir</b><br />
          <div className='spacer' />
          <DatePicker
              placeholder='Pilih Tanggal'
              disabled={false}
              onChange={this.onLast_login_dateChange.bind(this)}
              value={moment(last_login_date,dateFormat)}
              format={dateFormat}
          />

          {last_login_date_has_error?<div style={{color:'lightcoral'}}>{last_login_date_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Percobaan Login</b><br />
          <div className='spacer' />
          <NumericFormat
            value={login_attempt}
            customInput={Input}
            name = 'login_attempt'
            disabled={false}
            placeholder='Masukan Percobaan Login'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onUsersFormFieldChange('login_attempt', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {login_attempt_has_error?<div style={{color:'lightcoral'}}>{login_attempt_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Tanggal Expired</b><br />
          <div className='spacer' />
          <DatePicker
              placeholder='Pilih Tanggal'
              disabled={false}
              onChange={this.onExpired_dateChange.bind(this)}
              value={moment(expired_date,dateFormat)}
              format={dateFormat}
          />

          {expired_date_has_error?<div style={{color:'lightcoral'}}>{expired_date_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
        </Row>
        <div className='spacer' />
        <br />
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.users.gpUsersIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add users body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add users End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
