import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import { history }                                     from './../_helpers';

import {
  employeeActions,
  apartmentActions,
}                                                      from './../../_actions';

import {
  Row,
  Card,
  Col,
  Input,
  Button,
  Table,
  Popconfirm,
  Spin,
  Icon
}                                                      from 'antd';

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...employeeActions,
      ...apartmentActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
    return {
      employee: {
          gpEmployeeReport000Path        : state.employee.form.gpEmployeeReport000Path,
          gpEmployeeReport000IsFetching  : state.employee.form.gpEmployeeReport000IsFetching,
          gpEmployeeReport001Path        : state.employee.form.gpEmployeeReport001Path,
          gpEmployeeReport001IsFetching  : state.employee.form.gpEmployeeReport001IsFetching,
        },
      apartment: {
          gpApartmentReport000Path        : state.apartment.form.gpApartmentReport000Path,
          gpApartmentReport000IsFetching  : state.apartment.form.gpApartmentReport000IsFetching,
        },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

class ReportList extends Component {
    constructor(props) {
        super(props);

        this.columns = [{
          title: 'Nama Laporan',
          dataIndex: 'name',
          sorter: true,
          width: '%',
          },{
          title: 'Deskripsi',
          dataIndex: 'description',
          sorter: true,
          width: '%',
          },{
          title: 'Aksi',
          dataIndex: 'operation',
          render: (text, record) => {
            return (
              <div>
                <Button
                  type='primary'
                  className='row-action-button'
                  onClick={() => this.getReport(record.id_report)}
                  icon='printer' />
                <Button
                  type='primary'
                  className='row-action-button'
                  onClick={() => this.getExcelReport(record.id_report)}
                  icon='file-excel' />
                <Button
                  type='primary'
                  className='row-action-button'
                  onClick={() => this.getPDFReport(record.id_report)}
                  icon='file-pdf' />
              </div>
            );
          },
        }];

        this.getExcelReport = this.getExcelReport.bind(this);
        this.getPDFReport   = this.getPDFReport.bind(this);
        this.getReport      = this.getReport.bind(this);
    }

    state = {
      lsReportDataList  : [
                            {'id_report':'employee000','name':'Daftar Pekerja Kompleks', 'description': 'Daftar Pekerja Kompleks'},
                            {'id_report':'employee001','name':'Daftar Nama Pegawai', 'description': 'Daftar Nama Pegawai'},
                            {'id_report':'apartment000','name':'Daftar Apartemen', 'description': 'Daftar Apartemen'},
                           ],
      lsPagination       : {},
      lsPageSize         : 10,
      lsCurrentPage      : 1,
      lsSortedBy         : 'id_report',
      lsSortOrder        : 'descend',
      lsNumberOfRows     : 0,
      lsSearchText       : '',
      lsLoadingUserlevel  : true,
    }

    componentWillReceiveProps (nextProps) {
      if (nextProps.employee.gpEmployeeReport000Path){
        if (nextProps.employee.gpEmployeeReport000Path !== this.props.employee.gpEmployeeReport000Path) {
          this.setState({
            sEmployeeReport000Path        : nextProps.employee.gpEmployeeReport000Path,
            sEmployeeReport000IsFetching  : nextProps.employee.gpEmployeeReport000IsFetching,
          });
        };
      };

      if(nextProps.employee.gpEmployeeReport000IsFetching) {
        if (nextProps.employee.gpEmployeeReport000IsFetching !== this.props.employee.gpEmployeeReport000IsFetching) {
          this.setState({
            sEmployeeReport000IsFetching  : nextProps.employee.gpEmployeeReport000IsFetching,
          });
        };
      };

      if (nextProps.employee.gpEmployeeReport001Path){
        if (nextProps.employee.gpEmployeeReport001Path !== this.props.employee.gpEmployeeReport001Path) {
          this.setState({
            sEmployeeReport001Path        : nextProps.employee.gpEmployeeReport001Path,
            sEmployeeReport001IsFetching  : nextProps.employee.gpEmployeeReport001IsFetching,
          });
        };
      };

      if(nextProps.employee.gpEmployeeReport001IsFetching) {
        if (nextProps.employee.gpEmployeeReport001IsFetching !== this.props.employee.gpEmployeeReport001IsFetching) {
          this.setState({
            sEmployeeReport001IsFetching  : nextProps.employee.gpEmployeeReport001IsFetching,
          });
        };
      };

      if (nextProps.apartment.gpApartmentReport000Path){
        if (nextProps.apartment.gpApartmentReport000Path !== this.props.apartment.gpApartmentReport000Path) {
          this.setState({
            sApartmentReport000Path        : nextProps.apartment.gpApartmentReport000Path,
            sApartmentReport000IsFetching  : nextProps.apartment.gpApartmentReport000IsFetching,
          });
        };
      };

      if(nextProps.apartment.gpApartmentReport000IsFetching) {
        if (nextProps.apartment.gpApartmentReport000IsFetching !== this.props.apartment.gpApartmentReport000IsFetching) {
          this.setState({
            sApartmentReport000IsFetching  : nextProps.apartment.gpApartmentReport000IsFetching,
          });
        };
      };

    };
    getReport(id){
      history.push('/reportview' + id)
    }

    getExcelReport(id){
      switch(id){
          case 'employee000':
            return this.props.actions.get_employee_report_excel_000('r000')

          case 'employee001':
            return this.props.actions.get_employee_report_excel_001('r001')

          case 'apartment000':
            return this.props.actions.get_apartment_report_excel_000('r000')

      }
    }
    
    getPDFReport(id){
      switch(id){
          case 'employee000':
            return this.props.actions.get_employee_report_pdf_000('r000')

          case 'employee001':
            return this.props.actions.get_employee_report_pdf_001('r001')

          case 'apartment000':
            return this.props.actions.get_apartment_report_pdf_000('r000')

      }
    }
    
    getHTMLReport(id){
      switch(id){
          case 'employee000':
            return this.props.actions.get_employee_report_html_000('r000')

          case 'employee001':
            return this.props.actions.get_employee_report_html_001('r001')

          case 'apartment000':
            return this.props.actions.get_apartment_report_html_000('r000')

      }
    }
    
    render() {
        return (
          <div>
              <Table
                size        = 'small'
                dataSource  = {this.state.lsReportDataList}
                columns     = {this.columns}
                rowKey      = {record => record.id_report}
              />
          </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReportList);

