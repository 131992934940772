'use strict'
import {companyConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/company.initialstate'
import fieldValidation                              from './../_validation/company.field.validation'
import formValidation                               from './../_validation/company.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case companyConstants.COMPANY_GET_LIST_REQUEST:
    case companyConstants.COMPANY_LOOKUP_REQUEST:
    case companyConstants.COMPANY_ADD_REQUEST:
    case companyConstants.COMPANY_CHANGE_REQUEST:
    case companyConstants.COMPANY_FILTER_REQUEST:
    case companyConstants.COMPANY_VIEW_REQUEST:
    case companyConstants.COMPANY_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpCompanyIsFetching'],  true)

    case companyConstants.COMPANY_CHANGE_SUCCESS:
    case companyConstants.COMPANY_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpCompanyIsFetching'],  false)

    case companyConstants.COMPANY_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpCompanyIsFetching'],  false)
          .setIn(['form', 'company_count'],  action.payload)

    case companyConstants.COMPANY_FILTER_SUCCESS:
    case companyConstants.COMPANY_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpCompanyDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpCompanyDataList'], data.rows)
          .setIn(['form', 'gpCompanyDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpCompanyIsFetching'],  false)


  case companyConstants.COMPANY_LOOKUP_SUCCESS:
    console.log('[company.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpCompanyLookupList'], action.payload)
        .setIn(['form', 'gpCompanyIsFetching'], false)


    case companyConstants.COMPANY_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'company_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'company_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'address'],'')
          .setIn(['form', 'gpDataFields',                                                           'phone'],'')
          .setIn(['form', 'gpDataFields',                                                           'email'],'')

    case companyConstants.COMPANY_SHOW_MODAL:
      return state
        .setIn(['form', 'gpCompanyShowModal'],  action.payload)


    case companyConstants.COMPANY_SEND_PARAM:
      return state
        .setIn(['form', 'gpCompanyModalParam'],  action.payload[0])


    case companyConstants.COMPANY_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'company_id'],                                        action.payload[0].company_id)
          .setIn(['form', 'gpOriginalFields', 'company_id'],                                        action.payload[0].company_id)
          .setIn(['form', 'gpDataFields',     'company_name'],                                      action.payload[0].company_name)
          .setIn(['form', 'gpOriginalFields', 'company_name'],                                      action.payload[0].company_name)
          .setIn(['form', 'gpDataFields',     'address'],                                           action.payload[0].address)
          .setIn(['form', 'gpOriginalFields', 'address'],                                           action.payload[0].address)
          .setIn(['form', 'gpDataFields',     'phone'],                                             action.payload[0].phone)
          .setIn(['form', 'gpOriginalFields', 'phone'],                                             action.payload[0].phone)
          .setIn(['form', 'gpDataFields',     'email'],                                             action.payload[0].email)
          .setIn(['form', 'gpOriginalFields', 'email'],                                             action.payload[0].email)
          .setIn(['form', 'gpCompanyIsFetching'],                                                   false)
          return nextRecordState


    case companyConstants.COMPANY_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'company_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'company_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'address'],                                               '')
          .setIn(['form', 'gpDataFields', 'phone'],                                                 '')
          .setIn(['form', 'gpDataFields', 'email'],                                                 '')
          .setIn(['form', 'gpDataFields', 'company_id_has_error'],                                  false)
          .setIn(['form', 'gpDataFields', 'company_name_has_error'],                                false)
          .setIn(['form', 'gpDataFields', 'address_has_error'],                                     false)
          .setIn(['form', 'gpDataFields', 'phone_has_error'],                                       false)
          .setIn(['form', 'gpDataFields', 'email_has_error'],                                       false)

          .setIn(['form', 'gpCompanyIsValid'],                                                      false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('companyReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'company_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'company_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'address'],                                               '')
          .setIn(['form', 'gpDataFields', 'phone'],                                                 '')
          .setIn(['form', 'gpDataFields', 'email'],                                                 '')
          .setIn(['form', 'gpCompanyError'],                                                        null)
          return formValidation(nextRecordState, action)


    case companyConstants.COMPANY_GET_LIST_FAILURE:
    case companyConstants.COMPANY_LOOKUP_FAILURE:
    case companyConstants.COMPANY_ADD_FAILURE:
    case companyConstants.COMPANY_CHANGE_FAILURE:
    case companyConstants.COMPANY_FILTER_FAILURE:
    case companyConstants.COMPANY_VIEW_FAILURE:
    case companyConstants.COMPANY_COUNT_FAILURE:
      console.log('company.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpCompanyIsFetching'], false)
          .setIn(['form', 'gpCompanyError'], action.payload)


   case companyConstants.ON_COMPANY_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case companyConstants.ON_COMPANY_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case companyConstants.CHANGE_COMPANY_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpCompanyError'], null)
      return nextState}

    case companyConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'company_id'],                                            record.company_id)
          .setIn(['form', 'gpOriginalFields', 'company_id'],                                        record.company_id)
          .setIn(['form', 'gpDataFields', 'company_name'],                                          record.company_name)
          .setIn(['form', 'gpOriginalFields', 'company_name'],                                      record.company_name)
          .setIn(['form', 'gpDataFields', 'address'],                                               record.address)
          .setIn(['form', 'gpOriginalFields', 'address'],                                           record.address)
          .setIn(['form', 'gpDataFields', 'phone'],                                                 record.phone)
          .setIn(['form', 'gpOriginalFields', 'phone'],                                             record.phone)
          .setIn(['form', 'gpDataFields', 'email'],                                                 record.email)
          .setIn(['form', 'gpOriginalFields', 'email'],                                             record.email)

          .setIn(['form', 'gpDataFields', 'company_id_has_error'],                                  record.company_id_has_error)
          .setIn(['form', 'gpDataFields', 'company_name_has_error'],                                record.company_name_has_error)
          .setIn(['form', 'gpDataFields', 'address_has_error'],                                     record.address_has_error)
          .setIn(['form', 'gpDataFields', 'phone_has_error'],                                       record.phone_has_error)
          .setIn(['form', 'gpDataFields', 'email_has_error'],                                       record.email_has_error)



          .setIn(['form', 'gpCompanyDataList'],                                                     record.gpCompanyDataList)
          .setIn(['form', 'gpCompanyIsDisabled'],                                                   record.gpCompanyIsDisabled)
          .setIn(['form', 'gpCompanyError'],                                                        record.gpCompanyError)
          .setIn(['form', 'gpCompanyIsValid'],                                                      record.gpCompanyIsValid)
          .setIn(['form', 'gpCompanyIsFetching'],                                                   record.gpCompanyIsFetching)
          .setIn(['form', 'company_count'],                                                         record.company_count)
      return next
case companyConstants.COMPANY_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpCompanyLookupShowModal'],  action.payload)


  }
  return state
}
