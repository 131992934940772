const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        building_id                               : '',
        building_name                             : '',
        total_apartement                          : '',
        complex_id                                : '',
        complex_name                              : '',
        search_building_id                        : '',
        search_building_name                      : '',
        search_total_apartement                   : '',
        search_complex_id                         : '',
        complex_complex_id                        : '',
   }))(),
   gpBuildingDataList                             : [],
   gpBuildingDataListNumRows                      : 0,
   gpBuildingLookupList                           : [],
   gpBuildingIsDisabled                           : false,
   gpBuildingError                                : null,
   gpBuildingIsValid                              : true,
   gpBuildingIsFetching                           : false,
   building_count                                 : 0,
   building_active_tab                            : 0,
   gpBuildingShowModal                            : 'hide',
   gpBuildingModalParam                           : [],
   gpBuildingLookupShowModal                      : 'hide',
   gpDataFields: new (Record({
        complex_id                                : '',
        complex_name                              : '',
        building_id                               : '',
        building_name                             : '',
        total_apartement                          : '',
        search_building_id                        : '',
        search_building_name                      : '',
        search_total_apartement                   : '',
        complex_complex_id                        : '',
        building_id_has_error                     : false,
        building_name_has_error                   : false,
        total_apartement_has_error                : false,
        search_building_id_has_error              : false,
        search_building_name_has_error            : false,
        search_total_apartement_has_error         : false,
        complex_complex_id_has_error              : false,
        building_id_error_msg                     : false,
        building_name_error_msg                   : false,
        total_apartement_error_msg                : false,
        search_building_id_error_msg              : false,
        search_building_name_error_msg            : false,
        search_total_apartement_error_msg         : false,
        complex_complex_id_error_msg              : false,
        complex_id_has_error                      : false,
        complex_id_error_msg                      : false,
        search_complex_id                         : '',
        complex_id                                : '',
   }))(),
 })
 var buildingInitialState = Record({
   form: new Form()
 })
export default buildingInitialState
