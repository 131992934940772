export const infoConstants = {
    ADD_TENANT_SUCCESS: 'Penambahan data tenant baru berhasil ditambahkan',
    EDIT_TENANT_SUCCESS: 'Pembaruan data tenant berhasil diperbarui',
    DELETE_TENANT_SUCCESS: 'Penghapusan data tenant berhasil di hapus',
    ADD_TENANT_ERROR: 'Penambahan data tenant baru mengalami kegagalan',
    EDIT_TENANT_ERROR: 'Pembaruan data tenant mengalami kegagalan',
    DELETE_TENANT_ERROR: 'Penghapusan data tenant mengalami kegagalan',
    VIEW_TENANT_ERROR: 'View data tenant mengalami kegagalan',
    LIST_TENANT_ERROR: 'List data tenant mengalami kegagalan',
    LOOKUP_TENANT_ERROR: 'Lookup data tenant mengalami kegagalan',
    FILTER_TENANT_ERROR: 'Filter data tenant mengalami kegagalan',
    CHART_TENANT_ERROR: 'Proses charting tenant mengalami kegagalan',
    REPORT_TENANT_ERROR: 'Proses reporting tenant mengalami kegagalan',
    INFO_TENANT_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_TENANT_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_TENANT_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_TENANT_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_TENANT_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_TENANT_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_LEASE_SUCCESS: 'Penambahan data lease baru berhasil ditambahkan',
    EDIT_LEASE_SUCCESS: 'Pembaruan data lease berhasil diperbarui',
    DELETE_LEASE_SUCCESS: 'Penghapusan data lease berhasil di hapus',
    ADD_LEASE_ERROR: 'Penambahan data lease baru mengalami kegagalan',
    EDIT_LEASE_ERROR: 'Pembaruan data lease mengalami kegagalan',
    DELETE_LEASE_ERROR: 'Penghapusan data lease mengalami kegagalan',
    VIEW_LEASE_ERROR: 'View data lease mengalami kegagalan',
    LIST_LEASE_ERROR: 'List data lease mengalami kegagalan',
    LOOKUP_LEASE_ERROR: 'Lookup data lease mengalami kegagalan',
    FILTER_LEASE_ERROR: 'Filter data lease mengalami kegagalan',
    CHART_LEASE_ERROR: 'Proses charting lease mengalami kegagalan',
    REPORT_LEASE_ERROR: 'Proses reporting lease mengalami kegagalan',
    INFO_LEASE_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_LEASE_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_LEASE_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_LEASE_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_LEASE_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_LEASE_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_LEASE_DETAIL_SUCCESS: 'Penambahan data lease_detail baru berhasil ditambahkan',
    EDIT_LEASE_DETAIL_SUCCESS: 'Pembaruan data lease_detail berhasil diperbarui',
    DELETE_LEASE_DETAIL_SUCCESS: 'Penghapusan data lease_detail berhasil di hapus',
    ADD_LEASE_DETAIL_ERROR: 'Penambahan data lease_detail baru mengalami kegagalan',
    EDIT_LEASE_DETAIL_ERROR: 'Pembaruan data lease_detail mengalami kegagalan',
    DELETE_LEASE_DETAIL_ERROR: 'Penghapusan data lease_detail mengalami kegagalan',
    VIEW_LEASE_DETAIL_ERROR: 'View data lease_detail mengalami kegagalan',
    LIST_LEASE_DETAIL_ERROR: 'List data lease_detail mengalami kegagalan',
    LOOKUP_LEASE_DETAIL_ERROR: 'Lookup data lease_detail mengalami kegagalan',
    FILTER_LEASE_DETAIL_ERROR: 'Filter data lease_detail mengalami kegagalan',
    CHART_LEASE_DETAIL_ERROR: 'Proses charting lease_detail mengalami kegagalan',
    REPORT_LEASE_DETAIL_ERROR: 'Proses reporting lease_detail mengalami kegagalan',
    INFO_LEASE_DETAIL_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_LEASE_DETAIL_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_LEASE_DETAIL_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_LEASE_DETAIL_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_LEASE_DETAIL_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_LEASE_DETAIL_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_RENT_SUCCESS: 'Penambahan data rent baru berhasil ditambahkan',
    EDIT_RENT_SUCCESS: 'Pembaruan data rent berhasil diperbarui',
    DELETE_RENT_SUCCESS: 'Penghapusan data rent berhasil di hapus',
    ADD_RENT_ERROR: 'Penambahan data rent baru mengalami kegagalan',
    EDIT_RENT_ERROR: 'Pembaruan data rent mengalami kegagalan',
    DELETE_RENT_ERROR: 'Penghapusan data rent mengalami kegagalan',
    VIEW_RENT_ERROR: 'View data rent mengalami kegagalan',
    LIST_RENT_ERROR: 'List data rent mengalami kegagalan',
    LOOKUP_RENT_ERROR: 'Lookup data rent mengalami kegagalan',
    FILTER_RENT_ERROR: 'Filter data rent mengalami kegagalan',
    CHART_RENT_ERROR: 'Proses charting rent mengalami kegagalan',
    REPORT_RENT_ERROR: 'Proses reporting rent mengalami kegagalan',
    INFO_RENT_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_RENT_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_RENT_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_RENT_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_RENT_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_RENT_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_PAYMENT_SUCCESS: 'Penambahan data payment baru berhasil ditambahkan',
    EDIT_PAYMENT_SUCCESS: 'Pembaruan data payment berhasil diperbarui',
    DELETE_PAYMENT_SUCCESS: 'Penghapusan data payment berhasil di hapus',
    ADD_PAYMENT_ERROR: 'Penambahan data payment baru mengalami kegagalan',
    EDIT_PAYMENT_ERROR: 'Pembaruan data payment mengalami kegagalan',
    DELETE_PAYMENT_ERROR: 'Penghapusan data payment mengalami kegagalan',
    VIEW_PAYMENT_ERROR: 'View data payment mengalami kegagalan',
    LIST_PAYMENT_ERROR: 'List data payment mengalami kegagalan',
    LOOKUP_PAYMENT_ERROR: 'Lookup data payment mengalami kegagalan',
    FILTER_PAYMENT_ERROR: 'Filter data payment mengalami kegagalan',
    CHART_PAYMENT_ERROR: 'Proses charting payment mengalami kegagalan',
    REPORT_PAYMENT_ERROR: 'Proses reporting payment mengalami kegagalan',
    INFO_PAYMENT_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_PAYMENT_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_PAYMENT_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_PAYMENT_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_PAYMENT_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_PAYMENT_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_COMPLEX_SUCCESS: 'Penambahan data complex baru berhasil ditambahkan',
    EDIT_COMPLEX_SUCCESS: 'Pembaruan data complex berhasil diperbarui',
    DELETE_COMPLEX_SUCCESS: 'Penghapusan data complex berhasil di hapus',
    ADD_COMPLEX_ERROR: 'Penambahan data complex baru mengalami kegagalan',
    EDIT_COMPLEX_ERROR: 'Pembaruan data complex mengalami kegagalan',
    DELETE_COMPLEX_ERROR: 'Penghapusan data complex mengalami kegagalan',
    VIEW_COMPLEX_ERROR: 'View data complex mengalami kegagalan',
    LIST_COMPLEX_ERROR: 'List data complex mengalami kegagalan',
    LOOKUP_COMPLEX_ERROR: 'Lookup data complex mengalami kegagalan',
    FILTER_COMPLEX_ERROR: 'Filter data complex mengalami kegagalan',
    CHART_COMPLEX_ERROR: 'Proses charting complex mengalami kegagalan',
    REPORT_COMPLEX_ERROR: 'Proses reporting complex mengalami kegagalan',
    INFO_COMPLEX_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_COMPLEX_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_COMPLEX_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_COMPLEX_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_COMPLEX_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_COMPLEX_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_SERVICE_SUCCESS: 'Penambahan data service baru berhasil ditambahkan',
    EDIT_SERVICE_SUCCESS: 'Pembaruan data service berhasil diperbarui',
    DELETE_SERVICE_SUCCESS: 'Penghapusan data service berhasil di hapus',
    ADD_SERVICE_ERROR: 'Penambahan data service baru mengalami kegagalan',
    EDIT_SERVICE_ERROR: 'Pembaruan data service mengalami kegagalan',
    DELETE_SERVICE_ERROR: 'Penghapusan data service mengalami kegagalan',
    VIEW_SERVICE_ERROR: 'View data service mengalami kegagalan',
    LIST_SERVICE_ERROR: 'List data service mengalami kegagalan',
    LOOKUP_SERVICE_ERROR: 'Lookup data service mengalami kegagalan',
    FILTER_SERVICE_ERROR: 'Filter data service mengalami kegagalan',
    CHART_SERVICE_ERROR: 'Proses charting service mengalami kegagalan',
    REPORT_SERVICE_ERROR: 'Proses reporting service mengalami kegagalan',
    INFO_SERVICE_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_SERVICE_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_SERVICE_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_SERVICE_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_SERVICE_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_SERVICE_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_EMPLOYEE_SUCCESS: 'Penambahan data employee baru berhasil ditambahkan',
    EDIT_EMPLOYEE_SUCCESS: 'Pembaruan data employee berhasil diperbarui',
    DELETE_EMPLOYEE_SUCCESS: 'Penghapusan data employee berhasil di hapus',
    ADD_EMPLOYEE_ERROR: 'Penambahan data employee baru mengalami kegagalan',
    EDIT_EMPLOYEE_ERROR: 'Pembaruan data employee mengalami kegagalan',
    DELETE_EMPLOYEE_ERROR: 'Penghapusan data employee mengalami kegagalan',
    VIEW_EMPLOYEE_ERROR: 'View data employee mengalami kegagalan',
    LIST_EMPLOYEE_ERROR: 'List data employee mengalami kegagalan',
    LOOKUP_EMPLOYEE_ERROR: 'Lookup data employee mengalami kegagalan',
    FILTER_EMPLOYEE_ERROR: 'Filter data employee mengalami kegagalan',
    CHART_EMPLOYEE_ERROR: 'Proses charting employee mengalami kegagalan',
    REPORT_EMPLOYEE_ERROR: 'Proses reporting employee mengalami kegagalan',
    INFO_EMPLOYEE_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_EMPLOYEE_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_EMPLOYEE_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_EMPLOYEE_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_EMPLOYEE_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_EMPLOYEE_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_BUILDING_SUCCESS: 'Penambahan data building baru berhasil ditambahkan',
    EDIT_BUILDING_SUCCESS: 'Pembaruan data building berhasil diperbarui',
    DELETE_BUILDING_SUCCESS: 'Penghapusan data building berhasil di hapus',
    ADD_BUILDING_ERROR: 'Penambahan data building baru mengalami kegagalan',
    EDIT_BUILDING_ERROR: 'Pembaruan data building mengalami kegagalan',
    DELETE_BUILDING_ERROR: 'Penghapusan data building mengalami kegagalan',
    VIEW_BUILDING_ERROR: 'View data building mengalami kegagalan',
    LIST_BUILDING_ERROR: 'List data building mengalami kegagalan',
    LOOKUP_BUILDING_ERROR: 'Lookup data building mengalami kegagalan',
    FILTER_BUILDING_ERROR: 'Filter data building mengalami kegagalan',
    CHART_BUILDING_ERROR: 'Proses charting building mengalami kegagalan',
    REPORT_BUILDING_ERROR: 'Proses reporting building mengalami kegagalan',
    INFO_BUILDING_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_BUILDING_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_BUILDING_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_BUILDING_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_BUILDING_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_BUILDING_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_APARTMENT_SUCCESS: 'Penambahan data apartment baru berhasil ditambahkan',
    EDIT_APARTMENT_SUCCESS: 'Pembaruan data apartment berhasil diperbarui',
    DELETE_APARTMENT_SUCCESS: 'Penghapusan data apartment berhasil di hapus',
    ADD_APARTMENT_ERROR: 'Penambahan data apartment baru mengalami kegagalan',
    EDIT_APARTMENT_ERROR: 'Pembaruan data apartment mengalami kegagalan',
    DELETE_APARTMENT_ERROR: 'Penghapusan data apartment mengalami kegagalan',
    VIEW_APARTMENT_ERROR: 'View data apartment mengalami kegagalan',
    LIST_APARTMENT_ERROR: 'List data apartment mengalami kegagalan',
    LOOKUP_APARTMENT_ERROR: 'Lookup data apartment mengalami kegagalan',
    FILTER_APARTMENT_ERROR: 'Filter data apartment mengalami kegagalan',
    CHART_APARTMENT_ERROR: 'Proses charting apartment mengalami kegagalan',
    REPORT_APARTMENT_ERROR: 'Proses reporting apartment mengalami kegagalan',
    INFO_APARTMENT_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_APARTMENT_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_APARTMENT_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_APARTMENT_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_APARTMENT_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_APARTMENT_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_COMPANY_SUCCESS: 'Penambahan data company baru berhasil ditambahkan',
    EDIT_COMPANY_SUCCESS: 'Pembaruan data company berhasil diperbarui',
    DELETE_COMPANY_SUCCESS: 'Penghapusan data company berhasil di hapus',
    ADD_COMPANY_ERROR: 'Penambahan data company baru mengalami kegagalan',
    EDIT_COMPANY_ERROR: 'Pembaruan data company mengalami kegagalan',
    DELETE_COMPANY_ERROR: 'Penghapusan data company mengalami kegagalan',
    VIEW_COMPANY_ERROR: 'View data company mengalami kegagalan',
    LIST_COMPANY_ERROR: 'List data company mengalami kegagalan',
    LOOKUP_COMPANY_ERROR: 'Lookup data company mengalami kegagalan',
    FILTER_COMPANY_ERROR: 'Filter data company mengalami kegagalan',
    CHART_COMPANY_ERROR: 'Proses charting company mengalami kegagalan',
    REPORT_COMPANY_ERROR: 'Proses reporting company mengalami kegagalan',
    INFO_COMPANY_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_COMPANY_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_COMPANY_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_COMPANY_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_COMPANY_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_COMPANY_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_USERS_SUCCESS: 'Penambahan data users baru berhasil ditambahkan',
    EDIT_USERS_SUCCESS: 'Pembaruan data users berhasil diperbarui',
    DELETE_USERS_SUCCESS: 'Penghapusan data users berhasil di hapus',
    ADD_USERS_ERROR: 'Penambahan data users baru mengalami kegagalan',
    EDIT_USERS_ERROR: 'Pembaruan data users mengalami kegagalan',
    DELETE_USERS_ERROR: 'Penghapusan data users mengalami kegagalan',
    VIEW_USERS_ERROR: 'View data users mengalami kegagalan',
    LIST_USERS_ERROR: 'List data users mengalami kegagalan',
    LOOKUP_USERS_ERROR: 'Lookup data users mengalami kegagalan',
    FILTER_USERS_ERROR: 'Filter data users mengalami kegagalan',
    CHART_USERS_ERROR: 'Proses charting users mengalami kegagalan',
    REPORT_USERS_ERROR: 'Proses reporting users mengalami kegagalan',
    INFO_USERS_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_USERS_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_USERS_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_USERS_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_USERS_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_USERS_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


    ADD_USERLEVEL_SUCCESS: 'Penambahan data userlevel baru berhasil ditambahkan',
    EDIT_USERLEVEL_SUCCESS: 'Pembaruan data userlevel berhasil diperbarui',
    DELETE_USERLEVEL_SUCCESS: 'Penghapusan data userlevel berhasil di hapus',
    ADD_USERLEVEL_ERROR: 'Penambahan data userlevel baru mengalami kegagalan',
    EDIT_USERLEVEL_ERROR: 'Pembaruan data userlevel mengalami kegagalan',
    DELETE_USERLEVEL_ERROR: 'Penghapusan data userlevel mengalami kegagalan',
    VIEW_USERLEVEL_ERROR: 'View data userlevel mengalami kegagalan',
    LIST_USERLEVEL_ERROR: 'List data userlevel mengalami kegagalan',
    LOOKUP_USERLEVEL_ERROR: 'Lookup data userlevel mengalami kegagalan',
    FILTER_USERLEVEL_ERROR: 'Filter data userlevel mengalami kegagalan',
    CHART_USERLEVEL_ERROR: 'Proses charting userlevel mengalami kegagalan',
    REPORT_USERLEVEL_ERROR: 'Proses reporting userlevel mengalami kegagalan',
    INFO_USERLEVEL_EXPORT_SUCCESS: 'Proses ekspor data berhasil',
    INFO_USERLEVEL_EXPORT_ERROR: 'Proses ekspor data mengalami kegagalan',
    INFO_USERLEVEL_IMPORT_SUCCESS: 'Proses impor data berhasil',
    INFO_USERLEVEL_IMPORT_ERROR:'Proses impor data mengalami kegagalan',
    INFO_USERLEVEL_GET_SAMPLE_FILE_SUCCESS: 'Proses pengambilan sample file berhasil',
    INFO_USERLEVEL_GET_SAMPLE_FILE_ERROR:'Proses pengambilan sample file mengalami kegagalan',


};
