'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('lease_id'): {
        let lease_id    = value
        let isValid = true

      if (lease_id.length > 0) {
        if (lease_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_id_error_msg'], 'ID Rumah Sewa perlu di isi')
        }

        if (lease_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_id_error_msg'], 'ID Rumah Sewa panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(lease_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_id_error_msg'], 'ID Rumah Sewa tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'lease_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'lease_id_error_msg'], '')
        }
    }

    case ('lease_description'): {
        let lease_description    = value
        let isValid = true

        if (lease_description.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_description_error_msg'], 'Keterangan perlu di isi')
        }

        if (lease_description.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_description_error_msg'], 'Keterangan panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(lease_description)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_description_error_msg'], 'Keterangan tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'lease_description_has_error'], false)
            .setIn(['form', 'gpDataFields', 'lease_description_error_msg'], '')
        }
    }

    case ('start_date'): {
        let start_date    = value
        let isValid = true

        if (!check.validateDate(start_date)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'start_date_has_error'], true)
            .setIn(['form', 'gpDataFields', 'start_date_error_msg'], 'Tanggal Mulai tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'start_date_has_error'], false)
            .setIn(['form', 'gpDataFields', 'start_date_error_msg'], '')
        }
    }

    case ('end_date'): {
        let end_date    = value
        let isValid = true

        if (!check.validateDate(end_date)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'end_date_has_error'], true)
            .setIn(['form', 'gpDataFields', 'end_date_error_msg'], 'Tanggal Selesai tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'end_date_has_error'], false)
            .setIn(['form', 'gpDataFields', 'end_date_error_msg'], '')
        }
    }

    case ('deposit'): {
        let deposit    = value
        let isValid = true

        if (deposit.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'deposit_has_error'], true)
            .setIn(['form', 'gpDataFields', 'deposit_error_msg'], 'Uang Deposit perlu di isi')
        }

        if (deposit.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'deposit_has_error'], true)
            .setIn(['form', 'gpDataFields', 'deposit_error_msg'], 'Uang Deposit panjang maksimumnya 50 karakter.')
        }

        if (!check.validateMoney(deposit)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'deposit_has_error'], true)
            .setIn(['form', 'gpDataFields', 'deposit_error_msg'], 'Uang Deposit tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'deposit_has_error'], false)
            .setIn(['form', 'gpDataFields', 'deposit_error_msg'], '')
        }
    }

  }
  return state
}
