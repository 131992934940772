/* -------------------------------------------------------------------------- */
/* APARTMENT.ACTIONS.JS                                                       */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {apartmentConstants}                            from '../_constants';
import {apartmentService}                              from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const apartmentActions = {
    _addApartment,
    _editApartment,
    _filterApartment,
    _chart0Apartment,
    get_apartment_report_excel_000,
    get_apartment_report_pdf_000,
    get_apartment_report_html_000,
    _viewApartment,
    _listApartment,
    _removeApartment,
    _lookupApartment,
    _showApartmentModalForm,
    _sendApartmentFormParam,
    _activateAddApartment,
    _activateEditApartment,
    _activateFilterApartment,
    _onApartmentStateChange,
    _onApartmentFormFieldChange,
    action_to_import_apartment,
    action_to_export_apartment,
    action_to_get_sample_apartment,
    action_to_count_apartment,
};

export function _addApartment(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  apartmentService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupApartment());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_APARTMENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: apartmentConstants.APARTMENT_ADD_REQUEST         }}
    function success(result)  { return { type: apartmentConstants.APARTMENT_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: apartmentConstants.APARTMENT_ADD_FAILURE}}
}

export function _editApartment(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  apartmentService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupApartment());
          dispatch(_viewApartment(data.apartment_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_APARTMENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: apartmentConstants.APARTMENT_CHANGE_REQUEST         }}
    function success(result)  { return { type: apartmentConstants.APARTMENT_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: apartmentConstants.APARTMENT_CHANGE_FAILURE, payload: error }}
}

export function _filterApartment(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  apartmentService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_APARTMENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: apartmentConstants.APARTMENT_FILTER_REQUEST         }}
    function success(result, page)  { return { type: apartmentConstants.APARTMENT_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: apartmentConstants.APARTMENT_FILTER_FAILURE}}
}

export function _chart0Apartment(chart_type,countbox) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  apartmentService._chart0(chart_type,countbox)
    .then(
      response => {
        dispatch(success(response));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.CHART_APARTMENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: apartmentConstants.APARTMENT_CHART0_REQUEST         }}
    function success(result)  { return { type: apartmentConstants.APARTMENT_CHART0_SUCCESS, payload: result }}
    function failure()   { return { type: apartmentConstants.APARTMENT_CHART0_FAILURE}}
}


export function get_apartment_report_excel_000(data) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        apartmentService.get_apartment_report_excel_000(data)
            .then(
                response => {
                  if(response[0].status){
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.REPORT_APARTMENT_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_EXCEL_REQUEST         }}
    function success(result) { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_EXCEL_SUCCESS,  payload: result }}
    function failure()  { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_EXCEL_FAILURE}}
}


export function get_apartment_report_pdf_000(data) {
    let path = ''
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        apartmentService.get_apartment_report_pdf_000(data)
            .then(
                response => {
                  if(response[0].status){
                    dispatch(success(response));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.PDF_APARTMENT_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_PDF_REQUEST}}
    function success(result) { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_PDF_SUCCESS,  payload: result }}
    function failure()  { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_PDF_FAILURE}}
}


export function get_apartment_report_html_000(data) {
    let path = ''
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        apartmentService.get_apartment_report_html_000(data)
            .then(
                response => {
                  if(response.status){
                    dispatch(success(response));
                    dispatch(alertActions.success(response.message));
                    dispatch(alertActions.clear());
                  } else {
                    dispatch(failure());
                    dispatch(alertActions.error(response.message));
                    dispatch(alertActions.clear());
                  }
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.PDF_APARTMENT_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()       { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_HTML_REQUEST}}
    function success(result) { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_HTML_SUCCESS,  payload: result }}
    function failure()  { return { type: apartmentConstants.APARTMENT_REPORT_000_TO_HTML_FAILURE}}
}


export function _removeApartment(id) {
      const data = {
        apartment_id                              : '', 
        apartment_name                            : '', 
        total_bedroom                             : '', 
        rent_cost                                 : '', 
        building_id                               : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'apartment_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        apartmentService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listApartment(data));
          dispatch(_lookupApartment());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_APARTMENT_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: apartmentConstants.APARTMENT_REMOVE_REQUEST        }}
    function success(result) { return { type: apartmentConstants.APARTMENT_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: apartmentConstants.APARTMENT_REMOVE_FAILURE}}
}

export function _initApartment() {
  return dispatch => {dispatch(success());};
  function success() { return { type: apartmentConstants.APARTMENT_INIT_SUCCESS}}
}

export function _viewApartment(id) {
  return dispatch => {
      dispatch(request({id}));

      apartmentService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_APARTMENT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: apartmentConstants.APARTMENT_VIEW_REQUEST} }
  function success(result) { return { type: apartmentConstants.APARTMENT_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: apartmentConstants.APARTMENT_VIEW_FAILURE}}
}

export function action_to_count_apartment() {
  return dispatch => {
      dispatch(request());
      apartmentService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_APARTMENT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: apartmentConstants.APARTMENT_COUNT_REQUEST} }
  function success(result) { return { type: apartmentConstants.APARTMENT_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: apartmentConstants.APARTMENT_COUNT_FAILURE}}
}

export function _listApartment(data) {
    return dispatch => {
        dispatch(request(data));
        apartmentService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_APARTMENT_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: apartmentConstants.APARTMENT_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: apartmentConstants.APARTMENT_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: apartmentConstants.APARTMENT_GET_LIST_FAILURE}}
}
export function _lookupApartment() {
  return dispatch => {
    dispatch(request());
    apartmentService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_APARTMENT_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: apartmentConstants.APARTMENT_LOOKUP_REQUEST         }}
    function success(result) { return { type: apartmentConstants.APARTMENT_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: apartmentConstants.APARTMENT_LOOKUP_FAILURE}}
}

export function _activateEditApartment(data) {
  console.log('[apartment.action][_activateEditApartment][started]');
  const param = [{
      apartment_id        : data[0].apartment_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showApartmentModalForm('show'))
    dispatch(_sendApartmentFormParam(param));
  }
}

export function _activateAddApartment() {
  console.log('[apartment.action][_activateAddApartment][started]');
  const param = [{
      apartment_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initApartment());
    dispatch(_showApartmentModalForm('show'));
    dispatch(_sendApartmentFormParam(param));
  }
}

export function _activateFilterApartment() {
  console.log('[apartment.action][_activateFilterApartment][started]');
  const param = [{
      apartment_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initApartment());
    dispatch(_showApartmentModalForm('show'));
    dispatch(_sendApartmentFormParam(param));
  }
}

export function _onApartmentFormFieldChange (field, value) {
  console.log('[apartment.action][onApartmentFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: apartmentConstants.ON_APARTMENT_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onApartmentStateChange (field, value) {
  return {
    type: apartmentConstants.ON_APARTMENT_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showApartmentModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:apartmentConstants.APARTMENT_SHOW_MODAL, payload: showModal}}
}

export function _sendApartmentFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: apartmentConstants.APARTMENT_SEND_PARAM, payload: param}}
}

export function _showApartmentLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:apartmentConstants.APARTMENT_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_apartment(data) {
      const param = {
        apartment_id                              : '', 
        apartment_name                            : '', 
        total_bedroom                             : '', 
        rent_cost                                 : '', 
        building_id                               : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'apartment_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  apartmentService.service_to_import_apartment(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listApartment(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_APARTMENT_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_APARTMENT_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_APARTMENT_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: apartmentConstants.APARTMENT_IMPORT_REQUEST         }}
    function success(result)  { return { type: apartmentConstants.APARTMENT_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: apartmentConstants.APARTMENT_IMPORT_FAILURE}}
}

export function action_to_export_apartment() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  apartmentService.service_to_export_apartment()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_APARTMENT_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_APARTMENT_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: apartmentConstants.APARTMENT_EXPORT_REQUEST         }}
    function success(result)  { return { type: apartmentConstants.APARTMENT_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: apartmentConstants.APARTMENT_EXPORT_FAILURE}}
}

export function action_to_get_sample_apartment() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  apartmentService.service_to_get_sample_apartment()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_APARTMENT_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_APARTMENT_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_APARTMENT_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: apartmentConstants.APARTMENT_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: apartmentConstants.APARTMENT_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: apartmentConstants.APARTMENT_GET_SAMPLE_FILE_FAILURE}}
}

