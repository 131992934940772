import React, { Component }                           from 'react';
import { connect }                                    from 'react-redux';
import { bindActionCreators }                         from 'redux';
import { Link }                                       from 'react-router-dom';
import { Input, Grid,  Button, Segment }              from 'semantic-ui-react';
import { authenticateActions }                        from './../_actions';


function mapStateToProps (state) {
  return {
    authenticate: {
      form                      : state.authenticate.form,
      fields                    : state.authenticate.form.fields,
      isFetching                : state.authenticate.form.isFetching,
      isValid                   : state.authenticate.form.isValid,
    },
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
       ...authenticateActions },
       dispatch)
  }
}

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: '',
      },
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(name, e) {
    this.props.actions.forgotPasswordState()
    const { value } = e.target;
    console.log('name: ' + JSON.stringify(name));
    console.log('value: ' + JSON.stringify(value));
    this.props.actions._onAuthFormFieldChange(name, value)
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email } = this.props.authenticate.fields;
    if (email) {
        this.props.actions.forgot(email);
    }
  }

  render() {
    const { user, submitted } = this.state;

        const {
          email,
          email_has_error,
          email_error_msg,
        } = this.props.authenticate.fields;


    return (
          <div className='flex justify-center text-center p-12 bg-blue-50'>
              <div class='w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-6 dark:bg-gray-800 dark:border-gray-700'>
                <div style={{textAlign:'left'}}>
                  <img src={require('./../_applib/_static/_images/logo.jpg')} width='140px'/>
                </div>
                <br /><br /><br />
                <div className='login-input'>
                  <div style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}>Email</div>
                  <Input
                    size='large'
                    fluid
                    name='email'
                    value={email}
                    onChange={(e)=>this.handleChange('email', e)}
                  />
                  {email_has_error?<div style={{textAlign:'left', color: 'red'}}>{email_error_msg}</div>:null}
                </div><br /><br /><br />
                <div className='left'>
                  <Button
                    primary
                    fluid
                    size='large'
                    loading={submitted}
                    onClick={this.handleSubmit}>
                    Reset Password
                  </Button>
                </div>
              </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)

