'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('id_user'): {
        let id_user    = value
        let isValid = true

      if (id_user.length > 0) {
        if (id_user.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_user_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_user_error_msg'], 'ID User perlu di isi')
        }

        if (id_user.length > 100) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_user_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_user_error_msg'], 'ID User panjang maksimumnya 100 karakter.')
        }

        if (!check.validateAlpha(id_user)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_user_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_user_error_msg'], 'ID User tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'id_user_has_error'], false)
            .setIn(['form', 'gpDataFields', 'id_user_error_msg'], '')
        }
    }

    case ('fullname'): {
        let fullname    = value
        let isValid = true

        if (fullname.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'fullname_has_error'], true)
            .setIn(['form', 'gpDataFields', 'fullname_error_msg'], 'Nama Lengkap perlu di isi')
        }

        if (fullname.length > 100) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'fullname_has_error'], true)
            .setIn(['form', 'gpDataFields', 'fullname_error_msg'], 'Nama Lengkap panjang maksimumnya 100 karakter.')
        }

        if (!check.validateAlpha(fullname)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'fullname_has_error'], true)
            .setIn(['form', 'gpDataFields', 'fullname_error_msg'], 'Nama Lengkap tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'fullname_has_error'], false)
            .setIn(['form', 'gpDataFields', 'fullname_error_msg'], '')
        }
    }

    case ('phone'): {
        let phone    = value
        let isValid = true

        if (phone.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'No. Telepon perlu di isi')
        }

        if (phone.length > 20) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'No. Telepon panjang maksimumnya 20 karakter.')
        }

        if (!check.validatePhone(phone)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'No. Telepon tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], false)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], '')
        }
    }

    case ('email'): {
        let email    = value
        let isValid = true

        if (email.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email perlu di isi')
        }

        if (email.length > 100) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email panjang maksimumnya 100 karakter.')
        }

        if (!check.validateEmail(email)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], false)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], '')
        }
    }

    case ('passwd'): {
        let passwd    = value
        let isValid = true

        if (passwd.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'passwd_has_error'], true)
            .setIn(['form', 'gpDataFields', 'passwd_error_msg'], 'Kata Kunci perlu di isi')
        }

        if (passwd.length > 100) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'passwd_has_error'], true)
            .setIn(['form', 'gpDataFields', 'passwd_error_msg'], 'Kata Kunci panjang maksimumnya 100 karakter.')
        }

        if (!check.validateAlpha(passwd)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'passwd_has_error'], true)
            .setIn(['form', 'gpDataFields', 'passwd_error_msg'], 'Kata Kunci tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'passwd_has_error'], false)
            .setIn(['form', 'gpDataFields', 'passwd_error_msg'], '')
        }
    }

    case ('active'): {
        let active    = value
        let isValid = true

        if (!check.validateNumber(active)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'active_has_error'], true)
            .setIn(['form', 'gpDataFields', 'active_error_msg'], 'Aktif tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'active_has_error'], false)
            .setIn(['form', 'gpDataFields', 'active_error_msg'], '')
        }
    }

    case ('administrator'): {
        let administrator    = value
        let isValid = true

        if (!check.validateNumber(administrator)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'administrator_has_error'], true)
            .setIn(['form', 'gpDataFields', 'administrator_error_msg'], 'Admin tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'administrator_has_error'], false)
            .setIn(['form', 'gpDataFields', 'administrator_error_msg'], '')
        }
    }

    case ('id_userlevel'): {
        let id_userlevel    = value
        let isValid = true

        if (id_userlevel.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], 'ID UserLevel perlu di isi')
        }

        if (id_userlevel.length > 10) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], 'ID UserLevel panjang maksimumnya 10 karakter.')
        }

        if (!check.validateAlpha(id_userlevel)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], 'ID UserLevel tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], false)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], '')
        }
    }

    case ('last_login_date'): {
        let last_login_date    = value
        let isValid = true

        if (!check.validateDate(last_login_date)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'last_login_date_has_error'], true)
            .setIn(['form', 'gpDataFields', 'last_login_date_error_msg'], 'Tanggal Login Terakhir tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'last_login_date_has_error'], false)
            .setIn(['form', 'gpDataFields', 'last_login_date_error_msg'], '')
        }
    }

    case ('login_attempt'): {
        let login_attempt    = value
        let isValid = true

        if (!check.validateNumber(login_attempt)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'login_attempt_has_error'], true)
            .setIn(['form', 'gpDataFields', 'login_attempt_error_msg'], 'Percobaan Login tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'login_attempt_has_error'], false)
            .setIn(['form', 'gpDataFields', 'login_attempt_error_msg'], '')
        }
    }

    case ('expired_date'): {
        let expired_date    = value
        let isValid = true

        if (!check.validateDate(expired_date)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'expired_date_has_error'], true)
            .setIn(['form', 'gpDataFields', 'expired_date_error_msg'], 'Tanggal Expired tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'expired_date_has_error'], false)
            .setIn(['form', 'gpDataFields', 'expired_date_error_msg'], '')
        }
    }

  }
  return state
}
