export const paymentConstants = {
    ON_PAYMENT_FORM_FIELD_CHANGE                  : 'ON_PAYMENT_FORM_FIELD_CHANGE',
    CHANGE_PAYMENT_FIELD                          : 'CHANGE_PAYMENT_FIELD',
    ON_PAYMENT_STATE_CHANGE                       : 'ON_PAYMENT_STATE_CHANGE',

    PAYMENT_GET_LIST_REQUEST                      : 'PAYMENT_GET_LIST_REQUEST',
    PAYMENT_GET_LIST_SUCCESS                      : 'PAYMENT_GET_LIST_SUCCESS',
    PAYMENT_GET_LIST_FAILURE                      : 'PAYMENT_GET_LIST_FAILURE',

    PAYMENT_VIEW_REQUEST                          : 'PAYMENT_VIEW_REQUEST',
    PAYMENT_VIEW_SUCCESS                          : 'PAYMENT_VIEW_SUCCESS',
    PAYMENT_VIEW_FAILURE                          : 'PAYMENT_VIEW_FAILURE',

    PAYMENT_ADD_REQUEST                           : 'PAYMENT_ADD_REQUEST',
    PAYMENT_ADD_SUCCESS                           : 'PAYMENT_ADD_SUCCESS',
    PAYMENT_ADD_FAILURE                           : 'PAYMENT_ADD_FAILURE',

    PAYMENT_CHANGE_REQUEST                        : 'PAYMENT_CHANGE_REQUEST',
    PAYMENT_CHANGE_SUCCESS                        : 'PAYMENT_CHANGE_SUCCESS',
    PAYMENT_CHANGE_FAILURE                        : 'PAYMENT_CHANGE_FAILURE',

    PAYMENT_FILTER_REQUEST                        : 'PAYMENT_FILTER_REQUEST',
    PAYMENT_FILTER_SUCCESS                        : 'PAYMENT_FILTER_SUCCESS',
    PAYMENT_FILTER_FAILURE                        : 'PAYMENT_FILTER_FAILURE',

    PAYMENT_REMOVE_REQUEST                        : 'PAYMENT_REMOVE_REQUEST',
    PAYMENT_REMOVE_SUCCESS                        : 'PAYMENT_REMOVE_SUCCESS',
    PAYMENT_REMOVE_FAILURE                        : 'PAYMENT_REMOVE_FAILURE',

    PAYMENT_LOOKUP_REQUEST                        : 'PAYMENT_LOOKUP_REQUEST',
    PAYMENT_LOOKUP_SUCCESS                        : 'PAYMENT_LOOKUP_SUCCESS',
    PAYMENT_LOOKUP_FAILURE                        : 'PAYMENT_LOOKUP_FAILURE',

    PAYMENT_SHOW_MODAL                            : 'PAYMENT_SHOW_MODAL',
    PAYMENT_SEND_PARAM                            : 'PAYMENT_SEND_PARAM',
    PAYMENT_INITIALIZE_DATA                       : 'PAYMENT_INITIALIZE_DATA',
    PAYMENT_SHOW_LOOKUP_MODAL                     : 'PAYMENT_SHOW_LOOKUP_MODAL',
    PAYMENT_INIT_SUCCESS                          : 'PAYMENT_INIT_SUCCESS',

    PAYMENT_IMPORT_REQUEST                        : 'PAYMENT_IMPORT_REQUEST',
    PAYMENT_IMPORT_SUCCESS                        : 'PAYMENT_IMPORT_SUCCESS',
    PAYMENT_IMPORT_FAILURE                        : 'PAYMENT_IMPORT_FAILURE',

    PAYMENT_EXPORT_REQUEST                        : 'PAYMENT_EXPORT_REQUEST',
    PAYMENT_EXPORT_SUCCESS                        : 'PAYMENT_EXPORT_SUCCESS',
    PAYMENT_EXPORT_FAILURE                        : 'PAYMENT_EXPORT_FAILURE',

    PAYMENT_COUNT_REQUEST                         : 'PAYMENT_COUNT_REQUEST',
    PAYMENT_COUNT_SUCCESS                         : 'PAYMENT_COUNT_SUCCESS',
    PAYMENT_COUNT_FAILURE                         : 'PAYMENT_COUNT_FAILURE',

    PAYMENT_GET_SAMPLE_FILE_REQUEST               : 'PAYMENT_GET_SAMPLE_FILE_REQUEST',
    PAYMENT_GET_SAMPLE_FILE_SUCCESS               : 'PAYMENT_GET_SAMPLE_FILE_SUCCESS',
    PAYMENT_GET_SAMPLE_FILE_FAILURE               : 'PAYMENT_GET_SAMPLE_FILE_FAILURE',

};

