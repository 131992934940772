/* -------------------------------------------------------------------------- */
/* HOME.JS                                                                    */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import React                                          from 'react';
import { Link }                                       from 'react-router-dom';
import { connect }                                    from 'react-redux';
import {Row, Col}                                     from 'antd';
import {Icon}                                         from 'semantic-ui-react';
import * as APP                                       from './../../_applib/_settings/config';


class Home extends React.Component {
    state = {}

    componentDidMount(){
      APP.LOG(APP.userAuthority());
      this.setState({
        tenant_all: APP.userAuthority().includes('tenant'),
        lease_all: APP.userAuthority().includes('lease'),
        lease_detail_all: APP.userAuthority().includes('lease_detail'),
        rent_all: APP.userAuthority().includes('rent'),
        payment_all: APP.userAuthority().includes('payment'),
        complex_all: APP.userAuthority().includes('complex'),
        service_all: APP.userAuthority().includes('service'),
        employee_all: APP.userAuthority().includes('employee'),
        building_all: APP.userAuthority().includes('building'),
        apartment_all: APP.userAuthority().includes('apartment'),
        company_all: APP.userAuthority().includes('company'),
        users_all: APP.userAuthority().includes('users'),
        userlevel_all: APP.userAuthority().includes('userlevel'),
        tenant_all: true,
        lease_all: true,
        lease_detail_all: true,
        rent_all: true,
        payment_all: true,
        complex_all: true,
        service_all: true,
        employee_all: true,
        building_all: true,
        apartment_all: true,
        company_all: true,
        users_all: true,
        userlevel_all: true,
      })
    }
    
    render_tenant() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/tenant' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/librarian.png')} width='60px' height='60px' className='m-2'/>
              Penyewa
            </Link>
            </button>
        </div>
    }


    render_lease() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/lease' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/quiz.png')} width='60px' height='60px' className='m-2'/>
              Sewa
            </Link>
            </button>
        </div>
    }


    render_lease_detail() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/lease_detail' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/quiz.png')} width='60px' height='60px' className='m-2'/>
              Sewa Detail
            </Link>
            </button>
        </div>
    }


    render_rent() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/rent' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/book.png')} width='60px' height='60px' className='m-2'/>
              Invoice Sewa
            </Link>
            </button>
        </div>
    }


    render_payment() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/payment' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/finance.png')} width='60px' height='60px' className='m-2'/>
              Payment
            </Link>
            </button>
        </div>
    }


    render_complex() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/complex' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/city.png')} width='60px' height='60px' className='m-2'/>
              Lokasi
            </Link>
            </button>
        </div>
    }


    render_service() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/service' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/borrow.png')} width='60px' height='60px' className='m-2'/>
              Jasa Perbaikan
            </Link>
            </button>
        </div>
    }


    render_employee() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/employee' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/writer.png')} width='60px' height='60px' className='m-2'/>
              Petugas
            </Link>
            </button>
        </div>
    }


    render_building() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/building' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/company.png')} width='60px' height='60px' className='m-2'/>
              Gedung
            </Link>
            </button>
        </div>
    }


    render_apartment() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/apartment' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/book.png')} width='60px' height='60px' className='m-2'/>
              Ruang Sewa
            </Link>
            </button>
        </div>
    }


    render_company() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/company' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/company.png')} width='60px' height='60px' className='m-2'/>
              Perusahaan
            </Link>
            </button>
        </div>
    }


    render_users() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/users' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/librarian.png')} width='60px' height='60px' className='m-2'/>
              Pengguna
            </Link>
            </button>
        </div>
    }


    render_userlevel() {
        return  <div>
            <button type='button' className='h-36 w-32 inline-flex justify-center rounded-md border border-transparent bg-blue-50 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
            <Link to='/userlevel' className='text-blue-900 font-semibold'>
              <img src={require('./../_static/_icons/quiz.png')} width='60px' height='60px' className='m-2'/>
              Tingkat Pengguna
            </Link>
            </button>
        </div>
    }


    render() {
        return (
          <div>
            <div className='header-bottom' >
            </div>
            <div className='content'>
              <Row><div>
              </div></Row><Row>
                <a className='anchor' id='section_building'></a>
                <Row><div className='pt-6 pb-2 font-bold'>Lokasi Bangunan</div></Row>
                <div class='grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8'>
                { this.render_complex()}
                { this.render_building()}
                { this.render_apartment()}
              </div></Row><Row>
                <a className='anchor' id='section_employee'></a>
                <Row><div className='pt-6 pb-2 font-bold'>Pekerja</div></Row>
                <div class='grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8'>
                { this.render_employee()}
              </div></Row><Row>
                <a className='anchor' id='section_payment'></a>
                <Row><div className='pt-6 pb-2 font-bold'>Pembayaran</div></Row>
                <div class='grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8'>
                { this.render_payment()}
              </div></Row><Row>
                <a className='anchor' id='section_rent'></a>
                <Row><div className='pt-6 pb-2 font-bold'>Penyewaan</div></Row>
                <div class='grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8'>
                { this.render_tenant()}
                { this.render_lease()}
                { this.render_lease_detail()}
                { this.render_rent()}
              </div></Row><Row>
                <a className='anchor' id='section_service'></a>
                <Row><div className='pt-6 pb-2 font-bold'>Perbaikan</div></Row>
                <div class='grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8'>
                { this.render_service()}
              </div></Row><Row>
                <a className='anchor' id='section_setting'></a>
                <Row><div className='pt-6 pb-2 font-bold'>Pengaturan</div></Row>
                <div class='grid gap-4 grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8'>
                { this.render_company()}
                { this.render_users()}
                { this.render_userlevel()}
                </div>
              </Row>
            </div>
          </div>
        );
    }
}


function mapStateToProps(state) {
    const { users, authenticate } = state;
    const { user } = authenticate;
    return {
        user,
        users
    };
}

const connectedHome = connect(mapStateToProps)(Home);
export { connectedHome as Home };
