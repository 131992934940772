'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('company_id'): {
        let company_id    = value
        let isValid = true

      if (company_id.length > 0) {
        if (company_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'company_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'company_id_error_msg'], 'ID Perusahaan perlu di isi')
        }

        if (company_id.length > 10) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'company_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'company_id_error_msg'], 'ID Perusahaan panjang maksimumnya 10 karakter.')
        }

        if (!check.validateAlpha(company_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'company_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'company_id_error_msg'], 'ID Perusahaan tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'company_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'company_id_error_msg'], '')
        }
    }

    case ('company_name'): {
        let company_name    = value
        let isValid = true

        if (company_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'company_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'company_name_error_msg'], 'Nama Perusahaan perlu di isi')
        }

        if (company_name.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'company_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'company_name_error_msg'], 'Nama Perusahaan panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(company_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'company_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'company_name_error_msg'], 'Nama Perusahaan tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'company_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'company_name_error_msg'], '')
        }
    }

    case ('address'): {
        let address    = value
        let isValid = true

        if (address.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], true)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], 'Alamat perlu di isi')
        }

        if (address.length > 100) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], true)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], 'Alamat panjang maksimumnya 100 karakter.')
        }

        if (!check.validateAddress(address)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], true)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], 'Alamat tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], false)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], '')
        }
    }

    case ('phone'): {
        let phone    = value
        let isValid = true

        if (phone.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'Nomor Telepon perlu di isi')
        }

        if (phone.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'Nomor Telepon panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(phone)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], true)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], 'Nomor Telepon tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'phone_has_error'], false)
            .setIn(['form', 'gpDataFields', 'phone_error_msg'], '')
        }
    }

    case ('email'): {
        let email    = value
        let isValid = true

        if (email.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email perlu di isi')
        }

        if (email.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email panjang maksimumnya 50 karakter.')
        }

        if (!check.validateEmail(email)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], true)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], 'Email tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'email_has_error'], false)
            .setIn(['form', 'gpDataFields', 'email_error_msg'], '')
        }
    }

  }
  return state
}
