export function validateEmail(value) {
    var rules = /^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rules.test(String(value).toLowerCase());
}

export function validateName(value) {
    var rules = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
    return rules.test(String(value).toLowerCase());
}

export function validateText(value) {
    var rules = /^[a-zA-Z0-9-,_.!?/\\'\"\s]*$/;
    return rules.test(String(value).toLowerCase());
}

export function validateAlpha(value) {
    var rules = /^[a-zA-Z0-9-,_.!?/\\'\"\s]*$/;
    return rules.test(String(value).toLowerCase());
}

export function validateNumber(value) {
    var rules = /^(0|[1-9]\d*)$/;
    return rules.test(String(value).toLowerCase());
}

export function validateBoolean(value) {
    var rules = /^[0-9]*$/;
    return rules.test(String(value).toLowerCase());
}

export function validateDecimal(value) {
    var rules = /^((\d+(\.\d*)?)|(\.\d+))$/;
    return rules.test(String(value).toLowerCase());
}

export function validateDate(value) {
    var rules = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return rules.test(String(value).toLowerCase());
}

export function validatePhone(value) {
    var rules = /^(0|[1-9]\d*)$/;
    return rules.test(String(value).toLowerCase());
}

export function validateAddress(value) {
    var rules = /^[a-zA-Z0-9,.!?/\\'\"\s]*$/;
    return rules.test(String(value).toLowerCase());
}

export function validateMoney(value) {
    var rules = /^((\d+(\.\d*)?)|(\.\d+))$/;
    return rules.test(String(value).toLowerCase());
}

export function validatePercent(value) {
    var rules = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;
    return rules.test(String(value).toLowerCase());
}

