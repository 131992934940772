'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.employee_id_has_error &&
        state.form.gpDataFields.employee_name !== '' &&
        !state.form.gpDataFields.employee_name_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpEmployeeIsValid'], true)
  } else {
    return state.setIn(['form', 'gpEmployeeIsValid'], false)
  }
}
