import React                                          from 'react';
import ReactDOM                                       from 'react-dom';
import './_applib/_static/_styles/index.css';

import App                                            from './_applib/_app/app';
import { Provider }                                   from 'react-redux';

import { store }                                      from './_applib/_helpers';
import registerServiceWorker                          from './_applib/_helpers/registerServiceWorker';

import {registerObserver} from 'react-perf-devtool';

registerObserver();

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
