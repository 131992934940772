/* -------------------------------------------------------------------------- */
/* Import definition start                                                    */
/* -------------------------------------------------------------------------- */
import React                                           from 'react';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import ReportList                                      from './list';
import {
  Row,
  Icon,
  Breadcrumb,
  Col,
}                                                      from 'antd';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

class Report extends React.Component {

  render() {
    return (
      <div>
        <div className='header-middle' >
          Report
        </div>
        <div className='content' >
        <Row>
          <Col span={24}>
            <ReportList />
          </Col>
        </Row>
       </div>
      </div>
    );
  }
}

function mapStateToProps(state) {

}

const connectedReport = connect(mapStateToProps)(Report);
export { connectedReport as Report };

