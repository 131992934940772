export const tenantConstants = {
    ON_TENANT_FORM_FIELD_CHANGE                   : 'ON_TENANT_FORM_FIELD_CHANGE',
    CHANGE_TENANT_FIELD                           : 'CHANGE_TENANT_FIELD',
    ON_TENANT_STATE_CHANGE                        : 'ON_TENANT_STATE_CHANGE',

    TENANT_GET_LIST_REQUEST                       : 'TENANT_GET_LIST_REQUEST',
    TENANT_GET_LIST_SUCCESS                       : 'TENANT_GET_LIST_SUCCESS',
    TENANT_GET_LIST_FAILURE                       : 'TENANT_GET_LIST_FAILURE',

    TENANT_VIEW_REQUEST                           : 'TENANT_VIEW_REQUEST',
    TENANT_VIEW_SUCCESS                           : 'TENANT_VIEW_SUCCESS',
    TENANT_VIEW_FAILURE                           : 'TENANT_VIEW_FAILURE',

    TENANT_ADD_REQUEST                            : 'TENANT_ADD_REQUEST',
    TENANT_ADD_SUCCESS                            : 'TENANT_ADD_SUCCESS',
    TENANT_ADD_FAILURE                            : 'TENANT_ADD_FAILURE',

    TENANT_CHANGE_REQUEST                         : 'TENANT_CHANGE_REQUEST',
    TENANT_CHANGE_SUCCESS                         : 'TENANT_CHANGE_SUCCESS',
    TENANT_CHANGE_FAILURE                         : 'TENANT_CHANGE_FAILURE',

    TENANT_FILTER_REQUEST                         : 'TENANT_FILTER_REQUEST',
    TENANT_FILTER_SUCCESS                         : 'TENANT_FILTER_SUCCESS',
    TENANT_FILTER_FAILURE                         : 'TENANT_FILTER_FAILURE',

    TENANT_REMOVE_REQUEST                         : 'TENANT_REMOVE_REQUEST',
    TENANT_REMOVE_SUCCESS                         : 'TENANT_REMOVE_SUCCESS',
    TENANT_REMOVE_FAILURE                         : 'TENANT_REMOVE_FAILURE',

    TENANT_LOOKUP_REQUEST                         : 'TENANT_LOOKUP_REQUEST',
    TENANT_LOOKUP_SUCCESS                         : 'TENANT_LOOKUP_SUCCESS',
    TENANT_LOOKUP_FAILURE                         : 'TENANT_LOOKUP_FAILURE',

    TENANT_SHOW_MODAL                             : 'TENANT_SHOW_MODAL',
    TENANT_SEND_PARAM                             : 'TENANT_SEND_PARAM',
    TENANT_INITIALIZE_DATA                        : 'TENANT_INITIALIZE_DATA',
    TENANT_SHOW_LOOKUP_MODAL                      : 'TENANT_SHOW_LOOKUP_MODAL',
    TENANT_INIT_SUCCESS                           : 'TENANT_INIT_SUCCESS',

    TENANT_IMPORT_REQUEST                         : 'TENANT_IMPORT_REQUEST',
    TENANT_IMPORT_SUCCESS                         : 'TENANT_IMPORT_SUCCESS',
    TENANT_IMPORT_FAILURE                         : 'TENANT_IMPORT_FAILURE',

    TENANT_EXPORT_REQUEST                         : 'TENANT_EXPORT_REQUEST',
    TENANT_EXPORT_SUCCESS                         : 'TENANT_EXPORT_SUCCESS',
    TENANT_EXPORT_FAILURE                         : 'TENANT_EXPORT_FAILURE',

    TENANT_COUNT_REQUEST                          : 'TENANT_COUNT_REQUEST',
    TENANT_COUNT_SUCCESS                          : 'TENANT_COUNT_SUCCESS',
    TENANT_COUNT_FAILURE                          : 'TENANT_COUNT_FAILURE',

    TENANT_GET_SAMPLE_FILE_REQUEST                : 'TENANT_GET_SAMPLE_FILE_REQUEST',
    TENANT_GET_SAMPLE_FILE_SUCCESS                : 'TENANT_GET_SAMPLE_FILE_SUCCESS',
    TENANT_GET_SAMPLE_FILE_FAILURE                : 'TENANT_GET_SAMPLE_FILE_FAILURE',

};

