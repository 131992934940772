'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.lease_id_has_error &&
        state.form.gpDataFields.lease_description !== '' &&
        !state.form.gpDataFields.lease_description_has_error &&
        state.form.gpDataFields.start_date !== '' &&
        !state.form.gpDataFields.start_date_has_error &&
        state.form.gpDataFields.end_date !== '' &&
        !state.form.gpDataFields.end_date_has_error &&
        state.form.gpDataFields.deposit !== '' &&
        !state.form.gpDataFields.deposit_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpLeaseIsValid'], true)
  } else {
    return state.setIn(['form', 'gpLeaseIsValid'], false)
  }
}
