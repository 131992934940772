'use strict'

export class AuthToken {
  constructor () {
    this.SESSION_TOKEN_KEY = 'user';
  }

  storeAuthToken (token) {
    return localStorage.setItem(this.SESSION_TOKEN_KEY, {sessionToken: token})
  }

  getAuthToken() {
    return localStorage.getItem(this.SESSION_TOKEN_KEY)
  }

  deleteAuthToken () {
    return localStorage.removeItem(this.SESSION_TOKEN_KEY)
  }
}

export let authToken = new AuthToken()
