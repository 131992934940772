'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('payment_id'): {
        let payment_id    = value
        let isValid = true

      if (payment_id.length > 0) {
        if (payment_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'payment_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'payment_id_error_msg'], 'ID Pembayaran perlu di isi')
        }

        if (payment_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'payment_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'payment_id_error_msg'], 'ID Pembayaran panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(payment_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'payment_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'payment_id_error_msg'], 'ID Pembayaran tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'payment_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'payment_id_error_msg'], '')
        }
    }

    case ('payment_date'): {
        let payment_date    = value
        let isValid = true

        if (!check.validateDate(payment_date)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'payment_date_has_error'], true)
            .setIn(['form', 'gpDataFields', 'payment_date_error_msg'], 'Tanggal Bayar tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'payment_date_has_error'], false)
            .setIn(['form', 'gpDataFields', 'payment_date_error_msg'], '')
        }
    }

    case ('Payment_amount'): {
        let Payment_amount    = value
        let isValid = true

        if (Payment_amount.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'Payment_amount_has_error'], true)
            .setIn(['form', 'gpDataFields', 'Payment_amount_error_msg'], 'Jumlah Bayar perlu di isi')
        }

        if (Payment_amount.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'Payment_amount_has_error'], true)
            .setIn(['form', 'gpDataFields', 'Payment_amount_error_msg'], 'Jumlah Bayar panjang maksimumnya 50 karakter.')
        }

        if (!check.validateMoney(Payment_amount)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'Payment_amount_has_error'], true)
            .setIn(['form', 'gpDataFields', 'Payment_amount_error_msg'], 'Jumlah Bayar tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'Payment_amount_has_error'], false)
            .setIn(['form', 'gpDataFields', 'Payment_amount_error_msg'], '')
        }
    }

  }
  return state
}
