'use strict'

import { authenticateConstants }                            from './../_constants/authenticate.constants';
import { globalConstants }                          from './../_constants/global.constants';
import InitialState                                 from './../_initialstates/global.initialstate';

const initialState = new InitialState()
export default function globalReducer (state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.merge(state)

  switch (action.type) {
    case authenticateConstants.SET_SESSION_TOKEN:
      console.log('[global.reducer][login_success][action.payload]:'+ JSON.stringify(action.payload));
      return state.set('sessionToken', action.payload)
    case authenticateConstants.SIGNUP_SUCCESS:
    case authenticateConstants.LOGIN_SUCCESS:
      console.log('[global.reducer][login_x][action.payload]:'+ JSON.stringify(action.payload));
      return state.set('currentUser', action.payload)
    case authenticateConstants.SESSION_TOKEN_SUCCESS:
      console.log('[global.reducer][save_token_success][action.payload.sessionToken]:'+ JSON.stringify(action.payload.sessionToken));
      return state.set('currentUser', action.payload.sessionToken)
    case authenticateConstants.LOGOUT_SUCCESS:
      console.log('[global.reducer][logout_success]');
      return state.set('currentUser', null)
    case globalConstants.SET_STORE:
      console.log('[global.reducer][set_store][action.payload]:' + JSON.stringify(action.payload));
      return state.set('store', action.payload)
    case globalConstants.GET_STATE: {
      let _state = state.store.getState()
      if (action.payload) {
        let newState = {}
        newState['auth'] = _state.authenticate.toJS()
        newState['device'] = _state.device.toJS()
        newState['customer'] = _state.customer.toJS()
        newState['global'] = _state.global.set('currentState', null).set('store', null).toJS()
        return state.set('showState', action.payload)
        .set('currentState', newState)
      } else {
        return state.set('showState', action.payload)
      }
    }
    case globalConstants.SET_STATE:
      var global = JSON.parse(action.payload).global
      var next = state.set('currentUser', global.currentUser)
          .set('showState', false)
          .set('currentState', null)
      return next
  }

  return state
}
