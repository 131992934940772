'use strict'
import {userlevelConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/userlevel.initialstate'
import fieldValidation                              from './../_validation/userlevel.field.validation'
import formValidation                               from './../_validation/userlevel.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case userlevelConstants.USERLEVEL_GET_LIST_REQUEST:
    case userlevelConstants.USERLEVEL_LOOKUP_REQUEST:
    case userlevelConstants.USERLEVEL_ADD_REQUEST:
    case userlevelConstants.USERLEVEL_CHANGE_REQUEST:
    case userlevelConstants.USERLEVEL_FILTER_REQUEST:
    case userlevelConstants.USERLEVEL_VIEW_REQUEST:
    case userlevelConstants.USERLEVEL_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpUserlevelIsFetching'],  true)

    case userlevelConstants.USERLEVEL_CHANGE_SUCCESS:
    case userlevelConstants.USERLEVEL_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpUserlevelIsFetching'],  false)

    case userlevelConstants.USERLEVEL_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpUserlevelIsFetching'],  false)
          .setIn(['form', 'userlevel_count'],  action.payload)

    case userlevelConstants.USERLEVEL_FILTER_SUCCESS:
    case userlevelConstants.USERLEVEL_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpUserlevelDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpUserlevelDataList'], data.rows)
          .setIn(['form', 'gpUserlevelDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpUserlevelIsFetching'],  false)


  case userlevelConstants.USERLEVEL_LOOKUP_SUCCESS:
    console.log('[userlevel.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpUserlevelLookupList'], action.payload)
        .setIn(['form', 'gpUserlevelIsFetching'], false)


    case userlevelConstants.USERLEVEL_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'id_userlevel'],'')
          .setIn(['form', 'gpDataFields',                                                           'role_name'],'')

    case userlevelConstants.USERLEVEL_SHOW_MODAL:
      return state
        .setIn(['form', 'gpUserlevelShowModal'],  action.payload)


    case userlevelConstants.USERLEVEL_SEND_PARAM:
      return state
        .setIn(['form', 'gpUserlevelModalParam'],  action.payload[0])


    case userlevelConstants.USERLEVEL_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'id_userlevel'],                                      action.payload[0].id_userlevel)
          .setIn(['form', 'gpOriginalFields', 'id_userlevel'],                                      action.payload[0].id_userlevel)
          .setIn(['form', 'gpDataFields',     'role_name'],                                         action.payload[0].role_name)
          .setIn(['form', 'gpOriginalFields', 'role_name'],                                         action.payload[0].role_name)
          .setIn(['form', 'gpUserlevelIsFetching'],                                                 false)
          return nextRecordState


    case userlevelConstants.USERLEVEL_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'id_userlevel'],                                          '')
          .setIn(['form', 'gpDataFields', 'role_name'],                                             '')
          .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'],                                false)
          .setIn(['form', 'gpDataFields', 'role_name_has_error'],                                   false)

          .setIn(['form', 'gpUserlevelIsValid'],                                                    false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('userlevelReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'id_userlevel'],                                          '')
          .setIn(['form', 'gpDataFields', 'role_name'],                                             '')
          .setIn(['form', 'gpUserlevelError'],                                                      null)
          return formValidation(nextRecordState, action)


    case userlevelConstants.USERLEVEL_GET_LIST_FAILURE:
    case userlevelConstants.USERLEVEL_LOOKUP_FAILURE:
    case userlevelConstants.USERLEVEL_ADD_FAILURE:
    case userlevelConstants.USERLEVEL_CHANGE_FAILURE:
    case userlevelConstants.USERLEVEL_FILTER_FAILURE:
    case userlevelConstants.USERLEVEL_VIEW_FAILURE:
    case userlevelConstants.USERLEVEL_COUNT_FAILURE:
      console.log('userlevel.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpUserlevelIsFetching'], false)
          .setIn(['form', 'gpUserlevelError'], action.payload)


   case userlevelConstants.ON_USERLEVEL_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case userlevelConstants.ON_USERLEVEL_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case userlevelConstants.CHANGE_USERLEVEL_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpUserlevelError'], null)
      return nextState}

    case userlevelConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'id_userlevel'],                                          record.id_userlevel)
          .setIn(['form', 'gpOriginalFields', 'id_userlevel'],                                      record.id_userlevel)
          .setIn(['form', 'gpDataFields', 'role_name'],                                             record.role_name)
          .setIn(['form', 'gpOriginalFields', 'role_name'],                                         record.role_name)

          .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'],                                record.id_userlevel_has_error)
          .setIn(['form', 'gpDataFields', 'role_name_has_error'],                                   record.role_name_has_error)



          .setIn(['form', 'gpUserlevelDataList'],                                                   record.gpUserlevelDataList)
          .setIn(['form', 'gpUserlevelIsDisabled'],                                                 record.gpUserlevelIsDisabled)
          .setIn(['form', 'gpUserlevelError'],                                                      record.gpUserlevelError)
          .setIn(['form', 'gpUserlevelIsValid'],                                                    record.gpUserlevelIsValid)
          .setIn(['form', 'gpUserlevelIsFetching'],                                                 record.gpUserlevelIsFetching)
          .setIn(['form', 'userlevel_count'],                                                       record.userlevel_count)
      return next
case userlevelConstants.USERLEVEL_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpUserlevelLookupShowModal'],  action.payload)


  }
  return state
}
