// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as employeeActions                            from '../_actions/employee.actions';
import * as complexActions                             from './../_actions/complex.actions';
import ComplexEdit                                     from './../complex/edit';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     employee: {
       gpEmployeeShowModal                        : state.employee.form.gpEmployeeShowModal,
       gpEmployeeModalParam                       : state.employee.form.gpEmployeeModalParam,
       gpEmployeeIsFetching                       : state.employee.form.gpEmployeeIsFetching,
       gpDataFields                               : state.employee.form.gpDataFields,
       gpEmployeeIsValid                          : state.employee.form.gpEmployeeIsValid,
     },
     complex: {
      gpDataFields                                : state.complex.form.gpDataFields,
      gpcomplexIsFetching                         : state.complex.form.gpcomplexIsFetching,
     },
     complex_incoming: {
       gpComplexLookupList                        : state.complex.form.gpComplexLookupList,
       gpComplexError                             : state.complex.form.gpComplexError,
       gpComplexIsFetching                        : state.complex.form.gpComplexIsFetching,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...employeeActions,
      ...complexActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
          complex_id                              : this.props.complex.gpDataFields.complex_id,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editEmployee(this.props.match.params.employee_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addEmployee());
      }

      this.props.actions._onEmployeeFormFieldChange('complex_id', this.props.complex.gpDataFields.complex_id);
    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.employee.gpEmployeeModalParam) {
        if (nextProps.employee.gpEmployeeModalParam !== this.props.employee.gpEmployeeModalParam) {
          console.log('Information: gpEmployeeModalParam props changed');
          if(nextProps.employee.gpEmployeeModalParam['trxType']==='edt') {
            this.setState({
              employee_id      : nextProps.employee.gpEmployeeModalParam['employee_id'],
              trxType          : nextProps.employee.gpEmployeeModalParam['trxType'],
            }, this._editEmployee(nextProps.employee.gpEmployeeModalParam['employee_id']));
          };

          if(nextProps.employee.gpEmployeeModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.employee.gpEmployeeModalParam['trxType'],
            }, this._addEmployee());
          };

          if(nextProps.employee.gpEmployeeModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.employee.gpEmployeeModalParam['trxType'],
            }, this._filterEmployee());
          };

        };
      };

      if(nextProps.employee.gpEmployeeShowModal) {
        if (nextProps.employee.gpEmployeeShowModal !== this.props.employee.gpEmployeeShowModal) {
          console.log('Information: gpEmployeeShowModal props changed');
          if(nextProps.employee.gpEmployeeShowModal==='show'){
            this.setState({
              lsEmployeeShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.employee.gpEmployeeShowModal==='hide'){
            this.setState({
              lsEmployeeShowModal: false,
            });
          }
        };
      };

      this.setState({
        employee_id                               : nextProps.employee.gpDataFields.employee_id,
        employee_name                             : nextProps.employee.gpDataFields.employee_name,
        complex_id                                : nextProps.employee.gpDataFields.complex_id,
        complex_complex_id                        : nextProps.employee.gpDataFields.complex_complex_id,
        date_add                                  : nextProps.employee.gpDataFields.date_add,
        date_upd                                  : nextProps.employee.gpDataFields.date_upd,
      });

}
_addComplex() {
  this.props.actions._activateAddComplex();
}

_addEmployeeOnServer() {
    var id1 = this.state.employee_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(5);
    } else {
      id3 = this.state.employee_id;
    }
    const data = {
        employee_id                               : id3,
        employee_name                             : this.state.employee_name,
        complex_id                                : this.state.complex_id,
        complex_complex_id                        : this.state.complex_complex_id,
    }
    this.props.actions._addEmployee(data);
    this.goBack();
}

_editEmployeeOnServer() {
    const data = {
        employee_id                               : this.state.employee_id,
        employee_name                             : this.state.employee_name,
        complex_id                                : this.state.complex_id,
        complex_complex_id                        : this.state.complex_complex_id,
    }
    this.props.actions._editEmployee(data);
    this.goBack();
}

_filterEmployeeOnServer() {
    const data = {
        employee_id                               : this.state.employee_id,
        employee_name                             : this.state.employee_name,
        complex_id                                : this.state.complex_id,
        complex_complex_id                        : this.state.complex_complex_id,
    }
    this.props.actions._filterEmployee(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit employee                                                              */
/* -------------------------------------------------------------------------- */
_editEmployee(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewEmployee(id);
  this.props.actions._lookupComplex();
}

/* -------------------------------------------------------------------------- */
/*  add new employee                                     
/* -------------------------------------------------------------------------- */
_addEmployee() {
    let today = GF.today();
    this.props.actions._onEmployeeFormFieldChange('employee_id', '')
    this.props.actions._onEmployeeFormFieldChange('employee_name', '')
    this.props.actions._initEmployee();

    this.setState({
         employee_id                              : '',
         employee_name                            : '',
        complex_id                                : this.state.complex_id,
         isIDDisabled                             : false,
         lsEmployeeDetail                         : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
  this.props.actions._lookupComplex();
}

/* -------------------------------------------------------------------------- */
/*  filter employee                                     
/* -------------------------------------------------------------------------- */
_filterEmployee() {

    let today = GF.today();
    this.setState({
         employee_id                              : '',
         employee_name                            : '',
         lsEmployeeDetail                         : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
  this.props.actions._lookupComplex();
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showEmployeeModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addEmployeeOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editEmployeeOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterEmployeeOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showEmployeeModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[employee.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onEmployeeFormFieldChange(field, value)
}

onComplex_idChange = (e, data) => {
  const lvValue   = data.value;
  const lvField   = 'complex_id';
     if (lvValue !== '') {
       this.props.actions._onEmployeeFormFieldChange(lvField, lvValue)
     }
}

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsEmployeeShowModal,
    } = this.state;


     const {
       employee_id,
       employee_name,
       complex_complex_id,
       complex_id,
       complex_id_has_error,
       complex_id_error_msg,
       date_add,
       date_upd,
       employee_id_has_error,
       employee_name_has_error,
       date_add_has_error,
       date_upd_has_error,
       employee_id_error_msg,
       employee_name_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.employee.gpDataFields;


const listComplexOptions = _.map(this.props.complex_incoming.gpComplexLookupList, (Complex, index) => ({
  key: Complex.value,
  text: Complex.value  + ' - ' + Complex.label,
  value: Complex.value,
}))

const DropdownComplex_Complex_Id = () => (
  <Dropdown onChange={this.onComplex_idChange} placeholder='Complex' value={complex_id} search options={listComplexOptions} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'/>
)

const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Employee'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add employee start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add employee body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Petugas
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={6}>
          <b>ID Pekerja</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='employee_id'
              placeholder='[AUTO]'
              value={employee_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {employee_id_has_error?<div style={{color:'lightcoral'}}>{employee_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Nama Pekerja</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='employee_name'
              placeholder='Masukan Nama Pekerja'
              value={employee_name}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {employee_name_has_error?<div style={{color:'lightcoral'}}>{employee_name_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
         <Col lg={6} md={10}>
           <b>ID Komplek</b><br />
           <div className='spacer' />
           <DropdownComplex_Complex_Id />
           &nbsp;
           <Link className='text-blue-900' to='/complex/add/'>
             <button type='button' className='inline-flex h-10 justify-center rounded-md border text-center border-transparent bg-blue-100 px-2 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
               <Icon name='plus' />
             </button>
           </Link>
           <div className='spacer' />
           <br />
         </Col>
        </Row>
        <div className='spacer' />
        <br />
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.employee.gpEmployeeIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add employee body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add employee End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
