import React                                          from 'react';
import { Link }                                       from 'react-router-dom';
import { connect }                                    from 'react-redux';
import {Row, Col}                                     from 'antd';

class Setting extends React.Component {
    render() {
        return (
          <div>
            <div className='header-bottom' >
            </div>
            <div className='content'>
              <Row>

              </Row>
            </div>
          </div>
        );
    }
}

function mapStateToProps(state) {
    const { users, authenticate } = state;
    const { user } = authenticate;
    return {
        user,
        users
    };
}

const connectedSetting = connect(mapStateToProps)(Setting);
export { connectedSetting as Setting };
