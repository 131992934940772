/* -------------------------------------------------------------------------- */
/* USERLEVEL.ACTIONS.JS                                                       */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {userlevelConstants}                            from '../_constants';
import {userlevelService}                              from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const userlevelActions = {
    _addUserlevel,
    _editUserlevel,
    _filterUserlevel,
    _viewUserlevel,
    _listUserlevel,
    _removeUserlevel,
    _lookupUserlevel,
    _showUserlevelModalForm,
    _sendUserlevelFormParam,
    _activateAddUserlevel,
    _activateEditUserlevel,
    _activateFilterUserlevel,
    _onUserlevelStateChange,
    _onUserlevelFormFieldChange,
    action_to_import_userlevel,
    action_to_export_userlevel,
    action_to_get_sample_userlevel,
    action_to_count_userlevel,
};

export function _addUserlevel(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  userlevelService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupUserlevel());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_USERLEVEL_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: userlevelConstants.USERLEVEL_ADD_REQUEST         }}
    function success(result)  { return { type: userlevelConstants.USERLEVEL_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: userlevelConstants.USERLEVEL_ADD_FAILURE}}
}

export function _editUserlevel(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  userlevelService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupUserlevel());
          dispatch(_viewUserlevel(data.id_userlevel));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_USERLEVEL_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: userlevelConstants.USERLEVEL_CHANGE_REQUEST         }}
    function success(result)  { return { type: userlevelConstants.USERLEVEL_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: userlevelConstants.USERLEVEL_CHANGE_FAILURE, payload: error }}
}

export function _filterUserlevel(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  userlevelService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_USERLEVEL_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: userlevelConstants.USERLEVEL_FILTER_REQUEST         }}
    function success(result, page)  { return { type: userlevelConstants.USERLEVEL_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: userlevelConstants.USERLEVEL_FILTER_FAILURE}}
}

export function _removeUserlevel(id) {
      const data = {
        id_userlevel                              : '', 
        role_name                                 : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'id_userlevel',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        userlevelService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listUserlevel(data));
          dispatch(_lookupUserlevel());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_USERLEVEL_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: userlevelConstants.USERLEVEL_REMOVE_REQUEST        }}
    function success(result) { return { type: userlevelConstants.USERLEVEL_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: userlevelConstants.USERLEVEL_REMOVE_FAILURE}}
}

export function _initUserlevel() {
  return dispatch => {dispatch(success());};
  function success() { return { type: userlevelConstants.USERLEVEL_INIT_SUCCESS}}
}

export function _viewUserlevel(id) {
  return dispatch => {
      dispatch(request({id}));

      userlevelService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_USERLEVEL_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: userlevelConstants.USERLEVEL_VIEW_REQUEST} }
  function success(result) { return { type: userlevelConstants.USERLEVEL_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: userlevelConstants.USERLEVEL_VIEW_FAILURE}}
}

export function action_to_count_userlevel() {
  return dispatch => {
      dispatch(request());
      userlevelService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_USERLEVEL_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: userlevelConstants.USERLEVEL_COUNT_REQUEST} }
  function success(result) { return { type: userlevelConstants.USERLEVEL_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: userlevelConstants.USERLEVEL_COUNT_FAILURE}}
}

export function _listUserlevel(data) {
    return dispatch => {
        dispatch(request(data));
        userlevelService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_USERLEVEL_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: userlevelConstants.USERLEVEL_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: userlevelConstants.USERLEVEL_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: userlevelConstants.USERLEVEL_GET_LIST_FAILURE}}
}
export function _lookupUserlevel() {
  return dispatch => {
    dispatch(request());
    userlevelService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_USERLEVEL_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: userlevelConstants.USERLEVEL_LOOKUP_REQUEST         }}
    function success(result) { return { type: userlevelConstants.USERLEVEL_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: userlevelConstants.USERLEVEL_LOOKUP_FAILURE}}
}

export function _activateEditUserlevel(data) {
  console.log('[userlevel.action][_activateEditUserlevel][started]');
  const param = [{
      id_userlevel        : data[0].id_userlevel,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showUserlevelModalForm('show'))
    dispatch(_sendUserlevelFormParam(param));
  }
}

export function _activateAddUserlevel() {
  console.log('[userlevel.action][_activateAddUserlevel][started]');
  const param = [{
      id_userlevel        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initUserlevel());
    dispatch(_showUserlevelModalForm('show'));
    dispatch(_sendUserlevelFormParam(param));
  }
}

export function _activateFilterUserlevel() {
  console.log('[userlevel.action][_activateFilterUserlevel][started]');
  const param = [{
      id_userlevel        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initUserlevel());
    dispatch(_showUserlevelModalForm('show'));
    dispatch(_sendUserlevelFormParam(param));
  }
}

export function _onUserlevelFormFieldChange (field, value) {
  console.log('[userlevel.action][onUserlevelFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: userlevelConstants.ON_USERLEVEL_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onUserlevelStateChange (field, value) {
  return {
    type: userlevelConstants.ON_USERLEVEL_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showUserlevelModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:userlevelConstants.USERLEVEL_SHOW_MODAL, payload: showModal}}
}

export function _sendUserlevelFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: userlevelConstants.USERLEVEL_SEND_PARAM, payload: param}}
}

export function _showUserlevelLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:userlevelConstants.USERLEVEL_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_userlevel(data) {
      const param = {
        id_userlevel                              : '', 
        role_name                                 : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'id_userlevel',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  userlevelService.service_to_import_userlevel(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listUserlevel(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_USERLEVEL_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_USERLEVEL_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_USERLEVEL_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: userlevelConstants.USERLEVEL_IMPORT_REQUEST         }}
    function success(result)  { return { type: userlevelConstants.USERLEVEL_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: userlevelConstants.USERLEVEL_IMPORT_FAILURE}}
}

export function action_to_export_userlevel() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  userlevelService.service_to_export_userlevel()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_USERLEVEL_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_USERLEVEL_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: userlevelConstants.USERLEVEL_EXPORT_REQUEST         }}
    function success(result)  { return { type: userlevelConstants.USERLEVEL_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: userlevelConstants.USERLEVEL_EXPORT_FAILURE}}
}

export function action_to_get_sample_userlevel() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  userlevelService.service_to_get_sample_userlevel()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_USERLEVEL_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_USERLEVEL_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_USERLEVEL_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: userlevelConstants.USERLEVEL_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: userlevelConstants.USERLEVEL_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: userlevelConstants.USERLEVEL_GET_SAMPLE_FILE_FAILURE}}
}

