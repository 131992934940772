/* -------------------------------------------------------------------------- */
/* Import definition start                                                    */
/* -------------------------------------------------------------------------- */
import React, { Component }                           from 'react';
import { bindActionCreators }                         from 'redux';
import { connect }                                    from 'react-redux';
import { Link }                                       from 'react-router-dom';
import ComplexEdit                               from './edit';
import moment                                          from 'moment';
import {
  complexActions,
}                                                     from '../_actions';
import {
  Row,
  Col,
  Input,
  Popconfirm,
  DatePicker,
}                                                     from 'antd';

import {
  Button,
  Icon,
  Pagination,
  Table,
  Image,
  Modal
}                                                     from 'semantic-ui-react';

import ContentLoader                                  from 'react-content-loader'
import * as APP                                       from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';


/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...complexActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
    return {
    complex: {
        gpComplexDataList                         : state.complex.form.gpComplexDataList,
        gpComplexDataListNumRows                  : state.complex.form.gpComplexDataListNumRows,
        gpComplexIsFetching                       : state.complex.form.gpComplexIsFetching, 
        gpDataFields                              : state.complex.form.gpDataFields, 
        gpComplexShowModal                        : state.complex.form.gpComplexShowModal,
      },
      global: {
        currentUser : state.global.currentUser
      }
    };
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const dateFormat    = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */

class DataList extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        search_complex_id                         : '',
        search_complex_name                       : '',
        search_address                            : '',
        lsComplexIsFetching                       : true,
        lsComplexDataList                         : [],
        lsPagination                              : {},
        lsPageSize                                : 10,
        lsCurrentPage                             : 1,
        lsSortedBy                                : 'complex_id',
        lsSortOrder                               : 'descend',
        lsTotalPage                               : 0,
        lsNumberOfRows                            : 0,
        lsSearchText                              : '',
        lsLoadingComplex                          : true,
        direction                                 : null,
        file                                      : null,
}


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
  componentWillReceiveProps (nextProps) {

    if (nextProps.complex.gpComplexDataList){
      if (nextProps.complex.gpComplexDataList !== this.props.complex.gpComplexDataList) {
        this.setState({
          lsComplexDataList                       : nextProps.complex.gpComplexDataList,
          lsNumberOfRows                          : nextProps.complex.gpComplexDataListNumRows,
          lsTotalPage                             : Math.ceil(nextProps.complex.gpComplexDataListNumRows/this.state.lsPageSize)
        });

      };
    };

      this.setState({
        search_complex_id                         : nextProps.complex.gpDataFields.search_complex_id,
        search_complex_name                       : nextProps.complex.gpDataFields.search_complex_name,
        search_address                            : nextProps.complex.gpDataFields.search_address,
      });

};

/* -------------------------------------------------------------------------- */
/*  componentdidmount                                                         */
/* -------------------------------------------------------------------------- */
  componentDidMount(){
    this._loadComplexDataSets();
    APP.LOG(APP.userAuthority());
    this.setState({
      complex_all: true,
      complex_add: true,
      complex_edt: true,
      complex_del: true,
      complex_inq: true,
    })
}

    fileInputRef = React.createRef();

    fileChange(e){
      this.setState({ file: e.target.files[0] }, () => {
        console.log('File chosen --->', this.state.file);
      });
    };

    fileUpload(){
      console.log('file: ' + this.state.file);
      const formData = new FormData();
      formData.append('file', this.state.file, this.state.file.name);
      this.props.actions.action_to_import_complex(formData);
      this.setState({show: false});
      return true;
    };

    _onChange(e){
      const value   = e.target.value;
      const field   = e.target.name;
      console.log('[complex.list][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
      this.props.actions._onComplexFormFieldChange(field, value)
    }


    _refresh(){
      this.props.actions._onComplexFormFieldChange('search_complex_id','')
      this.props.actions._onComplexFormFieldChange('search_complex_name','')
      this.props.actions._onComplexFormFieldChange('search_address','')

      const data = {
        complex_id                                : '', 
        complex_name                              : '', 
        address                                   : '', 
        page_size                                 : this.state.lsPageSize,
        current_page                              : 1,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
      }

      this.setState({
        lsCurrentPage      : 1,
      }, this.props.actions._listComplex(data));

    }


/* -------------------------------------------------------------------------- */
/*  load complex list                                      */
/* -------------------------------------------------------------------------- */
    _loadComplexDataSets() {

      const data = {
        complex_id                                : this.state.search_complex_id,
        complex_name                              : this.state.search_complex_name,
        address                                   : this.state.search_address,
        page_size                                 : this.state.lsPageSize,
        current_page                              : 1,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
      }

      this.setState({
        lsCurrentPage                             : 1,
      }, this.props.actions._listComplex(data));

}

/* -------------------------------------------------------------------------- */
/*  edit records                                                              */
/* -------------------------------------------------------------------------- */
    _editComplex(id, index) {

      this.props.actions._onComplexFormFieldChange('complex_id', this.props.complex.gpComplexDataList[index]['complex_id'])
      this.props.actions._onComplexFormFieldChange('complex_name', this.props.complex.gpComplexDataList[index]['complex_name'])
      this.props.actions._onComplexFormFieldChange('address', this.props.complex.gpComplexDataList[index]['address'])

      const data = [{
        complex_id                                : id,
      }];
      this.props.actions._activateEditComplex(data)
    }

    _viewComplex(id, index) {
      this.props.actions._onComplexFormFieldChange('complex_id', this.props.complex.gpComplexDataList[index]['complex_id'])
      this.props.actions._onComplexFormFieldChange('complex_name', this.props.complex.gpComplexDataList[index]['complex_name'])
      this.props.actions._onComplexFormFieldChange('address', this.props.complex.gpComplexDataList[index]['address'])
      this.props.actions._viewComplex(id);
    }


    _removeComplex(id) {
        this.props.actions._removeComplex(id);
    }

    _addComplex(){
      this.props.actions._activateAddComplex()
    }

    _filterComplex(){
      this.props.actions._activateFilterComplex()
    }

    import_complex(){
      this.props.actions.action_to_import_complex()
    }

    export_complex(){
      this.props.actions.action_to_export_complex()
    }

    get_sample_complex(){
      this.props.actions.action_to_get_sample_complex()
      this.setState({show: false});
    }
  _handlePaginationChange= (e, param) => {
    const data = {
        complex_id                                : this.state.search_complex_id,
        complex_name                              : this.state.search_complex_name,
        address                                   : this.state.search_address,
        page_size                                 : this.state.lsPageSize,
        current_page                              : param.activePage,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
    }

    this.setState({
     lsCurrentPage      : param.activePage,
    }, this.props.actions._listComplex(data))
  }


  _handleSort = (clickedColumn) => () => {
    const { direction, lsSortOrder } = this.state

    const data = {
        trancode          : 'filter',
        complex_id                                : this.state.search_complex_id,
        complex_name                              : this.state.search_complex_name,
        address                                   : this.state.search_address,
        page_size                                 : this.state.lsPageSize,
        current_page                              : this.state.lsCurrentPage,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
    }

    this.setState({
      column                                      : clickedColumn,
      lsSortedBy                                  : clickedColumn,
      lsSortOrder                                 : lsSortOrder === 'ascend' ? 'descend' : 'ascend',
      data                                        : '',
      direction                                   : direction === 'ascending' ? 'descending' : 'ascending',
    }, this.props.actions._listComplex(data))

}
/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
    render() {
      const {
        search_complex_id,
        search_complex_name,
        search_address,
        search_complex_id_has_error,
        search_complex_name_has_error,
        search_address_has_error,
        search_complex_id_error_msg,
        search_complex_name_error_msg,
        search_address_error_msg,
      } = this.props.complex.gpDataFields;

      const {
        complex_add,
        complex_edt,
        complex_del,
        complex_inq,
        file,
      } = this.state;

      const dataRows = this.props.complex.gpComplexDataList.map((data, index) =>
        <tr key={index}>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.complex_id.replace(/(.{30})..+/, "$1…")}
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              <Link className='-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50' to={'/complex/view/' + data.complex_id} onClick={() => this._viewComplex(data.complex_id, index)}>{data.complex_name}</Link>
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.address.replace(/(.{30})..+/, "$1…")}
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <div className='bg-white flex flex-col xs:flex-row items-left xs:justify-between'>
                <div className='flex items-center'>
                  <Link disabled = {!complex_inq} to={'/complex/view/' + data.complex_id} onClick={() => this._viewComplex(data.complex_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-blue-500 bg-white hover:bg-gray-100'
                      disabled = {!complex_inq}
                      ><Icon name='eye' /></button>
                  </Link>
                  <Link disabled = {!complex_edt} to={'/complex/edit/' + data.complex_id}  onClick = {() => this._editComplex(data.complex_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-blue-500 bg-white hover:bg-gray-100'
                      onClick = {() => this._editComplex(data.complex_id, index)}
                      disabled = {!complex_edt}
                      ><Icon name='edit' /></button>
                  </Link>
                  <Popconfirm title='Apakah, Anda yakin akan melakukan penghapusan?' onConfirm={() => this._removeComplex(data.complex_id)}>
                    <Link to={'#'}>
                      <button type='button' className='w-full px-2 py-0 text-base text-blue-500 bg-white hover:bg-gray-100'
                        disabled = {!complex_del}
                        ><Icon name='trash' /></button>
                    </Link>
                  </Popconfirm>
                </div>
            </div>
          </td>
        </tr>
      );


      const simpleDataRows = this.props.complex.gpComplexDataList.map((data, index) =>
        <div className='w-full gap-6 pb-4 rounded-lg'><Link to={'/complex/view/' + data.complex_id} onClick={() => this._viewComplex(data.complex_id, index)}>
        <table className='w-full bg-indigo-50 p-2 rounded-lg'>
        <tr key={index}>
        <td className='text-gray-900 w-full p-6'>
          <b>ID Komplek</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.complex_id.replace(/(.{30})..+/, "$1…")}
            </p>
          <b>Nama Komplek</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.complex_name}
            </p>
          <b>Alamat</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.address.replace(/(.{30})..+/, "$1…")}
            </p>
          <td className='px-5 py-3 text-base'>
            <div className='flex flex-col xs:flex-row items-left xs:justify-between'>
                <div className='flex items-center'>
                  <Link disabled = {!complex_inq} to={'/complex/view/' + data.complex_id} onClick={() => this._viewComplex(data.complex_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-indigo-500 hover:bg-gray-100'
                      disabled = {!complex_inq}
                      ><Icon name='eye' /></button>
                  </Link>
                  <Link disabled = {!complex_edt} to={'/complex/edit/' + data.complex_id}  onClick = {() => this._editComplex(data.complex_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-indigo-500 hover:bg-gray-100'
                      onClick = {() => this._editComplex(data.complex_id, index)}
                      disabled = {!complex_edt}
                      ><Icon name='edit' /></button>
                  </Link>
                  <Popconfirm title='Apakah, Anda yakin akan melakukan penghapusan?' onConfirm={() => this._removeComplex(data.complex_id)}>
                    <Link to={'#'}>
                      <button type='button' className='w-full px-2 py-0 text-base text-indigo-500 hover:bg-gray-100'
                        disabled = {!complex_del}
                        ><Icon name='trash' /></button>
                    </Link>
                  </Popconfirm>
                </div>
            </div>
          </td>
          </td>
        </tr>
        </table></Link>
        </div>
      );


/* ------------------------------------------------------------------------ */
/* Complex Table Columns Name definition start                              */
/* ------------------------------------------------------------------------ */
      const { column, data, direction } = this.state
/* ------------------------------------------------------------------------ */
/* Complex Table Columns Name definition End                        */
/* ------------------------------------------------------------------------ */
/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
      return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
      <div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add complex start                                        */}
{/* ------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add complex End                                          */}
{/* ------------------------------------------------------------------------ */}

{/* ------------------------------------------------------------------------ */}
{/*  top navigation start                                                    */}
{/* ------------------------------------------------------------------------ */}

{/* ------------------------------------------------------------------------ */}
{/*  top navigation end                                                      */}
{/* ------------------------------------------------------------------------ */}


{/* ------------------------------------------------------------------------ */}
{/*  Table Start                                                             */}
{/* ------------------------------------------------------------------------ */}
        <div>
          <Modal open={this.state.show}>
            <Modal.Header>Select a File</Modal.Header>
            <Modal.Description>
              <br /><br />
              <Button
                content='Pilih File'
                labelPosition='left'
                icon='file'
                onClick={() => this.fileInputRef.current.click()}
              />
              <input
                ref={this.fileInputRef}
                type='file'
                hidden
                onChange={this.fileChange.bind(this)}
              />
              <br /><br />
              {this.state.file? 'Import file :' + this.state.file.name:null}
              <br /><br />
              <Link to='./complex' onClick={() => this.get_sample_complex()}>Sample</Link>
              <br /><br />
            </Modal.Description>
            <Modal.Actions>
              <Button onClick={()=>this.fileUpload()}>Upload</Button>
              <Button onClick={()=>{this.setState({show: false})}}>Close</Button>
            </Modal.Actions>
          </Modal>
          <div>
            <Row>
              <Col span={10}>
                <div className='inline-flex rounded-md shadow-sm' role='group'>
                <Link className='text-blue-900 inline-flex justify-center' disabled = {!complex_add} to='/complex/add/'>
                  <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
                    <Icon name='add' /><div className='hidden md:block'>Tambah</div>
                  </button>
                </Link>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  disabled = {!complex_inq}
                  onClick={() => this._filterComplex()}
                  ><Icon name='search' /><div className='hidden md:block'>Search</div>
                </button>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  disabled = {!complex_inq}
                  onClick={() => this._refresh()}
                  ><Icon name='refresh' /><div className='hidden md:block'>Refresh</div>
                </button>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  onClick={()=>{this.setState({show: true})}}
                  ><Icon name='upload' /><div className='hidden md:block'>Import</div>
                </button>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  onClick={() => this.export_complex()}
                  ><Icon name='download' /><div className='hidden md:block'>Export</div>
                </button>
                </div>
              </Col>
              <Col span={10}></Col>
            </Row>
            <div className='spacer' />
          </div>
        <div>
        <div className='w-full overflow-hidden rounded-lg shadow-xs'>
          <div className='w-full overflow-x-auto'>
            <div class='overflow-auto hidden md:block'>
            <table className='w-full whitespace-no-wrap'>
              <thead>
                <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800'>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      ID Komplek
                      <a href='#' onClick={this._handleSort('complex_id')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Nama Komplek
                      <a href='#' onClick={this._handleSort('complex_name')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Alamat
                      <a href='#' onClick={this._handleSort('address')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Actions
                    </div>
                  </th>
                  </tr>
                </thead>
              <tbody>

              {dataRows}

              </tbody>
            </table>
            </div>

            <div class='grid grid-cols-1 md:hidden'>
              {simpleDataRows}
            </div>

             <div className='px-5 bg-white py-5 flex flex-col xs:flex-row xs:justify-between'>
                    <div className='flex float-right text-base'>
                      <div className='items-left w-full hidden md:block'>
                        Show {(this.state.lsCurrentPage-1) * 10 + 1 } - {(this.state.lsCurrentPage * 10 + 1)>this.state.lsNumberOfRows ? this.state.lsNumberOfRows:(this.state.lsCurrentPage * 10 + 1)} from {this.state.lsNumberOfRows}
                      </div>
                      <div className='items-right'>
                        <Pagination size='tiny' className='w-full p-0 text-base text-blue-600 rounded-3xl bg-white' floated='right' defaultActivePage={this.state.lsCurrentPage} onPageChange={this._handlePaginationChange} totalPages={this.state.lsTotalPage} />
                      </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Table End                                                               */}
{/* ------------------------------------------------------------------------ */}
     </div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
    );
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
  }
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */

export default connect(mapStateToProps, mapDispatchToProps)(DataList);

