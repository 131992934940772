import React                                          from 'react';
import { Route, Redirect }                            from 'react-router-dom';
import NavBar                                          from './../_components/navbar';
import {
  Layout,
  Row,
  Col,
  Menu,
  message,
  Dropdown,
  Breadcrumb,
  Avatar}                                              from 'antd';
  import {Icon, Button}                                  from 'semantic-ui-react';

const { Header, Content, Footer, Sider } = Layout;
export const PrivateHeader = ({ menu, name  }) => (
    localStorage.getItem('user')
        ?<div>
        <Header
          className='header-top'
          style={{backgroundColor:'#fff', fontSize:14, height:44, lineHeight:'44px', padding:'0px'}}>
            <Row>
              <Col span={2}></Col>
              <Col span={2}></Col>
              <Col span={2}></Col>
              <Col span={2}></Col>
              <Col span={8}></Col>
              <Col span={8}>
                  <Avatar style={{ color: 'rgb(57, 73, 108)', backgroundColor: 'rgb(28, 179, 221)', verticalAlign: 'middle' }} size='medium'>
                    AR
                  </Avatar>
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className='ant-dropdown-link' href='#'>
                      <span> {name}</span> <Icon type='down' />
                    </a>
                  </Dropdown>
              </Col>
            </Row>
        </Header>
        <NavBar />ada isi</div>
        : ''
)
