// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as apartmentActions                           from '../_actions/apartment.actions';
import * as buildingActions                            from './../_actions/building.actions';
import BuildingEdit                                    from './../building/edit';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     apartment: {
       gpApartmentShowModal                       : state.apartment.form.gpApartmentShowModal,
       gpApartmentModalParam                      : state.apartment.form.gpApartmentModalParam,
       gpApartmentIsFetching                      : state.apartment.form.gpApartmentIsFetching,
       gpDataFields                               : state.apartment.form.gpDataFields,
       gpApartmentIsValid                         : state.apartment.form.gpApartmentIsValid,
     },
     building: {
      gpDataFields                                : state.building.form.gpDataFields,
      gpbuildingIsFetching                        : state.building.form.gpbuildingIsFetching,
     },
     building_incoming: {
       gpBuildingLookupList                       : state.building.form.gpBuildingLookupList,
       gpBuildingError                            : state.building.form.gpBuildingError,
       gpBuildingIsFetching                       : state.building.form.gpBuildingIsFetching,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...apartmentActions,
      ...buildingActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
          building_id                             : this.props.building.gpDataFields.building_id,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editApartment(this.props.match.params.apartment_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addApartment());
      }

      this.props.actions._onApartmentFormFieldChange('building_id', this.props.building.gpDataFields.building_id);
    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.apartment.gpApartmentModalParam) {
        if (nextProps.apartment.gpApartmentModalParam !== this.props.apartment.gpApartmentModalParam) {
          console.log('Information: gpApartmentModalParam props changed');
          if(nextProps.apartment.gpApartmentModalParam['trxType']==='edt') {
            this.setState({
              apartment_id      : nextProps.apartment.gpApartmentModalParam['apartment_id'],
              trxType          : nextProps.apartment.gpApartmentModalParam['trxType'],
            }, this._editApartment(nextProps.apartment.gpApartmentModalParam['apartment_id']));
          };

          if(nextProps.apartment.gpApartmentModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.apartment.gpApartmentModalParam['trxType'],
            }, this._addApartment());
          };

          if(nextProps.apartment.gpApartmentModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.apartment.gpApartmentModalParam['trxType'],
            }, this._filterApartment());
          };

        };
      };

      if(nextProps.apartment.gpApartmentShowModal) {
        if (nextProps.apartment.gpApartmentShowModal !== this.props.apartment.gpApartmentShowModal) {
          console.log('Information: gpApartmentShowModal props changed');
          if(nextProps.apartment.gpApartmentShowModal==='show'){
            this.setState({
              lsApartmentShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.apartment.gpApartmentShowModal==='hide'){
            this.setState({
              lsApartmentShowModal: false,
            });
          }
        };
      };

      this.setState({
        apartment_id                              : nextProps.apartment.gpDataFields.apartment_id,
        apartment_name                            : nextProps.apartment.gpDataFields.apartment_name,
        total_bedroom                             : nextProps.apartment.gpDataFields.total_bedroom,
        rent_cost                                 : nextProps.apartment.gpDataFields.rent_cost,
        building_id                               : nextProps.apartment.gpDataFields.building_id,
        building_building_id                      : nextProps.apartment.gpDataFields.building_building_id,
        date_add                                  : nextProps.apartment.gpDataFields.date_add,
        date_upd                                  : nextProps.apartment.gpDataFields.date_upd,
      });

}
_addBuilding() {
  this.props.actions._activateAddBuilding();
}

_addApartmentOnServer() {
    var id1 = this.state.apartment_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(5);
    } else {
      id3 = this.state.apartment_id;
    }
    const data = {
        apartment_id                              : id3,
        apartment_name                            : this.state.apartment_name,
        total_bedroom                             : this.state.total_bedroom,
        rent_cost                                 : this.state.rent_cost,
        building_id                               : this.state.building_id,
        building_building_id                      : this.state.building_building_id,
    }
    this.props.actions._addApartment(data);
    this.goBack();
}

_editApartmentOnServer() {
    const data = {
        apartment_id                              : this.state.apartment_id,
        apartment_name                            : this.state.apartment_name,
        total_bedroom                             : this.state.total_bedroom,
        rent_cost                                 : this.state.rent_cost,
        building_id                               : this.state.building_id,
        building_building_id                      : this.state.building_building_id,
    }
    this.props.actions._editApartment(data);
    this.goBack();
}

_filterApartmentOnServer() {
    const data = {
        apartment_id                              : this.state.apartment_id,
        apartment_name                            : this.state.apartment_name,
        total_bedroom                             : this.state.total_bedroom,
        rent_cost                                 : this.state.rent_cost,
        building_id                               : this.state.building_id,
        building_building_id                      : this.state.building_building_id,
    }
    this.props.actions._filterApartment(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit apartment                                                              */
/* -------------------------------------------------------------------------- */
_editApartment(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewApartment(id);
  this.props.actions._lookupBuilding();
}

/* -------------------------------------------------------------------------- */
/*  add new apartment                                     
/* -------------------------------------------------------------------------- */
_addApartment() {
    let today = GF.today();
    this.props.actions._onApartmentFormFieldChange('apartment_id', '')
    this.props.actions._onApartmentFormFieldChange('apartment_name', '')
    this.props.actions._onApartmentFormFieldChange('total_bedroom', '')
    this.props.actions._onApartmentFormFieldChange('rent_cost', '')
    this.props.actions._initApartment();

    this.setState({
         apartment_id                             : '',
         apartment_name                           : '',
         total_bedroom                            : '',
         rent_cost                                : '',
        building_id                               : this.state.building_id,
         isIDDisabled                             : false,
         lsApartmentDetail                        : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
  this.props.actions._lookupBuilding();
}

/* -------------------------------------------------------------------------- */
/*  filter apartment                                     
/* -------------------------------------------------------------------------- */
_filterApartment() {

    let today = GF.today();
    this.setState({
         apartment_id                             : '',
         apartment_name                           : '',
         total_bedroom                            : '',
         rent_cost                                : '',
         lsApartmentDetail                        : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
  this.props.actions._lookupBuilding();
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showApartmentModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addApartmentOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editApartmentOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterApartmentOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showApartmentModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[apartment.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onApartmentFormFieldChange(field, value)
}

onBuilding_idChange = (e, data) => {
  const lvValue   = data.value;
  const lvField   = 'building_id';
     if (lvValue !== '') {
       this.props.actions._onApartmentFormFieldChange(lvField, lvValue)
     }
}

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsApartmentShowModal,
    } = this.state;


     const {
       apartment_id,
       apartment_name,
       total_bedroom,
       rent_cost,
       building_building_id,
       building_id,
       building_id_has_error,
       building_id_error_msg,
       date_add,
       date_upd,
       apartment_id_has_error,
       apartment_name_has_error,
       total_bedroom_has_error,
       rent_cost_has_error,
       date_add_has_error,
       date_upd_has_error,
       apartment_id_error_msg,
       apartment_name_error_msg,
       total_bedroom_error_msg,
       rent_cost_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.apartment.gpDataFields;


const listBuildingOptions = _.map(this.props.building_incoming.gpBuildingLookupList, (Building, index) => ({
  key: Building.value,
  text: Building.value  + ' - ' + Building.label,
  value: Building.value,
}))

const DropdownBuilding_Building_Id = () => (
  <Dropdown onChange={this.onBuilding_idChange} placeholder='Building' value={building_id} search options={listBuildingOptions} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'/>
)

const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Apartment'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add apartment start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add apartment body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Ruang Sewa
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={6}>
          <b>ID Apartment</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='apartment_id'
              placeholder='[AUTO]'
              value={apartment_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {apartment_id_has_error?<div style={{color:'lightcoral'}}>{apartment_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Nama Apartment</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='apartment_name'
              placeholder='Masukan Nama Apartment'
              value={apartment_name}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {apartment_name_has_error?<div style={{color:'lightcoral'}}>{apartment_name_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Jumlah Kamar Tidur</b><br />
          <div className='spacer' />
          <NumericFormat
            value={total_bedroom}
            customInput={Input}
            name = 'total_bedroom'
            disabled={false}
            placeholder='Masukan Jumlah Kamar Tidur'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onApartmentFormFieldChange('total_bedroom', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {total_bedroom_has_error?<div style={{color:'lightcoral'}}>{total_bedroom_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Biaya Sewa</b><br />
          <div className='spacer' />
          <NumericFormat
            value={rent_cost}
            customInput={Input}
            name = 'rent_cost'
            disabled={false}
            prefix='Rp '
            placeholder='Masukan Biaya Sewa'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onApartmentFormFieldChange('rent_cost', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {rent_cost_has_error?<div style={{color:'lightcoral'}}>{rent_cost_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
         <Col lg={6} md={10}>
           <b>ID Gedung</b><br />
           <div className='spacer' />
           <DropdownBuilding_Building_Id />
           &nbsp;
           <Link className='text-blue-900' to='/building/add/'>
             <button type='button' className='inline-flex h-10 justify-center rounded-md border text-center border-transparent bg-blue-100 px-2 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
               <Icon name='plus' />
             </button>
           </Link>
           <div className='spacer' />
           <br />
         </Col>
        </Row>
        <div className='spacer' />
        <br />
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.apartment.gpApartmentIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add apartment body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add apartment End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
