'use strict'
import {complexConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/complex.initialstate'
import fieldValidation                              from './../_validation/complex.field.validation'
import formValidation                               from './../_validation/complex.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case complexConstants.COMPLEX_GET_LIST_REQUEST:
    case complexConstants.COMPLEX_LOOKUP_REQUEST:
    case complexConstants.COMPLEX_ADD_REQUEST:
    case complexConstants.COMPLEX_CHANGE_REQUEST:
    case complexConstants.COMPLEX_FILTER_REQUEST:
    case complexConstants.COMPLEX_VIEW_REQUEST:
    case complexConstants.COMPLEX_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpComplexIsFetching'],  true)

    case complexConstants.COMPLEX_CHANGE_SUCCESS:
    case complexConstants.COMPLEX_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpComplexIsFetching'],  false)

    case complexConstants.COMPLEX_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpComplexIsFetching'],  false)
          .setIn(['form', 'complex_count'],  action.payload)

    case complexConstants.COMPLEX_FILTER_SUCCESS:
    case complexConstants.COMPLEX_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpComplexDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpComplexDataList'], data.rows)
          .setIn(['form', 'gpComplexDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpComplexIsFetching'],  false)


  case complexConstants.COMPLEX_LOOKUP_SUCCESS:
    console.log('[complex.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpComplexLookupList'], action.payload)
        .setIn(['form', 'gpComplexIsFetching'], false)


    case complexConstants.COMPLEX_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'complex_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'complex_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'address'],'')

    case complexConstants.COMPLEX_SHOW_MODAL:
      return state
        .setIn(['form', 'gpComplexShowModal'],  action.payload)


    case complexConstants.COMPLEX_SEND_PARAM:
      return state
        .setIn(['form', 'gpComplexModalParam'],  action.payload[0])


    case complexConstants.COMPLEX_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'complex_id'],                                        action.payload[0].complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_id'],                                        action.payload[0].complex_id)
          .setIn(['form', 'gpDataFields',     'complex_name'],                                      action.payload[0].complex_name)
          .setIn(['form', 'gpOriginalFields', 'complex_name'],                                      action.payload[0].complex_name)
          .setIn(['form', 'gpDataFields',     'address'],                                           action.payload[0].address)
          .setIn(['form', 'gpOriginalFields', 'address'],                                           action.payload[0].address)
          .setIn(['form', 'gpComplexIsFetching'],                                                   false)
          return nextRecordState


    case complexConstants.COMPLEX_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'complex_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'address'],                                               '')
          .setIn(['form', 'gpDataFields', 'complex_id_has_error'],                                  false)
          .setIn(['form', 'gpDataFields', 'complex_name_has_error'],                                false)
          .setIn(['form', 'gpDataFields', 'address_has_error'],                                     false)

          .setIn(['form', 'gpComplexIsValid'],                                                      false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('complexReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'complex_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'address'],                                               '')
          .setIn(['form', 'gpComplexError'],                                                        null)
          return formValidation(nextRecordState, action)


    case complexConstants.COMPLEX_GET_LIST_FAILURE:
    case complexConstants.COMPLEX_LOOKUP_FAILURE:
    case complexConstants.COMPLEX_ADD_FAILURE:
    case complexConstants.COMPLEX_CHANGE_FAILURE:
    case complexConstants.COMPLEX_FILTER_FAILURE:
    case complexConstants.COMPLEX_VIEW_FAILURE:
    case complexConstants.COMPLEX_COUNT_FAILURE:
      console.log('complex.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpComplexIsFetching'], false)
          .setIn(['form', 'gpComplexError'], action.payload)


   case complexConstants.ON_COMPLEX_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case complexConstants.ON_COMPLEX_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case complexConstants.CHANGE_COMPLEX_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpComplexError'], null)
      return nextState}

    case complexConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            record.complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_id'],                                        record.complex_id)
          .setIn(['form', 'gpDataFields', 'complex_name'],                                          record.complex_name)
          .setIn(['form', 'gpOriginalFields', 'complex_name'],                                      record.complex_name)
          .setIn(['form', 'gpDataFields', 'address'],                                               record.address)
          .setIn(['form', 'gpOriginalFields', 'address'],                                           record.address)

          .setIn(['form', 'gpDataFields', 'complex_id_has_error'],                                  record.complex_id_has_error)
          .setIn(['form', 'gpDataFields', 'complex_name_has_error'],                                record.complex_name_has_error)
          .setIn(['form', 'gpDataFields', 'address_has_error'],                                     record.address_has_error)



          .setIn(['form', 'gpComplexDataList'],                                                     record.gpComplexDataList)
          .setIn(['form', 'gpComplexIsDisabled'],                                                   record.gpComplexIsDisabled)
          .setIn(['form', 'gpComplexError'],                                                        record.gpComplexError)
          .setIn(['form', 'gpComplexIsValid'],                                                      record.gpComplexIsValid)
          .setIn(['form', 'gpComplexIsFetching'],                                                   record.gpComplexIsFetching)
          .setIn(['form', 'complex_count'],                                                         record.complex_count)
      return next
case complexConstants.COMPLEX_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpComplexLookupShowModal'],  action.payload)


  }
  return state
}
