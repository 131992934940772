'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('building_id'): {
        let building_id    = value
        let isValid = true

      if (building_id.length > 0) {
        if (building_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'building_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'building_id_error_msg'], 'ID Gedung perlu di isi')
        }

        if (building_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'building_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'building_id_error_msg'], 'ID Gedung panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(building_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'building_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'building_id_error_msg'], 'ID Gedung tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'building_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'building_id_error_msg'], '')
        }
    }

    case ('building_name'): {
        let building_name    = value
        let isValid = true

        if (building_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'building_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'building_name_error_msg'], 'Nama Gedung perlu di isi')
        }

        if (building_name.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'building_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'building_name_error_msg'], 'Nama Gedung panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(building_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'building_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'building_name_error_msg'], 'Nama Gedung tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'building_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'building_name_error_msg'], '')
        }
    }

    case ('total_apartement'): {
        let total_apartement    = value
        let isValid = true

        if (total_apartement.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'total_apartement_has_error'], true)
            .setIn(['form', 'gpDataFields', 'total_apartement_error_msg'], 'Jumlah Apartemen perlu di isi')
        }

        if (total_apartement.length > 10) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'total_apartement_has_error'], true)
            .setIn(['form', 'gpDataFields', 'total_apartement_error_msg'], 'Jumlah Apartemen panjang maksimumnya 10 karakter.')
        }

        if (!check.validateNumber(total_apartement)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'total_apartement_has_error'], true)
            .setIn(['form', 'gpDataFields', 'total_apartement_error_msg'], 'Jumlah Apartemen tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'total_apartement_has_error'], false)
            .setIn(['form', 'gpDataFields', 'total_apartement_error_msg'], '')
        }
    }

  }
  return state
}
