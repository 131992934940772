const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        lease_detail_id                           : '',
        description                               : '',
        quantity                                  : 0,
        unit_price                                : 0,
        lease_id                                  : '',
        lease_description                         : '',
        search_lease_detail_id                    : '',
        search_description                        : '',
        search_quantity                           : 0,
        search_unit_price                         : 0,
   }))(),
   gpLease_detailDataList                         : [],
   gpLease_detailDataListNumRows                  : 0,
   gpLease_detailLookupList                       : [],
   gpLease_detailIsDisabled                       : false,
   gpLease_detailError                            : null,
   gpLease_detailIsValid                          : true,
   gpLease_detailIsFetching                       : false,
   lease_detail_count                             : 0,
   lease_detail_active_tab                        : 0,
   gpLease_detailShowModal                        : 'hide',
   gpLease_detailModalParam                       : [],
   gpLease_detailLookupShowModal                  : 'hide',
   gpDataFields: new (Record({
        lease_id                                  : '',
        lease_description                         : '',
        lease_detail_id                           : '',
        description                               : '',
        quantity                                  : 0,
        unit_price                                : 0,
        search_lease_detail_id                    : '',
        search_description                        : '',
        search_quantity                           : 0,
        search_unit_price                         : 0,
        lease_detail_id_has_error                 : false,
        description_has_error                     : false,
        quantity_has_error                        : false,
        unit_price_has_error                      : false,
        search_lease_detail_id_has_error          : false,
        search_description_has_error              : false,
        search_quantity_has_error                 : false,
        search_unit_price_has_error               : false,
        lease_detail_id_error_msg                 : false,
        description_error_msg                     : false,
        quantity_error_msg                        : false,
        unit_price_error_msg                      : false,
        search_lease_detail_id_error_msg          : false,
        search_description_error_msg              : false,
        search_quantity_error_msg                 : false,
        search_unit_price_error_msg               : false,
   }))(),
 })
 var lease_detailInitialState = Record({
   form: new Form()
 })
export default lease_detailInitialState
