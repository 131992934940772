import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import Lease_detailEdit                                from './edit';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  lease_detailActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...lease_detailActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    lease_detail: {
      gpDataFields                                : state.lease_detail.form.gpDataFields,
      gpLease_detailIsFetching                    : state.lease_detail.form.gpLease_detailIsFetching,
      lease_detail_active_tab                     : state.lease_detail.form.lease_detail_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsLease_detailIsFetching                    : true,
      lease_detail_id                             : '',
      description                                 : '',
      quantity                                    : '',
      unit_price                                  : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        lease_detail_id                           : nextProps.lease_detail.gpDataFields.lease_detail_id,
        description                               : nextProps.lease_detail.gpDataFields.description,
        quantity                                  : nextProps.lease_detail.gpDataFields.quantity,
        unit_price                                : nextProps.lease_detail.gpDataFields.unit_price,
        date_upd                                  : nextProps.lease_detail.gpDataFields.date_upd,
        date_add                                  : nextProps.lease_detail.gpDataFields.date_add,
        lsLease_detailIsFetching                  : nextProps.lease_detail.gpLease_detailIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewLease_detail(this.props.match.params.lease_detail_id);
      this.setState({
        lsLease_detailIsFetching                  : this.props.lease_detail.gpLease_detailIsFetching,
        lease_detail_id                           : this.props.lease_detail.gpDataFields.lease_detail_id,
        description                               : this.props.lease_detail.gpDataFields.description,
        quantity                                  : this.props.lease_detail.gpDataFields.quantity,
        unit_price                                : this.props.lease_detail.gpDataFields.unit_price,
      });
    }


    _editLease_detail(id) {
      const data = [{
          lease_detail_id      : id,
      }];
      this.props.actions._activateEditLease_detail(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onLease_detailStateChange('lease_detail_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
      ]


      const {
        lease_detail_active_tab
      } = this.props.lease_detail;


      const {
        lease_detail_id,
        description,
        quantity,
        unit_price,
        date_add,
        date_upd
      } = this.props.lease_detail.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Sewa Detail
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/lease_detail/edit/' + lease_detail_id}
                onClick = {() => this._editLease_detail(lease_detail_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Detail</span><br />
                  {lease_detail_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Keterangan</span><br />
                  {description}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Jumlah</span><br />
            <NumericFormat
              value={quantity}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Harga</span><br />
            <NumericFormat
              value={unit_price}
              thousandSeparator='.'
              prefix='Rp '
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

