'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.id_userlevel_has_error &&
        state.form.gpDataFields.role_name !== '' &&
        !state.form.gpDataFields.role_name_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpUserlevelIsValid'], true)
  } else {
    return state.setIn(['form', 'gpUserlevelIsValid'], false)
  }
}
