// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as paymentActions                             from '../_actions/payment.actions';
import * as rentActions                                from './../_actions/rent.actions';
import RentEdit                                        from './../rent/edit';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     payment: {
       gpPaymentShowModal                         : state.payment.form.gpPaymentShowModal,
       gpPaymentModalParam                        : state.payment.form.gpPaymentModalParam,
       gpPaymentIsFetching                        : state.payment.form.gpPaymentIsFetching,
       gpDataFields                               : state.payment.form.gpDataFields,
       gpPaymentIsValid                           : state.payment.form.gpPaymentIsValid,
     },
     rent: {
      gpDataFields                                : state.rent.form.gpDataFields,
      gprentIsFetching                            : state.rent.form.gprentIsFetching,
     },
     rent_incoming: {
       gpRentLookupList                           : state.rent.form.gpRentLookupList,
       gpRentError                                : state.rent.form.gpRentError,
       gpRentIsFetching                           : state.rent.form.gpRentIsFetching,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...paymentActions,
      ...rentActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
          rent_id                                 : this.props.rent.gpDataFields.rent_id,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editPayment(this.props.match.params.payment_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addPayment());
      }

      this.props.actions._onPaymentFormFieldChange('rent_id', this.props.rent.gpDataFields.rent_id);
    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.payment.gpPaymentModalParam) {
        if (nextProps.payment.gpPaymentModalParam !== this.props.payment.gpPaymentModalParam) {
          console.log('Information: gpPaymentModalParam props changed');
          if(nextProps.payment.gpPaymentModalParam['trxType']==='edt') {
            this.setState({
              payment_id      : nextProps.payment.gpPaymentModalParam['payment_id'],
              trxType          : nextProps.payment.gpPaymentModalParam['trxType'],
            }, this._editPayment(nextProps.payment.gpPaymentModalParam['payment_id']));
          };

          if(nextProps.payment.gpPaymentModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.payment.gpPaymentModalParam['trxType'],
            }, this._addPayment());
          };

          if(nextProps.payment.gpPaymentModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.payment.gpPaymentModalParam['trxType'],
            }, this._filterPayment());
          };

        };
      };

      if(nextProps.payment.gpPaymentShowModal) {
        if (nextProps.payment.gpPaymentShowModal !== this.props.payment.gpPaymentShowModal) {
          console.log('Information: gpPaymentShowModal props changed');
          if(nextProps.payment.gpPaymentShowModal==='show'){
            this.setState({
              lsPaymentShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.payment.gpPaymentShowModal==='hide'){
            this.setState({
              lsPaymentShowModal: false,
            });
          }
        };
      };

      this.setState({
        payment_id                                : nextProps.payment.gpDataFields.payment_id,
        payment_date                              : nextProps.payment.gpDataFields.payment_date,
        Payment_amount                            : nextProps.payment.gpDataFields.Payment_amount,
        rent_id                                   : nextProps.payment.gpDataFields.rent_id,
        rent_rent_id                              : nextProps.payment.gpDataFields.rent_rent_id,
        date_add                                  : nextProps.payment.gpDataFields.date_add,
        date_upd                                  : nextProps.payment.gpDataFields.date_upd,
      });

}
_addRent() {
  this.props.actions._activateAddRent();
}

_addPaymentOnServer() {
    var id1 = this.state.payment_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(5);
    } else {
      id3 = this.state.payment_id;
    }
    const data = {
        payment_id                                : id3,
        payment_date                              : this.state.payment_date,
        Payment_amount                            : this.state.Payment_amount,
        rent_id                                   : this.state.rent_id,
        rent_rent_id                              : this.state.rent_rent_id,
    }
    this.props.actions._addPayment(data);
    this.goBack();
}

_editPaymentOnServer() {
    const data = {
        payment_id                                : this.state.payment_id,
        payment_date                              : this.state.payment_date,
        Payment_amount                            : this.state.Payment_amount,
        rent_id                                   : this.state.rent_id,
        rent_rent_id                              : this.state.rent_rent_id,
    }
    this.props.actions._editPayment(data);
    this.goBack();
}

_filterPaymentOnServer() {
    const data = {
        payment_id                                : this.state.payment_id,
        payment_date                              : this.state.payment_date,
        Payment_amount                            : this.state.Payment_amount,
        rent_id                                   : this.state.rent_id,
        rent_rent_id                              : this.state.rent_rent_id,
    }
    this.props.actions._filterPayment(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit payment                                                              */
/* -------------------------------------------------------------------------- */
_editPayment(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewPayment(id);
  this.props.actions._lookupRent();
}

/* -------------------------------------------------------------------------- */
/*  add new payment                                     
/* -------------------------------------------------------------------------- */
_addPayment() {
    let today = GF.today();
    this.props.actions._onPaymentFormFieldChange('payment_id', '')
    this.props.actions._onPaymentFormFieldChange('payment_date', '')
    this.props.actions._onPaymentFormFieldChange('Payment_amount', '')
    this.props.actions._onPaymentFormFieldChange('payment_date', today)
    this.props.actions._initPayment();

    this.setState({
         payment_id                               : '',
         payment_date                             : today,
         Payment_amount                           : '',
        rent_id                                   : this.state.rent_id,
         isIDDisabled                             : false,
         lsPaymentDetail                          : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
  this.props.actions._lookupRent();
}

/* -------------------------------------------------------------------------- */
/*  filter payment                                     
/* -------------------------------------------------------------------------- */
_filterPayment() {

    let today = GF.today();
    this.setState({
         payment_id                               : '',
         payment_date                             : today,
         Payment_amount                           : '',
         lsPaymentDetail                          : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
  this.props.actions._lookupRent();
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showPaymentModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addPaymentOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editPaymentOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterPaymentOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showPaymentModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[payment.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onPaymentFormFieldChange(field, value)
}

onPayment_dateChange = (value) => {
  const lvValue   = moment(value, dateFormat).format(dateFormat);
  const lvField   = 'payment_date';
     if (lvValue !== '') {
       this.props.actions._onPaymentFormFieldChange(lvField, lvValue)
     }
}

onRent_idChange = (e, data) => {
  const lvValue   = data.value;
  const lvField   = 'rent_id';
     if (lvValue !== '') {
       this.props.actions._onPaymentFormFieldChange(lvField, lvValue)
     }
}

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsPaymentShowModal,
    } = this.state;


     const {
       payment_id,
       payment_date,
       Payment_amount,
       rent_rent_id,
       rent_id,
       rent_id_has_error,
       rent_id_error_msg,
       date_add,
       date_upd,
       payment_id_has_error,
       payment_date_has_error,
       Payment_amount_has_error,
       date_add_has_error,
       date_upd_has_error,
       payment_id_error_msg,
       payment_date_error_msg,
       Payment_amount_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.payment.gpDataFields;


const listRentOptions = _.map(this.props.rent_incoming.gpRentLookupList, (Rent, index) => ({
  key: Rent.value,
  text: Rent.value  + ' - ' + Rent.label,
  value: Rent.value,
}))

const DropdownRent_Rent_Id = () => (
  <Dropdown onChange={this.onRent_idChange} placeholder='Rent' value={rent_id} search options={listRentOptions} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'/>
)

const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Payment'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add payment start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add payment body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Payment
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={6}>
          <b>ID Pembayaran</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='payment_id'
              placeholder='[AUTO]'
              value={payment_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {payment_id_has_error?<div style={{color:'lightcoral'}}>{payment_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Tanggal Bayar</b><br />
          <div className='spacer' />
          <DatePicker
              placeholder='Pilih Tanggal'
              disabled={false}
              onChange={this.onPayment_dateChange.bind(this)}
              value={moment(payment_date,dateFormat)}
              format={dateFormat}
          />

          {payment_date_has_error?<div style={{color:'lightcoral'}}>{payment_date_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Jumlah Bayar</b><br />
          <div className='spacer' />
          <NumericFormat
            value={Payment_amount}
            customInput={Input}
            name = 'Payment_amount'
            disabled={false}
            prefix='Rp '
            placeholder='Masukan Jumlah Bayar'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onPaymentFormFieldChange('Payment_amount', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {Payment_amount_has_error?<div style={{color:'lightcoral'}}>{Payment_amount_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
         <Col lg={6} md={10}>
           <b>ID Penyewaan</b><br />
           <div className='spacer' />
           <DropdownRent_Rent_Id />
           &nbsp;
           <Link className='text-blue-900' to='/rent/add/'>
             <button type='button' className='inline-flex h-10 justify-center rounded-md border text-center border-transparent bg-blue-100 px-2 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
               <Icon name='plus' />
             </button>
           </Link>
           <div className='spacer' />
           <br />
         </Col>
        </Row>
        <div className='spacer' />
        <br />
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.payment.gpPaymentIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add payment body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add payment End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
