'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.service_id_has_error &&
        state.form.gpDataFields.service_name !== '' &&
        !state.form.gpDataFields.service_name_has_error &&
        state.form.gpDataFields.problem !== '' &&
        !state.form.gpDataFields.problem_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpServiceIsValid'], true)
  } else {
    return state.setIn(['form', 'gpServiceIsValid'], false)
  }
}
