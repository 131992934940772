'use strict'

import { authenticateConstants }                       from './../_constants';
import InitialState                                    from './../_initialstates/authenticate.initialstate'
import fieldValidation                                 from './../_validation/authenticate.field.validation'
import formValidation                                  from './../_validation/authenticate.form.validation'

const initialState = new InitialState()

export default function authenticateReducer (state = initialState, action) {
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)
  console.log('auth.reducer.action.type: ' + action.type);
  switch (action.type) {
    case authenticateConstants.SESSION_TOKEN_REQUEST:
    case authenticateConstants.SIGNUP_REQUEST:
    case authenticateConstants.LOGOUT_REQUEST:
    case authenticateConstants.LOGIN_REQUEST:
    case authenticateConstants.RESET_PASSWORD_REQUEST: {
      console.log('auth.LOGOUT_REQUEST');
      let nextState = state
        .setIn(['form', 'isFetching'], true)
        .setIn(['form', 'error'], null)
      return nextState
    }
    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('auth.LOGOUT_SUCCESS');
      return state.setIn(['form', 'state'], action.type)
        .setIn(['form', 'error'], null)
        .setIn(['form', 'isLoggedIn'], false)
        .setIn(['form', 'fields', 'fullname'], '')
        .setIn(['form', 'fields', 'email'], '')
        .setIn(['form', 'fields', 'phone'], '')
        .setIn(['form', 'fields', 'password'], '')
        .setIn(['form', 'fields', 'password_confirm'], '')

    case authenticateConstants.LOGOUT:
    console.log('auth.LOGOUT');
      return formValidation(
      state.setIn(['form', 'state'], action.type)
        .setIn(['form', 'error'], null)
        .setIn(['form', 'fields', 'fullname'], '')
        .setIn(['form', 'fields', 'email'], '')
        .setIn(['form', 'fields', 'phone'], '')
        .setIn(['form', 'fields', 'password'], '')
        .setIn(['form', 'fields', 'password_confirm'], '')
    )
    case authenticateConstants.LOGIN:
    case authenticateConstants.REGISTER:
    case authenticateConstants.FORGOT_PASSWORD:
    console.log('authReducer >> LOGIN');
      return formValidation(
      state.setIn(['form', 'state'], action.type)
        .setIn(['form', 'error'], null)
    )
    case authenticateConstants.ON_AUTH_FORM_FIELD_CHANGE: {
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'fields', field], value)
          .setIn(['form', 'error'], null)
      console.log('authReducer >> ON_AUTH_FORM_FIELD_CHANGE');
      return formValidation(
      fieldValidation(nextState, action)
      , action)
    }
    case authenticateConstants.CHANGE_FIELD: {
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'fields', field], value)
          .setIn(['form', 'fields', field+'_has_error'], null)
          .setIn(['form', 'fields', field+'_error_msg'], null)
          .setIn(['form', 'error'], null)
      return nextState}
    case authenticateConstants.SESSION_TOKEN_SUCCESS:
    case authenticateConstants.SIGNUP_SUCCESS:
    case authenticateConstants.LOGOUT_SUCCESS:
    case authenticateConstants.RESET_PASSWORD_SUCCESS:
      let nextState = state
        .setIn(['form', 'isFetching'], false)
        .setIn(['form', 'error'], null)
      return nextState
    case authenticateConstants.LOGIN_SUCCESS:
      return state
        .setIn(['form', 'isLoggedIn'], true)
        .setIn(['form', 'isFetching'], false)
        .setIn(['form', 'error'], null)
    case authenticateConstants.SESSION_TOKEN_FAILURE:
    case authenticateConstants.SIGNUP_FAILURE:
    case authenticateConstants.LOGOUT_FAILURE:
    case authenticateConstants.LOGIN_FAILURE:
    case authenticateConstants.RESET_PASSWORD_FAILURE:
    console.log('::authReducer.failure.error: ' + JSON.stringify(action.payload))
      return state.setIn(['form', 'isFetching'], false)
      .setIn(['form', 'error'], action.payload)
    case authenticateConstants.SET_STATE:
      var form = JSON.parse(action.payload).authenticate.form
      var next = state.setIn(['form', 'state'], form.state)
          .setIn(['form', 'disabled'], form.disabled)
          .setIn(['form', 'error'], form.error)
          .setIn(['form', 'isValid'], form.isValid)
          .setIn(['form', 'isFetching'], form.isFetching)
          .setIn(['form', 'fields', 'fullname'], form.fields.fullname)
          .setIn(['form', 'fields', 'fullname_has_error'], form.fields.fullname_has_error)
          .setIn(['form', 'fields', 'email'], form.fields.email)
          .setIn(['form', 'fields', 'email_has_error'], form.fields.email_has_error)
          .setIn(['form', 'fields', 'phone'], form.fields.phone)
          .setIn(['form', 'fields', 'phone_has_error'], form.fields.phone_has_error)
          .setIn(['form', 'fields', 'password'], form.fields.password)
          .setIn(['form', 'fields', 'password_has_error'], form.fields.password_has_error)
          .setIn(['form', 'fields', 'password_confirm'], form.fields.password_confirm)
          .setIn(['form', 'fields', 'password_confirm_has_error'], form.fields.password_confirm_has_error)
      return next
    case authenticateConstants.DELETE_TOKEN_REQUEST:
    case authenticateConstants.DELETE_TOKEN_SUCCESS:
      return state

  }
  return state
}
