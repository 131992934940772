import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import RentEdit                                        from './edit';
import ApartmentList                                    from './../apartment/simple_data_list';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  rentActions,
  apartmentActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...rentActions,
      ...apartmentActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    rent: {
      gpDataFields                                : state.rent.form.gpDataFields,
      gpRentIsFetching                            : state.rent.form.gpRentIsFetching,
      rent_active_tab                             : state.rent.form.rent_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
        this.props.actions._onApartmentFormFieldChange('rent_id', this.props.match.params.rent_id);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsRentIsFetching                            : true,
      rent_id                                     : '',
      rent_description                            : '',
      rent_fee                                    : '',
      late_fee                                    : '',
      due_date                                    : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        rent_id                                   : nextProps.rent.gpDataFields.rent_id,
        rent_description                          : nextProps.rent.gpDataFields.rent_description,
        rent_fee                                  : nextProps.rent.gpDataFields.rent_fee,
        late_fee                                  : nextProps.rent.gpDataFields.late_fee,
        due_date                                  : nextProps.rent.gpDataFields.due_date,
        date_upd                                  : nextProps.rent.gpDataFields.date_upd,
        date_add                                  : nextProps.rent.gpDataFields.date_add,
        lsRentIsFetching                          : nextProps.rent.gpRentIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewRent(this.props.match.params.rent_id);
      this.props.actions._onApartmentFormFieldChange('rent_id', this.props.rent.gpDataFields.rent_id);
      this.setState({
        lsRentIsFetching                          : this.props.rent.gpRentIsFetching,
        rent_id                                   : this.props.rent.gpDataFields.rent_id,
        rent_description                          : this.props.rent.gpDataFields.rent_description,
        rent_fee                                  : this.props.rent.gpDataFields.rent_fee,
        late_fee                                  : this.props.rent.gpDataFields.late_fee,
        due_date                                  : this.props.rent.gpDataFields.due_date,
      });
    }


    _editRent(id) {
      const data = [{
          rent_id      : id,
      }];
      this.props.actions._activateEditRent(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onRentStateChange('rent_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
        {
          menuItem: { key: 'apartment', content: 'Ruang Sewa' },
          render: () => <Tab.Pane attached={false} ><ApartmentList /></Tab.Pane>,
        },
      ]


      const {
        rent_active_tab
      } = this.props.rent;


      const {
        lease_id,
        lease_description,
        rent_id,
        rent_description,
        rent_fee,
        late_fee,
        due_date,
        date_add,
        date_upd
      } = this.props.rent.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Invoice Sewa
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/rent/edit/' + rent_id}
                onClick = {() => this._editRent(rent_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Rumah Sewa</span><br />
                  {lease_id} - {lease_description}</Col>

                  <Col md={8} className='p-2'><span className='font-bold'>ID Penyewaan</span><br />
                  {rent_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Jenis Penyewaan</span><br />
                  {rent_description}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Biaya Sewa</span><br />
            <NumericFormat
              value={rent_fee}
              thousandSeparator='.'
              prefix='Rp '
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Biaya Keterlambatan</span><br />
            <NumericFormat
              value={late_fee}
              thousandSeparator='.'
              prefix='Rp '
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Tanggal Jatuh Tempo</span><br />
                  {due_date}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
            <div className='spacer' />
            <div>
              <Tab menu={{borderless:true, pointing: true}} onTabChange={this.handleTabChange.bind(this)} activeIndex={rent_active_tab} panes={panes} />
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

