/* -------------------------------------------------------------------------- */
/* LEASE.ACTIONS.JS                                                           */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {leaseConstants}                                from '../_constants';
import {leaseService}                                  from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const leaseActions = {
    _addLease,
    _editLease,
    _filterLease,
    _viewLease,
    _listLease,
    _removeLease,
    _lookupLease,
    _showLeaseModalForm,
    _sendLeaseFormParam,
    _activateAddLease,
    _activateEditLease,
    _activateFilterLease,
    _onLeaseStateChange,
    _onLeaseFormFieldChange,
    action_to_import_lease,
    action_to_export_lease,
    action_to_get_sample_lease,
    action_to_count_lease,
};

export function _addLease(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  leaseService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupLease());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_LEASE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: leaseConstants.LEASE_ADD_REQUEST         }}
    function success(result)  { return { type: leaseConstants.LEASE_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: leaseConstants.LEASE_ADD_FAILURE}}
}

export function _editLease(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  leaseService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupLease());
          dispatch(_viewLease(data.lease_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_LEASE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: leaseConstants.LEASE_CHANGE_REQUEST         }}
    function success(result)  { return { type: leaseConstants.LEASE_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: leaseConstants.LEASE_CHANGE_FAILURE, payload: error }}
}

export function _filterLease(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  leaseService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_LEASE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: leaseConstants.LEASE_FILTER_REQUEST         }}
    function success(result, page)  { return { type: leaseConstants.LEASE_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: leaseConstants.LEASE_FILTER_FAILURE}}
}

export function _removeLease(id) {
      const data = {
        lease_id                                  : '', 
        lease_description                         : '', 
        deposit                                   : '', 
        tenant_id                                 : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'lease_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        leaseService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listLease(data));
          dispatch(_lookupLease());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_LEASE_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: leaseConstants.LEASE_REMOVE_REQUEST        }}
    function success(result) { return { type: leaseConstants.LEASE_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: leaseConstants.LEASE_REMOVE_FAILURE}}
}

export function _initLease() {
  return dispatch => {dispatch(success());};
  function success() { return { type: leaseConstants.LEASE_INIT_SUCCESS}}
}

export function _viewLease(id) {
  return dispatch => {
      dispatch(request({id}));

      leaseService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_LEASE_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: leaseConstants.LEASE_VIEW_REQUEST} }
  function success(result) { return { type: leaseConstants.LEASE_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: leaseConstants.LEASE_VIEW_FAILURE}}
}

export function action_to_count_lease() {
  return dispatch => {
      dispatch(request());
      leaseService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_LEASE_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: leaseConstants.LEASE_COUNT_REQUEST} }
  function success(result) { return { type: leaseConstants.LEASE_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: leaseConstants.LEASE_COUNT_FAILURE}}
}

export function _listLease(data) {
    return dispatch => {
        dispatch(request(data));
        leaseService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_LEASE_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: leaseConstants.LEASE_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: leaseConstants.LEASE_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: leaseConstants.LEASE_GET_LIST_FAILURE}}
}
export function _lookupLease() {
  return dispatch => {
    dispatch(request());
    leaseService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_LEASE_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: leaseConstants.LEASE_LOOKUP_REQUEST         }}
    function success(result) { return { type: leaseConstants.LEASE_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: leaseConstants.LEASE_LOOKUP_FAILURE}}
}

export function _activateEditLease(data) {
  console.log('[lease.action][_activateEditLease][started]');
  const param = [{
      lease_id        : data[0].lease_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showLeaseModalForm('show'))
    dispatch(_sendLeaseFormParam(param));
  }
}

export function _activateAddLease() {
  console.log('[lease.action][_activateAddLease][started]');
  const param = [{
      lease_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initLease());
    dispatch(_showLeaseModalForm('show'));
    dispatch(_sendLeaseFormParam(param));
  }
}

export function _activateFilterLease() {
  console.log('[lease.action][_activateFilterLease][started]');
  const param = [{
      lease_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initLease());
    dispatch(_showLeaseModalForm('show'));
    dispatch(_sendLeaseFormParam(param));
  }
}

export function _onLeaseFormFieldChange (field, value) {
  console.log('[lease.action][onLeaseFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: leaseConstants.ON_LEASE_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onLeaseStateChange (field, value) {
  return {
    type: leaseConstants.ON_LEASE_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showLeaseModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:leaseConstants.LEASE_SHOW_MODAL, payload: showModal}}
}

export function _sendLeaseFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: leaseConstants.LEASE_SEND_PARAM, payload: param}}
}

export function _showLeaseLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:leaseConstants.LEASE_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_lease(data) {
      const param = {
        lease_id                                  : '', 
        lease_description                         : '', 
        deposit                                   : '', 
        tenant_id                                 : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'lease_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  leaseService.service_to_import_lease(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listLease(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_LEASE_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_LEASE_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_LEASE_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: leaseConstants.LEASE_IMPORT_REQUEST         }}
    function success(result)  { return { type: leaseConstants.LEASE_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: leaseConstants.LEASE_IMPORT_FAILURE}}
}

export function action_to_export_lease() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  leaseService.service_to_export_lease()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_LEASE_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_LEASE_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: leaseConstants.LEASE_EXPORT_REQUEST         }}
    function success(result)  { return { type: leaseConstants.LEASE_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: leaseConstants.LEASE_EXPORT_FAILURE}}
}

export function action_to_get_sample_lease() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  leaseService.service_to_get_sample_lease()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_LEASE_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_LEASE_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_LEASE_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: leaseConstants.LEASE_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: leaseConstants.LEASE_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: leaseConstants.LEASE_GET_SAMPLE_FILE_FAILURE}}
}

