// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as complexActions                             from '../_actions/complex.actions';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     complex: {
       gpComplexShowModal                         : state.complex.form.gpComplexShowModal,
       gpComplexModalParam                        : state.complex.form.gpComplexModalParam,
       gpComplexIsFetching                        : state.complex.form.gpComplexIsFetching,
       gpDataFields                               : state.complex.form.gpDataFields,
       gpComplexIsValid                           : state.complex.form.gpComplexIsValid,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...complexActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editComplex(this.props.match.params.complex_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addComplex());
      }

    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.complex.gpComplexModalParam) {
        if (nextProps.complex.gpComplexModalParam !== this.props.complex.gpComplexModalParam) {
          console.log('Information: gpComplexModalParam props changed');
          if(nextProps.complex.gpComplexModalParam['trxType']==='edt') {
            this.setState({
              complex_id      : nextProps.complex.gpComplexModalParam['complex_id'],
              trxType          : nextProps.complex.gpComplexModalParam['trxType'],
            }, this._editComplex(nextProps.complex.gpComplexModalParam['complex_id']));
          };

          if(nextProps.complex.gpComplexModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.complex.gpComplexModalParam['trxType'],
            }, this._addComplex());
          };

          if(nextProps.complex.gpComplexModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.complex.gpComplexModalParam['trxType'],
            }, this._filterComplex());
          };

        };
      };

      if(nextProps.complex.gpComplexShowModal) {
        if (nextProps.complex.gpComplexShowModal !== this.props.complex.gpComplexShowModal) {
          console.log('Information: gpComplexShowModal props changed');
          if(nextProps.complex.gpComplexShowModal==='show'){
            this.setState({
              lsComplexShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.complex.gpComplexShowModal==='hide'){
            this.setState({
              lsComplexShowModal: false,
            });
          }
        };
      };

      this.setState({
        complex_id                                : nextProps.complex.gpDataFields.complex_id,
        complex_name                              : nextProps.complex.gpDataFields.complex_name,
        address                                   : nextProps.complex.gpDataFields.address,
        date_add                                  : nextProps.complex.gpDataFields.date_add,
        date_upd                                  : nextProps.complex.gpDataFields.date_upd,
      });

}
_addComplexOnServer() {
    var id1 = this.state.complex_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(5);
    } else {
      id3 = this.state.complex_id;
    }
    const data = {
        complex_id                                : id3,
        complex_name                              : this.state.complex_name,
        address                                   : this.state.address,
    }
    this.props.actions._addComplex(data);
    this.goBack();
}

_editComplexOnServer() {
    const data = {
        complex_id                                : this.state.complex_id,
        complex_name                              : this.state.complex_name,
        address                                   : this.state.address,
    }
    this.props.actions._editComplex(data);
    this.goBack();
}

_filterComplexOnServer() {
    const data = {
        complex_id                                : this.state.complex_id,
        complex_name                              : this.state.complex_name,
        address                                   : this.state.address,
    }
    this.props.actions._filterComplex(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit complex                                                              */
/* -------------------------------------------------------------------------- */
_editComplex(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewComplex(id);
}

/* -------------------------------------------------------------------------- */
/*  add new complex                                     
/* -------------------------------------------------------------------------- */
_addComplex() {
    let today = GF.today();
    this.props.actions._onComplexFormFieldChange('complex_id', '')
    this.props.actions._onComplexFormFieldChange('complex_name', '')
    this.props.actions._onComplexFormFieldChange('address', '')
    this.props.actions._initComplex();

    this.setState({
         complex_id                               : '',
         complex_name                             : '',
         address                                  : '',
         isIDDisabled                             : false,
         lsComplexDetail                          : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
}

/* -------------------------------------------------------------------------- */
/*  filter complex                                     
/* -------------------------------------------------------------------------- */
_filterComplex() {

    let today = GF.today();
    this.setState({
         complex_id                               : '',
         complex_name                             : '',
         address                                  : '',
         lsComplexDetail                          : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showComplexModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addComplexOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editComplexOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterComplexOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showComplexModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[complex.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onComplexFormFieldChange(field, value)
}

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsComplexShowModal,
    } = this.state;


     const {
       complex_id,
       complex_name,
       address,
       date_add,
       date_upd,
       complex_id_has_error,
       complex_name_has_error,
       address_has_error,
       date_add_has_error,
       date_upd_has_error,
       complex_id_error_msg,
       complex_name_error_msg,
       address_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.complex.gpDataFields;


const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Complex'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add complex start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add complex body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Lokasi
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={6}>
          <b>ID Komplek</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='complex_id'
              placeholder='[AUTO]'
              value={complex_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {complex_id_has_error?<div style={{color:'lightcoral'}}>{complex_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Nama Komplek</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='complex_name'
              placeholder='Masukan Nama Komplek'
              value={complex_name}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {complex_name_has_error?<div style={{color:'lightcoral'}}>{complex_name_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={12}>
          <b>Alamat</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='address'
              placeholder='Masukan Alamat'
              value={address}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {address_has_error?<div style={{color:'lightcoral'}}>{address_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
        </Row>
        <div className='spacer' />
        <br />
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.complex.gpComplexIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add complex body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add complex End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
