'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('lease_detail_id'): {
        let lease_detail_id    = value
        let isValid = true

      if (lease_detail_id.length > 0) {
        if (lease_detail_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_detail_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_detail_id_error_msg'], 'ID Detail perlu di isi')
        }

        if (lease_detail_id.length > 10) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_detail_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_detail_id_error_msg'], 'ID Detail panjang maksimumnya 10 karakter.')
        }

        if (!check.validateAlpha(lease_detail_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'lease_detail_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'lease_detail_id_error_msg'], 'ID Detail tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'lease_detail_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'lease_detail_id_error_msg'], '')
        }
    }

    case ('description'): {
        let description    = value
        let isValid = true

        if (description.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'description_error_msg'], 'Keterangan perlu di isi')
        }

        if (description.length > 30) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'description_error_msg'], 'Keterangan panjang maksimumnya 30 karakter.')
        }

        if (!check.validateAlpha(description)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'description_error_msg'], 'Keterangan tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'description_has_error'], false)
            .setIn(['form', 'gpDataFields', 'description_error_msg'], '')
        }
    }

    case ('quantity'): {
        let quantity    = value
        let isValid = true

        if (!check.validateNumber(quantity)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'quantity_has_error'], true)
            .setIn(['form', 'gpDataFields', 'quantity_error_msg'], 'Jumlah tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'quantity_has_error'], false)
            .setIn(['form', 'gpDataFields', 'quantity_error_msg'], '')
        }
    }

    case ('unit_price'): {
        let unit_price    = value
        let isValid = true

        if (!check.validateMoney(unit_price)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'unit_price_has_error'], true)
            .setIn(['form', 'gpDataFields', 'unit_price_error_msg'], 'Harga tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'unit_price_has_error'], false)
            .setIn(['form', 'gpDataFields', 'unit_price_error_msg'], '')
        }
    }

  }
  return state
}
