'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('id_userlevel'): {
        let id_userlevel    = value
        let isValid = true

      if (id_userlevel.length > 0) {
        if (id_userlevel.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], 'ID User Level perlu di isi')
        }

        if (id_userlevel.length > 20) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], 'ID User Level panjang maksimumnya 20 karakter.')
        }

        if (!check.validateAlpha(id_userlevel)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], true)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], 'ID User Level tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'id_userlevel_has_error'], false)
            .setIn(['form', 'gpDataFields', 'id_userlevel_error_msg'], '')
        }
    }

    case ('role_name'): {
        let role_name    = value
        let isValid = true

        if (role_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'role_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'role_name_error_msg'], 'Nama Role perlu di isi')
        }

        if (role_name.length > 100) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'role_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'role_name_error_msg'], 'Nama Role panjang maksimumnya 100 karakter.')
        }

        if (!check.validateAlpha(role_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'role_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'role_name_error_msg'], 'Nama Role tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'role_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'role_name_error_msg'], '')
        }
    }

  }
  return state
}
