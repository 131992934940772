'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.tenant_id_has_error &&
        state.form.gpDataFields.tenant_name !== '' &&
        !state.form.gpDataFields.tenant_name_has_error &&
        state.form.gpDataFields.email !== '' &&
        !state.form.gpDataFields.email_has_error &&
        state.form.gpDataFields.phone !== '' &&
        !state.form.gpDataFields.phone_has_error &&
        state.form.gpDataFields.ktp !== '' &&
        !state.form.gpDataFields.ktp_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpTenantIsValid'], true)
  } else {
    return state.setIn(['form', 'gpTenantIsValid'], false)
  }
}
