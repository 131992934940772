'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.apartment_id_has_error &&
        state.form.gpDataFields.apartment_name !== '' &&
        !state.form.gpDataFields.apartment_name_has_error &&
        state.form.gpDataFields.total_bedroom !== '' &&
        !state.form.gpDataFields.total_bedroom_has_error &&
        state.form.gpDataFields.rent_cost !== '' &&
        !state.form.gpDataFields.rent_cost_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpApartmentIsValid'], true)
  } else {
    return state.setIn(['form', 'gpApartmentIsValid'], false)
  }
}
