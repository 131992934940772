'use strict'
import {serviceConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/service.initialstate'
import fieldValidation                              from './../_validation/service.field.validation'
import formValidation                               from './../_validation/service.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case serviceConstants.SERVICE_GET_LIST_REQUEST:
    case serviceConstants.SERVICE_LOOKUP_REQUEST:
    case serviceConstants.SERVICE_ADD_REQUEST:
    case serviceConstants.SERVICE_CHANGE_REQUEST:
    case serviceConstants.SERVICE_FILTER_REQUEST:
    case serviceConstants.SERVICE_VIEW_REQUEST:
    case serviceConstants.SERVICE_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpServiceIsFetching'],  true)

    case serviceConstants.SERVICE_CHANGE_SUCCESS:
    case serviceConstants.SERVICE_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpServiceIsFetching'],  false)

    case serviceConstants.SERVICE_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpServiceIsFetching'],  false)
          .setIn(['form', 'service_count'],  action.payload)

    case serviceConstants.SERVICE_FILTER_SUCCESS:
    case serviceConstants.SERVICE_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpServiceDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpServiceDataList'], data.rows)
          .setIn(['form', 'gpServiceDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpServiceIsFetching'],  false)


  case serviceConstants.SERVICE_LOOKUP_SUCCESS:
    console.log('[service.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpServiceLookupList'], action.payload)
        .setIn(['form', 'gpServiceIsFetching'], false)


    case serviceConstants.SERVICE_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'service_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'service_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'problem'],'')
          .setIn(['form', 'gpDataFields',                                                           'apartment_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'apartment_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'employee_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'employee_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'apartment_apartment_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'employee_employee_id'],'')

    case serviceConstants.SERVICE_SHOW_MODAL:
      return state
        .setIn(['form', 'gpServiceShowModal'],  action.payload)


    case serviceConstants.SERVICE_SEND_PARAM:
      return state
        .setIn(['form', 'gpServiceModalParam'],  action.payload[0])


    case serviceConstants.SERVICE_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'service_id'],                                        action.payload[0].service_id)
          .setIn(['form', 'gpOriginalFields', 'service_id'],                                        action.payload[0].service_id)
          .setIn(['form', 'gpDataFields',     'service_name'],                                      action.payload[0].service_name)
          .setIn(['form', 'gpOriginalFields', 'service_name'],                                      action.payload[0].service_name)
          .setIn(['form', 'gpDataFields',     'problem'],                                           action.payload[0].problem)
          .setIn(['form', 'gpOriginalFields', 'problem'],                                           action.payload[0].problem)
          .setIn(['form', 'gpDataFields',     'apartment_id'],                                      action.payload[0].apartment_id)
          .setIn(['form', 'gpOriginalFields', 'apartment_id'],                                      action.payload[0].apartment_id)
          .setIn(['form', 'gpDataFields',     'apartment_name'],                                    action.payload[0].apartment_name)
          .setIn(['form', 'gpOriginalFields', 'apartment_name'],                                    action.payload[0].apartment_name)
          .setIn(['form', 'gpDataFields',     'employee_id'],                                       action.payload[0].employee_id)
          .setIn(['form', 'gpOriginalFields', 'employee_id'],                                       action.payload[0].employee_id)
          .setIn(['form', 'gpDataFields',     'employee_name'],                                     action.payload[0].employee_name)
          .setIn(['form', 'gpOriginalFields', 'employee_name'],                                     action.payload[0].employee_name)
          .setIn(['form', 'gpDataFields', 'apartment_apartment_id'],                                action.payload[0].apartment_apartment_id)
          .setIn(['form', 'gpOriginalFields', 'apartment_apartment_id'],                            action.payload[0].apartment_apartment_id)
          .setIn(['form', 'gpDataFields', 'employee_employee_id'],                                  action.payload[0].employee_employee_id)
          .setIn(['form', 'gpOriginalFields', 'employee_employee_id'],                              action.payload[0].employee_employee_id)
          .setIn(['form', 'gpServiceIsFetching'],                                                   false)
          return nextRecordState


    case serviceConstants.SERVICE_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'service_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'service_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'problem'],                                               '')
          .setIn(['form', 'gpDataFields', 'service_id_has_error'],                                  false)
          .setIn(['form', 'gpDataFields', 'service_name_has_error'],                                false)
          .setIn(['form', 'gpDataFields', 'problem_has_error'],                                     false)

          .setIn(['form', 'gpServiceIsValid'],                                                      false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('serviceReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'service_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'service_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'problem'],                                               '')
          .setIn(['form', 'gpDataFields', 'apartment_id'],                                          '')
          .setIn(['form', 'gpDataFields', 'apartment_name'],                                        '')
          .setIn(['form', 'gpDataFields', 'employee_id'],                                           '')
          .setIn(['form', 'gpDataFields', 'employee_name'],                                         '')
          .setIn(['form', 'gpDataFields', 'apartment_id'],                                          '')
          .setIn(['form', 'gpDataFields', 'employee_id'],                                           '')
          .setIn(['form', 'gpServiceError'],                                                        null)
          return formValidation(nextRecordState, action)


    case serviceConstants.SERVICE_GET_LIST_FAILURE:
    case serviceConstants.SERVICE_LOOKUP_FAILURE:
    case serviceConstants.SERVICE_ADD_FAILURE:
    case serviceConstants.SERVICE_CHANGE_FAILURE:
    case serviceConstants.SERVICE_FILTER_FAILURE:
    case serviceConstants.SERVICE_VIEW_FAILURE:
    case serviceConstants.SERVICE_COUNT_FAILURE:
      console.log('service.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpServiceIsFetching'], false)
          .setIn(['form', 'gpServiceError'], action.payload)


   case serviceConstants.ON_SERVICE_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case serviceConstants.ON_SERVICE_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case serviceConstants.CHANGE_SERVICE_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpServiceError'], null)
      return nextState}

    case serviceConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'service_id'],                                            record.service_id)
          .setIn(['form', 'gpOriginalFields', 'service_id'],                                        record.service_id)
          .setIn(['form', 'gpDataFields', 'service_name'],                                          record.service_name)
          .setIn(['form', 'gpOriginalFields', 'service_name'],                                      record.service_name)
          .setIn(['form', 'gpDataFields', 'problem'],                                               record.problem)
          .setIn(['form', 'gpOriginalFields', 'problem'],                                           record.problem)
          .setIn(['form', 'gpDataFields', 'apartment_id'],                                          record.apartment_id)
          .setIn(['form', 'gpOriginalFields', 'apartment_id'],                                      record.apartment_id)
          .setIn(['form', 'gpDataFields', 'apartment_id_has_error'],                                record.apartment_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'apartment_id_has_error'],                            record.apartment_id_has_error)
          .setIn(['form', 'gpDataFields', 'apartment_name'],                                        record.apartment_name)
          .setIn(['form', 'gpOriginalFields', 'apartment_name'],                                    record.apartment_name)
          .setIn(['form', 'gpDataFields', 'apartment_name_has_error'],                              record.apartment_name_has_error)
          .setIn(['form', 'gpOriginalFields', 'apartment_name_has_error'],                          record.apartment_name_has_error)
          .setIn(['form', 'gpDataFields', 'employee_id'],                                           record.employee_id)
          .setIn(['form', 'gpOriginalFields', 'employee_id'],                                       record.employee_id)
          .setIn(['form', 'gpDataFields', 'employee_id_has_error'],                                 record.employee_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'employee_id_has_error'],                             record.employee_id_has_error)
          .setIn(['form', 'gpDataFields', 'employee_name'],                                         record.employee_name)
          .setIn(['form', 'gpOriginalFields', 'employee_name'],                                     record.employee_name)
          .setIn(['form', 'gpDataFields', 'employee_name_has_error'],                               record.employee_name_has_error)
          .setIn(['form', 'gpOriginalFields', 'employee_name_has_error'],                           record.employee_name_has_error)
          .setIn(['form', 'gpDataFields', 'apartment_apartment_id'],                                record.apartment_apartment_id)
          .setIn(['form', 'gpOriginalFields', 'apartment_apartment_id'],                            record.apartment_apartment_id)
          .setIn(['form', 'gpDataFields', 'employee_employee_id'],                                  record.employee_employee_id)
          .setIn(['form', 'gpOriginalFields', 'employee_employee_id'],                              record.employee_employee_id)

          .setIn(['form', 'gpDataFields', 'service_id_has_error'],                                  record.service_id_has_error)
          .setIn(['form', 'gpDataFields', 'service_name_has_error'],                                record.service_name_has_error)
          .setIn(['form', 'gpDataFields', 'problem_has_error'],                                     record.problem_has_error)

          .setIn(['form', 'gpDataFields', 'apartment_apartment_id_has_error'],                      action.payload.apartment_apartment_id_has_error)
          .setIn(['form', 'gpDataFields', 'employee_employee_id_has_error'],                        action.payload.employee_employee_id_has_error)


          .setIn(['form', 'gpServiceDataList'],                                                     record.gpServiceDataList)
          .setIn(['form', 'gpServiceIsDisabled'],                                                   record.gpServiceIsDisabled)
          .setIn(['form', 'gpServiceError'],                                                        record.gpServiceError)
          .setIn(['form', 'gpServiceIsValid'],                                                      record.gpServiceIsValid)
          .setIn(['form', 'gpServiceIsFetching'],                                                   record.gpServiceIsFetching)
          .setIn(['form', 'service_count'],                                                         record.service_count)
      return next
case serviceConstants.SERVICE_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpServiceLookupShowModal'],  action.payload)


  }
  return state
}
