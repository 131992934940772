import React                    from 'react';
import ReactDOM                 from 'react-dom';
import {Link}                   from 'react-router-dom';
import {Icon, Dropdown}                   from 'semantic-ui-react';
import { HashLink }             from 'react-router-hash-link';
import './navbar.css';


class NavBar extends React.Component {
  render() {

    return (
        <div>
          <ul className='navbar'>
            <li className='navbar-submenu'><span>Home</span>
                <Dropdown
                  button
                  icon='chevron down'
                  multiple
                  iconposition='right'
                  style={{borderColor:'rgb(198, 234, 255)', backgroundColor:'rgb(198, 234, 255)'}}>
                  <Dropdown.Menu>
                    <Dropdown.Item as={HashLink} smooth to='/home#section_building'>Lokasi Bangunan</Dropdown.Item>
                    <Dropdown.Item as={HashLink} smooth to='/home#section_employee'>Pekerja</Dropdown.Item>
                    <Dropdown.Item as={HashLink} smooth to='/home#section_payment'>Pembayaran</Dropdown.Item>
                    <Dropdown.Item as={HashLink} smooth to='/home#section_rent'>Penyewaan</Dropdown.Item>
                    <Dropdown.Item as={HashLink} smooth to='/home#section_service'>Perbaikan</Dropdown.Item>
                    <Dropdown.Item as={HashLink} smooth to='/home#section_setting'>Pengaturan</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
            </li>
          </ul>
        </div>

      )
  }
}

export default NavBar;
