/* -------------------------------------------------------------------------- */
/* COMPANY.ACTIONS.JS                                                         */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {companyConstants}                              from '../_constants';
import {companyService}                                from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const companyActions = {
    _addCompany,
    _editCompany,
    _filterCompany,
    _viewCompany,
    _listCompany,
    _removeCompany,
    _lookupCompany,
    _showCompanyModalForm,
    _sendCompanyFormParam,
    _activateAddCompany,
    _activateEditCompany,
    _activateFilterCompany,
    _onCompanyStateChange,
    _onCompanyFormFieldChange,
    action_to_import_company,
    action_to_export_company,
    action_to_get_sample_company,
    action_to_count_company,
};

export function _addCompany(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  companyService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupCompany());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_COMPANY_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: companyConstants.COMPANY_ADD_REQUEST         }}
    function success(result)  { return { type: companyConstants.COMPANY_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: companyConstants.COMPANY_ADD_FAILURE}}
}

export function _editCompany(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  companyService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupCompany());
          dispatch(_viewCompany(data.company_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_COMPANY_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: companyConstants.COMPANY_CHANGE_REQUEST         }}
    function success(result)  { return { type: companyConstants.COMPANY_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: companyConstants.COMPANY_CHANGE_FAILURE, payload: error }}
}

export function _filterCompany(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  companyService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_COMPANY_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: companyConstants.COMPANY_FILTER_REQUEST         }}
    function success(result, page)  { return { type: companyConstants.COMPANY_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: companyConstants.COMPANY_FILTER_FAILURE}}
}

export function _removeCompany(id) {
      const data = {
        company_id                                : '', 
        company_name                              : '', 
        address                                   : '', 
        phone                                     : '', 
        email                                     : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'company_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        companyService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listCompany(data));
          dispatch(_lookupCompany());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_COMPANY_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: companyConstants.COMPANY_REMOVE_REQUEST        }}
    function success(result) { return { type: companyConstants.COMPANY_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: companyConstants.COMPANY_REMOVE_FAILURE}}
}

export function _initCompany() {
  return dispatch => {dispatch(success());};
  function success() { return { type: companyConstants.COMPANY_INIT_SUCCESS}}
}

export function _viewCompany(id) {
  return dispatch => {
      dispatch(request({id}));

      companyService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_COMPANY_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: companyConstants.COMPANY_VIEW_REQUEST} }
  function success(result) { return { type: companyConstants.COMPANY_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: companyConstants.COMPANY_VIEW_FAILURE}}
}

export function action_to_count_company() {
  return dispatch => {
      dispatch(request());
      companyService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_COMPANY_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: companyConstants.COMPANY_COUNT_REQUEST} }
  function success(result) { return { type: companyConstants.COMPANY_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: companyConstants.COMPANY_COUNT_FAILURE}}
}

export function _listCompany(data) {
    return dispatch => {
        dispatch(request(data));
        companyService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_COMPANY_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: companyConstants.COMPANY_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: companyConstants.COMPANY_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: companyConstants.COMPANY_GET_LIST_FAILURE}}
}
export function _lookupCompany() {
  return dispatch => {
    dispatch(request());
    companyService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_COMPANY_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: companyConstants.COMPANY_LOOKUP_REQUEST         }}
    function success(result) { return { type: companyConstants.COMPANY_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: companyConstants.COMPANY_LOOKUP_FAILURE}}
}

export function _activateEditCompany(data) {
  console.log('[company.action][_activateEditCompany][started]');
  const param = [{
      company_id        : data[0].company_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showCompanyModalForm('show'))
    dispatch(_sendCompanyFormParam(param));
  }
}

export function _activateAddCompany() {
  console.log('[company.action][_activateAddCompany][started]');
  const param = [{
      company_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initCompany());
    dispatch(_showCompanyModalForm('show'));
    dispatch(_sendCompanyFormParam(param));
  }
}

export function _activateFilterCompany() {
  console.log('[company.action][_activateFilterCompany][started]');
  const param = [{
      company_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initCompany());
    dispatch(_showCompanyModalForm('show'));
    dispatch(_sendCompanyFormParam(param));
  }
}

export function _onCompanyFormFieldChange (field, value) {
  console.log('[company.action][onCompanyFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: companyConstants.ON_COMPANY_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onCompanyStateChange (field, value) {
  return {
    type: companyConstants.ON_COMPANY_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showCompanyModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:companyConstants.COMPANY_SHOW_MODAL, payload: showModal}}
}

export function _sendCompanyFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: companyConstants.COMPANY_SEND_PARAM, payload: param}}
}

export function _showCompanyLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:companyConstants.COMPANY_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_company(data) {
      const param = {
        company_id                                : '', 
        company_name                              : '', 
        address                                   : '', 
        phone                                     : '', 
        email                                     : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'company_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  companyService.service_to_import_company(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listCompany(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_COMPANY_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_COMPANY_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_COMPANY_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: companyConstants.COMPANY_IMPORT_REQUEST         }}
    function success(result)  { return { type: companyConstants.COMPANY_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: companyConstants.COMPANY_IMPORT_FAILURE}}
}

export function action_to_export_company() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  companyService.service_to_export_company()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_COMPANY_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_COMPANY_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: companyConstants.COMPANY_EXPORT_REQUEST         }}
    function success(result)  { return { type: companyConstants.COMPANY_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: companyConstants.COMPANY_EXPORT_FAILURE}}
}

export function action_to_get_sample_company() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  companyService.service_to_get_sample_company()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_COMPANY_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_COMPANY_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_COMPANY_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: companyConstants.COMPANY_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: companyConstants.COMPANY_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: companyConstants.COMPANY_GET_SAMPLE_FILE_FAILURE}}
}

