const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        apartment_id                              : '',
        apartment_name                            : '',
        total_bedroom                             : '',
        rent_cost                                 : '',
        building_id                               : '',
        building_name                             : '',
        search_apartment_id                       : '',
        search_apartment_name                     : '',
        search_total_bedroom                      : '',
        search_rent_cost                          : '',
        search_building_id                        : '',
        building_building_id                      : '',
   }))(),
   gpApartmentDataList                            : [],
   gpApartmentDataListNumRows                     : 0,
   gpApartmentLookupList                          : [],
   gpApartmentIsDisabled                          : false,
   gpApartmentError                               : null,
   gpApartmentIsValid                             : true,
   gpApartmentIsFetching                          : false,
   apartment_count                                : 0,
   apartment_active_tab                           : 0,
   gpApartmentReport000Path                       : '',
   gpApartmentReport000IsFetching                 : false,
   gpApartmentChart0DataList                      : [],
   gpApartmentChart0Error                         : null,
   gpApartmentChart0IsFetching                    : false,
   gpApartmentShowModal                           : 'hide',
   gpApartmentModalParam                          : [],
   gpApartmentLookupShowModal                     : 'hide',
   gpDataFields: new (Record({
        building_id                               : '',
        building_name                             : '',
        apartment_id                              : '',
        apartment_name                            : '',
        total_bedroom                             : '',
        rent_cost                                 : '',
        search_apartment_id                       : '',
        search_apartment_name                     : '',
        search_total_bedroom                      : '',
        search_rent_cost                          : '',
        building_building_id                      : '',
        apartment_id_has_error                    : false,
        apartment_name_has_error                  : false,
        total_bedroom_has_error                   : false,
        rent_cost_has_error                       : false,
        search_apartment_id_has_error             : false,
        search_apartment_name_has_error           : false,
        search_total_bedroom_has_error            : false,
        search_rent_cost_has_error                : false,
        building_building_id_has_error            : false,
        apartment_id_error_msg                    : false,
        apartment_name_error_msg                  : false,
        total_bedroom_error_msg                   : false,
        rent_cost_error_msg                       : false,
        search_apartment_id_error_msg             : false,
        search_apartment_name_error_msg           : false,
        search_total_bedroom_error_msg            : false,
        search_rent_cost_error_msg                : false,
        building_building_id_error_msg            : false,
        building_id_has_error                     : false,
        building_id_error_msg                     : false,
        search_building_id                        : '',
        building_id                               : '',
   }))(),
 })
 var apartmentInitialState = Record({
   form: new Form()
 })
export default apartmentInitialState
