// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as lease_detailActions                        from '../_actions/lease_detail.actions';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     lease_detail: {
       gpLease_detailShowModal                    : state.lease_detail.form.gpLease_detailShowModal,
       gpLease_detailModalParam                   : state.lease_detail.form.gpLease_detailModalParam,
       gpLease_detailIsFetching                   : state.lease_detail.form.gpLease_detailIsFetching,
       gpDataFields                               : state.lease_detail.form.gpDataFields,
       gpLease_detailIsValid                      : state.lease_detail.form.gpLease_detailIsValid,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...lease_detailActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editLease_detail(this.props.match.params.lease_detail_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addLease_detail());
      }

    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.lease_detail.gpLease_detailModalParam) {
        if (nextProps.lease_detail.gpLease_detailModalParam !== this.props.lease_detail.gpLease_detailModalParam) {
          console.log('Information: gpLease_detailModalParam props changed');
          if(nextProps.lease_detail.gpLease_detailModalParam['trxType']==='edt') {
            this.setState({
              lease_detail_id      : nextProps.lease_detail.gpLease_detailModalParam['lease_detail_id'],
              trxType          : nextProps.lease_detail.gpLease_detailModalParam['trxType'],
            }, this._editLease_detail(nextProps.lease_detail.gpLease_detailModalParam['lease_detail_id']));
          };

          if(nextProps.lease_detail.gpLease_detailModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.lease_detail.gpLease_detailModalParam['trxType'],
            }, this._addLease_detail());
          };

          if(nextProps.lease_detail.gpLease_detailModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.lease_detail.gpLease_detailModalParam['trxType'],
            }, this._filterLease_detail());
          };

        };
      };

      if(nextProps.lease_detail.gpLease_detailShowModal) {
        if (nextProps.lease_detail.gpLease_detailShowModal !== this.props.lease_detail.gpLease_detailShowModal) {
          console.log('Information: gpLease_detailShowModal props changed');
          if(nextProps.lease_detail.gpLease_detailShowModal==='show'){
            this.setState({
              lsLease_detailShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.lease_detail.gpLease_detailShowModal==='hide'){
            this.setState({
              lsLease_detailShowModal: false,
            });
          }
        };
      };

      this.setState({
        lease_detail_id                           : nextProps.lease_detail.gpDataFields.lease_detail_id,
        description                               : nextProps.lease_detail.gpDataFields.description,
        quantity                                  : nextProps.lease_detail.gpDataFields.quantity,
        unit_price                                : nextProps.lease_detail.gpDataFields.unit_price,
        date_add                                  : nextProps.lease_detail.gpDataFields.date_add,
        date_upd                                  : nextProps.lease_detail.gpDataFields.date_upd,
      });

}
_addLease_detailOnServer() {
    var id1 = this.state.lease_detail_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(10);
    } else {
      id3 = this.state.lease_detail_id;
    }
    const data = {
        lease_detail_id                           : id3,
        description                               : this.state.description,
        quantity                                  : this.state.quantity,
        unit_price                                : this.state.unit_price,
    }
    this.props.actions._addLease_detail(data);
    this.goBack();
}

_editLease_detailOnServer() {
    const data = {
        lease_detail_id                           : this.state.lease_detail_id,
        description                               : this.state.description,
        quantity                                  : this.state.quantity,
        unit_price                                : this.state.unit_price,
    }
    this.props.actions._editLease_detail(data);
    this.goBack();
}

_filterLease_detailOnServer() {
    const data = {
        lease_detail_id                           : this.state.lease_detail_id,
        description                               : this.state.description,
        quantity                                  : this.state.quantity,
        unit_price                                : this.state.unit_price,
    }
    this.props.actions._filterLease_detail(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit lease_detail                                                              */
/* -------------------------------------------------------------------------- */
_editLease_detail(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewLease_detail(id);
}

/* -------------------------------------------------------------------------- */
/*  add new lease_detail                                     
/* -------------------------------------------------------------------------- */
_addLease_detail() {
    let today = GF.today();
    this.props.actions._onLease_detailFormFieldChange('lease_detail_id', '')
    this.props.actions._onLease_detailFormFieldChange('description', '')
    this.props.actions._onLease_detailFormFieldChange('quantity', 0)
    this.props.actions._onLease_detailFormFieldChange('unit_price', 0)
    this.props.actions._initLease_detail();

    this.setState({
         lease_detail_id                          : '',
         description                              : '',
         quantity                                 : 0,
         unit_price                               : 0,
         isIDDisabled                             : false,
         lsLease_detailDetail                     : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
}

/* -------------------------------------------------------------------------- */
/*  filter lease_detail                                     
/* -------------------------------------------------------------------------- */
_filterLease_detail() {

    let today = GF.today();
    this.setState({
         lease_detail_id                          : '',
         description                              : '',
         quantity                                 : 0,
         unit_price                               : 0,
         lsLease_detailDetail                     : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showLease_detailModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addLease_detailOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editLease_detailOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterLease_detailOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showLease_detailModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[lease_detail.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onLease_detailFormFieldChange(field, value)
}

onQuantityChange = (value) => {
  const lvValue   = value;
  const lvField   = 'quantity';
     if (lvValue !== '') {
       this.props.actions._onLease_detailFormFieldChange(lvField, lvValue)
     }
}

onUnit_priceChange = (value) => {
  const lvValue   = value;
  const lvField   = 'unit_price';
     if (lvValue !== '') {
       this.props.actions._onLease_detailFormFieldChange(lvField, lvValue)
     }
}

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsLease_detailShowModal,
    } = this.state;


     const {
       lease_detail_id,
       description,
       quantity,
       unit_price,
       date_add,
       date_upd,
       lease_detail_id_has_error,
       description_has_error,
       quantity_has_error,
       unit_price_has_error,
       date_add_has_error,
       date_upd_has_error,
       lease_detail_id_error_msg,
       description_error_msg,
       quantity_error_msg,
       unit_price_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.lease_detail.gpDataFields;


const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Lease_detail'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease_detail start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease_detail body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Sewa Detail
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={6}>
          <b>ID Detail</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='lease_detail_id'
              placeholder='[AUTO]'
              value={lease_detail_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {lease_detail_id_has_error?<div style={{color:'lightcoral'}}>{lease_detail_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Keterangan</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='description'
              placeholder='Masukan Keterangan'
              value={description}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {description_has_error?<div style={{color:'lightcoral'}}>{description_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Jumlah</b><br />
          <div className='spacer' />
          <NumericFormat
            value={quantity}
            customInput={Input}
            name = 'quantity'
            disabled={false}
            placeholder='Masukan Jumlah'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onLease_detailFormFieldChange('quantity', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {quantity_has_error?<div style={{color:'lightcoral'}}>{quantity_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Harga</b><br />
          <div className='spacer' />
          <NumericFormat
            value={unit_price}
            customInput={Input}
            name = 'unit_price'
            disabled={false}
            prefix='Rp '
            placeholder='Masukan Harga'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onLease_detailFormFieldChange('unit_price', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {unit_price_has_error?<div style={{color:'lightcoral'}}>{unit_price_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
        </Row>
        <div className='spacer' />
        <br />
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.lease_detail.gpLease_detailIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease_detail body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease_detail End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
