import React                                          from 'react';
import { Link }                                       from 'react-router-dom';
import { connect }                                    from 'react-redux';
import {Row, Col}                                     from 'antd';
import {Icon}                                          from 'semantic-ui-react';
import * as APP                                       from './../../_applib/_settings/config'; 



class Transaksi extends React.Component {
    state = {}
    componentDidMount(){
      APP.LOG(APP.userAuthority());
      this.setState({
        language_all: true,
        timeline_all: true,
        notification_all: true,
      })
    }
    renderLanguage() {
      if(this.state.language_all) {
        return  <span>
          <Col span={2} className='home-square-menu'>
            <Link to='/language'>
              <img src={require('./../../_applib/_static/_icons/quiz.png')} width='60px' height='60px'/><br />
              Language
            </Link>
          </Col>
        </span>
      } else {
        return null
      }
    }

    renderTimeline() {
      if(this.state.timeline_all) {
        return  <span>
          <Col span={2} className='home-square-menu'>
            <Link to='/timeline'>
              <img src={require('./../../_applib/_static/_icons/quiz.png')} width='60px' height='60px'/><br />
              Timeline
            </Link>
          </Col>
        </span>
      } else {
        return null
      }
    }

    renderNotification() {
      if(this.state.notification_all) {
        return  <span>
          <Col span={2} className='home-square-menu'>
            <Link to='/notification'>
              <img src={require('./../../_applib/_static/_icons/quiz.png')} width='60px' height='60px'/><br />
              Notification
            </Link>
          </Col>
        </span>
      } else {
        return null
      }
    }


    render() {
        return (
          <div>
            <div className='header-bottom' >
            </div>
            <div className='content'>
              <Row>
             { this.renderLanguage()}
             { this.renderTimeline()}
             { this.renderNotification()}
              </Row>
            </div>
          </div>
        );
    }
}


function mapStateToProps(state) {
    const { users, authenticate } = state;
    const { user } = authenticate;
    return {
        user,
        users
    };
}

const connectedTransaksi = connect(mapStateToProps)(Transaksi);
export { connectedTransaksi as Transaksi };
