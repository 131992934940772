/* -------------------------------------------------------------------------- */
/* TENANT.ACTIONS.JS                                                          */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {tenantConstants}                               from '../_constants';
import {tenantService}                                 from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const tenantActions = {
    _addTenant,
    _editTenant,
    _filterTenant,
    _viewTenant,
    _listTenant,
    _removeTenant,
    _lookupTenant,
    _showTenantModalForm,
    _sendTenantFormParam,
    _activateAddTenant,
    _activateEditTenant,
    _activateFilterTenant,
    _onTenantStateChange,
    _onTenantFormFieldChange,
    action_to_import_tenant,
    action_to_export_tenant,
    action_to_get_sample_tenant,
    action_to_count_tenant,
};

export function _addTenant(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  tenantService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupTenant());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_TENANT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: tenantConstants.TENANT_ADD_REQUEST         }}
    function success(result)  { return { type: tenantConstants.TENANT_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: tenantConstants.TENANT_ADD_FAILURE}}
}

export function _editTenant(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  tenantService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupTenant());
          dispatch(_viewTenant(data.tenant_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_TENANT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: tenantConstants.TENANT_CHANGE_REQUEST         }}
    function success(result)  { return { type: tenantConstants.TENANT_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: tenantConstants.TENANT_CHANGE_FAILURE, payload: error }}
}

export function _filterTenant(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  tenantService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_TENANT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: tenantConstants.TENANT_FILTER_REQUEST         }}
    function success(result, page)  { return { type: tenantConstants.TENANT_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: tenantConstants.TENANT_FILTER_FAILURE}}
}

export function _removeTenant(id) {
      const data = {
        tenant_id                                 : '', 
        tenant_name                               : '', 
        email                                     : '', 
        phone                                     : '', 
        ktp                                       : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'tenant_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        tenantService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listTenant(data));
          dispatch(_lookupTenant());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_TENANT_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: tenantConstants.TENANT_REMOVE_REQUEST        }}
    function success(result) { return { type: tenantConstants.TENANT_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: tenantConstants.TENANT_REMOVE_FAILURE}}
}

export function _initTenant() {
  return dispatch => {dispatch(success());};
  function success() { return { type: tenantConstants.TENANT_INIT_SUCCESS}}
}

export function _viewTenant(id) {
  return dispatch => {
      dispatch(request({id}));

      tenantService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_TENANT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: tenantConstants.TENANT_VIEW_REQUEST} }
  function success(result) { return { type: tenantConstants.TENANT_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: tenantConstants.TENANT_VIEW_FAILURE}}
}

export function action_to_count_tenant() {
  return dispatch => {
      dispatch(request());
      tenantService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_TENANT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: tenantConstants.TENANT_COUNT_REQUEST} }
  function success(result) { return { type: tenantConstants.TENANT_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: tenantConstants.TENANT_COUNT_FAILURE}}
}

export function _listTenant(data) {
    return dispatch => {
        dispatch(request(data));
        tenantService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_TENANT_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: tenantConstants.TENANT_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: tenantConstants.TENANT_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: tenantConstants.TENANT_GET_LIST_FAILURE}}
}
export function _lookupTenant() {
  return dispatch => {
    dispatch(request());
    tenantService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_TENANT_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: tenantConstants.TENANT_LOOKUP_REQUEST         }}
    function success(result) { return { type: tenantConstants.TENANT_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: tenantConstants.TENANT_LOOKUP_FAILURE}}
}

export function _activateEditTenant(data) {
  console.log('[tenant.action][_activateEditTenant][started]');
  const param = [{
      tenant_id        : data[0].tenant_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showTenantModalForm('show'))
    dispatch(_sendTenantFormParam(param));
  }
}

export function _activateAddTenant() {
  console.log('[tenant.action][_activateAddTenant][started]');
  const param = [{
      tenant_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initTenant());
    dispatch(_showTenantModalForm('show'));
    dispatch(_sendTenantFormParam(param));
  }
}

export function _activateFilterTenant() {
  console.log('[tenant.action][_activateFilterTenant][started]');
  const param = [{
      tenant_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initTenant());
    dispatch(_showTenantModalForm('show'));
    dispatch(_sendTenantFormParam(param));
  }
}

export function _onTenantFormFieldChange (field, value) {
  console.log('[tenant.action][onTenantFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: tenantConstants.ON_TENANT_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onTenantStateChange (field, value) {
  return {
    type: tenantConstants.ON_TENANT_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showTenantModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:tenantConstants.TENANT_SHOW_MODAL, payload: showModal}}
}

export function _sendTenantFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: tenantConstants.TENANT_SEND_PARAM, payload: param}}
}

export function _showTenantLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:tenantConstants.TENANT_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_tenant(data) {
      const param = {
        tenant_id                                 : '', 
        tenant_name                               : '', 
        email                                     : '', 
        phone                                     : '', 
        ktp                                       : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'tenant_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  tenantService.service_to_import_tenant(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listTenant(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_TENANT_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_TENANT_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_TENANT_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: tenantConstants.TENANT_IMPORT_REQUEST         }}
    function success(result)  { return { type: tenantConstants.TENANT_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: tenantConstants.TENANT_IMPORT_FAILURE}}
}

export function action_to_export_tenant() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  tenantService.service_to_export_tenant()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_TENANT_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_TENANT_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: tenantConstants.TENANT_EXPORT_REQUEST         }}
    function success(result)  { return { type: tenantConstants.TENANT_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: tenantConstants.TENANT_EXPORT_FAILURE}}
}

export function action_to_get_sample_tenant() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  tenantService.service_to_get_sample_tenant()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_TENANT_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_TENANT_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_TENANT_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: tenantConstants.TENANT_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: tenantConstants.TENANT_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: tenantConstants.TENANT_GET_SAMPLE_FILE_FAILURE}}
}

