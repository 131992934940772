/* -------------------------------------------------------------------------- */
/* Import definition start                                                    */
/* -------------------------------------------------------------------------- */
import React, { Component }                           from 'react';
import { bindActionCreators }                         from 'redux';
import { connect }                                    from 'react-redux';
import { Link }                                       from 'react-router-dom';
import RentEdit                               from './edit';
import moment                                          from 'moment';
import {
  rentActions,
  leaseActions,
}                                                     from '../_actions';
import {
  Row,
  Col,
  Input,
  Popconfirm,
  DatePicker,
}                                                     from 'antd';

import {
  Button,
  Icon,
  Pagination,
  Table,
  Image,
  Modal
}                                                     from 'semantic-ui-react';

import ContentLoader                                  from 'react-content-loader'
import * as APP                                       from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';


/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...rentActions,
      ...leaseActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
    return {
    rent: {
        gpRentDataList                            : state.rent.form.gpRentDataList,
        gpRentDataListNumRows                     : state.rent.form.gpRentDataListNumRows,
        gpRentIsFetching                          : state.rent.form.gpRentIsFetching, 
        gpDataFields                              : state.rent.form.gpDataFields, 
        gpRentShowModal                           : state.rent.form.gpRentShowModal,
      },
      global: {
        currentUser : state.global.currentUser
      }
    };
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const dateFormat    = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */

class DataList extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        search_rent_id                            : '',
        search_rent_description                   : '',
        search_rent_fee                           : '',
        search_late_fee                           : '',
        lsRentIsFetching                          : true,
        lsRentDataList                            : [],
        lsPagination                              : {},
        lsPageSize                                : 10,
        lsCurrentPage                             : 1,
        lsSortedBy                                : 'rent_id',
        lsSortOrder                               : 'descend',
        lsTotalPage                               : 0,
        lsNumberOfRows                            : 0,
        lsSearchText                              : '',
        lsLoadingRent                             : true,
        direction                                 : null,
        file                                      : null,
        lease_id                                  : '',
}


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
  componentWillReceiveProps (nextProps) {

    if (nextProps.rent.gpRentDataList){
      if (nextProps.rent.gpRentDataList !== this.props.rent.gpRentDataList) {
        this.setState({
          lsRentDataList                          : nextProps.rent.gpRentDataList,
          lsNumberOfRows                          : nextProps.rent.gpRentDataListNumRows,
          lsTotalPage                             : Math.ceil(nextProps.rent.gpRentDataListNumRows/this.state.lsPageSize)
        });

      };
    };

      this.setState({
        search_rent_id                            : nextProps.rent.gpDataFields.search_rent_id,
        search_rent_description                   : nextProps.rent.gpDataFields.search_rent_description,
        search_rent_fee                           : nextProps.rent.gpDataFields.search_rent_fee,
        search_late_fee                           : nextProps.rent.gpDataFields.search_late_fee,
        lease_id                                  : nextProps.rent.gpDataFields.lease_id,
      });

};

/* -------------------------------------------------------------------------- */
/*  componentdidmount                                                         */
/* -------------------------------------------------------------------------- */
  componentDidMount(){
    this._loadRentDataSets();
    APP.LOG(APP.userAuthority());
    this.setState({
      rent_all: true,
      rent_add: true,
      rent_edt: true,
      rent_del: true,
      rent_inq: true,
    })
}

    fileInputRef = React.createRef();

    fileChange(e){
      this.setState({ file: e.target.files[0] }, () => {
        console.log('File chosen --->', this.state.file);
      });
    };

    fileUpload(){
      console.log('file: ' + this.state.file);
      const formData = new FormData();
      formData.append('file', this.state.file, this.state.file.name);
      this.props.actions.action_to_import_rent(formData);
      this.setState({show: false});
      return true;
    };

    _onChange(e){
      const value   = e.target.value;
      const field   = e.target.name;
      console.log('[rent.list][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
      this.props.actions._onRentFormFieldChange(field, value)
    }


    _refresh(){
      this.props.actions._onRentFormFieldChange('search_rent_id','')
      this.props.actions._onRentFormFieldChange('search_rent_description','')
      this.props.actions._onRentFormFieldChange('search_rent_fee','')
      this.props.actions._onRentFormFieldChange('search_late_fee','')

      const data = {
        rent_id                                   : '', 
        rent_description                          : '', 
        rent_fee                                  : '', 
        late_fee                                  : '', 
        lease_id                                  : '', 
        page_size                                 : this.state.lsPageSize,
        current_page                              : 1,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
      }

      this.setState({
        lsCurrentPage      : 1,
      }, this.props.actions._listRent(data));

    }


/* -------------------------------------------------------------------------- */
/*  load rent list                                      */
/* -------------------------------------------------------------------------- */
    _loadRentDataSets() {

      const data = {
        rent_id                                   : this.state.search_rent_id,
        rent_description                          : this.state.search_rent_description,
        rent_fee                                  : this.state.search_rent_fee,
        late_fee                                  : this.state.search_late_fee,
        lease_id                                  : this.state.lease_id, 
        page_size                                 : this.state.lsPageSize,
        current_page                              : 1,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
      }

      this.setState({
        lsCurrentPage                             : 1,
      }, this.props.actions._listRent(data));

}

/* -------------------------------------------------------------------------- */
/*  edit records                                                              */
/* -------------------------------------------------------------------------- */
    _editRent(id, index) {

      this.props.actions._onRentFormFieldChange('lease_id', this.props.rent.gpDataFields.rent_id);
      this.props.actions._onRentFormFieldChange('rent_id', this.props.rent.gpRentDataList[index]['rent_id'])
      this.props.actions._onRentFormFieldChange('rent_description', this.props.rent.gpRentDataList[index]['rent_description'])
      this.props.actions._onRentFormFieldChange('rent_fee', this.props.rent.gpRentDataList[index]['rent_fee'])
      this.props.actions._onRentFormFieldChange('late_fee', this.props.rent.gpRentDataList[index]['late_fee'])
      this.props.actions._onRentFormFieldChange('due_date', this.props.rent.gpRentDataList[index]['due_date'])

      const data = [{
        rent_id                                   : id,
      }];
      this.props.actions._activateEditRent(data)
    }

    _viewRent(id, index) {
      this.props.actions._onRentFormFieldChange('lease_id', this.props.rent.gpRentDataList[index]['rent_id']);
      this.props.actions._onRentFormFieldChange('rent_id', this.props.rent.gpRentDataList[index]['rent_id'])
      this.props.actions._onRentFormFieldChange('rent_description', this.props.rent.gpRentDataList[index]['rent_description'])
      this.props.actions._onRentFormFieldChange('rent_fee', this.props.rent.gpRentDataList[index]['rent_fee'])
      this.props.actions._onRentFormFieldChange('late_fee', this.props.rent.gpRentDataList[index]['late_fee'])
      this.props.actions._onRentFormFieldChange('due_date', this.props.rent.gpRentDataList[index]['due_date'])
      this.props.actions._viewRent(id);
    }


    _removeRent(id) {
        this.props.actions._removeRent(id);
    }

    _addRent(){
      this.props.actions._activateAddRent()
    }

    _filterRent(){
      this.props.actions._activateFilterRent()
    }

    import_rent(){
      this.props.actions.action_to_import_rent()
    }

    export_rent(){
      this.props.actions.action_to_export_rent()
    }

    get_sample_rent(){
      this.props.actions.action_to_get_sample_rent()
      this.setState({show: false});
    }
  _handlePaginationChange= (e, param) => {
    const data = {
        rent_id                                   : this.state.search_rent_id,
        rent_description                          : this.state.search_rent_description,
        rent_fee                                  : this.state.search_rent_fee,
        late_fee                                  : this.state.search_late_fee,
        lease_id                                  : this.state.lease_id,
        page_size                                 : this.state.lsPageSize,
        current_page                              : param.activePage,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
    }

    this.setState({
     lsCurrentPage      : param.activePage,
    }, this.props.actions._listRent(data))
  }


  _handleSort = (clickedColumn) => () => {
    const { direction, lsSortOrder } = this.state

    const data = {
        trancode          : 'filter',
        rent_id                                   : this.state.search_rent_id,
        rent_description                          : this.state.search_rent_description,
        rent_fee                                  : this.state.search_rent_fee,
        late_fee                                  : this.state.search_late_fee,
        lease_id                                  : this.state.lease_id,
        page_size                                 : this.state.lsPageSize,
        current_page                              : this.state.lsCurrentPage,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
    }

    this.setState({
      column                                      : clickedColumn,
      lsSortedBy                                  : clickedColumn,
      lsSortOrder                                 : lsSortOrder === 'ascend' ? 'descend' : 'ascend',
      data                                        : '',
      direction                                   : direction === 'ascending' ? 'descending' : 'ascending',
    }, this.props.actions._listRent(data))

}
/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
    render() {
      const {
        search_rent_id,
        search_rent_description,
        search_rent_fee,
        search_late_fee,
        search_rent_id_has_error,
        search_rent_description_has_error,
        search_rent_fee_has_error,
        search_late_fee_has_error,
        search_due_dateStr_has_error,
        search_due_dateEnd_has_error,
        search_rent_id_error_msg,
        search_rent_description_error_msg,
        search_rent_fee_error_msg,
        search_late_fee_error_msg,
        search_due_dateStr_error_msg,
        search_due_dateEnd_error_msg,
      } = this.props.rent.gpDataFields;

      const {
        rent_add,
        rent_edt,
        rent_del,
        rent_inq,
        file,
      } = this.state;

      const dataRows = this.props.rent.gpRentDataList.map((data, index) =>
        <tr key={index}>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.rent_id.replace(/(.{30})..+/, "$1…")}
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              <Link className='-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50' to={'/rent/view/' + data.rent_id} onClick={() => this._viewRent(data.rent_id, index)}>{data.rent_description}</Link>
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              <NumericFormat
                value={data.rent_fee}
                thousandSeparator='.'
                prefix='Rp '
                decimalSeparator=','
                decimalScale={2}
                displayType='text'
                renderText={(value) => <span>{value}</span>}
              />
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              <NumericFormat
                value={data.late_fee}
                thousandSeparator='.'
                prefix='Rp '
                decimalSeparator=','
                decimalScale={2}
                displayType='text'
                renderText={(value) => <span>{value}</span>}
              />
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.due_date.replace(/(.{30})..+/, "$1…")}
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <p className='text-gray-900 whitespace-no-wrap'>
              <Link className='-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50' to={'/lease/view/' + data.lease_id} >{data.lease_description}</Link>
            </p>
          </td>
          <td className='px-5 py-3 border-b border-gray-200 bg-white text-base'>
            <div className='bg-white flex flex-col xs:flex-row items-left xs:justify-between'>
                <div className='flex items-center'>
                  <Link disabled = {!rent_inq} to={'/rent/view/' + data.rent_id} onClick={() => this._viewRent(data.rent_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-blue-500 bg-white hover:bg-gray-100'
                      disabled = {!rent_inq}
                      ><Icon name='eye' /></button>
                  </Link>
                  <Link disabled = {!rent_edt} to={'/rent/edit/' + data.rent_id}  onClick = {() => this._editRent(data.rent_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-blue-500 bg-white hover:bg-gray-100'
                      onClick = {() => this._editRent(data.rent_id, index)}
                      disabled = {!rent_edt}
                      ><Icon name='edit' /></button>
                  </Link>
                  <Popconfirm title='Apakah, Anda yakin akan melakukan penghapusan?' onConfirm={() => this._removeRent(data.rent_id)}>
                    <Link to={'#'}>
                      <button type='button' className='w-full px-2 py-0 text-base text-blue-500 bg-white hover:bg-gray-100'
                        disabled = {!rent_del}
                        ><Icon name='trash' /></button>
                    </Link>
                  </Popconfirm>
                </div>
            </div>
          </td>
        </tr>
      );


      const simpleDataRows = this.props.rent.gpRentDataList.map((data, index) =>
        <div className='w-full gap-6 pb-4 rounded-lg'><Link to={'/rent/view/' + data.rent_id} onClick={() => this._viewRent(data.rent_id, index)}>
        <table className='w-full bg-indigo-50 p-2 rounded-lg'>
        <tr key={index}>
        <td className='text-gray-900 w-full p-6'>
          <b>ID Penyewaan</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.rent_id.replace(/(.{30})..+/, "$1…")}
            </p>
          <b>Jenis Penyewaan</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.rent_description}
            </p>
          <b>Biaya Sewa</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              <NumericFormat
                value={data.rent_fee}
                thousandSeparator='.'
                prefix='Rp '
                decimalSeparator=','
                decimalScale={2}
                displayType='text'
                renderText={(value) => <span>{value}</span>}
              />
            </p>
          <b>Biaya Keterlambatan</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              <NumericFormat
                value={data.late_fee}
                thousandSeparator='.'
                prefix='Rp '
                decimalSeparator=','
                decimalScale={2}
                displayType='text'
                renderText={(value) => <span>{value}</span>}
              />
            </p>
          <b>Tanggal Jatuh Tempo</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              {data.due_date.replace(/(.{30})..+/, "$1…")}
            </p>
          <b>Keterangan</b><br />
            <p className='text-gray-900 whitespace-no-wrap'>
              <Link className='flex items-center rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50' to={'/lease/view/' + data.lease_id} >{data.lease_description}</Link>
            </p>
          <td className='px-5 py-3 text-base'>
            <div className='flex flex-col xs:flex-row items-left xs:justify-between'>
                <div className='flex items-center'>
                  <Link disabled = {!rent_inq} to={'/rent/view/' + data.rent_id} onClick={() => this._viewRent(data.rent_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-indigo-500 hover:bg-gray-100'
                      disabled = {!rent_inq}
                      ><Icon name='eye' /></button>
                  </Link>
                  <Link disabled = {!rent_edt} to={'/rent/edit/' + data.rent_id}  onClick = {() => this._editRent(data.rent_id, index)}>
                    <button type='button' className='w-full px-2 py-0 text-base text-indigo-500 hover:bg-gray-100'
                      onClick = {() => this._editRent(data.rent_id, index)}
                      disabled = {!rent_edt}
                      ><Icon name='edit' /></button>
                  </Link>
                  <Popconfirm title='Apakah, Anda yakin akan melakukan penghapusan?' onConfirm={() => this._removeRent(data.rent_id)}>
                    <Link to={'#'}>
                      <button type='button' className='w-full px-2 py-0 text-base text-indigo-500 hover:bg-gray-100'
                        disabled = {!rent_del}
                        ><Icon name='trash' /></button>
                    </Link>
                  </Popconfirm>
                </div>
            </div>
          </td>
          </td>
        </tr>
        </table></Link>
        </div>
      );


/* ------------------------------------------------------------------------ */
/* Rent Table Columns Name definition start                              */
/* ------------------------------------------------------------------------ */
      const { column, data, direction } = this.state
/* ------------------------------------------------------------------------ */
/* Rent Table Columns Name definition End                        */
/* ------------------------------------------------------------------------ */
/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
      return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
      <div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add rent start                                        */}
{/* ------------------------------------------------------------------------ */}
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add rent End                                          */}
{/* ------------------------------------------------------------------------ */}

{/* ------------------------------------------------------------------------ */}
{/*  top navigation start                                                    */}
{/* ------------------------------------------------------------------------ */}

{/* ------------------------------------------------------------------------ */}
{/*  top navigation end                                                      */}
{/* ------------------------------------------------------------------------ */}


{/* ------------------------------------------------------------------------ */}
{/*  Table Start                                                             */}
{/* ------------------------------------------------------------------------ */}
        <div>
          <Modal open={this.state.show}>
            <Modal.Header>Select a File</Modal.Header>
            <Modal.Description>
              <br /><br />
              <Button
                content='Pilih File'
                labelPosition='left'
                icon='file'
                onClick={() => this.fileInputRef.current.click()}
              />
              <input
                ref={this.fileInputRef}
                type='file'
                hidden
                onChange={this.fileChange.bind(this)}
              />
              <br /><br />
              {this.state.file? 'Import file :' + this.state.file.name:null}
              <br /><br />
              <Link to='./rent' onClick={() => this.get_sample_rent()}>Sample</Link>
              <br /><br />
            </Modal.Description>
            <Modal.Actions>
              <Button onClick={()=>this.fileUpload()}>Upload</Button>
              <Button onClick={()=>{this.setState({show: false})}}>Close</Button>
            </Modal.Actions>
          </Modal>
          <div>
            <Row>
              <Col span={10}>
                <div className='inline-flex rounded-md shadow-sm' role='group'>
                <Link className='text-blue-900 inline-flex justify-center' disabled = {!rent_add} to='/rent/add/'>
                  <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
                    <Icon name='add' /><div className='hidden md:block'>Tambah</div>
                  </button>
                </Link>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  disabled = {!rent_inq}
                  onClick={() => this._filterRent()}
                  ><Icon name='search' /><div className='hidden md:block'>Search</div>
                </button>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  disabled = {!rent_inq}
                  onClick={() => this._refresh()}
                  ><Icon name='refresh' /><div className='hidden md:block'>Refresh</div>
                </button>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  onClick={()=>{this.setState({show: true})}}
                  ><Icon name='upload' /><div className='hidden md:block'>Import</div>
                </button>
                &nbsp;&nbsp;
                <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-4 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
                  onClick={() => this.export_rent()}
                  ><Icon name='download' /><div className='hidden md:block'>Export</div>
                </button>
                </div>
              </Col>
              <Col span={10}></Col>
            </Row>
            <div className='spacer' />
          </div>
        <div>
        <div className='w-full overflow-hidden rounded-lg shadow-xs'>
          <div className='w-full overflow-x-auto'>
            <div class='overflow-auto hidden md:block'>
            <table className='w-full whitespace-no-wrap'>
              <thead>
                <tr className='text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800'>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      ID Penyewaan
                      <a href='#' onClick={this._handleSort('rent_id')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Jenis Penyewaan
                      <a href='#' onClick={this._handleSort('rent_description')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Biaya Sewa
                      <a href='#' onClick={this._handleSort('rent_fee')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Biaya Keterlambatan
                      <a href='#' onClick={this._handleSort('late_fee')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Tanggal Jatuh Tempo
                      <a href='#' onClick={this._handleSort('due_date')}>
                        <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-3 h-3' aria-hidden='true' fill='#999999' viewBox='0 0 320 512'>
                          <path d='M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z'/>
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Keterangan
                    </div>
                  </th>
                  <th className='px-4 py-3'>
                    <div className='flex items-center'>
                      Actions
                    </div>
                  </th>
                  </tr>
                </thead>
              <tbody>

              {dataRows}

              </tbody>
            </table>
            </div>

            <div class='grid grid-cols-1 md:hidden'>
              {simpleDataRows}
            </div>

             <div className='px-5 bg-white py-5 flex flex-col xs:flex-row xs:justify-between'>
                    <div className='flex float-right text-base'>
                      <div className='items-left w-full hidden md:block'>
                        Show {(this.state.lsCurrentPage-1) * 10 + 1 } - {(this.state.lsCurrentPage * 10 + 1)>this.state.lsNumberOfRows ? this.state.lsNumberOfRows:(this.state.lsCurrentPage * 10 + 1)} from {this.state.lsNumberOfRows}
                      </div>
                      <div className='items-right'>
                        <Pagination size='tiny' className='w-full p-0 text-base text-blue-600 rounded-3xl bg-white' floated='right' defaultActivePage={this.state.lsCurrentPage} onPageChange={this._handlePaginationChange} totalPages={this.state.lsTotalPage} />
                      </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Table End                                                               */}
{/* ------------------------------------------------------------------------ */}
     </div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
    );
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
  }
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */

export default connect(mapStateToProps, mapDispatchToProps)(DataList);

