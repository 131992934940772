/* -------------------------------------------------------------------------- */
/* PAYMENT.ACTIONS.JS                                                         */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {paymentConstants}                              from '../_constants';
import {paymentService}                                from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const paymentActions = {
    _addPayment,
    _editPayment,
    _filterPayment,
    _viewPayment,
    _listPayment,
    _removePayment,
    _lookupPayment,
    _showPaymentModalForm,
    _sendPaymentFormParam,
    _activateAddPayment,
    _activateEditPayment,
    _activateFilterPayment,
    _onPaymentStateChange,
    _onPaymentFormFieldChange,
    action_to_import_payment,
    action_to_export_payment,
    action_to_get_sample_payment,
    action_to_count_payment,
};

export function _addPayment(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  paymentService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupPayment());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_PAYMENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: paymentConstants.PAYMENT_ADD_REQUEST         }}
    function success(result)  { return { type: paymentConstants.PAYMENT_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: paymentConstants.PAYMENT_ADD_FAILURE}}
}

export function _editPayment(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  paymentService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupPayment());
          dispatch(_viewPayment(data.payment_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_PAYMENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: paymentConstants.PAYMENT_CHANGE_REQUEST         }}
    function success(result)  { return { type: paymentConstants.PAYMENT_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: paymentConstants.PAYMENT_CHANGE_FAILURE, payload: error }}
}

export function _filterPayment(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  paymentService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_PAYMENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: paymentConstants.PAYMENT_FILTER_REQUEST         }}
    function success(result, page)  { return { type: paymentConstants.PAYMENT_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: paymentConstants.PAYMENT_FILTER_FAILURE}}
}

export function _removePayment(id) {
      const data = {
        payment_id                                : '', 
        Payment_amount                            : '', 
        rent_id                                   : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'payment_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        paymentService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listPayment(data));
          dispatch(_lookupPayment());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_PAYMENT_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: paymentConstants.PAYMENT_REMOVE_REQUEST        }}
    function success(result) { return { type: paymentConstants.PAYMENT_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: paymentConstants.PAYMENT_REMOVE_FAILURE}}
}

export function _initPayment() {
  return dispatch => {dispatch(success());};
  function success() { return { type: paymentConstants.PAYMENT_INIT_SUCCESS}}
}

export function _viewPayment(id) {
  return dispatch => {
      dispatch(request({id}));

      paymentService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_PAYMENT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: paymentConstants.PAYMENT_VIEW_REQUEST} }
  function success(result) { return { type: paymentConstants.PAYMENT_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: paymentConstants.PAYMENT_VIEW_FAILURE}}
}

export function action_to_count_payment() {
  return dispatch => {
      dispatch(request());
      paymentService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_PAYMENT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: paymentConstants.PAYMENT_COUNT_REQUEST} }
  function success(result) { return { type: paymentConstants.PAYMENT_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: paymentConstants.PAYMENT_COUNT_FAILURE}}
}

export function _listPayment(data) {
    return dispatch => {
        dispatch(request(data));
        paymentService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_PAYMENT_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: paymentConstants.PAYMENT_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: paymentConstants.PAYMENT_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: paymentConstants.PAYMENT_GET_LIST_FAILURE}}
}
export function _lookupPayment() {
  return dispatch => {
    dispatch(request());
    paymentService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_PAYMENT_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: paymentConstants.PAYMENT_LOOKUP_REQUEST         }}
    function success(result) { return { type: paymentConstants.PAYMENT_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: paymentConstants.PAYMENT_LOOKUP_FAILURE}}
}

export function _activateEditPayment(data) {
  console.log('[payment.action][_activateEditPayment][started]');
  const param = [{
      payment_id        : data[0].payment_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showPaymentModalForm('show'))
    dispatch(_sendPaymentFormParam(param));
  }
}

export function _activateAddPayment() {
  console.log('[payment.action][_activateAddPayment][started]');
  const param = [{
      payment_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initPayment());
    dispatch(_showPaymentModalForm('show'));
    dispatch(_sendPaymentFormParam(param));
  }
}

export function _activateFilterPayment() {
  console.log('[payment.action][_activateFilterPayment][started]');
  const param = [{
      payment_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initPayment());
    dispatch(_showPaymentModalForm('show'));
    dispatch(_sendPaymentFormParam(param));
  }
}

export function _onPaymentFormFieldChange (field, value) {
  console.log('[payment.action][onPaymentFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: paymentConstants.ON_PAYMENT_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onPaymentStateChange (field, value) {
  return {
    type: paymentConstants.ON_PAYMENT_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showPaymentModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:paymentConstants.PAYMENT_SHOW_MODAL, payload: showModal}}
}

export function _sendPaymentFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: paymentConstants.PAYMENT_SEND_PARAM, payload: param}}
}

export function _showPaymentLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:paymentConstants.PAYMENT_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_payment(data) {
      const param = {
        payment_id                                : '', 
        Payment_amount                            : '', 
        rent_id                                   : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'payment_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  paymentService.service_to_import_payment(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listPayment(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_PAYMENT_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_PAYMENT_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_PAYMENT_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: paymentConstants.PAYMENT_IMPORT_REQUEST         }}
    function success(result)  { return { type: paymentConstants.PAYMENT_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: paymentConstants.PAYMENT_IMPORT_FAILURE}}
}

export function action_to_export_payment() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  paymentService.service_to_export_payment()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_PAYMENT_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_PAYMENT_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: paymentConstants.PAYMENT_EXPORT_REQUEST         }}
    function success(result)  { return { type: paymentConstants.PAYMENT_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: paymentConstants.PAYMENT_EXPORT_FAILURE}}
}

export function action_to_get_sample_payment() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  paymentService.service_to_get_sample_payment()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_PAYMENT_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_PAYMENT_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_PAYMENT_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: paymentConstants.PAYMENT_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: paymentConstants.PAYMENT_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: paymentConstants.PAYMENT_GET_SAMPLE_FILE_FAILURE}}
}

