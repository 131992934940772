import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import ApartmentEdit                                   from './edit';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  apartmentActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...apartmentActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    apartment: {
      gpDataFields                                : state.apartment.form.gpDataFields,
      gpApartmentIsFetching                       : state.apartment.form.gpApartmentIsFetching,
      apartment_active_tab                        : state.apartment.form.apartment_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsApartmentIsFetching                       : true,
      apartment_id                                : '',
      apartment_name                              : '',
      total_bedroom                               : '',
      rent_cost                                   : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        apartment_id                              : nextProps.apartment.gpDataFields.apartment_id,
        apartment_name                            : nextProps.apartment.gpDataFields.apartment_name,
        total_bedroom                             : nextProps.apartment.gpDataFields.total_bedroom,
        rent_cost                                 : nextProps.apartment.gpDataFields.rent_cost,
        date_upd                                  : nextProps.apartment.gpDataFields.date_upd,
        date_add                                  : nextProps.apartment.gpDataFields.date_add,
        lsApartmentIsFetching                     : nextProps.apartment.gpApartmentIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewApartment(this.props.match.params.apartment_id);
      this.setState({
        lsApartmentIsFetching                     : this.props.apartment.gpApartmentIsFetching,
        apartment_id                              : this.props.apartment.gpDataFields.apartment_id,
        apartment_name                            : this.props.apartment.gpDataFields.apartment_name,
        total_bedroom                             : this.props.apartment.gpDataFields.total_bedroom,
        rent_cost                                 : this.props.apartment.gpDataFields.rent_cost,
      });
    }


    _editApartment(id) {
      const data = [{
          apartment_id      : id,
      }];
      this.props.actions._activateEditApartment(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onApartmentStateChange('apartment_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
      ]


      const {
        apartment_active_tab
      } = this.props.apartment;


      const {
        building_id,
        building_name,
        apartment_id,
        apartment_name,
        total_bedroom,
        rent_cost,
        date_add,
        date_upd
      } = this.props.apartment.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Ruang Sewa
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/apartment/edit/' + apartment_id}
                onClick = {() => this._editApartment(apartment_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Gedung</span><br />
                  {building_id} - {building_name}</Col>

                  <Col md={8} className='p-2'><span className='font-bold'>ID Apartment</span><br />
                  {apartment_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Nama Apartment</span><br />
                  {apartment_name}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Jumlah Kamar Tidur</span><br />
            <NumericFormat
              value={total_bedroom}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Biaya Sewa</span><br />
            <NumericFormat
              value={rent_cost}
              thousandSeparator='.'
              prefix='Rp '
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

