import React, {Component}                              from 'react';
import { bindActionCreators }                          from 'redux';
import {Router, Route, Link, Switch}                   from 'react-router-dom';
import {connect}                                       from 'react-redux';
import {history}                                       from './../_helpers';
import { alertActions,
         authenticateActions}                          from './../../_actions';
import {PrivateRoute, PrivateHeader}                   from './../_helpers';
import {PrivateSideNav, PrivateContent}                from './../_helpers';
import {Home}                                          from './home';
import {Data}                                          from './../../_menu/Data';
import {Parameter}                                     from './../../_menu/Parameter';
import {Transaksi}                                     from './../../_menu/Transaksi';
import {Administration}                                from './../_administration';
import {Setting}                                       from './../_settings';
import {Dashboard}                                     from './../_dashboard';
import {Report}                                        from './../_report';
import Tenant                                          from './../../tenant';
import TenantView                                      from './../../tenant/view';
import TenantEdit                                      from './../../tenant/edit';
import Lease                                           from './../../lease';
import LeaseView                                       from './../../lease/view';
import LeaseEdit                                       from './../../lease/edit';
import Lease_detail                                    from './../../lease_detail';
import Lease_detailView                                from './../../lease_detail/view';
import Lease_detailEdit                                from './../../lease_detail/edit';
import Rent                                            from './../../rent';
import RentView                                        from './../../rent/view';
import RentEdit                                        from './../../rent/edit';
import Payment                                         from './../../payment';
import PaymentView                                     from './../../payment/view';
import PaymentEdit                                     from './../../payment/edit';
import Complex                                         from './../../complex';
import ComplexView                                     from './../../complex/view';
import ComplexEdit                                     from './../../complex/edit';
import Service                                         from './../../service';
import ServiceView                                     from './../../service/view';
import ServiceEdit                                     from './../../service/edit';
import Employee                                        from './../../employee';
import EmployeeView                                    from './../../employee/view';
import EmployeeEdit                                    from './../../employee/edit';
import ReportViewEmployee000                           from './../_report/viewemployee000';
import ViewPDFReportEmployee000                        from './../_report/view_pdf_employee_000';
import ReportViewEmployee001                           from './../_report/viewemployee001';
import ViewPDFReportEmployee001                        from './../_report/view_pdf_employee_001';
import Building                                        from './../../building';
import BuildingView                                    from './../../building/view';
import BuildingEdit                                    from './../../building/edit';
import Apartment                                       from './../../apartment';
import ApartmentView                                   from './../../apartment/view';
import ApartmentEdit                                   from './../../apartment/edit';
import ReportViewApartment000                          from './../_report/viewapartment000';
import ViewPDFReportApartment000                       from './../_report/view_pdf_apartment_000';
import Company                                         from './../../company';
import CompanyView                                     from './../../company/view';
import CompanyEdit                                     from './../../company/edit';
import Users                                           from './../../users';
import UsersView                                       from './../../users/view';
import UsersEdit                                       from './../../users/edit';
import Userlevel                                       from './../../userlevel';
import UserlevelView                                   from './../../userlevel/view';
import UserlevelEdit                                   from './../../userlevel/edit';
import Login                                           from './../../_authenticate/login';
import Logout                                          from './../../_authenticate/logout';
import Registration                                    from './../../_authenticate/register';
import ForgotPassword                                  from './../../_authenticate/forgotpassword';
import {Icon, Button}                                  from 'semantic-ui-react';
import {Pricing}                                       from './pricing';
import {Solution}                                      from './solution';
import {About}                                         from './about';
import {MainPage}                                      from './main';
import * as APP                                        from './../_settings/config';
import styled                                          from 'styled-components';
import SideNav, { Toggle, Nav }                        from '@trendmicro/react-sidenav';
import { NavItem, NavIcon, NavText }                   from '@trendmicro/react-sidenav';
import NavBar                                          from './../_components/navbar';
import {
  Layout,
  Row,
  Col,
  Menu,
  message,
  Dropdown,
  Breadcrumb,
  Avatar}                                              from 'antd';

import 'antd/dist/antd.css';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    padding: 0px;
    margin-left: ${props => (props.expanded ? 240 : 64*props.show)}px;
    @media screen and (max-width: 768px) {
      margin-left: 0px;
    }
`;

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...alertActions,
      ...authenticateActions
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  const { alert, authenticate } = state;
  const { isLoggedIn, loggedUser } = authenticate.form;
  return {
      alert,
      loggedUser,
      isLoggedIn
  };
}

const { Header, Content, Footer, Sider } = Layout;

class App extends Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
        });
        this.state = {
          mode: 'inline',
          theme: 'light',
          collapsed: false,
          isLoggedIn: false,
          name: '',
          selected: 'home',
          expanded: false,
          showMenu: 0,
        };

    }


    componentWillReceiveProps (nextProps) {
      console.log('[app.js][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps));
      if(nextProps.loggedUser){
        this.setState({
          name: nextProps.form.loggedUser.fullname,
        });
      }

      this.setState({
        name: APP.getfullname()
      })

      this.setState({
        isLoggedIn: nextProps.isLoggedIn,
      });

      if(!APP.getIsLoggedIn()){
        this.setState({
          showMenu: 0,
        });
      } else {
        this.setState({
          showMenu: 1,
        });
      }
    }


    componentDidMount(){
      APP.LOG(APP.getfullname());
      this.setState({
        name: APP.getfullname()
      })

      this.setState({
        isLoggedIn: APP.getIsLoggedIn()
      })

      if(!APP.getIsLoggedIn()){
        this.setState({
          showMenu: 0,
        });
      } else {
        this.setState({
          showMenu: 1,
        });
      }

    }


    onSelect = (selected) => {
        this.setState({ selected: selected });
        const to = '/' + selected;
        history.push(to);
    };

    onToggle = (expanded) => {
        this.setState({ expanded: expanded });
    };

    onCollapse = (collapsed) => {
      this.setState({ collapsed });
    }

    changeTheme(value){
      this.setState({
        theme: value ? 'dark' : 'light',
      });
    }

    logOut() {
      this.props.actions.logout('Log Out');
    }
    handleClick(e){
      console.log('click ', e);
      this.setState({
        current: e.key,
      });
    }

    render() {
        const { alert } = this.props;
        const { isLoggedIn, name } = this.state;

        let info;
        if(alert.type == 'alert-success'){
          info = message.success(alert.message);
        }

        if(alert.type == 'alert-danger'){
          info = message.error(alert.message);
        }

        const menu = (
          <Menu>
            <Menu.Item key='0'>
              <a onClick={()=>{this.logOut()}}><Icon name='sign-out alternate' /> Logout</a>
            </Menu.Item>
          </Menu>
        );

        return (
          <Router history={history}>
            <div>
              <Layout style={{ minHeight: '100vh' }}>
                <Layout>
                  <PrivateSideNav onSelect={this.onSelect}  onToggle={this.onToggle}/>
                  <Main expanded={this.state.expanded} show={this.state.showMenu}>
                  <PrivateHeader menu={menu} name={name}/>
                  <PrivateContent>
                    <div>
                      {alert.message && info }

                        <div>
                          <Switch>
                            <PrivateRoute exact path='/' component={Home} />
                            <PrivateRoute exact path='/home' component={Home} />
                            <PrivateRoute exact path='/administration' component={Administration} />
                            <PrivateRoute exact path='/setting' component={Setting} />
                            <PrivateRoute exact path='/dashboard' component={Dashboard} />
                            <PrivateRoute exact path='/report' component={Report} />
                            <PrivateRoute exact path='/data' component={Data} />
                            <PrivateRoute exact path='/parameter' component={Parameter} />
                            <PrivateRoute exact path='/transaksi' component={Transaksi} />
                            <PrivateRoute exact path='/tenant' component={Tenant} />
                            <PrivateRoute exact path='/tenant/view/:tenant_id' component={TenantView} />
                            <PrivateRoute exact path='/tenant/edit/:tenant_id' component={TenantEdit} />
                            <PrivateRoute exact path='/tenant/add/' component={TenantEdit} />
                            <PrivateRoute exact path='/lease' component={Lease} />
                            <PrivateRoute exact path='/lease/view/:lease_id' component={LeaseView} />
                            <PrivateRoute exact path='/lease/edit/:lease_id' component={LeaseEdit} />
                            <PrivateRoute exact path='/lease/add/' component={LeaseEdit} />
                            <PrivateRoute exact path='/lease_detail' component={Lease_detail} />
                            <PrivateRoute exact path='/lease_detail/view/:lease_detail_id' component={Lease_detailView} />
                            <PrivateRoute exact path='/lease_detail/edit/:lease_detail_id' component={Lease_detailEdit} />
                            <PrivateRoute exact path='/lease_detail/add/' component={Lease_detailEdit} />
                            <PrivateRoute exact path='/rent' component={Rent} />
                            <PrivateRoute exact path='/rent/view/:rent_id' component={RentView} />
                            <PrivateRoute exact path='/rent/edit/:rent_id' component={RentEdit} />
                            <PrivateRoute exact path='/rent/add/' component={RentEdit} />
                            <PrivateRoute exact path='/payment' component={Payment} />
                            <PrivateRoute exact path='/payment/view/:payment_id' component={PaymentView} />
                            <PrivateRoute exact path='/payment/edit/:payment_id' component={PaymentEdit} />
                            <PrivateRoute exact path='/payment/add/' component={PaymentEdit} />
                            <PrivateRoute exact path='/complex' component={Complex} />
                            <PrivateRoute exact path='/complex/view/:complex_id' component={ComplexView} />
                            <PrivateRoute exact path='/complex/edit/:complex_id' component={ComplexEdit} />
                            <PrivateRoute exact path='/complex/add/' component={ComplexEdit} />
                            <PrivateRoute exact path='/service' component={Service} />
                            <PrivateRoute exact path='/service/view/:service_id' component={ServiceView} />
                            <PrivateRoute exact path='/service/edit/:service_id' component={ServiceEdit} />
                            <PrivateRoute exact path='/service/add/' component={ServiceEdit} />
                            <PrivateRoute exact path='/employee' component={Employee} />
                            <PrivateRoute exact path='/employee/view/:employee_id' component={EmployeeView} />
                            <PrivateRoute exact path='/employee/edit/:employee_id' component={EmployeeEdit} />
                            <PrivateRoute exact path='/employee/add/' component={EmployeeEdit} />
                            <PrivateRoute exact path='/reportviewemployee000' component={ReportViewEmployee000} />
                            <PrivateRoute exact path='/viewpdfreportemployee000' component={ViewPDFReportEmployee000} />
                            <PrivateRoute exact path='/reportviewemployee001' component={ReportViewEmployee001} />
                            <PrivateRoute exact path='/viewpdfreportemployee001' component={ViewPDFReportEmployee001} />
                            <PrivateRoute exact path='/building' component={Building} />
                            <PrivateRoute exact path='/building/view/:building_id' component={BuildingView} />
                            <PrivateRoute exact path='/building/edit/:building_id' component={BuildingEdit} />
                            <PrivateRoute exact path='/building/add/' component={BuildingEdit} />
                            <PrivateRoute exact path='/apartment' component={Apartment} />
                            <PrivateRoute exact path='/apartment/view/:apartment_id' component={ApartmentView} />
                            <PrivateRoute exact path='/apartment/edit/:apartment_id' component={ApartmentEdit} />
                            <PrivateRoute exact path='/apartment/add/' component={ApartmentEdit} />
                            <PrivateRoute exact path='/reportviewapartment000' component={ReportViewApartment000} />
                            <PrivateRoute exact path='/viewpdfreportapartment000' component={ViewPDFReportApartment000} />
                            <PrivateRoute exact path='/company' component={Company} />
                            <PrivateRoute exact path='/company/view/:company_id' component={CompanyView} />
                            <PrivateRoute exact path='/company/edit/:company_id' component={CompanyEdit} />
                            <PrivateRoute exact path='/company/add/' component={CompanyEdit} />
                            <PrivateRoute exact path='/users' component={Users} />
                            <PrivateRoute exact path='/users/view/:id_user' component={UsersView} />
                            <PrivateRoute exact path='/users/edit/:id_user' component={UsersEdit} />
                            <PrivateRoute exact path='/users/add/' component={UsersEdit} />
                            <PrivateRoute exact path='/userlevel' component={Userlevel} />
                            <PrivateRoute exact path='/userlevel/view/:id_userlevel' component={UserlevelView} />
                            <PrivateRoute exact path='/userlevel/edit/:id_userlevel' component={UserlevelEdit} />
                            <PrivateRoute exact path='/userlevel/add/' component={UserlevelEdit} />
                            <Route exact path='/' component={MainPage} /> 
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/logout' component={Logout} />
                            <Route path='/register' component={Registration} />
                            <Route path='/forgotpassword' component={ForgotPassword} />
                            <Route exact path='/pricing' component={Pricing} />
                            <Route exact path='/about' component={About} />
                        </Switch>
                      </div>
                    </div>
                  </PrivateContent>
                  <Footer style={{ textAlign: 'center' }}>
                    by <b>ameemo</b>
                  </Footer>
                  </Main>
                </Layout>
              </Layout>
              </div>
           </Router>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
