// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as leaseActions                               from '../_actions/lease.actions';
import * as tenantActions                              from './../_actions/tenant.actions';
import TenantEdit                                      from './../tenant/edit';
import * as lease_detailActions                        from './../_actions/lease_detail.actions';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     lease: {
       gpLeaseShowModal                           : state.lease.form.gpLeaseShowModal,
       gpLeaseModalParam                          : state.lease.form.gpLeaseModalParam,
       gpLeaseIsFetching                          : state.lease.form.gpLeaseIsFetching,
       gpDataFields                               : state.lease.form.gpDataFields,
       gpLeaseIsValid                             : state.lease.form.gpLeaseIsValid,
     },
     tenant: {
      gpDataFields                                : state.tenant.form.gpDataFields,
      gptenantIsFetching                          : state.tenant.form.gptenantIsFetching,
     },
     lease_detail: {
      gpLease_detailDataList                      : state.lease_detail.form.gpLease_detailDataList,
      gpLease_detailDataListNumRows               : state.lease_detail.form.gpLease_detailDataListNumRows,
      gpLease_detailIsFetching                    : state.lease_detail.form.gpLease_detailIsFetching,
     },
     tenant_incoming: {
       gpTenantLookupList                         : state.tenant.form.gpTenantLookupList,
       gpTenantError                              : state.tenant.form.gpTenantError,
       gpTenantIsFetching                         : state.tenant.form.gpTenantIsFetching,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...leaseActions,
      ...tenantActions,
      ...lease_detailActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
          tenant_id                               : this.props.tenant.gpDataFields.tenant_id,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
       this.props.actions._onLease_detailFormFieldChange('lease_id', this.props.match.params.lease_id);
       this.state = {
         lease_detail: [
           {
             key                                  : Date.now(),
             lease_detail_id                      :'',
             description                          :'',
             quantity                             :'',
             unit_price                           :'',
           }
         ]
       };
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        this._loadLease_detailDataSets();
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editLease(this.props.match.params.lease_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addLease());
      }

      this.props.actions._onLeaseFormFieldChange('tenant_id', this.props.tenant.gpDataFields.tenant_id);
      this.props.actions._onLease_detailFormFieldChange('lease_id', this.props.match.params.lease_id);
    }


    _loadLease_detailDataSets() {
      const data = {
        lease_detail_id                           : '',
        description                               : '',
        quantity                                  : '',
        unit_price                                : '',
        lease_id                                  : this.props.match.params.lease_id,
        page_size                                 : 100,
        current_page                              : 1,
        sort_field                                : 'lease_detail_id',
        sort_order                                : 'DESC',
      }
      this.setState({
        lsCurrentPage                             : 1,
      }, this.props.actions._listLease_detail(data));
    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.lease.gpLeaseModalParam) {
        if (nextProps.lease.gpLeaseModalParam !== this.props.lease.gpLeaseModalParam) {
          console.log('Information: gpLeaseModalParam props changed');
          if(nextProps.lease.gpLeaseModalParam['trxType']==='edt') {
            this.setState({
              lease_id      : nextProps.lease.gpLeaseModalParam['lease_id'],
              trxType          : nextProps.lease.gpLeaseModalParam['trxType'],
            }, this._editLease(nextProps.lease.gpLeaseModalParam['lease_id']));
          };

          if(nextProps.lease.gpLeaseModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.lease.gpLeaseModalParam['trxType'],
            }, this._addLease());
          };

          if(nextProps.lease.gpLeaseModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.lease.gpLeaseModalParam['trxType'],
            }, this._filterLease());
          };

        };
      };

      if(nextProps.lease.gpLeaseShowModal) {
        if (nextProps.lease.gpLeaseShowModal !== this.props.lease.gpLeaseShowModal) {
          console.log('Information: gpLeaseShowModal props changed');
          if(nextProps.lease.gpLeaseShowModal==='show'){
            this.setState({
              lsLeaseShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.lease.gpLeaseShowModal==='hide'){
            this.setState({
              lsLeaseShowModal: false,
            });
          }
        };
      };

      if (nextProps.lease_detail.gpLease_detailDataList){
        if (nextProps.lease_detail.gpLease_detailDataList !== this.props.lease_detail.gpLease_detailDataList) {
          this.setState({
            lslease_detailDataList                  : nextProps.lease_detail.gpLease_detailDataList,
          }, ()=>{
            let i=0;
            this.state.lslease_detailDataList.map((data) => {
              i=i+1;
              this.state.lease_detail.unshift({
                key                               : Date.now()+i,
                lease_detail_id                   : data.lease_detail_id,
                description                       : data.description,
                quantity                          : data.quantity,
                unit_price                        : data.unit_price,
              })
            });
          });
        };
      };


      this.setState({
        lease_id                                  : nextProps.lease.gpDataFields.lease_id,
        lease_description                         : nextProps.lease.gpDataFields.lease_description,
        start_date                                : nextProps.lease.gpDataFields.start_date,
        end_date                                  : nextProps.lease.gpDataFields.end_date,
        deposit                                   : nextProps.lease.gpDataFields.deposit,
        tenant_id                                 : nextProps.lease.gpDataFields.tenant_id,
        tenant_tenant_id                          : nextProps.lease.gpDataFields.tenant_tenant_id,
        date_add                                  : nextProps.lease.gpDataFields.date_add,
        date_upd                                  : nextProps.lease.gpDataFields.date_upd,
      });

}
_addTenant() {
  this.props.actions._activateAddTenant();
}

_addLeaseOnServer() {
    var id1 = this.state.lease_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(5);
    } else {
      id3 = this.state.lease_id;
    }
    const data = {
        lease_id                                  : id3,
        lease_description                         : this.state.lease_description,
        start_date                                : this.state.start_date,
        end_date                                  : this.state.end_date,
        deposit                                   : this.state.deposit,
        tenant_id                                 : this.state.tenant_id,
        tenant_tenant_id                          : this.state.tenant_tenant_id,
        lease_detail                              :this.state.lease_detail,
    }
    this.props.actions._addLease(data);
    this.goBack();
}

_editLeaseOnServer() {
    const data = {
        lease_id                                  : this.state.lease_id,
        lease_description                         : this.state.lease_description,
        start_date                                : this.state.start_date,
        end_date                                  : this.state.end_date,
        deposit                                   : this.state.deposit,
        tenant_id                                 : this.state.tenant_id,
        tenant_tenant_id                          : this.state.tenant_tenant_id,
        lease_detail                              :this.state.lease_detail,
    }
    this.props.actions._editLease(data);
    this.goBack();
}

_filterLeaseOnServer() {
    const data = {
        lease_id                                  : this.state.lease_id,
        lease_description                         : this.state.lease_description,
        start_date                                : this.state.start_date,
        end_date                                  : this.state.end_date,
        deposit                                   : this.state.deposit,
        tenant_id                                 : this.state.tenant_id,
        tenant_tenant_id                          : this.state.tenant_tenant_id,
        lease_detail                              :this.state.lease_detail,
    }
    this.props.actions._filterLease(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit lease                                                              */
/* -------------------------------------------------------------------------- */
_editLease(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewLease(id);
  this.props.actions._lookupTenant();
}

/* -------------------------------------------------------------------------- */
/*  add new lease                                     
/* -------------------------------------------------------------------------- */
_addLease() {
    let today = GF.today();
    this.props.actions._onLeaseFormFieldChange('lease_id', '')
    this.props.actions._onLeaseFormFieldChange('lease_description', '')
    this.props.actions._onLeaseFormFieldChange('start_date', '')
    this.props.actions._onLeaseFormFieldChange('end_date', '')
    this.props.actions._onLeaseFormFieldChange('deposit', '')
    this.props.actions._onLeaseFormFieldChange('start_date', today)
    this.props.actions._onLeaseFormFieldChange('end_date', today)
    this.props.actions._initLease();

    this.setState({
         lease_id                                 : '',
         lease_description                        : '',
         start_date                               : today,
         end_date                                 : today,
         deposit                                  : '',
        tenant_id                                 : this.state.tenant_id,
         isIDDisabled                             : false,
         lsLeaseDetail                            : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
  this.props.actions._lookupTenant();
}

/* -------------------------------------------------------------------------- */
/*  filter lease                                     
/* -------------------------------------------------------------------------- */
_filterLease() {

    let today = GF.today();
    this.setState({
         lease_id                                 : '',
         lease_description                        : '',
         start_date                               : today,
         end_date                                 : today,
         deposit                                  : '',
         lsLeaseDetail                            : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
  this.props.actions._lookupTenant();
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showLeaseModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addLeaseOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editLeaseOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterLeaseOnServer();
  }
}

  onChange = (inputData) => {
    this.setState((prevState) => {
      const new_lease_detail = prevState.lease_detail.map((element) => {
        if (element.key === inputData.key) return inputData;
        return element;
      });
      return { lease_detail: new_lease_detail };
    });
  };

  addElement = () => {
    const { 
      lease_detail_id,
      description,
      quantity,
      unit_price,
    } = this.state;

    this.setState((prevState) => ({
      lease_detail: prevState.lease_detail.concat({
        key: Date.now(),
        lease_detail_id,
        description,
        quantity,
        unit_price,
      })
    }));
    console.log('VAL:' + JSON.stringify(this.state));
  };

  removeElement = (id) => {
    this.setState((prevState) => ({
      lease_detail: prevState.lease_detail.filter((data) => data.key !== id)
    }));
  };

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showLeaseModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[lease.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onLeaseFormFieldChange(field, value)
}

onStart_dateChange = (value) => {
  const lvValue   = moment(value, dateFormat).format(dateFormat);
  const lvField   = 'start_date';
     if (lvValue !== '') {
       this.props.actions._onLeaseFormFieldChange(lvField, lvValue)
     }
}

onEnd_dateChange = (value) => {
  const lvValue   = moment(value, dateFormat).format(dateFormat);
  const lvField   = 'end_date';
     if (lvValue !== '') {
       this.props.actions._onLeaseFormFieldChange(lvField, lvValue)
     }
}

onTenant_idChange = (e, data) => {
  const lvValue   = data.value;
  const lvField   = 'tenant_id';
     if (lvValue !== '') {
       this.props.actions._onLeaseFormFieldChange(lvField, lvValue)
     }
}


  handleChange_ = (data, ev) => {
    const { name, value } = ev.target;
    this.onChange({
      ...data,
      [name]: value
    });
  };

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsLeaseShowModal,
    } = this.state;

     const { lease_detail } = this.state;

     const {
       lease_id,
       lease_description,
       start_date,
       end_date,
       deposit,
       tenant_tenant_id,
       tenant_id,
       tenant_id_has_error,
       tenant_id_error_msg,
       date_add,
       date_upd,
       lease_id_has_error,
       lease_description_has_error,
       start_date_has_error,
       end_date_has_error,
       deposit_has_error,
       date_add_has_error,
       date_upd_has_error,
       lease_id_error_msg,
       lease_description_error_msg,
       start_date_error_msg,
       end_date_error_msg,
       deposit_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.lease.gpDataFields;


const listTenantOptions = _.map(this.props.tenant_incoming.gpTenantLookupList, (Tenant, index) => ({
  key: Tenant.value,
  text: Tenant.value  + ' - ' + Tenant.label,
  value: Tenant.value,
}))

const DropdownTenant_Tenant_Id = () => (
  <Dropdown onChange={this.onTenant_idChange} placeholder='Tenant' value={tenant_id} search options={listTenantOptions} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'/>
)

const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Lease'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Sewa
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={6}>
          <b>ID Rumah Sewa</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='lease_id'
              placeholder='[AUTO]'
              value={lease_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {lease_id_has_error?<div style={{color:'lightcoral'}}>{lease_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Keterangan</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='lease_description'
              placeholder='Masukan Keterangan'
              value={lease_description}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {lease_description_has_error?<div style={{color:'lightcoral'}}>{lease_description_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Tanggal Mulai</b><br />
          <div className='spacer' />
          <DatePicker
              placeholder='Pilih Tanggal'
              disabled={false}
              onChange={this.onStart_dateChange.bind(this)}
              value={moment(start_date,dateFormat)}
              format={dateFormat}
          />

          {start_date_has_error?<div style={{color:'lightcoral'}}>{start_date_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Tanggal Selesai</b><br />
          <div className='spacer' />
          <DatePicker
              placeholder='Pilih Tanggal'
              disabled={false}
              onChange={this.onEnd_dateChange.bind(this)}
              value={moment(end_date,dateFormat)}
              format={dateFormat}
          />

          {end_date_has_error?<div style={{color:'lightcoral'}}>{end_date_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Uang Deposit</b><br />
          <div className='spacer' />
          <NumericFormat
            value={deposit}
            customInput={Input}
            name = 'deposit'
            disabled={false}
            prefix='Rp '
            placeholder='Masukan Uang Deposit'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onLeaseFormFieldChange('deposit', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {deposit_has_error?<div style={{color:'lightcoral'}}>{deposit_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
         <Col lg={6} md={4}>
           <b>ID Penyewa</b><br />
           <div className='spacer' />
           <DropdownTenant_Tenant_Id />
           &nbsp;
           <Link className='text-blue-900' to='/tenant/add/'>
             <button type='button' className='inline-flex h-10 justify-center rounded-md border text-center border-transparent bg-blue-100 px-2 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
               <Icon name='plus' />
             </button>
           </Link>
           <div className='spacer' />
           <br />
         </Col>
        </Row>
        <div className='spacer' />
        <Row gutter={16}>
          </Row>
          <div className='spacer' />
            <div className='col-md-12 form-group'>
              <Row gutter={16}>
                <Col>
                  <Row gutter={16} className='rounded-md border-t-4 border-blue-300 bg-blue-200 p-2 font-bold'>
                    <Col lg={5} md={4}>ID Detail</Col>
                    <Col lg={5} md={4}>Keterangan</Col>
                    <Col lg={5} md={4}>Jumlah</Col>
                    <Col lg={5} md={4}>Harga</Col>
                    <Col span={2}>Actions</Col>
                  </Row>
                  {lease_detail.map((data) => (
                  <React.Fragment key={data.key}>
                  <div className='spacer' />
                    <Row gutter={16}>
                      <Col span={5}>
                        <Input
                          className='form-control'
                          name='lease_detail_id'
                          value={data.lease_detail_id}
                          onChange={e=>this.handleChange_(data, e)}
                          placeholder='ID Detail'
                          type='text'
                        />
                      </Col>
                      <Col span={5}>
                        <Input
                          className='form-control'
                          name='description'
                          value={data.description}
                          onChange={e=>this.handleChange_(data, e)}
                          placeholder='Keterangan'
                          type='text'
                        />
                      </Col>
                      <Col span={5}>
                        <Input
                          className='form-control'
                          name='quantity'
                          value={data.quantity}
                          onChange={e=>this.handleChange_(data, e)}
                          placeholder='Jumlah'
                          type='text'
                        />
                      </Col>
                      <Col span={5}>
                        <Input
                          className='form-control'
                          name='unit_price'
                          value={data.unit_price}
                          onChange={e=>this.handleChange_(data, e)}
                          placeholder='Harga'
                          type='text'
                        />
                      </Col>
                        <button
                          type='button'
                          className='align-middle border border-transparent bg-red-200 pl-1 text-base font-medium text-red-900 hover:bg-red-200'
                          onClick={() => this.removeElement(data.key)}
                          disabled={lease_detail.length <= 1}
                        ><Icon name='minus' className='text-red-700' /></button>
                   </Row>
                   <div className='spacer' />
                 </React.Fragment>
               ))}
             </Col>
           </Row>
         </div>
         <br />
         <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white hover:bg-blue-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2' type='button' onClick={this.addElement}>
           <Icon name='plus' />Tambah Produk
         </button>
         <br />
         <br />
        <br />
        <Row><Col span={20}></Col><Col span={4}>
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.lease.gpLeaseIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
        </Col></Row>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add lease End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
