/* -------------------------------------------------------------------------- */
/* USERLEVEL.COUNT                                                            */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* Import definition start                                                    */
/* -------------------------------------------------------------------------- */
import React, { Component }                           from 'react';
import { bindActionCreators }                         from 'redux';
import { connect }                                    from 'react-redux';
import {
  userlevelActions,
}                                                      from '../_actions';
import {
  Table,
}                                                     from 'antd';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {

  return {
    actions: bindActionCreators({
      ...userlevelActions,
      }
      , dispatch)
  }

}

function mapStateToProps(state) {
  return {
    process: {
      userlevel_count                              : state.userlevel.form.userlevel_count,
    }
  }
}

class Count extends Component {

  constructor(props){
      super(props);
  }

  state = {
    userlevel_count                          : 0,
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      userlevel_count                  : nextProps.process.userlevel_count,
    });
  }
  componentDidMount(){
    this.props.actions.action_to_count_userlevel();
    this.setState({
      userlevel_count                  : this.props.process.userlevel_count,
    });
  }

  render() {
    return (
      <div>
        <div style={{fontSize:'20px', border: '#cccccc 1px solid', textAlign:'center', width:'50px', cellPadding:'100px',cellSpacing:'100px', fontWeight:'bold'}} >
          <div><small><small>Count</small></small></div>
          {this.state.userlevel_count}
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Count);
