'use strict'

import {authenticateConstants} from './../_constants'
import {Record} from 'immutable'

const Form = Record({
  state: authenticateConstants.LOGIN,
  disabled: false,
  error: null,
  isValid: false,
  isFetching: false,
  isLoggedIn: false,
  fields: new (Record({
     fullname                        : '',
     fullname_has_error              : false,
     fullname_error_msg              : '',
     email                           : '',
     email_has_error                 : false,
     email_error_msg                 : '',
     phone                           : '',
     phone_has_error                 : false,
     phone_error_msg                 : '',
     password                        : '',
     password_has_error              : false,
     password_error_msg              : '',
     password_confirm                : '',
     password_confirm_has_error      : false,
     password_confirm_error_msg      : '',
     showPassword                    : false,
     icon                            : 'eye-off-outline',
  }))()
})

var InitialState = Record({
  form: new Form()
})
export default InitialState
