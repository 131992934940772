'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.payment_id_has_error &&
        state.form.gpDataFields.payment_date !== '' &&
        !state.form.gpDataFields.payment_date_has_error &&
        state.form.gpDataFields.Payment_amount !== '' &&
        !state.form.gpDataFields.Payment_amount_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpPaymentIsValid'], true)
  } else {
    return state.setIn(['form', 'gpPaymentIsValid'], false)
  }
}
