'use strict'
import {lease_detailConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/lease_detail.initialstate'
import fieldValidation                              from './../_validation/lease_detail.field.validation'
import formValidation                               from './../_validation/lease_detail.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case lease_detailConstants.LEASE_DETAIL_GET_LIST_REQUEST:
    case lease_detailConstants.LEASE_DETAIL_LOOKUP_REQUEST:
    case lease_detailConstants.LEASE_DETAIL_ADD_REQUEST:
    case lease_detailConstants.LEASE_DETAIL_CHANGE_REQUEST:
    case lease_detailConstants.LEASE_DETAIL_FILTER_REQUEST:
    case lease_detailConstants.LEASE_DETAIL_VIEW_REQUEST:
    case lease_detailConstants.LEASE_DETAIL_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpLease_detailIsFetching'],  true)

    case lease_detailConstants.LEASE_DETAIL_CHANGE_SUCCESS:
    case lease_detailConstants.LEASE_DETAIL_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpLease_detailIsFetching'],  false)

    case lease_detailConstants.LEASE_DETAIL_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpLease_detailIsFetching'],  false)
          .setIn(['form', 'lease_detail_count'],  action.payload)

    case lease_detailConstants.LEASE_DETAIL_FILTER_SUCCESS:
    case lease_detailConstants.LEASE_DETAIL_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpLease_detailDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpLease_detailDataList'], data.rows)
          .setIn(['form', 'gpLease_detailDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpLease_detailIsFetching'],  false)


  case lease_detailConstants.LEASE_DETAIL_LOOKUP_SUCCESS:
    console.log('[lease_detail.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpLease_detailLookupList'], action.payload)
        .setIn(['form', 'gpLease_detailIsFetching'], false)


    case lease_detailConstants.LEASE_DETAIL_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'lease_detail_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'description'],'')
          .setIn(['form', 'gpDataFields',                                                           'quantity'],'')
          .setIn(['form', 'gpDataFields',                                                           'unit_price'],'')

    case lease_detailConstants.LEASE_DETAIL_SHOW_MODAL:
      return state
        .setIn(['form', 'gpLease_detailShowModal'],  action.payload)


    case lease_detailConstants.LEASE_DETAIL_SEND_PARAM:
      return state
        .setIn(['form', 'gpLease_detailModalParam'],  action.payload[0])


    case lease_detailConstants.LEASE_DETAIL_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'lease_detail_id'],                                   action.payload[0].lease_detail_id)
          .setIn(['form', 'gpOriginalFields', 'lease_detail_id'],                                   action.payload[0].lease_detail_id)
          .setIn(['form', 'gpDataFields',     'description'],                                       action.payload[0].description)
          .setIn(['form', 'gpOriginalFields', 'description'],                                       action.payload[0].description)
          .setIn(['form', 'gpDataFields',     'quantity'],                                          action.payload[0].quantity)
          .setIn(['form', 'gpOriginalFields', 'quantity'],                                          action.payload[0].quantity)
          .setIn(['form', 'gpDataFields',     'unit_price'],                                        action.payload[0].unit_price)
          .setIn(['form', 'gpOriginalFields', 'unit_price'],                                        action.payload[0].unit_price)
          .setIn(['form', 'gpLease_detailIsFetching'],                                              false)
          return nextRecordState


    case lease_detailConstants.LEASE_DETAIL_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'lease_detail_id'],                                       '')
          .setIn(['form', 'gpDataFields', 'description'],                                           '')
          .setIn(['form', 'gpDataFields', 'quantity'],                                              '')
          .setIn(['form', 'gpDataFields', 'unit_price'],                                            '')
          .setIn(['form', 'gpDataFields', 'lease_detail_id_has_error'],                             false)
          .setIn(['form', 'gpDataFields', 'description_has_error'],                                 false)
          .setIn(['form', 'gpDataFields', 'quantity_has_error'],                                    false)
          .setIn(['form', 'gpDataFields', 'unit_price_has_error'],                                  false)

          .setIn(['form', 'gpLease_detailIsValid'],                                                 false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('lease_detailReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'lease_detail_id'],                                       '')
          .setIn(['form', 'gpDataFields', 'description'],                                           '')
          .setIn(['form', 'gpDataFields', 'quantity'],                                              '')
          .setIn(['form', 'gpDataFields', 'unit_price'],                                            '')
          .setIn(['form', 'gpLease_detailError'],                                                   null)
          return formValidation(nextRecordState, action)


    case lease_detailConstants.LEASE_DETAIL_GET_LIST_FAILURE:
    case lease_detailConstants.LEASE_DETAIL_LOOKUP_FAILURE:
    case lease_detailConstants.LEASE_DETAIL_ADD_FAILURE:
    case lease_detailConstants.LEASE_DETAIL_CHANGE_FAILURE:
    case lease_detailConstants.LEASE_DETAIL_FILTER_FAILURE:
    case lease_detailConstants.LEASE_DETAIL_VIEW_FAILURE:
    case lease_detailConstants.LEASE_DETAIL_COUNT_FAILURE:
      console.log('lease_detail.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpLease_detailIsFetching'], false)
          .setIn(['form', 'gpLease_detailError'], action.payload)


   case lease_detailConstants.ON_LEASE_DETAIL_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case lease_detailConstants.ON_LEASE_DETAIL_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case lease_detailConstants.CHANGE_LEASE_DETAIL_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpLease_detailError'], null)
      return nextState}

    case lease_detailConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'lease_detail_id'],                                       record.lease_detail_id)
          .setIn(['form', 'gpOriginalFields', 'lease_detail_id'],                                   record.lease_detail_id)
          .setIn(['form', 'gpDataFields', 'description'],                                           record.description)
          .setIn(['form', 'gpOriginalFields', 'description'],                                       record.description)
          .setIn(['form', 'gpDataFields', 'quantity'],                                              record.quantity)
          .setIn(['form', 'gpOriginalFields', 'quantity'],                                          record.quantity)
          .setIn(['form', 'gpDataFields', 'unit_price'],                                            record.unit_price)
          .setIn(['form', 'gpOriginalFields', 'unit_price'],                                        record.unit_price)

          .setIn(['form', 'gpDataFields', 'lease_detail_id_has_error'],                             record.lease_detail_id_has_error)
          .setIn(['form', 'gpDataFields', 'description_has_error'],                                 record.description_has_error)
          .setIn(['form', 'gpDataFields', 'quantity_has_error'],                                    record.quantity_has_error)
          .setIn(['form', 'gpDataFields', 'unit_price_has_error'],                                  record.unit_price_has_error)



          .setIn(['form', 'gpLease_detailDataList'],                                                record.gpLease_detailDataList)
          .setIn(['form', 'gpLease_detailIsDisabled'],                                              record.gpLease_detailIsDisabled)
          .setIn(['form', 'gpLease_detailError'],                                                   record.gpLease_detailError)
          .setIn(['form', 'gpLease_detailIsValid'],                                                 record.gpLease_detailIsValid)
          .setIn(['form', 'gpLease_detailIsFetching'],                                              record.gpLease_detailIsFetching)
          .setIn(['form', 'lease_detail_count'],                                                    record.lease_detail_count)
      return next
case lease_detailConstants.LEASE_DETAIL_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpLease_detailLookupShowModal'],  action.payload)


  }
  return state
}
