'use strict'
import {tenantConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/tenant.initialstate'
import fieldValidation                              from './../_validation/tenant.field.validation'
import formValidation                               from './../_validation/tenant.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case tenantConstants.TENANT_GET_LIST_REQUEST:
    case tenantConstants.TENANT_LOOKUP_REQUEST:
    case tenantConstants.TENANT_ADD_REQUEST:
    case tenantConstants.TENANT_CHANGE_REQUEST:
    case tenantConstants.TENANT_FILTER_REQUEST:
    case tenantConstants.TENANT_VIEW_REQUEST:
    case tenantConstants.TENANT_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpTenantIsFetching'],  true)

    case tenantConstants.TENANT_CHANGE_SUCCESS:
    case tenantConstants.TENANT_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpTenantIsFetching'],  false)

    case tenantConstants.TENANT_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpTenantIsFetching'],  false)
          .setIn(['form', 'tenant_count'],  action.payload)

    case tenantConstants.TENANT_FILTER_SUCCESS:
    case tenantConstants.TENANT_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpTenantDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpTenantDataList'], data.rows)
          .setIn(['form', 'gpTenantDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpTenantIsFetching'],  false)


  case tenantConstants.TENANT_LOOKUP_SUCCESS:
    console.log('[tenant.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpTenantLookupList'], action.payload)
        .setIn(['form', 'gpTenantIsFetching'], false)


    case tenantConstants.TENANT_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'tenant_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'tenant_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'email'],'')
          .setIn(['form', 'gpDataFields',                                                           'phone'],'')
          .setIn(['form', 'gpDataFields',                                                           'ktp'],'')

    case tenantConstants.TENANT_SHOW_MODAL:
      return state
        .setIn(['form', 'gpTenantShowModal'],  action.payload)


    case tenantConstants.TENANT_SEND_PARAM:
      return state
        .setIn(['form', 'gpTenantModalParam'],  action.payload[0])


    case tenantConstants.TENANT_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'tenant_id'],                                         action.payload[0].tenant_id)
          .setIn(['form', 'gpOriginalFields', 'tenant_id'],                                         action.payload[0].tenant_id)
          .setIn(['form', 'gpDataFields',     'tenant_name'],                                       action.payload[0].tenant_name)
          .setIn(['form', 'gpOriginalFields', 'tenant_name'],                                       action.payload[0].tenant_name)
          .setIn(['form', 'gpDataFields',     'email'],                                             action.payload[0].email)
          .setIn(['form', 'gpOriginalFields', 'email'],                                             action.payload[0].email)
          .setIn(['form', 'gpDataFields',     'phone'],                                             action.payload[0].phone)
          .setIn(['form', 'gpOriginalFields', 'phone'],                                             action.payload[0].phone)
          .setIn(['form', 'gpDataFields',     'ktp'],                                               action.payload[0].ktp)
          .setIn(['form', 'gpOriginalFields', 'ktp'],                                               action.payload[0].ktp)
          .setIn(['form', 'gpTenantIsFetching'],                                                    false)
          return nextRecordState


    case tenantConstants.TENANT_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'tenant_id'],                                             '')
          .setIn(['form', 'gpDataFields', 'tenant_name'],                                           '')
          .setIn(['form', 'gpDataFields', 'email'],                                                 '')
          .setIn(['form', 'gpDataFields', 'phone'],                                                 '')
          .setIn(['form', 'gpDataFields', 'ktp'],                                                   '')
          .setIn(['form', 'gpDataFields', 'tenant_id_has_error'],                                   false)
          .setIn(['form', 'gpDataFields', 'tenant_name_has_error'],                                 false)
          .setIn(['form', 'gpDataFields', 'email_has_error'],                                       false)
          .setIn(['form', 'gpDataFields', 'phone_has_error'],                                       false)
          .setIn(['form', 'gpDataFields', 'ktp_has_error'],                                         false)

          .setIn(['form', 'gpTenantIsValid'],                                                       false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('tenantReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'tenant_id'],                                             '')
          .setIn(['form', 'gpDataFields', 'tenant_name'],                                           '')
          .setIn(['form', 'gpDataFields', 'email'],                                                 '')
          .setIn(['form', 'gpDataFields', 'phone'],                                                 '')
          .setIn(['form', 'gpDataFields', 'ktp'],                                                   '')
          .setIn(['form', 'gpTenantError'],                                                         null)
          return formValidation(nextRecordState, action)


    case tenantConstants.TENANT_GET_LIST_FAILURE:
    case tenantConstants.TENANT_LOOKUP_FAILURE:
    case tenantConstants.TENANT_ADD_FAILURE:
    case tenantConstants.TENANT_CHANGE_FAILURE:
    case tenantConstants.TENANT_FILTER_FAILURE:
    case tenantConstants.TENANT_VIEW_FAILURE:
    case tenantConstants.TENANT_COUNT_FAILURE:
      console.log('tenant.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpTenantIsFetching'], false)
          .setIn(['form', 'gpTenantError'], action.payload)


   case tenantConstants.ON_TENANT_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case tenantConstants.ON_TENANT_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case tenantConstants.CHANGE_TENANT_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpTenantError'], null)
      return nextState}

    case tenantConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'tenant_id'],                                             record.tenant_id)
          .setIn(['form', 'gpOriginalFields', 'tenant_id'],                                         record.tenant_id)
          .setIn(['form', 'gpDataFields', 'tenant_name'],                                           record.tenant_name)
          .setIn(['form', 'gpOriginalFields', 'tenant_name'],                                       record.tenant_name)
          .setIn(['form', 'gpDataFields', 'email'],                                                 record.email)
          .setIn(['form', 'gpOriginalFields', 'email'],                                             record.email)
          .setIn(['form', 'gpDataFields', 'phone'],                                                 record.phone)
          .setIn(['form', 'gpOriginalFields', 'phone'],                                             record.phone)
          .setIn(['form', 'gpDataFields', 'ktp'],                                                   record.ktp)
          .setIn(['form', 'gpOriginalFields', 'ktp'],                                               record.ktp)

          .setIn(['form', 'gpDataFields', 'tenant_id_has_error'],                                   record.tenant_id_has_error)
          .setIn(['form', 'gpDataFields', 'tenant_name_has_error'],                                 record.tenant_name_has_error)
          .setIn(['form', 'gpDataFields', 'email_has_error'],                                       record.email_has_error)
          .setIn(['form', 'gpDataFields', 'phone_has_error'],                                       record.phone_has_error)
          .setIn(['form', 'gpDataFields', 'ktp_has_error'],                                         record.ktp_has_error)



          .setIn(['form', 'gpTenantDataList'],                                                      record.gpTenantDataList)
          .setIn(['form', 'gpTenantIsDisabled'],                                                    record.gpTenantIsDisabled)
          .setIn(['form', 'gpTenantError'],                                                         record.gpTenantError)
          .setIn(['form', 'gpTenantIsValid'],                                                       record.gpTenantIsValid)
          .setIn(['form', 'gpTenantIsFetching'],                                                    record.gpTenantIsFetching)
          .setIn(['form', 'tenant_count'],                                                          record.tenant_count)
      return next
case tenantConstants.TENANT_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpTenantLookupShowModal'],  action.payload)


  }
  return state
}
