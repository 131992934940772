export const authenticateConstants = {
  SESSION_TOKEN_REQUEST: 'SESSION_TOKEN_REQUEST',
  SESSION_TOKEN_SUCCESS: 'SESSION_TOKEN_SUCCESS',
  SESSION_TOKEN_FAILURE: 'SESSION_TOKEN_FAILURE',

  DELETE_TOKEN_REQUEST: 'DELETE_TOKEN_REQUEST',
  DELETE_TOKEN_SUCCESS: 'DELETE_TOKEN_SUCCESS',
  DELETE_TOKEN_FAILURE: 'DELETE_TOKEN_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  ACTIVATE_USER_REQUEST: 'ACTIVATE_USER_REQUEST',
  ACTIVATE_USER_SUCCESS: 'ACTIVATE_USER_SUCCESS',
  ACTIVATE_USER_FAILURE: 'ACTIVATE_USER_FAILURE',

  LOGOUT: 'LOGOUT',
  REGISTER: 'REGISTER',
  LOGIN: 'LOGIN',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  ON_AUTH_FORM_FIELD_CHANGE: 'ON_AUTH_FORM_FIELD_CHANGE',

  CHANGE_FIELD: 'CHANGE_FIELD',
};
