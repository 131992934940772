import React                                          from 'react';
import { Route, Redirect }                            from 'react-router-dom';
import NavBar                                          from './../_components/navbar';
import {
  Layout,
  Row,
  Col,
  Menu,
  message,
  Dropdown,
  Breadcrumb,
  Avatar}                                              from 'antd';
  import {Icon, Button}                                  from 'semantic-ui-react';
  import SideNav, { Toggle, Nav }                        from '@trendmicro/react-sidenav';
  import { NavItem, NavIcon, NavText }                   from '@trendmicro/react-sidenav';

const { Header, Content, Footer, Sider } = Layout;
export const PrivateSideNav = ({onSelect, onToggle }) => (
        localStorage.getItem('user')
            ?<div>
                  <SideNav onSelect={onSelect} className='hidden md:block' onToggle={onToggle} style={{position:'fixed',overflowY: 'visible'}}>
                    <SideNav.Toggle />
                    <SideNav.Nav defaultSelected='home'>
                      <NavItem eventKey='home'>
                        <NavIcon>
                          <i className='fa fa-fw fa-home' style={{ fontSize: '1.7em' }} />
                        </NavIcon>
                        <NavText>
                            Home
                        </NavText>
                      </NavItem>
                <NavItem eventKey='home#section_building'>
                    <NavIcon>
                      <i className='fa fa-fw fa-hospital-o' style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText title='BUILDING'>
                        Lokasi Bangunan
                    </NavText>
                <NavItem eventKey='complex'>
                    <NavText title='complex' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Lokasi
                    </NavText>
                </NavItem>
                <NavItem eventKey='building'>
                    <NavText title='building' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Gedung
                    </NavText>
                </NavItem>
                <NavItem eventKey='apartment'>
                    <NavText title='apartment' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Ruang Sewa
                    </NavText>
                </NavItem>
              </NavItem>
                <NavItem eventKey='home#section_employee'>
                    <NavIcon>
                      <i className='fa fa-fw fa-users' style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText title='EMPLOYEE'>
                        Pekerja
                    </NavText>
                <NavItem eventKey='employee'>
                    <NavText title='employee' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Petugas
                    </NavText>
                </NavItem>
              </NavItem>
                <NavItem eventKey='home#section_payment'>
                    <NavIcon>
                      <i className='fa fa-fw fa-credit-card' style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText title='PAYMENT'>
                        Pembayaran
                    </NavText>
                <NavItem eventKey='payment'>
                    <NavText title='payment' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Payment
                    </NavText>
                </NavItem>
              </NavItem>
                <NavItem eventKey='home#section_rent'>
                    <NavIcon>
                      <i className='fa fa-fw fa-handshake-o' style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText title='RENT'>
                        Penyewaan
                    </NavText>
                <NavItem eventKey='tenant'>
                    <NavText title='tenant' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Penyewa
                    </NavText>
                </NavItem>
                <NavItem eventKey='lease'>
                    <NavText title='lease' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Sewa
                    </NavText>
                </NavItem>
                <NavItem eventKey='lease_detail'>
                    <NavText title='lease_detail' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Sewa Detail
                    </NavText>
                </NavItem>
                <NavItem eventKey='rent'>
                    <NavText title='rent' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Invoice Sewa
                    </NavText>
                </NavItem>
              </NavItem>
                <NavItem eventKey='home#section_service'>
                    <NavIcon>
                      <i className='fa fa-fw fa-wrench' style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText title='SERVICE'>
                        Perbaikan
                    </NavText>
                <NavItem eventKey='service'>
                    <NavText title='service' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Jasa Perbaikan
                    </NavText>
                </NavItem>
              </NavItem>
                <NavItem eventKey='home#section_setting'>
                    <NavIcon>
                      <i className='fa fa-fw fa-gears' style={{ fontSize: '1.5em' }} />
                    </NavIcon>
                    <NavText title='SETTING'>
                        Pengaturan
                    </NavText>
                <NavItem eventKey='company'>
                    <NavText title='company' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Perusahaan
                    </NavText>
                </NavItem>
                <NavItem eventKey='users'>
                    <NavText title='users' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Pengguna
                    </NavText>
                </NavItem>
                <NavItem eventKey='userlevel'>
                    <NavText title='userlevel' style={{ marginLeft: '10px' }}>
                      <i className='fa fa-fw fa-play' style={{ fontSize: '1em' }} /> Tingkat Pengguna
                    </NavText>
                </NavItem>
                      </NavItem>
                      <NavItem eventKey='dashboard'>
                        <NavIcon>
                          <i className='fa fa-fw fa-pie-chart' style={{ fontSize: '1.5em' }} />
                        </NavIcon>
                        <NavText>
                            Dashboard
                        </NavText>
                      </NavItem>
                      <NavItem eventKey='report'>
                        <NavIcon>
                          <i className='fa fa-fw fa-file-text-o' style={{ fontSize: '1.5em' }} />
                        </NavIcon>
                        <NavText>
                          Laporan
                        </NavText>
                      </NavItem>
                      <NavItem eventKey='logout'>
                        <NavIcon>
                          <i className='fa fa-fw fa-power-off' style={{ fontSize: '1.5em' }} />
                        </NavIcon>
                        <NavText>
                          Logout
                        </NavText>
                      </NavItem>
                    </SideNav.Nav>
                  </SideNav></div>
            : ''
    )

