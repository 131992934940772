'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.building_id_has_error &&
        state.form.gpDataFields.building_name !== '' &&
        !state.form.gpDataFields.building_name_has_error &&
        state.form.gpDataFields.total_apartement !== '' &&
        !state.form.gpDataFields.total_apartement_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpBuildingIsValid'], true)
  } else {
    return state.setIn(['form', 'gpBuildingIsValid'], false)
  }
}
