import React, { Component }                            from 'react';                                                                      
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import { PDFViewer }                                   from '@react-pdf/renderer';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink }                                    from '@react-pdf/renderer';
import {
  employeeActions,
}                                                      from './../../_actions';

import {
  Row,
  Card,
  Col,
  Input,
  Button,
  Table,
  Popconfirm,
  Spin,
  Icon,
  Breadcrumb
}                                                      from 'antd';

import {
       FaHome,
}                                                      from 'react-icons/fa';

import ReactToPrint                                    from 'react-to-print';



function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...employeeActions,
      }
      , dispatch)
  }
}


function mapStateToProps(state) {
    return {
      employee: {
          gpEmployeeDataList        : state.employee.form.gpEmployeeDataList,
          gpEmployeeDataListNumRows : state.employee.form.gpEmployeeDataListNumRows,
          gpEmployeeIsFetching      : state.employee.form.gpEmployeeIsFetching,
          gpEmployeeShowModal       : state.employee.form.ggpEmployeeShowModal,
        },
      global: {
        currentUser : state.global.currentUser
      }
    };
}


    const styles = StyleSheet.create({                  
      page: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        backgroundColor: '#f8f9fb',
        color: '#79787c',
      },
      section: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 124,
      },
      head_info: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
        alignItems: 'flex-end',
        fontSize: 7,
        textAlign: 'left',
      },
      logo: {
        width: 100,
      },
      body: {
        width: '100%',
        marginTop: 32,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
      },
      info_body: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      },
      linebreak: {
        width: '100%',
        height: 1,
        backgroundColor: '#e7eaf0',
        marginTop: 6,
        marginBottom: 6,
        borderRadius: 999,
      },
      body_left: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      body_right: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      info_left: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      info_right: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      body_wrapper: {
        display: 'flex',
        flexDirection: 'column',
      },
      items_body: {
        width: '100%',
        marginTop: 32,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        borderRadius: 8,
        padding: 12,
      },
      tab_title: {
        color: '#36424d',
        fontWeight: 'bold',
        fontSize: 10,
      },
      info_title: {
        color: '#36424d',
        fontWeight: 'bold',
        fontSize: 9,
      },
      category_title: {
        color: '#36424d',
        fontWeight: 700,
        fontSize: 9,
      },
      item_wrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        marginBottom: 3,
      },
      item_title: {
        fontSize: 6,
        fontWeight: 600,
        marginRight: 6,
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      item_item: {
        fontSize: 7.5,
        fontWeight: 500,
        color: '#36424d',
        paddingTop: 2,
        marginBottom: 2,
      },
      item_title_list: {
        fontSize: 6,
        fontWeight: 500,
        marginRight: 6,
        textTransform: 'uppercase',
      },
      item_item_list: {
        fontSize: 7,
        fontWeight: 500,
        color: '#36424d',
      },
      box_wrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 6,
      },
      single_box: {
        width: '50%',
      },
      full_item: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: -8,
      },
      six: {
        width: '5%',
        height: 'auto',
      },
      more_six: {
        height: 'auto',
        width: '80%',
      },
      six_title: {
        width: '5%',
        height: 'auto',
        fontSize: 6.5,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      more_six_title: {
        height: 'auto',
        width: '80%',
        fontSize: 6.5,
        fontWeight: 600,
        textTransform: 'uppercase',
      },
      viewer: {
        width: '100%',
        height: window.innerHeight,
        showToolbar: false,
      },
    });


const MyDocument = () => (
  <Document>
    <Page size='A4' style={styles.page}>
      <View style={styles.section}>
        <Text>
         Logo here
        </Text>

        <View style={styles.head_info}>
          <Text style={styles.info_title}>Stackoverflow Demo, Inc.</Text>
          <Text>Worldwide, Web3</Text>
          <Text>fake@stackoverflow.com</Text>
          <Text>www.stackoverflow.com</Text>
        </View>
      </View>

      <View style={styles.body}>
        <View
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Text style={styles.category_title}>Rechung Nr: #4422</Text>
          <Text style={styles.category_title}>20.01.2022</Text>
        </View>
        <View style={styles.linebreak} />
        <View style={styles.box_wrapper}>
          <View style={{ marginRight: 100 }}>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>MARKE:</Text>
              <Text style={styles.item_item}>Mercedes</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>MODELL:</Text>
              <Text style={styles.item_item}>G-Wagon</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>JAHR:</Text>
              <Text style={styles.item_item}>2022:</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>IM VERKEHR: </Text>
              <Text style={styles.item_item}>20.01.2022</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>TERMIN: </Text>
              <Text style={styles.item_item}>17.3.2002</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>AKZEPTIERTES DATUM: </Text>
              <Text style={styles.item_item}>
                20.01.2022
              </Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>PRODUKTIONSNUMMER</Text>
              <Text style={styles.item_item}>G-122-W</Text>
            </View>
          </View>
          <View style={{ textAlign: 'right' }}>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>KILOMETERSTAND: </Text>
              <Text style={styles.item_item}>12.000</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>Auftragsnummer: </Text>
              <Text style={styles.item_item}>SK 3141 H</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>Schaden Nr: </Text>
              <Text style={styles.item_item}>SK 3141 H</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>Rechnungsempf: </Text>
              <Text style={styles.item_item}>SK 3141 H</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>ZEICHENNUMMER: </Text>
              <Text style={styles.item_item}>SK 3141 H</Text>
            </View>
            <View style={styles.item_wrapper}>
              <Text style={styles.item_title}>Chassi Number: </Text>
              <Text style={styles.item_item}>SK 3141 H</Text>
            </View>
          </View>
        </View>
      </View>

        <View style={styles.body}>
          <Text style={styles.tab_title}>Arbeitswerte</Text>
          <View style={styles.linebreak} />
          <View style={styles.body_wrapper}>
            <View style={styles.body_right}>
              <View style={{ width: '100%', display: 'flex' }}>
                <View style={styles.six_title}>
                  <Text>ID</Text>
                </View>
                <View style={styles.more_six_title}>
                  <Text>Description</Text>
                </View>
                <View style={styles.six_title}>
                  <Text>STD</Text>
                </View>
                <View style={styles.six_title}>
                  <Text>PREIS</Text>
                </View>
                <View style={styles.six_title}>
                  <Text>GESAMT</Text>
                </View>
              </View>
                <View style={styles.full_item}>
                  <View style={styles.six}>
                    <Text style={styles.item_title_list}>2</Text>
                  </View>
                  <View style={styles.more_six}>
                    <Text style={styles.item_item_list}>
                      Test Description
                    </Text>
                  </View>
                  <View style={{ ...styles.six, textAlign: 'center' }}>
                    <Text style={styles.item_item_list}>1KG</Text>
                  </View>
                  <View style={{ ...styles.six, textAlign: 'center' }}>
                    <Text style={styles.item_item_list}>$2</Text>
                  </View>
                  <View style={{ ...styles.six, textAlign: 'right' }}>
                    <Text style={styles.item_item_list}>$4</Text>
                  </View>
                </View>
            </View>
          </View>
        </View>
    </Page>
  </Document>
);


class Report01 extends Component {
  constructor(props) {
      super(props);

  }


        state = {
          search_employee_id                      : '',
          search_employee_name                    : '',
          lsEmployeeIsFetching                    : true,
          lsEmployeeDataList                      : [],
          lsPagination                            : {},
          lsPageSize                              : 10,
          lsCurrentPage                           : 1,
          lsSortedBy                              : 'employee_id',
          lsSortOrder                             : 'descend',
          lsTotalPage                             : 0,
          lsNumberOfRows                          : 0,
          lsSearchText                            : '',
          lsLoadingEmployee                       : true,
          direction                               : null,
          complex_id                              : '',
          features                                : {
            width                                 : 1200,
            height                                : 800,
             }
           }


  componentWillReceiveProps (nextProps) {

    if (nextProps.employee.gpEmployeeDataList){
      if (nextProps.employee.gpEmployeeDataList !== this.props.employee.gpEmployeeDataList) {
        this.setState({
          lsEmployeeDataList              : nextProps.employee.gpEmployeeDataList,
          lsNumberOfRows              : nextProps.employee.gpEmployeeDataListNumRows,
        });

        const lsPagination = { ...this.state.pagination };
        lsPagination.total = nextProps.employee.gpEmployeeDataListNumRows*1;
        this.setState({
          pagination: lsPagination,
        });
      };
    };
  };


  componentDidMount(){
    this._loadEmployeeDataSets();
  }


/* -------------------------------------------------------------------------- */
/*  load employee list                                      */
/* -------------------------------------------------------------------------- */
    _loadEmployeeDataSets() {

      const data = {
        employee_id                               : this.state.search_employee_id,
        employee_name                             : this.state.search_employee_name,
        complex_id                                : this.state.complex_id, 
        page_size                                 : this.state.lsPageSize,
        current_page                              : 1,
        sort_field                                : this.state.lsSortedBy,
        sort_order                                : this.state.lsSortOrder,
      }


        this.setState({
          lsCurrentPage                           : 1,
        }, this.props.actions._listEmployee(data));
      }


      _print(){
        window.print();
      }


  render() {
    var index = 1;
    return (
      <div>
        <PDFDownloadLink document={<MyDocument />} fileName='somename.pdf' >
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
        </PDFDownloadLink>
        <div style={{padding:'20px', backgroundColor:'#fff', borderWidth: '1px'}} >
          <PDFViewer  style={styles.viewer}>
            <MyDocument />
          </PDFViewer>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report01);
