export const lease_detailConstants = {
    ON_LEASE_DETAIL_FORM_FIELD_CHANGE             : 'ON_LEASE_DETAIL_FORM_FIELD_CHANGE',
    CHANGE_LEASE_DETAIL_FIELD                     : 'CHANGE_LEASE_DETAIL_FIELD',
    ON_LEASE_DETAIL_STATE_CHANGE                  : 'ON_LEASE_DETAIL_STATE_CHANGE',

    LEASE_DETAIL_GET_LIST_REQUEST                 : 'LEASE_DETAIL_GET_LIST_REQUEST',
    LEASE_DETAIL_GET_LIST_SUCCESS                 : 'LEASE_DETAIL_GET_LIST_SUCCESS',
    LEASE_DETAIL_GET_LIST_FAILURE                 : 'LEASE_DETAIL_GET_LIST_FAILURE',

    LEASE_DETAIL_VIEW_REQUEST                     : 'LEASE_DETAIL_VIEW_REQUEST',
    LEASE_DETAIL_VIEW_SUCCESS                     : 'LEASE_DETAIL_VIEW_SUCCESS',
    LEASE_DETAIL_VIEW_FAILURE                     : 'LEASE_DETAIL_VIEW_FAILURE',

    LEASE_DETAIL_ADD_REQUEST                      : 'LEASE_DETAIL_ADD_REQUEST',
    LEASE_DETAIL_ADD_SUCCESS                      : 'LEASE_DETAIL_ADD_SUCCESS',
    LEASE_DETAIL_ADD_FAILURE                      : 'LEASE_DETAIL_ADD_FAILURE',

    LEASE_DETAIL_CHANGE_REQUEST                   : 'LEASE_DETAIL_CHANGE_REQUEST',
    LEASE_DETAIL_CHANGE_SUCCESS                   : 'LEASE_DETAIL_CHANGE_SUCCESS',
    LEASE_DETAIL_CHANGE_FAILURE                   : 'LEASE_DETAIL_CHANGE_FAILURE',

    LEASE_DETAIL_FILTER_REQUEST                   : 'LEASE_DETAIL_FILTER_REQUEST',
    LEASE_DETAIL_FILTER_SUCCESS                   : 'LEASE_DETAIL_FILTER_SUCCESS',
    LEASE_DETAIL_FILTER_FAILURE                   : 'LEASE_DETAIL_FILTER_FAILURE',

    LEASE_DETAIL_REMOVE_REQUEST                   : 'LEASE_DETAIL_REMOVE_REQUEST',
    LEASE_DETAIL_REMOVE_SUCCESS                   : 'LEASE_DETAIL_REMOVE_SUCCESS',
    LEASE_DETAIL_REMOVE_FAILURE                   : 'LEASE_DETAIL_REMOVE_FAILURE',

    LEASE_DETAIL_LOOKUP_REQUEST                   : 'LEASE_DETAIL_LOOKUP_REQUEST',
    LEASE_DETAIL_LOOKUP_SUCCESS                   : 'LEASE_DETAIL_LOOKUP_SUCCESS',
    LEASE_DETAIL_LOOKUP_FAILURE                   : 'LEASE_DETAIL_LOOKUP_FAILURE',

    LEASE_DETAIL_SHOW_MODAL                       : 'LEASE_DETAIL_SHOW_MODAL',
    LEASE_DETAIL_SEND_PARAM                       : 'LEASE_DETAIL_SEND_PARAM',
    LEASE_DETAIL_INITIALIZE_DATA                  : 'LEASE_DETAIL_INITIALIZE_DATA',
    LEASE_DETAIL_SHOW_LOOKUP_MODAL                : 'LEASE_DETAIL_SHOW_LOOKUP_MODAL',
    LEASE_DETAIL_INIT_SUCCESS                     : 'LEASE_DETAIL_INIT_SUCCESS',

    LEASE_DETAIL_IMPORT_REQUEST                   : 'LEASE_DETAIL_IMPORT_REQUEST',
    LEASE_DETAIL_IMPORT_SUCCESS                   : 'LEASE_DETAIL_IMPORT_SUCCESS',
    LEASE_DETAIL_IMPORT_FAILURE                   : 'LEASE_DETAIL_IMPORT_FAILURE',

    LEASE_DETAIL_EXPORT_REQUEST                   : 'LEASE_DETAIL_EXPORT_REQUEST',
    LEASE_DETAIL_EXPORT_SUCCESS                   : 'LEASE_DETAIL_EXPORT_SUCCESS',
    LEASE_DETAIL_EXPORT_FAILURE                   : 'LEASE_DETAIL_EXPORT_FAILURE',

    LEASE_DETAIL_COUNT_REQUEST                    : 'LEASE_DETAIL_COUNT_REQUEST',
    LEASE_DETAIL_COUNT_SUCCESS                    : 'LEASE_DETAIL_COUNT_SUCCESS',
    LEASE_DETAIL_COUNT_FAILURE                    : 'LEASE_DETAIL_COUNT_FAILURE',

    LEASE_DETAIL_GET_SAMPLE_FILE_REQUEST          : 'LEASE_DETAIL_GET_SAMPLE_FILE_REQUEST',
    LEASE_DETAIL_GET_SAMPLE_FILE_SUCCESS          : 'LEASE_DETAIL_GET_SAMPLE_FILE_SUCCESS',
    LEASE_DETAIL_GET_SAMPLE_FILE_FAILURE          : 'LEASE_DETAIL_GET_SAMPLE_FILE_FAILURE',

};

