export const buildingConstants = {
    ON_BUILDING_FORM_FIELD_CHANGE                 : 'ON_BUILDING_FORM_FIELD_CHANGE',
    CHANGE_BUILDING_FIELD                         : 'CHANGE_BUILDING_FIELD',
    ON_BUILDING_STATE_CHANGE                      : 'ON_BUILDING_STATE_CHANGE',

    BUILDING_GET_LIST_REQUEST                     : 'BUILDING_GET_LIST_REQUEST',
    BUILDING_GET_LIST_SUCCESS                     : 'BUILDING_GET_LIST_SUCCESS',
    BUILDING_GET_LIST_FAILURE                     : 'BUILDING_GET_LIST_FAILURE',

    BUILDING_VIEW_REQUEST                         : 'BUILDING_VIEW_REQUEST',
    BUILDING_VIEW_SUCCESS                         : 'BUILDING_VIEW_SUCCESS',
    BUILDING_VIEW_FAILURE                         : 'BUILDING_VIEW_FAILURE',

    BUILDING_ADD_REQUEST                          : 'BUILDING_ADD_REQUEST',
    BUILDING_ADD_SUCCESS                          : 'BUILDING_ADD_SUCCESS',
    BUILDING_ADD_FAILURE                          : 'BUILDING_ADD_FAILURE',

    BUILDING_CHANGE_REQUEST                       : 'BUILDING_CHANGE_REQUEST',
    BUILDING_CHANGE_SUCCESS                       : 'BUILDING_CHANGE_SUCCESS',
    BUILDING_CHANGE_FAILURE                       : 'BUILDING_CHANGE_FAILURE',

    BUILDING_FILTER_REQUEST                       : 'BUILDING_FILTER_REQUEST',
    BUILDING_FILTER_SUCCESS                       : 'BUILDING_FILTER_SUCCESS',
    BUILDING_FILTER_FAILURE                       : 'BUILDING_FILTER_FAILURE',

    BUILDING_REMOVE_REQUEST                       : 'BUILDING_REMOVE_REQUEST',
    BUILDING_REMOVE_SUCCESS                       : 'BUILDING_REMOVE_SUCCESS',
    BUILDING_REMOVE_FAILURE                       : 'BUILDING_REMOVE_FAILURE',

    BUILDING_LOOKUP_REQUEST                       : 'BUILDING_LOOKUP_REQUEST',
    BUILDING_LOOKUP_SUCCESS                       : 'BUILDING_LOOKUP_SUCCESS',
    BUILDING_LOOKUP_FAILURE                       : 'BUILDING_LOOKUP_FAILURE',

    BUILDING_SHOW_MODAL                           : 'BUILDING_SHOW_MODAL',
    BUILDING_SEND_PARAM                           : 'BUILDING_SEND_PARAM',
    BUILDING_INITIALIZE_DATA                      : 'BUILDING_INITIALIZE_DATA',
    BUILDING_SHOW_LOOKUP_MODAL                    : 'BUILDING_SHOW_LOOKUP_MODAL',
    BUILDING_INIT_SUCCESS                         : 'BUILDING_INIT_SUCCESS',

    BUILDING_IMPORT_REQUEST                       : 'BUILDING_IMPORT_REQUEST',
    BUILDING_IMPORT_SUCCESS                       : 'BUILDING_IMPORT_SUCCESS',
    BUILDING_IMPORT_FAILURE                       : 'BUILDING_IMPORT_FAILURE',

    BUILDING_EXPORT_REQUEST                       : 'BUILDING_EXPORT_REQUEST',
    BUILDING_EXPORT_SUCCESS                       : 'BUILDING_EXPORT_SUCCESS',
    BUILDING_EXPORT_FAILURE                       : 'BUILDING_EXPORT_FAILURE',

    BUILDING_COUNT_REQUEST                        : 'BUILDING_COUNT_REQUEST',
    BUILDING_COUNT_SUCCESS                        : 'BUILDING_COUNT_SUCCESS',
    BUILDING_COUNT_FAILURE                        : 'BUILDING_COUNT_FAILURE',

    BUILDING_GET_SAMPLE_FILE_REQUEST              : 'BUILDING_GET_SAMPLE_FILE_REQUEST',
    BUILDING_GET_SAMPLE_FILE_SUCCESS              : 'BUILDING_GET_SAMPLE_FILE_SUCCESS',
    BUILDING_GET_SAMPLE_FILE_FAILURE              : 'BUILDING_GET_SAMPLE_FILE_FAILURE',

};

