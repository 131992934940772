export const usersConstants = {
    ON_USERS_FORM_FIELD_CHANGE                    : 'ON_USERS_FORM_FIELD_CHANGE',
    CHANGE_USERS_FIELD                            : 'CHANGE_USERS_FIELD',
    ON_USERS_STATE_CHANGE                         : 'ON_USERS_STATE_CHANGE',

    USERS_GET_LIST_REQUEST                        : 'USERS_GET_LIST_REQUEST',
    USERS_GET_LIST_SUCCESS                        : 'USERS_GET_LIST_SUCCESS',
    USERS_GET_LIST_FAILURE                        : 'USERS_GET_LIST_FAILURE',

    USERS_VIEW_REQUEST                            : 'USERS_VIEW_REQUEST',
    USERS_VIEW_SUCCESS                            : 'USERS_VIEW_SUCCESS',
    USERS_VIEW_FAILURE                            : 'USERS_VIEW_FAILURE',

    USERS_ADD_REQUEST                             : 'USERS_ADD_REQUEST',
    USERS_ADD_SUCCESS                             : 'USERS_ADD_SUCCESS',
    USERS_ADD_FAILURE                             : 'USERS_ADD_FAILURE',

    USERS_CHANGE_REQUEST                          : 'USERS_CHANGE_REQUEST',
    USERS_CHANGE_SUCCESS                          : 'USERS_CHANGE_SUCCESS',
    USERS_CHANGE_FAILURE                          : 'USERS_CHANGE_FAILURE',

    USERS_FILTER_REQUEST                          : 'USERS_FILTER_REQUEST',
    USERS_FILTER_SUCCESS                          : 'USERS_FILTER_SUCCESS',
    USERS_FILTER_FAILURE                          : 'USERS_FILTER_FAILURE',

    USERS_REMOVE_REQUEST                          : 'USERS_REMOVE_REQUEST',
    USERS_REMOVE_SUCCESS                          : 'USERS_REMOVE_SUCCESS',
    USERS_REMOVE_FAILURE                          : 'USERS_REMOVE_FAILURE',

    USERS_LOOKUP_REQUEST                          : 'USERS_LOOKUP_REQUEST',
    USERS_LOOKUP_SUCCESS                          : 'USERS_LOOKUP_SUCCESS',
    USERS_LOOKUP_FAILURE                          : 'USERS_LOOKUP_FAILURE',

    USERS_SHOW_MODAL                              : 'USERS_SHOW_MODAL',
    USERS_SEND_PARAM                              : 'USERS_SEND_PARAM',
    USERS_INITIALIZE_DATA                         : 'USERS_INITIALIZE_DATA',
    USERS_SHOW_LOOKUP_MODAL                       : 'USERS_SHOW_LOOKUP_MODAL',
    USERS_INIT_SUCCESS                            : 'USERS_INIT_SUCCESS',

    USERS_IMPORT_REQUEST                          : 'USERS_IMPORT_REQUEST',
    USERS_IMPORT_SUCCESS                          : 'USERS_IMPORT_SUCCESS',
    USERS_IMPORT_FAILURE                          : 'USERS_IMPORT_FAILURE',

    USERS_EXPORT_REQUEST                          : 'USERS_EXPORT_REQUEST',
    USERS_EXPORT_SUCCESS                          : 'USERS_EXPORT_SUCCESS',
    USERS_EXPORT_FAILURE                          : 'USERS_EXPORT_FAILURE',

    USERS_COUNT_REQUEST                           : 'USERS_COUNT_REQUEST',
    USERS_COUNT_SUCCESS                           : 'USERS_COUNT_SUCCESS',
    USERS_COUNT_FAILURE                           : 'USERS_COUNT_FAILURE',

    USERS_GET_SAMPLE_FILE_REQUEST                 : 'USERS_GET_SAMPLE_FILE_REQUEST',
    USERS_GET_SAMPLE_FILE_SUCCESS                 : 'USERS_GET_SAMPLE_FILE_SUCCESS',
    USERS_GET_SAMPLE_FILE_FAILURE                 : 'USERS_GET_SAMPLE_FILE_FAILURE',

};

