'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('complex_id'): {
        let complex_id    = value
        let isValid = true

      if (complex_id.length > 0) {
        if (complex_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'complex_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'complex_id_error_msg'], 'ID Komplek perlu di isi')
        }

        if (complex_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'complex_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'complex_id_error_msg'], 'ID Komplek panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(complex_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'complex_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'complex_id_error_msg'], 'ID Komplek tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'complex_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'complex_id_error_msg'], '')
        }
    }

    case ('complex_name'): {
        let complex_name    = value
        let isValid = true

        if (complex_name.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'complex_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'complex_name_error_msg'], 'Nama Komplek perlu di isi')
        }

        if (complex_name.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'complex_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'complex_name_error_msg'], 'Nama Komplek panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(complex_name)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'complex_name_has_error'], true)
            .setIn(['form', 'gpDataFields', 'complex_name_error_msg'], 'Nama Komplek tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'complex_name_has_error'], false)
            .setIn(['form', 'gpDataFields', 'complex_name_error_msg'], '')
        }
    }

    case ('address'): {
        let address    = value
        let isValid = true

        if (address.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], true)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], 'Alamat perlu di isi')
        }

        if (address.length > 200) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], true)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], 'Alamat panjang maksimumnya 200 karakter.')
        }

        if (!check.validateAlpha(address)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], true)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], 'Alamat tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'address_has_error'], false)
            .setIn(['form', 'gpDataFields', 'address_error_msg'], '')
        }
    }

  }
  return state
}
