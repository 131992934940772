import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import CompanyEdit                                     from './edit';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  companyActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...companyActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    company: {
      gpDataFields                                : state.company.form.gpDataFields,
      gpCompanyIsFetching                         : state.company.form.gpCompanyIsFetching,
      company_active_tab                          : state.company.form.company_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsCompanyIsFetching                         : true,
      company_id                                  : '',
      company_name                                : '',
      address                                     : '',
      phone                                       : '',
      email                                       : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        company_id                                : nextProps.company.gpDataFields.company_id,
        company_name                              : nextProps.company.gpDataFields.company_name,
        address                                   : nextProps.company.gpDataFields.address,
        phone                                     : nextProps.company.gpDataFields.phone,
        email                                     : nextProps.company.gpDataFields.email,
        date_upd                                  : nextProps.company.gpDataFields.date_upd,
        date_add                                  : nextProps.company.gpDataFields.date_add,
        lsCompanyIsFetching                       : nextProps.company.gpCompanyIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewCompany(this.props.match.params.company_id);
      this.setState({
        lsCompanyIsFetching                       : this.props.company.gpCompanyIsFetching,
        company_id                                : this.props.company.gpDataFields.company_id,
        company_name                              : this.props.company.gpDataFields.company_name,
        address                                   : this.props.company.gpDataFields.address,
        phone                                     : this.props.company.gpDataFields.phone,
        email                                     : this.props.company.gpDataFields.email,
      });
    }


    _editCompany(id) {
      const data = [{
          company_id      : id,
      }];
      this.props.actions._activateEditCompany(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onCompanyStateChange('company_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
      ]


      const {
        company_active_tab
      } = this.props.company;


      const {
        company_id,
        company_name,
        address,
        phone,
        email,
        date_add,
        date_upd
      } = this.props.company.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Perusahaan
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/company/edit/' + company_id}
                onClick = {() => this._editCompany(company_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Perusahaan</span><br />
                  {company_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Nama Perusahaan</span><br />
                  {company_name}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Alamat</span><br />
                  {address}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Nomor Telepon</span><br />
                  {phone}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Email</span><br />
                  {email}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

