/* -------------------------------------------------------------------------- */
/* LEASE_DETAIL.INDEX                                                         */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* Import definition start                                                    */
/* -------------------------------------------------------------------------- */
import React, { Component }                           from 'react';
import { bindActionCreators }                         from 'redux';
import { connect }                                    from 'react-redux';
import { Link }                                       from 'react-router-dom';
import Lease_detailList                               from './list';
import Lease_detailCount                              from './count';
import {
       FaHome,
}                                                       from 'react-icons/fa';
import {
  lease_detailActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
}                                                     from 'antd';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {

  return {
    actions: bindActionCreators({
      ...lease_detailActions,
      }
      , dispatch)
  }

}

function mapStateToProps(state) {

}

class Lease_detail extends Component {

  render() {
    return (
      <div>
        <div className='header-bottom' ></div>
        <div className='content'>
        <Row>
          <Col span={24}>
            <Lease_detailList />
          </Col>
        </Row>
       </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Lease_detail);
