const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        lease_id                                  : '',
        lease_description                         : '',
        start_date                                : '',
        end_date                                  : '',
        deposit                                   : '',
        tenant_id                                 : '',
        tenant_name                               : '',
        search_lease_id                           : '',
        search_lease_description                  : '',
        search_deposit                            : '',
        search_tenant_id                          : '',
        tenant_tenant_id                          : '',
   }))(),
   gpLeaseDataList                                : [],
   gpLeaseDataListNumRows                         : 0,
   gpLeaseLookupList                              : [],
   gpLeaseIsDisabled                              : false,
   gpLeaseError                                   : null,
   gpLeaseIsValid                                 : true,
   gpLeaseIsFetching                              : false,
   lease_count                                    : 0,
   lease_active_tab                               : 0,
   gpLeaseShowModal                               : 'hide',
   gpLeaseModalParam                              : [],
   gpLeaseLookupShowModal                         : 'hide',
   gpDataFields: new (Record({
        tenant_id                                 : '',
        tenant_name                               : '',
        lease_id                                  : '',
        lease_description                         : '',
        start_date                                : '',
        end_date                                  : '',
        deposit                                   : '',
        search_lease_id                           : '',
        search_lease_description                  : '',
        search_deposit                            : '',
        tenant_tenant_id                          : '',
        lease_id_has_error                        : false,
        lease_description_has_error               : false,
        start_date_has_error                      : false,
        end_date_has_error                        : false,
        deposit_has_error                         : false,
        search_lease_id_has_error                 : false,
        search_lease_description_has_error        : false,
        search_deposit_has_error                  : false,
        tenant_tenant_id_has_error                : false,
        lease_id_error_msg                        : false,
        lease_description_error_msg               : false,
        start_date_error_msg                      : false,
        end_date_error_msg                        : false,
        deposit_error_msg                         : false,
        search_lease_id_error_msg                 : false,
        search_lease_description_error_msg        : false,
        search_deposit_error_msg                  : false,
        tenant_tenant_id_error_msg                : false,
        tenant_id_has_error                       : false,
        tenant_id_error_msg                       : false,
        search_tenant_id                          : '',
        tenant_id                                 : '',
   }))(),
 })
 var leaseInitialState = Record({
   form: new Form()
 })
export default leaseInitialState
