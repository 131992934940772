'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.rent_id_has_error &&
        state.form.gpDataFields.rent_description !== '' &&
        !state.form.gpDataFields.rent_description_has_error &&
        state.form.gpDataFields.rent_fee !== '' &&
        !state.form.gpDataFields.rent_fee_has_error &&
        state.form.gpDataFields.late_fee !== '' &&
        !state.form.gpDataFields.late_fee_has_error &&
        state.form.gpDataFields.due_date !== '' &&
        !state.form.gpDataFields.due_date_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpRentIsValid'], true)
  } else {
    return state.setIn(['form', 'gpRentIsValid'], false)
  }
}
