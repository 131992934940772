'use strict'
import * as check                                     from './validation'
export default function fieldValidation (state, action) {
  const {field, value} = action.payload
  switch (field) {
    case ('rent_id'): {
        let rent_id    = value
        let isValid = true

      if (rent_id.length > 0) {
        if (rent_id.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_id_error_msg'], 'ID Penyewaan perlu di isi')
        }

        if (rent_id.length > 5) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_id_error_msg'], 'ID Penyewaan panjang maksimumnya 5 karakter.')
        }

        if (!check.validateAlpha(rent_id)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_id_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_id_error_msg'], 'ID Penyewaan tidak valid')
        }
      }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'rent_id_has_error'], false)
            .setIn(['form', 'gpDataFields', 'rent_id_error_msg'], '')
        }
    }

    case ('rent_description'): {
        let rent_description    = value
        let isValid = true

        if (rent_description.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_description_error_msg'], 'Jenis Penyewaan perlu di isi')
        }

        if (rent_description.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_description_error_msg'], 'Jenis Penyewaan panjang maksimumnya 50 karakter.')
        }

        if (!check.validateAlpha(rent_description)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_description_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_description_error_msg'], 'Jenis Penyewaan tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'rent_description_has_error'], false)
            .setIn(['form', 'gpDataFields', 'rent_description_error_msg'], '')
        }
    }

    case ('rent_fee'): {
        let rent_fee    = value
        let isValid = true

        if (rent_fee.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_fee_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_fee_error_msg'], 'Biaya Sewa perlu di isi')
        }

        if (rent_fee.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_fee_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_fee_error_msg'], 'Biaya Sewa panjang maksimumnya 50 karakter.')
        }

        if (!check.validateMoney(rent_fee)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'rent_fee_has_error'], true)
            .setIn(['form', 'gpDataFields', 'rent_fee_error_msg'], 'Biaya Sewa tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'rent_fee_has_error'], false)
            .setIn(['form', 'gpDataFields', 'rent_fee_error_msg'], '')
        }
    }

    case ('late_fee'): {
        let late_fee    = value
        let isValid = true

        if (late_fee.length == 0) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'late_fee_has_error'], true)
            .setIn(['form', 'gpDataFields', 'late_fee_error_msg'], 'Biaya Keterlambatan perlu di isi')
        }

        if (late_fee.length > 50) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'late_fee_has_error'], true)
            .setIn(['form', 'gpDataFields', 'late_fee_error_msg'], 'Biaya Keterlambatan panjang maksimumnya 50 karakter.')
        }

        if (!check.validateMoney(late_fee)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'late_fee_has_error'], true)
            .setIn(['form', 'gpDataFields', 'late_fee_error_msg'], 'Biaya Keterlambatan tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'late_fee_has_error'], false)
            .setIn(['form', 'gpDataFields', 'late_fee_error_msg'], '')
        }
    }

    case ('due_date'): {
        let due_date    = value
        let isValid = true

        if (!check.validateDate(due_date)) {
            isValid = false
            return state
            .setIn(['form', 'gpDataFields', 'due_date_has_error'], true)
            .setIn(['form', 'gpDataFields', 'due_date_error_msg'], 'Tanggal Jatuh Tempo tidak valid')
        }

        if(isValid){
            return state
            .setIn(['form', 'gpDataFields', 'due_date_has_error'], false)
            .setIn(['form', 'gpDataFields', 'due_date_error_msg'], '')
        }
    }

  }
  return state
}
