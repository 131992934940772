const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        id_user                                   : '',
        fullname                                  : '',
        phone                                     : '',
        email                                     : '',
        passwd                                    : '',
        active                                    : 0,
        administrator                             : 0,
        id_userlevel                              : '',
        last_login_date                           : '',
        login_attempt                             : 0,
        expired_date                              : '',
        search_id_user                            : '',
        search_fullname                           : '',
        search_phone                              : '',
        search_email                              : '',
        search_passwd                             : '',
        search_active                             : 0,
        search_administrator                      : 0,
        search_id_userlevel                       : '',
        search_login_attempt                      : 0,
   }))(),
   gpUsersDataList                                : [],
   gpUsersDataListNumRows                         : 0,
   gpUsersLookupList                              : [],
   gpUsersIsDisabled                              : false,
   gpUsersError                                   : null,
   gpUsersIsValid                                 : true,
   gpUsersIsFetching                              : false,
   users_count                                    : 0,
   users_active_tab                               : 0,
   gpUsersShowModal                               : 'hide',
   gpUsersModalParam                              : [],
   gpUsersLookupShowModal                         : 'hide',
   gpDataFields: new (Record({
        id_user                                   : '',
        fullname                                  : '',
        phone                                     : '',
        email                                     : '',
        passwd                                    : '',
        active                                    : 0,
        administrator                             : 0,
        id_userlevel                              : '',
        last_login_date                           : '',
        login_attempt                             : 0,
        expired_date                              : '',
        search_id_user                            : '',
        search_fullname                           : '',
        search_phone                              : '',
        search_email                              : '',
        search_passwd                             : '',
        search_active                             : 0,
        search_administrator                      : 0,
        search_id_userlevel                       : '',
        search_login_attempt                      : 0,
        id_user_has_error                         : false,
        fullname_has_error                        : false,
        phone_has_error                           : false,
        email_has_error                           : false,
        passwd_has_error                          : false,
        active_has_error                          : false,
        administrator_has_error                   : false,
        id_userlevel_has_error                    : false,
        last_login_date_has_error                 : false,
        login_attempt_has_error                   : false,
        expired_date_has_error                    : false,
        search_id_user_has_error                  : false,
        search_fullname_has_error                 : false,
        search_phone_has_error                    : false,
        search_email_has_error                    : false,
        search_passwd_has_error                   : false,
        search_active_has_error                   : false,
        search_administrator_has_error            : false,
        search_id_userlevel_has_error             : false,
        search_login_attempt_has_error            : false,
        id_user_error_msg                         : false,
        fullname_error_msg                        : false,
        phone_error_msg                           : false,
        email_error_msg                           : false,
        passwd_error_msg                          : false,
        active_error_msg                          : false,
        administrator_error_msg                   : false,
        id_userlevel_error_msg                    : false,
        last_login_date_error_msg                 : false,
        login_attempt_error_msg                   : false,
        expired_date_error_msg                    : false,
        search_id_user_error_msg                  : false,
        search_fullname_error_msg                 : false,
        search_phone_error_msg                    : false,
        search_email_error_msg                    : false,
        search_passwd_error_msg                   : false,
        search_active_error_msg                   : false,
        search_administrator_error_msg            : false,
        search_id_userlevel_error_msg             : false,
        search_login_attempt_error_msg            : false,
   }))(),
 })
 var usersInitialState = Record({
   form: new Form()
 })
export default usersInitialState
