'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.id_user_has_error &&
        state.form.gpDataFields.fullname !== '' &&
        !state.form.gpDataFields.fullname_has_error &&
        state.form.gpDataFields.phone !== '' &&
        !state.form.gpDataFields.phone_has_error &&
        state.form.gpDataFields.email !== '' &&
        !state.form.gpDataFields.email_has_error &&
        state.form.gpDataFields.passwd !== '' &&
        !state.form.gpDataFields.passwd_has_error &&
        state.form.gpDataFields.active !== '' &&
        !state.form.gpDataFields.active_has_error &&
        state.form.gpDataFields.administrator !== '' &&
        !state.form.gpDataFields.administrator_has_error &&
        state.form.gpDataFields.id_userlevel !== '' &&
        !state.form.gpDataFields.id_userlevel_has_error &&
        state.form.gpDataFields.last_login_date !== '' &&
        !state.form.gpDataFields.last_login_date_has_error &&
        state.form.gpDataFields.login_attempt !== '' &&
        !state.form.gpDataFields.login_attempt_has_error &&
        state.form.gpDataFields.expired_date !== '' &&
        !state.form.gpDataFields.expired_date_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpUsersIsValid'], true)
  } else {
    return state.setIn(['form', 'gpUsersIsValid'], false)
  }
}
