const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        company_id                                : '',
        company_name                              : '',
        address                                   : '',
        phone                                     : '',
        email                                     : '',
        search_company_id                         : '',
        search_company_name                       : '',
        search_address                            : '',
        search_phone                              : '',
        search_email                              : '',
   }))(),
   gpCompanyDataList                              : [],
   gpCompanyDataListNumRows                       : 0,
   gpCompanyLookupList                            : [],
   gpCompanyIsDisabled                            : false,
   gpCompanyError                                 : null,
   gpCompanyIsValid                               : true,
   gpCompanyIsFetching                            : false,
   company_count                                  : 0,
   company_active_tab                             : 0,
   gpCompanyShowModal                             : 'hide',
   gpCompanyModalParam                            : [],
   gpCompanyLookupShowModal                       : 'hide',
   gpDataFields: new (Record({
        company_id                                : '',
        company_name                              : '',
        address                                   : '',
        phone                                     : '',
        email                                     : '',
        search_company_id                         : '',
        search_company_name                       : '',
        search_address                            : '',
        search_phone                              : '',
        search_email                              : '',
        company_id_has_error                      : false,
        company_name_has_error                    : false,
        address_has_error                         : false,
        phone_has_error                           : false,
        email_has_error                           : false,
        search_company_id_has_error               : false,
        search_company_name_has_error             : false,
        search_address_has_error                  : false,
        search_phone_has_error                    : false,
        search_email_has_error                    : false,
        company_id_error_msg                      : false,
        company_name_error_msg                    : false,
        address_error_msg                         : false,
        phone_error_msg                           : false,
        email_error_msg                           : false,
        search_company_id_error_msg               : false,
        search_company_name_error_msg             : false,
        search_address_error_msg                  : false,
        search_phone_error_msg                    : false,
        search_email_error_msg                    : false,
   }))(),
 })
 var companyInitialState = Record({
   form: new Form()
 })
export default companyInitialState
