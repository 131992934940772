import React, { Component }                           from 'react';
import { connect }                                    from 'react-redux';
import { bindActionCreators }                         from 'redux';
import { Link }                                       from 'react-router-dom';
import { Input, Grid,  Button, Segment }              from 'semantic-ui-react'
import sha256                                         from 'crypto-js/sha256'
import { authenticateActions }                        from './../_actions'

function mapStateToProps (state) {
  return {
    authenticate: {
      form                      : state.authenticate.form,
      fields                    : state.authenticate.form.fields,
      isFetching                : state.authenticate.form.isFetching,
      isValid                   : state.authenticate.form.isValid,
    },
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
       ...authenticateActions },
       dispatch)
  }
}

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted                    : false,
      password_confirm             : '',
      password_confirm_valid       : false,
      password_confirm_has_error   : false,
      password_confirm_error_msg   : '',
    };

    this.handleChange      = this.handleChange.bind(this);
    this.handleLocalChange = this.handleLocalChange.bind(this);
    this.handleSubmit      = this.handleSubmit.bind(this);
  }

  handleChange(name, e) {
      this.props.actions.registerState()
      const { value } = e.target;
      console.log('name: ' + JSON.stringify(name));
      console.log('value: ' + JSON.stringify(value));
      this.props.actions._onAuthFormFieldChange(name, value)
  }

  handleLocalChange(event) {
    const { name, value } = event.target
    const {
      password_confirm                ,
      password_confirm_has_error      ,
      password_confirm_error_msg      ,
    } = this.state
  
    this.setState({
      [name]: value
    });
  
    if(this.props.authenticate.fields.password != value){
      this.setState({
        'password_confirm_valid'    : false,
        'password_confirm_has_error': true,
        'password_confirm_error_msg': 'Password tidak sama',
      });
    } else {
      this.setState({
        'password_confirm_valid'    : true,
        'password_confirm_has_error': false,
        'password_confirm_error_msg': '',
      });
    }
  }

  handleSubmit (event) {
    event.preventDefault()

    const {
      fullname,
      email,
      phone,
      password,
    } = this.props.authenticate.fields;

    let hash = sha256(password).toString()
    this.props.actions.register(fullname, email, phone, hash);

  }

  render() {
    const { registering  } = this.props

    const {
      password_confirm,
      password_confirm_valid,
      password_confirm_has_error,
      password_confirm_error_msg
    } = this.state;

    const {
      fullname,
      email,
      phone,
      password,
      fullname_has_error,
      email_has_error,
      phone_has_error,
      password_has_error,
      fullname_error_msg,
      email_error_msg,
      phone_error_msg,
      password_error_msg,
      showPassword,
      icon,
    } = this.props.authenticate.fields;

    console.log('isValid: ' + this.props.authenticate.form.isValid);

    return (
          <div className='flex justify-center text-center p-12 bg-blue-50'>
              <div class='w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-6 dark:bg-gray-800 dark:border-gray-700'>
                <div style={{textAlign:'left'}}>
                  <img src={require('./../_applib/_static/_images/logo.jpg')} width='140px'/>
                </div>
                <br /><br /><br />
                <div className='login-input'>
                  <div style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}>Full Name</div>
                  <Input
                    autoFocus
                    name='fullname'
                    value={fullname}
                    onChange={(e)=>this.handleChange('fullname', e)}
                    size='large'
                    fluid
                  />
                  {fullname_has_error?<div style={{textAlign:'left', color: 'red'}}>{fullname_error_msg}</div>:null}
                </div>
                <br />
                <div className='login-input'>
                  <div style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}>Email</div>
                  <Input
                    name='email'
                    value={email}
                    onChange={(e)=>this.handleChange('email', e)}
                    size='large'
                    fluid
                  />
                  {email_has_error?<div style={{textAlign:'left', color: 'red'}}>{email_error_msg}</div>:null}
                </div>
                <br />
                <div className='login-input'>
                  <div style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}>Phone</div>
                  <Input
                    name='phone'
                    value={phone}
                    onChange={(e)=>this.handleChange('phone', e)}
                    size='large'
                    fluid
                  />
                  {phone_has_error?<div style={{textAlign:'left', color: 'red'}}>{phone_error_msg}</div>:null}
                </div>
                <br />
                <div className='login-input'>
                  <div style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}>Password</div>
                  <Input
                    name='password'
                    type='password'
                    value={password}
                    onChange={(e)=>this.handleChange('password', e)}
                    size='large'
                    fluid
                  />
                  {password_has_error?<div style={{textAlign:'left', color: 'red'}}>{password_error_msg}</div>:null}
                </div>
                <br />
                <div className='login-input'>
                  <div style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}>Confirm Password</div>
                  <Input
                    name='password_confirm'
                    type='password'
                    value={password_confirm}
                    onChange={this.handleLocalChange}
                    size='large'
                    fluid
                  />
                  {password_confirm_has_error?<div style={{textAlign:'left', color: 'red'}}>{password_confirm_error_msg}</div>:null}
                </div>
                <br /><br /><br />
              <div>
              <Button primary
                fluid
                disabled={!(this.props.authenticate.form.isValid && password_confirm_valid)}
                size='large'
                loading={registering}
                onClick={this.handleSubmit}>
                Register
              </Button>
            </div>
          </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration)

