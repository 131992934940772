'use strict'
import {leaseConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/lease.initialstate'
import fieldValidation                              from './../_validation/lease.field.validation'
import formValidation                               from './../_validation/lease.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case leaseConstants.LEASE_GET_LIST_REQUEST:
    case leaseConstants.LEASE_LOOKUP_REQUEST:
    case leaseConstants.LEASE_ADD_REQUEST:
    case leaseConstants.LEASE_CHANGE_REQUEST:
    case leaseConstants.LEASE_FILTER_REQUEST:
    case leaseConstants.LEASE_VIEW_REQUEST:
    case leaseConstants.LEASE_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpLeaseIsFetching'],  true)

    case leaseConstants.LEASE_CHANGE_SUCCESS:
    case leaseConstants.LEASE_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpLeaseIsFetching'],  false)

    case leaseConstants.LEASE_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpLeaseIsFetching'],  false)
          .setIn(['form', 'lease_count'],  action.payload)

    case leaseConstants.LEASE_FILTER_SUCCESS:
    case leaseConstants.LEASE_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpLeaseDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpLeaseDataList'], data.rows)
          .setIn(['form', 'gpLeaseDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpLeaseIsFetching'],  false)


  case leaseConstants.LEASE_LOOKUP_SUCCESS:
    console.log('[lease.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpLeaseLookupList'], action.payload)
        .setIn(['form', 'gpLeaseIsFetching'], false)


    case leaseConstants.LEASE_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'lease_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'lease_description'],'')
          .setIn(['form', 'gpDataFields',                                                           'start_date'],'')
          .setIn(['form', 'gpDataFields',                                                           'end_date'],'')
          .setIn(['form', 'gpDataFields',                                                           'deposit'],'')
          .setIn(['form', 'gpDataFields',                                                           'tenant_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'tenant_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'tenant_tenant_id'],'')

    case leaseConstants.LEASE_SHOW_MODAL:
      return state
        .setIn(['form', 'gpLeaseShowModal'],  action.payload)


    case leaseConstants.LEASE_SEND_PARAM:
      return state
        .setIn(['form', 'gpLeaseModalParam'],  action.payload[0])


    case leaseConstants.LEASE_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'lease_id'],                                          action.payload[0].lease_id)
          .setIn(['form', 'gpOriginalFields', 'lease_id'],                                          action.payload[0].lease_id)
          .setIn(['form', 'gpDataFields',     'lease_description'],                                 action.payload[0].lease_description)
          .setIn(['form', 'gpOriginalFields', 'lease_description'],                                 action.payload[0].lease_description)
          .setIn(['form', 'gpDataFields',     'start_date'],                                        action.payload[0].start_date)
          .setIn(['form', 'gpOriginalFields', 'start_date'],                                        action.payload[0].start_date)
          .setIn(['form', 'gpDataFields',     'end_date'],                                          action.payload[0].end_date)
          .setIn(['form', 'gpOriginalFields', 'end_date'],                                          action.payload[0].end_date)
          .setIn(['form', 'gpDataFields',     'deposit'],                                           action.payload[0].deposit)
          .setIn(['form', 'gpOriginalFields', 'deposit'],                                           action.payload[0].deposit)
          .setIn(['form', 'gpDataFields',     'tenant_id'],                                         action.payload[0].tenant_id)
          .setIn(['form', 'gpOriginalFields', 'tenant_id'],                                         action.payload[0].tenant_id)
          .setIn(['form', 'gpDataFields',     'tenant_name'],                                       action.payload[0].tenant_name)
          .setIn(['form', 'gpOriginalFields', 'tenant_name'],                                       action.payload[0].tenant_name)
          .setIn(['form', 'gpDataFields', 'tenant_tenant_id'],                                      action.payload[0].tenant_tenant_id)
          .setIn(['form', 'gpOriginalFields', 'tenant_tenant_id'],                                  action.payload[0].tenant_tenant_id)
          .setIn(['form', 'gpLeaseIsFetching'],                                                     false)
          return nextRecordState


    case leaseConstants.LEASE_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'lease_id'],                                              '')
          .setIn(['form', 'gpDataFields', 'lease_description'],                                     '')
          .setIn(['form', 'gpDataFields', 'start_date'],                                            '')
          .setIn(['form', 'gpDataFields', 'end_date'],                                              '')
          .setIn(['form', 'gpDataFields', 'deposit'],                                               '')
          .setIn(['form', 'gpDataFields', 'lease_id_has_error'],                                    false)
          .setIn(['form', 'gpDataFields', 'lease_description_has_error'],                           false)
          .setIn(['form', 'gpDataFields', 'start_date_has_error'],                                  false)
          .setIn(['form', 'gpDataFields', 'end_date_has_error'],                                    false)
          .setIn(['form', 'gpDataFields', 'deposit_has_error'],                                     false)

          .setIn(['form', 'gpLeaseIsValid'],                                                        false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('leaseReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'lease_id'],                                              '')
          .setIn(['form', 'gpDataFields', 'lease_description'],                                     '')
          .setIn(['form', 'gpDataFields', 'start_date'],                                            '')
          .setIn(['form', 'gpDataFields', 'end_date'],                                              '')
          .setIn(['form', 'gpDataFields', 'deposit'],                                               '')
          .setIn(['form', 'gpDataFields', 'tenant_id'],                                             '')
          .setIn(['form', 'gpDataFields', 'tenant_name'],                                           '')
          .setIn(['form', 'gpDataFields', 'tenant_id'],                                             '')
          .setIn(['form', 'gpLeaseError'],                                                          null)
          return formValidation(nextRecordState, action)


    case leaseConstants.LEASE_GET_LIST_FAILURE:
    case leaseConstants.LEASE_LOOKUP_FAILURE:
    case leaseConstants.LEASE_ADD_FAILURE:
    case leaseConstants.LEASE_CHANGE_FAILURE:
    case leaseConstants.LEASE_FILTER_FAILURE:
    case leaseConstants.LEASE_VIEW_FAILURE:
    case leaseConstants.LEASE_COUNT_FAILURE:
      console.log('lease.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpLeaseIsFetching'], false)
          .setIn(['form', 'gpLeaseError'], action.payload)


   case leaseConstants.ON_LEASE_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case leaseConstants.ON_LEASE_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case leaseConstants.CHANGE_LEASE_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpLeaseError'], null)
      return nextState}

    case leaseConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'lease_id'],                                              record.lease_id)
          .setIn(['form', 'gpOriginalFields', 'lease_id'],                                          record.lease_id)
          .setIn(['form', 'gpDataFields', 'lease_description'],                                     record.lease_description)
          .setIn(['form', 'gpOriginalFields', 'lease_description'],                                 record.lease_description)
          .setIn(['form', 'gpDataFields', 'start_date'],                                            record.start_date)
          .setIn(['form', 'gpOriginalFields', 'start_date'],                                        record.start_date)
          .setIn(['form', 'gpDataFields', 'end_date'],                                              record.end_date)
          .setIn(['form', 'gpOriginalFields', 'end_date'],                                          record.end_date)
          .setIn(['form', 'gpDataFields', 'deposit'],                                               record.deposit)
          .setIn(['form', 'gpOriginalFields', 'deposit'],                                           record.deposit)
          .setIn(['form', 'gpDataFields', 'tenant_id'],                                             record.tenant_id)
          .setIn(['form', 'gpOriginalFields', 'tenant_id'],                                         record.tenant_id)
          .setIn(['form', 'gpDataFields', 'tenant_id_has_error'],                                   record.tenant_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'tenant_id_has_error'],                               record.tenant_id_has_error)
          .setIn(['form', 'gpDataFields', 'tenant_name'],                                           record.tenant_name)
          .setIn(['form', 'gpOriginalFields', 'tenant_name'],                                       record.tenant_name)
          .setIn(['form', 'gpDataFields', 'tenant_name_has_error'],                                 record.tenant_name_has_error)
          .setIn(['form', 'gpOriginalFields', 'tenant_name_has_error'],                             record.tenant_name_has_error)
          .setIn(['form', 'gpDataFields', 'tenant_tenant_id'],                                      record.tenant_tenant_id)
          .setIn(['form', 'gpOriginalFields', 'tenant_tenant_id'],                                  record.tenant_tenant_id)

          .setIn(['form', 'gpDataFields', 'lease_id_has_error'],                                    record.lease_id_has_error)
          .setIn(['form', 'gpDataFields', 'lease_description_has_error'],                           record.lease_description_has_error)
          .setIn(['form', 'gpDataFields', 'start_date_has_error'],                                  record.start_date_has_error)
          .setIn(['form', 'gpDataFields', 'end_date_has_error'],                                    record.end_date_has_error)
          .setIn(['form', 'gpDataFields', 'deposit_has_error'],                                     record.deposit_has_error)

          .setIn(['form', 'gpDataFields', 'tenant_tenant_id_has_error'],                            action.payload.tenant_tenant_id_has_error)


          .setIn(['form', 'gpLeaseDataList'],                                                       record.gpLeaseDataList)
          .setIn(['form', 'gpLeaseIsDisabled'],                                                     record.gpLeaseIsDisabled)
          .setIn(['form', 'gpLeaseError'],                                                          record.gpLeaseError)
          .setIn(['form', 'gpLeaseIsValid'],                                                        record.gpLeaseIsValid)
          .setIn(['form', 'gpLeaseIsFetching'],                                                     record.gpLeaseIsFetching)
          .setIn(['form', 'lease_count'],                                                           record.lease_count)
      return next
case leaseConstants.LEASE_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpLeaseLookupShowModal'],  action.payload)


  }
  return state
}
