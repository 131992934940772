/* -------------------------------------------------------------------------- */
/* APARTMENT.INDEX                                                            */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* Import definition start                                                    */
/* -------------------------------------------------------------------------- */
import React, { Component }                           from 'react';
import { bindActionCreators }                         from 'redux';
import { connect }                                    from 'react-redux';
import { Link }                                       from 'react-router-dom';
import ApartmentList                               from './list';
import ApartmentCount                              from './count';
import {
       FaHome,
}                                                       from 'react-icons/fa';
import {
  apartmentActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
}                                                     from 'antd';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {

  return {
    actions: bindActionCreators({
      ...apartmentActions,
      }
      , dispatch)
  }

}

function mapStateToProps(state) {

}

class Apartment extends Component {

  render() {
    return (
      <div>
        <div className='header-bottom' ></div>
        <div className='content'>
        <Row>
          <Col span={24}>
            <ApartmentList />
          </Col>
        </Row>
       </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Apartment);
