'use strict'
import {employeeConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/employee.initialstate'
import fieldValidation                              from './../_validation/employee.field.validation'
import formValidation                               from './../_validation/employee.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case employeeConstants.EMPLOYEE_GET_LIST_REQUEST:
    case employeeConstants.EMPLOYEE_LOOKUP_REQUEST:
    case employeeConstants.EMPLOYEE_ADD_REQUEST:
    case employeeConstants.EMPLOYEE_CHANGE_REQUEST:
    case employeeConstants.EMPLOYEE_FILTER_REQUEST:
    case employeeConstants.EMPLOYEE_VIEW_REQUEST:
    case employeeConstants.EMPLOYEE_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpEmployeeIsFetching'],  true)

    case employeeConstants.EMPLOYEE_CHART0_REQUEST:
      return state
        .setIn(['form', 'gpEmployeeChart0IsFetching'],  true)

    case employeeConstants.EMPLOYEE_REPORT_000_TO_EXCEL_REQUEST:
    case employeeConstants.EMPLOYEE_REPORT_000_TO_PDF_REQUEST:
      return state
        .setIn(['form', 'gpEmployeeReport000IsFetching'],  true)
    
    case employeeConstants.EMPLOYEE_REPORT_000_TO_EXCEL_SUCCESS:
    case employeeConstants.EMPLOYEE_REPORT_000_TO_PDF_SUCCESS:
      return state
        .setIn(['form', 'gpEmployeeReport000Path'],  action.payload)
        .setIn(['form', 'gpEmployeeReport000IsFetching'],  false)
    
    case employeeConstants.EMPLOYEE_REPORT_000_TO_EXCEL_FAILURE:
    case employeeConstants.EMPLOYEE_REPORT_000_TO_PDF_FAILURE:
      return state
        .setIn(['form', 'gpEmployeeReport000IsFetching'],  false)

    case employeeConstants.EMPLOYEE_REPORT_001_TO_EXCEL_REQUEST:
    case employeeConstants.EMPLOYEE_REPORT_001_TO_PDF_REQUEST:
      return state
        .setIn(['form', 'gpEmployeeReport001IsFetching'],  true)
    
    case employeeConstants.EMPLOYEE_REPORT_001_TO_EXCEL_SUCCESS:
    case employeeConstants.EMPLOYEE_REPORT_001_TO_PDF_SUCCESS:
      return state
        .setIn(['form', 'gpEmployeeReport001Path'],  action.payload)
        .setIn(['form', 'gpEmployeeReport001IsFetching'],  false)
    
    case employeeConstants.EMPLOYEE_REPORT_001_TO_EXCEL_FAILURE:
    case employeeConstants.EMPLOYEE_REPORT_001_TO_PDF_FAILURE:
      return state
        .setIn(['form', 'gpEmployeeReport001IsFetching'],  false)

    case employeeConstants.EMPLOYEE_CHANGE_SUCCESS:
    case employeeConstants.EMPLOYEE_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpEmployeeIsFetching'],  false)

    case employeeConstants.EMPLOYEE_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpEmployeeIsFetching'],  false)
          .setIn(['form', 'employee_count'],  action.payload)

    case employeeConstants.EMPLOYEE_FILTER_SUCCESS:
    case employeeConstants.EMPLOYEE_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpEmployeeDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpEmployeeDataList'], data.rows)
          .setIn(['form', 'gpEmployeeDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpEmployeeIsFetching'],  false)


case employeeConstants.EMPLOYEE_CHART0_SUCCESS:
  console.log('EMPLOYEE_CHART0_SUCCESS.payload:' + JSON.stringify(action.payload));
  for(let i = 0; i < action.payload.length; i++) {
    action.payload[i].count = parseInt(action.payload[i].count);
  }
  return state
      .setIn(['form', 'gpEmployeeChart0DataList'], action.payload)
      .setIn(['form', 'gpEmployeeChart0IsFetching'], false)

  case employeeConstants.EMPLOYEE_LOOKUP_SUCCESS:
    console.log('[employee.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpEmployeeLookupList'], action.payload)
        .setIn(['form', 'gpEmployeeIsFetching'], false)


    case employeeConstants.EMPLOYEE_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'employee_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'employee_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'complex_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'complex_name'],'')
          .setIn(['form', 'gpDataFields',                                                           'complex_complex_id'],'')

    case employeeConstants.EMPLOYEE_SHOW_MODAL:
      return state
        .setIn(['form', 'gpEmployeeShowModal'],  action.payload)


    case employeeConstants.EMPLOYEE_SEND_PARAM:
      return state
        .setIn(['form', 'gpEmployeeModalParam'],  action.payload[0])


    case employeeConstants.EMPLOYEE_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'employee_id'],                                       action.payload[0].employee_id)
          .setIn(['form', 'gpOriginalFields', 'employee_id'],                                       action.payload[0].employee_id)
          .setIn(['form', 'gpDataFields',     'employee_name'],                                     action.payload[0].employee_name)
          .setIn(['form', 'gpOriginalFields', 'employee_name'],                                     action.payload[0].employee_name)
          .setIn(['form', 'gpDataFields',     'complex_id'],                                        action.payload[0].complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_id'],                                        action.payload[0].complex_id)
          .setIn(['form', 'gpDataFields',     'complex_name'],                                      action.payload[0].complex_name)
          .setIn(['form', 'gpOriginalFields', 'complex_name'],                                      action.payload[0].complex_name)
          .setIn(['form', 'gpDataFields', 'complex_complex_id'],                                    action.payload[0].complex_complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_complex_id'],                                action.payload[0].complex_complex_id)
          .setIn(['form', 'gpEmployeeIsFetching'],                                                  false)
          return nextRecordState


    case employeeConstants.EMPLOYEE_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'employee_id'],                                           '')
          .setIn(['form', 'gpDataFields', 'employee_name'],                                         '')
          .setIn(['form', 'gpDataFields', 'employee_id_has_error'],                                 false)
          .setIn(['form', 'gpDataFields', 'employee_name_has_error'],                               false)

          .setIn(['form', 'gpEmployeeIsValid'],                                                     false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('employeeReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'employee_id'],                                           '')
          .setIn(['form', 'gpDataFields', 'employee_name'],                                         '')
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            '')
          .setIn(['form', 'gpDataFields', 'complex_name'],                                          '')
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            '')
          .setIn(['form', 'gpEmployeeError'],                                                       null)
          return formValidation(nextRecordState, action)


    case employeeConstants.EMPLOYEE_GET_LIST_FAILURE:
    case employeeConstants.EMPLOYEE_LOOKUP_FAILURE:
    case employeeConstants.EMPLOYEE_ADD_FAILURE:
    case employeeConstants.EMPLOYEE_CHANGE_FAILURE:
    case employeeConstants.EMPLOYEE_FILTER_FAILURE:
    case employeeConstants.EMPLOYEE_VIEW_FAILURE:
    case employeeConstants.EMPLOYEE_COUNT_FAILURE:
      console.log('employee.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpEmployeeIsFetching'], false)
          .setIn(['form', 'gpEmployeeError'], action.payload)


    case employeeConstants.EMPLOYEE_CHART0_FAILURE:
      return state
          .setIn(['form', 'gpEmployeeChart0IsFetching'], false)
          .setIn(['form', 'gpEmployeeChart0Error'], action.payload)


   case employeeConstants.ON_EMPLOYEE_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case employeeConstants.ON_EMPLOYEE_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case employeeConstants.CHANGE_EMPLOYEE_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpEmployeeError'], null)
      return nextState}

    case employeeConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'employee_id'],                                           record.employee_id)
          .setIn(['form', 'gpOriginalFields', 'employee_id'],                                       record.employee_id)
          .setIn(['form', 'gpDataFields', 'employee_name'],                                         record.employee_name)
          .setIn(['form', 'gpOriginalFields', 'employee_name'],                                     record.employee_name)
          .setIn(['form', 'gpDataFields', 'complex_id'],                                            record.complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_id'],                                        record.complex_id)
          .setIn(['form', 'gpDataFields', 'complex_id_has_error'],                                  record.complex_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'complex_id_has_error'],                              record.complex_id_has_error)
          .setIn(['form', 'gpDataFields', 'complex_name'],                                          record.complex_name)
          .setIn(['form', 'gpOriginalFields', 'complex_name'],                                      record.complex_name)
          .setIn(['form', 'gpDataFields', 'complex_name_has_error'],                                record.complex_name_has_error)
          .setIn(['form', 'gpOriginalFields', 'complex_name_has_error'],                            record.complex_name_has_error)
          .setIn(['form', 'gpDataFields', 'complex_complex_id'],                                    record.complex_complex_id)
          .setIn(['form', 'gpOriginalFields', 'complex_complex_id'],                                record.complex_complex_id)

          .setIn(['form', 'gpDataFields', 'employee_id_has_error'],                                 record.employee_id_has_error)
          .setIn(['form', 'gpDataFields', 'employee_name_has_error'],                               record.employee_name_has_error)

          .setIn(['form', 'gpDataFields', 'complex_complex_id_has_error'],                          action.payload.complex_complex_id_has_error)

          .setIn(['form', 'gpEmployeeChart0DataList']     ,                                         record.gpEmployeeChart0DataList)
          .setIn(['form', 'gpEmployeeChart0Error']        ,                                         record.gpEmployeeChart0Error)
          .setIn(['form', 'gpEmployeeChart0IsFetching']   ,                                         record.gpEmployeeChart0IsFetching)

          .setIn(['form', 'gpEmployeeDataList'],                                                    record.gpEmployeeDataList)
          .setIn(['form', 'gpEmployeeIsDisabled'],                                                  record.gpEmployeeIsDisabled)
          .setIn(['form', 'gpEmployeeError'],                                                       record.gpEmployeeError)
          .setIn(['form', 'gpEmployeeIsValid'],                                                     record.gpEmployeeIsValid)
          .setIn(['form', 'gpEmployeeIsFetching'],                                                  record.gpEmployeeIsFetching)
          .setIn(['form', 'employee_count'],                                                        record.employee_count)
      return next
case employeeConstants.EMPLOYEE_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpEmployeeLookupShowModal'],  action.payload)


  }
  return state
}
