/* -------------------------------------------------------------------------- */
/* RENT.ACTIONS.JS                                                            */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
import {rentConstants}                                 from '../_constants';
import {rentService}                                   from '../_services';
import {alertActions}                                  from './';
import {infoConstants}                                 from './../_constants';

export const rentActions = {
    _addRent,
    _editRent,
    _filterRent,
    _viewRent,
    _listRent,
    _removeRent,
    _lookupRent,
    _showRentModalForm,
    _sendRentFormParam,
    _activateAddRent,
    _activateEditRent,
    _activateFilterRent,
    _onRentStateChange,
    _onRentFormFieldChange,
    action_to_import_rent,
    action_to_export_rent,
    action_to_get_sample_rent,
    action_to_count_rent,
};

export function _addRent(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  rentService._add(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupRent());
            dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.ADD_RENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: rentConstants.RENT_ADD_REQUEST         }}
    function success(result)  { return { type: rentConstants.RENT_ADD_SUCCESS, payload: result }}
    function failure()   { return { type: rentConstants.RENT_ADD_FAILURE}}
}

export function _editRent(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  rentService._edit(data)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_lookupRent());
          dispatch(_viewRent(data.rent_id));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(infoConstants.EDIT_RENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: rentConstants.RENT_CHANGE_REQUEST         }}
    function success(result)  { return { type: rentConstants.RENT_CHANGE_SUCCESS, payload: result }}
    function failure(error)   { return { type: rentConstants.RENT_CHANGE_FAILURE, payload: error }}
}

export function _filterRent(data) {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  rentService._filter(data)
    .then(
      response => {
        dispatch(success(response.response, 1));
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.FILTER_RENT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: rentConstants.RENT_FILTER_REQUEST         }}
    function success(result, page)  { return { type: rentConstants.RENT_FILTER_SUCCESS, payload: {data: result, page: page} }}
    function failure()   { return { type: rentConstants.RENT_FILTER_FAILURE}}
}

export function _removeRent(id) {
      const data = {
        rent_id                                   : '', 
        rent_description                          : '', 
        rent_fee                                  : '', 
        late_fee                                  : '', 
        lease_id                                  : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'rent_id',
        sort_order                                : 'descend',
      }

    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request({id}));
        rentService._remove(id)
    .then(
      response => {
        if(response.status == 'ERR'){
          dispatch(failure(response));
          dispatch(alertActions.error(response.code + ' - ' + response.message));
          dispatch(alertActions.clear());
        } else {
          dispatch(success(response));
          dispatch(alertActions.success(response.code + ' - ' + response.message));
          dispatch(_listRent(data));
          dispatch(_lookupRent());
          dispatch(alertActions.clear());
        }
       },
       error => {
         dispatch(failure());
         dispatch(alertActions.error(infoConstants.DELETE_RENT_ERROR));
         dispatch(alertActions.clear());
       }
     );
    };

    function request()       { return { type: rentConstants.RENT_REMOVE_REQUEST        }}
    function success(result) { return { type: rentConstants.RENT_REMOVE_SUCCESS, payload: result}}
    function failure()  { return { type: rentConstants.RENT_REMOVE_FAILURE}}
}

export function _initRent() {
  return dispatch => {dispatch(success());};
  function success() { return { type: rentConstants.RENT_INIT_SUCCESS}}
}

export function _viewRent(id) {
  return dispatch => {
      dispatch(request({id}));

      rentService._view(id)
          .then(
              response => {
                  dispatch(success(response));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.VIEW_RENT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: rentConstants.RENT_VIEW_REQUEST} }
  function success(result) { return { type: rentConstants.RENT_VIEW_SUCCESS,  payload: result } }
  function failure()  { return { type: rentConstants.RENT_VIEW_FAILURE}}
}

export function action_to_count_rent() {
  return dispatch => {
      dispatch(request());
      rentService._count()
          .then(
              response => {
                  dispatch(success(response[0].data_result));
              },
              error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.COUNT_RENT_ERROR));
                  dispatch(alertActions.clear());
              }
          );
  };

  function request()       { return { type: rentConstants.RENT_COUNT_REQUEST} }
  function success(result) { return { type: rentConstants.RENT_COUNT_SUCCESS,  payload: result } }
  function failure()  { return { type: rentConstants.RENT_COUNT_FAILURE}}
}

export function _listRent(data) {
    return dispatch => {
        dispatch(request(data));
        rentService._list(data)
            .then(
                response => {
                  dispatch(success(response,data.current_page));
                },
                error => {
                  dispatch(failure());
                  dispatch(alertActions.error(infoConstants.LIST_RENT_ERROR));
                  dispatch(alertActions.clear());
                }
            );
    };

    function request()              { return { type: rentConstants.RENT_GET_LIST_REQUEST         }}
    function success(result, page)  { return { type: rentConstants.RENT_GET_LIST_SUCCESS, payload: {data: result, page: page} }}
    function failure()         { return { type: rentConstants.RENT_GET_LIST_FAILURE}}
}
export function _lookupRent() {
  return dispatch => {
    dispatch(request());
    rentService._lookup()
        .then(
            response => {
              dispatch(success(response));
            },
            error => {
              dispatch(failure());
              dispatch(alertActions.error(infoConstants.LOOKUP_RENT_ERROR));
              dispatch(alertActions.clear());
            }
        );
  };

    function request()       { return { type: rentConstants.RENT_LOOKUP_REQUEST         }}
    function success(result) { return { type: rentConstants.RENT_LOOKUP_SUCCESS,  payload: result }}
    function failure()  { return { type: rentConstants.RENT_LOOKUP_FAILURE}}
}

export function _activateEditRent(data) {
  console.log('[rent.action][_activateEditRent][started]');
  const param = [{
      rent_id        : data[0].rent_id,
      trxType           : 'edt',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_showRentModalForm('show'))
    dispatch(_sendRentFormParam(param));
  }
}

export function _activateAddRent() {
  console.log('[rent.action][_activateAddRent][started]');
  const param = [{
      rent_id        : 0,
      trxType           : 'add',
      trxText           : 'Simpan'
  }];
  return dispatch => {
    dispatch(_initRent());
    dispatch(_showRentModalForm('show'));
    dispatch(_sendRentFormParam(param));
  }
}

export function _activateFilterRent() {
  console.log('[rent.action][_activateFilterRent][started]');
  const param = [{
      rent_id        : 0,
      trxType           : 'filter',
      trxText           : 'Search'
  }];
  return dispatch => {
    dispatch(_initRent());
    dispatch(_showRentModalForm('show'));
    dispatch(_sendRentFormParam(param));
  }
}

export function _onRentFormFieldChange (field, value) {
  console.log('[rent.action][onRentFormFieldChange][value|field]:' + value + '|' + field);
  return {
    type: rentConstants.ON_RENT_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _onRentStateChange (field, value) {
  return {
    type: rentConstants.ON_RENT_STATE_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _showRentModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:rentConstants.RENT_SHOW_MODAL, payload: showModal}}
}

export function _sendRentFormParam(param) {
  return dispatch => {
    dispatch(success(param))
  }
  function success(param) { return {type: rentConstants.RENT_SEND_PARAM, payload: param}}
}

export function _showRentLookupModalForm(showModal) {
  return dispatch => {
    dispatch(success(showModal))
  }
  function success(showModal) { return {type:rentConstants.RENT_SHOW_LOOKUP_MODAL, payload: showModal}}
}

export function action_to_import_rent(data) {
      const param = {
        rent_id                                   : '', 
        rent_description                          : '', 
        rent_fee                                  : '', 
        late_fee                                  : '', 
        lease_id                                  : '', 
        page_size                                 : 10,
        current_page                              : 1,
        sort_field                                : 'rent_id',
        sort_order                                : 'descend',
      }

 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  rentService.service_to_import_rent(data)
    .then(
      response => {
        if(response.status){
          dispatch(_listRent(param));
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_RENT_IMPORT_SUCCESS));
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_RENT_IMPORT_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_RENT_IMPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: rentConstants.RENT_IMPORT_REQUEST         }}
    function success(result)  { return { type: rentConstants.RENT_IMPORT_SUCCESS, payload: result }}
    function failure()   { return { type: rentConstants.RENT_IMPORT_FAILURE}}
}

export function action_to_export_rent() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  rentService.service_to_export_rent()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_RENT_EXPORT_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(response.message));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_RENT_EXPORT_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: rentConstants.RENT_EXPORT_REQUEST         }}
    function success(result)  { return { type: rentConstants.RENT_EXPORT_SUCCESS, payload: result }}
    function failure()   { return { type: rentConstants.RENT_EXPORT_FAILURE}}
}

export function action_to_get_sample_rent() {
 return dispatch => {
  dispatch(alertActions.clear());
  dispatch(request());
  rentService.service_to_get_sample_rent()
    .then(
      response => {
        if(response[0].status){
          dispatch(success(response));
          dispatch(alertActions.success(infoConstants.INFO_RENT_GET_SAMPLE_FILE_SUCCESS))
          dispatch(alertActions.clear());
        } else {
          dispatch(failure());
          dispatch(alertActions.error(infoConstants.INFO_RENT_GET_SAMPLE_FILE_ERROR));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(failure());
        dispatch(alertActions.error(infoConstants.INFO_RENT_GET_SAMPLE_FILE_ERROR));
        dispatch(alertActions.clear());
      }
     );
    };
    function request()        { return { type: rentConstants.RENT_GET_SAMPLE_FILE_REQUEST         }}
    function success(result)  { return { type: rentConstants.RENT_GET_SAMPLE_FILE_SUCCESS, payload: result }}
    function failure()   { return { type: rentConstants.RENT_GET_SAMPLE_FILE_FAILURE}}
}

