// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as rentActions                                from '../_actions/rent.actions';
import * as leaseActions                               from './../_actions/lease.actions';
import LeaseEdit                                       from './../lease/edit';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     rent: {
       gpRentShowModal                            : state.rent.form.gpRentShowModal,
       gpRentModalParam                           : state.rent.form.gpRentModalParam,
       gpRentIsFetching                           : state.rent.form.gpRentIsFetching,
       gpDataFields                               : state.rent.form.gpDataFields,
       gpRentIsValid                              : state.rent.form.gpRentIsValid,
     },
     lease: {
      gpDataFields                                : state.lease.form.gpDataFields,
      gpleaseIsFetching                           : state.lease.form.gpleaseIsFetching,
     },
     lease_incoming: {
       gpLeaseLookupList                          : state.lease.form.gpLeaseLookupList,
       gpLeaseError                               : state.lease.form.gpLeaseError,
       gpLeaseIsFetching                          : state.lease.form.gpLeaseIsFetching,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...rentActions,
      ...leaseActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
          lease_id                                : this.props.lease.gpDataFields.lease_id,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editRent(this.props.match.params.rent_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addRent());
      }

      this.props.actions._onRentFormFieldChange('lease_id', this.props.lease.gpDataFields.lease_id);
    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.rent.gpRentModalParam) {
        if (nextProps.rent.gpRentModalParam !== this.props.rent.gpRentModalParam) {
          console.log('Information: gpRentModalParam props changed');
          if(nextProps.rent.gpRentModalParam['trxType']==='edt') {
            this.setState({
              rent_id      : nextProps.rent.gpRentModalParam['rent_id'],
              trxType          : nextProps.rent.gpRentModalParam['trxType'],
            }, this._editRent(nextProps.rent.gpRentModalParam['rent_id']));
          };

          if(nextProps.rent.gpRentModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.rent.gpRentModalParam['trxType'],
            }, this._addRent());
          };

          if(nextProps.rent.gpRentModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.rent.gpRentModalParam['trxType'],
            }, this._filterRent());
          };

        };
      };

      if(nextProps.rent.gpRentShowModal) {
        if (nextProps.rent.gpRentShowModal !== this.props.rent.gpRentShowModal) {
          console.log('Information: gpRentShowModal props changed');
          if(nextProps.rent.gpRentShowModal==='show'){
            this.setState({
              lsRentShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.rent.gpRentShowModal==='hide'){
            this.setState({
              lsRentShowModal: false,
            });
          }
        };
      };

      this.setState({
        rent_id                                   : nextProps.rent.gpDataFields.rent_id,
        rent_description                          : nextProps.rent.gpDataFields.rent_description,
        rent_fee                                  : nextProps.rent.gpDataFields.rent_fee,
        late_fee                                  : nextProps.rent.gpDataFields.late_fee,
        due_date                                  : nextProps.rent.gpDataFields.due_date,
        lease_id                                  : nextProps.rent.gpDataFields.lease_id,
        lease_lease_id                            : nextProps.rent.gpDataFields.lease_lease_id,
        date_add                                  : nextProps.rent.gpDataFields.date_add,
        date_upd                                  : nextProps.rent.gpDataFields.date_upd,
      });

}
_addLease() {
  this.props.actions._activateAddLease();
}

_addRentOnServer() {
    var id1 = this.state.rent_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(5);
    } else {
      id3 = this.state.rent_id;
    }
    const data = {
        rent_id                                   : id3,
        rent_description                          : this.state.rent_description,
        rent_fee                                  : this.state.rent_fee,
        late_fee                                  : this.state.late_fee,
        due_date                                  : this.state.due_date,
        lease_id                                  : this.state.lease_id,
        lease_lease_id                            : this.state.lease_lease_id,
    }
    this.props.actions._addRent(data);
    this.goBack();
}

_editRentOnServer() {
    const data = {
        rent_id                                   : this.state.rent_id,
        rent_description                          : this.state.rent_description,
        rent_fee                                  : this.state.rent_fee,
        late_fee                                  : this.state.late_fee,
        due_date                                  : this.state.due_date,
        lease_id                                  : this.state.lease_id,
        lease_lease_id                            : this.state.lease_lease_id,
    }
    this.props.actions._editRent(data);
    this.goBack();
}

_filterRentOnServer() {
    const data = {
        rent_id                                   : this.state.rent_id,
        rent_description                          : this.state.rent_description,
        rent_fee                                  : this.state.rent_fee,
        late_fee                                  : this.state.late_fee,
        due_date                                  : this.state.due_date,
        lease_id                                  : this.state.lease_id,
        lease_lease_id                            : this.state.lease_lease_id,
    }
    this.props.actions._filterRent(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit rent                                                              */
/* -------------------------------------------------------------------------- */
_editRent(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewRent(id);
  this.props.actions._lookupLease();
}

/* -------------------------------------------------------------------------- */
/*  add new rent                                     
/* -------------------------------------------------------------------------- */
_addRent() {
    let today = GF.today();
    this.props.actions._onRentFormFieldChange('rent_id', '')
    this.props.actions._onRentFormFieldChange('rent_description', '')
    this.props.actions._onRentFormFieldChange('rent_fee', '')
    this.props.actions._onRentFormFieldChange('late_fee', '')
    this.props.actions._onRentFormFieldChange('due_date', '')
    this.props.actions._onRentFormFieldChange('due_date', today)
    this.props.actions._initRent();

    this.setState({
         rent_id                                  : '',
         rent_description                         : '',
         rent_fee                                 : '',
         late_fee                                 : '',
         due_date                                 : today,
        lease_id                                  : this.state.lease_id,
         isIDDisabled                             : false,
         lsRentDetail                             : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
  this.props.actions._lookupLease();
}

/* -------------------------------------------------------------------------- */
/*  filter rent                                     
/* -------------------------------------------------------------------------- */
_filterRent() {

    let today = GF.today();
    this.setState({
         rent_id                                  : '',
         rent_description                         : '',
         rent_fee                                 : '',
         late_fee                                 : '',
         due_date                                 : today,
         lsRentDetail                             : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
  this.props.actions._lookupLease();
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showRentModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addRentOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editRentOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterRentOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showRentModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[rent.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onRentFormFieldChange(field, value)
}

onDue_dateChange = (value) => {
  const lvValue   = moment(value, dateFormat).format(dateFormat);
  const lvField   = 'due_date';
     if (lvValue !== '') {
       this.props.actions._onRentFormFieldChange(lvField, lvValue)
     }
}

onLease_idChange = (e, data) => {
  const lvValue   = data.value;
  const lvField   = 'lease_id';
     if (lvValue !== '') {
       this.props.actions._onRentFormFieldChange(lvField, lvValue)
     }
}


  handleChange_ = (data, ev) => {
    const { name, value } = ev.target;
    this.onChange({
      ...data,
      [name]: value
    });
  };

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsRentShowModal,
    } = this.state;


     const {
       rent_id,
       rent_description,
       rent_fee,
       late_fee,
       due_date,
       lease_lease_id,
       lease_id,
       lease_id_has_error,
       lease_id_error_msg,
       date_add,
       date_upd,
       rent_id_has_error,
       rent_description_has_error,
       rent_fee_has_error,
       late_fee_has_error,
       due_date_has_error,
       date_add_has_error,
       date_upd_has_error,
       rent_id_error_msg,
       rent_description_error_msg,
       rent_fee_error_msg,
       late_fee_error_msg,
       due_date_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.rent.gpDataFields;


const listLeaseOptions = _.map(this.props.lease_incoming.gpLeaseLookupList, (Lease, index) => ({
  key: Lease.value,
  text: Lease.value  + ' - ' + Lease.label,
  value: Lease.value,
}))

const DropdownLease_Lease_Id = () => (
  <Dropdown onChange={this.onLease_idChange} placeholder='Lease' value={lease_id} search options={listLeaseOptions} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'/>
)

const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Rent'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add rent start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add rent body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Invoice Sewa
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={6}>
          <b>ID Penyewaan</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='rent_id'
              placeholder='[AUTO]'
              value={rent_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {rent_id_has_error?<div style={{color:'lightcoral'}}>{rent_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Jenis Penyewaan</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='rent_description'
              placeholder='Masukan Jenis Penyewaan'
              value={rent_description}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {rent_description_has_error?<div style={{color:'lightcoral'}}>{rent_description_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Biaya Sewa</b><br />
          <div className='spacer' />
          <NumericFormat
            value={rent_fee}
            customInput={Input}
            name = 'rent_fee'
            disabled={false}
            prefix='Rp '
            placeholder='Masukan Biaya Sewa'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onRentFormFieldChange('rent_fee', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {rent_fee_has_error?<div style={{color:'lightcoral'}}>{rent_fee_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Biaya Keterlambatan</b><br />
          <div className='spacer' />
          <NumericFormat
            value={late_fee}
            customInput={Input}
            name = 'late_fee'
            disabled={false}
            prefix='Rp '
            placeholder='Masukan Biaya Keterlambatan'
            thousandSeparator='.'
            valueIsNumericString = {true}
            decimalSeparator=','
            decimalScale={2}
            onValueChange={(values, sourceInfo) => {
              this.props.actions._onRentFormFieldChange('late_fee', values.value)
            }}
            onPressEnter={this.handleModalSubmit.bind(this)}
            />
          {late_fee_has_error?<div style={{color:'lightcoral'}}>{late_fee_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={6}>
          <b>Tanggal Jatuh Tempo</b><br />
          <div className='spacer' />
          <DatePicker
              placeholder='Pilih Tanggal'
              disabled={false}
              onChange={this.onDue_dateChange.bind(this)}
              value={moment(due_date,dateFormat)}
              format={dateFormat}
          />

          {due_date_has_error?<div style={{color:'lightcoral'}}>{due_date_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
         <Col lg={6} md={4}>
           <b>ID Rumah Sewa</b><br />
           <div className='spacer' />
           <DropdownLease_Lease_Id />
           &nbsp;
           <Link className='text-blue-900' to='/lease/add/'>
             <button type='button' className='inline-flex h-10 justify-center rounded-md border text-center border-transparent bg-blue-100 px-2 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'>
               <Icon name='plus' />
             </button>
           </Link>
           <div className='spacer' />
           <br />
         </Col>
        </Row>
        <div className='spacer' />
        <br />
        <Row><Col span={20}></Col><Col span={4}>
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.rent.gpRentIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
        </Col></Row>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add rent body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add rent End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
