const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        rent_id                                   : '',
        rent_description                          : '',
        rent_fee                                  : '',
        late_fee                                  : '',
        due_date                                  : '',
        lease_id                                  : '',
        lease_description                         : '',
        search_rent_id                            : '',
        search_rent_description                   : '',
        search_rent_fee                           : '',
        search_late_fee                           : '',
        search_lease_id                           : '',
        lease_lease_id                            : '',
   }))(),
   gpRentDataList                                 : [],
   gpRentDataListNumRows                          : 0,
   gpRentLookupList                               : [],
   gpRentIsDisabled                               : false,
   gpRentError                                    : null,
   gpRentIsValid                                  : true,
   gpRentIsFetching                               : false,
   rent_count                                     : 0,
   rent_active_tab                                : 0,
   gpRentShowModal                                : 'hide',
   gpRentModalParam                               : [],
   gpRentLookupShowModal                          : 'hide',
   gpDataFields: new (Record({
        lease_id                                  : '',
        lease_description                         : '',
        rent_id                                   : '',
        rent_description                          : '',
        rent_fee                                  : '',
        late_fee                                  : '',
        due_date                                  : '',
        search_rent_id                            : '',
        search_rent_description                   : '',
        search_rent_fee                           : '',
        search_late_fee                           : '',
        lease_lease_id                            : '',
        rent_id_has_error                         : false,
        rent_description_has_error                : false,
        rent_fee_has_error                        : false,
        late_fee_has_error                        : false,
        due_date_has_error                        : false,
        search_rent_id_has_error                  : false,
        search_rent_description_has_error         : false,
        search_rent_fee_has_error                 : false,
        search_late_fee_has_error                 : false,
        lease_lease_id_has_error                  : false,
        rent_id_error_msg                         : false,
        rent_description_error_msg                : false,
        rent_fee_error_msg                        : false,
        late_fee_error_msg                        : false,
        due_date_error_msg                        : false,
        search_rent_id_error_msg                  : false,
        search_rent_description_error_msg         : false,
        search_rent_fee_error_msg                 : false,
        search_late_fee_error_msg                 : false,
        lease_lease_id_error_msg                  : false,
        lease_id_has_error                        : false,
        lease_id_error_msg                        : false,
        search_lease_id                           : '',
        lease_id                                  : '',
   }))(),
 })
 var rentInitialState = Record({
   form: new Form()
 })
export default rentInitialState
