import { authHeader,
         authHeaderNoContentType
       } from '../_applib/_helpers';
import * as CONFIG                                    from './../_applib/_settings/config';
import {
  authenticateActions
}                                                     from '../_actions';

export const employeeService = {
   _add,
   _edit,
   _filter,
   _view,
   _count,
   _list,
   _lookup,
   _remove,
   service_to_export_employee,
   service_to_import_employee,
   service_to_get_sample_employee,
   _chart0,
   get_employee_report_excel_000,
   get_employee_report_pdf_000,
   get_employee_report_html_000,
   get_employee_report_excel_001,
   get_employee_report_pdf_001,
   get_employee_report_html_001,
}

const targetURL       = CONFIG.URL + 'employee';
const download        = require('downloadjs');

function _add(data) {
  const options = {
    method    : 'POST',
    headers   : { ...authHeader()},
    body      : JSON.stringify(data)
  };
  return fetch(targetURL, options)
    .then(_handleResponse)
    .then(_returnResponse);
};

function _edit(data) {
  const options = {
    method    : 'PATCH',
    headers   : { ...authHeader()},
    body      : JSON.stringify(data)
  };
  return fetch(targetURL +
      '/' + data.employee_id, options)
    .then(_handleResponse)
    .then(_returnResponse);
};

function _filter(data) {
  const options = {
    method    : 'POST',
    headers   : { ...authHeader()},
    body      : JSON.stringify(data)
  };
  return fetch(targetURL, options)
    .then(_handleResponse)
    .then(_returnResponse);
};

function _chart0(chart_type) {
  const OPTIONS = _requestHeader('GET');
  let PATH = targetURL + '?type=' + chart_type;
  return fetch(PATH, OPTIONS)
    .then(_handleResponse)
    .then(_returnResponse);
};

async function get_employee_report_excel_000(data) {
  const options = _requestHeader('GET');
  const res = await fetch(targetURL + '/report/excel/r000' , options)
  const blob = await res.blob();
  const today = new Date();

  const file_date = [
    today.getDate().toString().padStart(2,'0'),
    (today.getMonth() + 1).toString().padStart(2,'0'),
    today.getFullYear(),
  ].join('_');

  const file_name = 'employee_' + file_date + '.xlsx';
  download(blob, file_name);
  return [{'status':true}];
};


async function get_employee_report_pdf_000(data) {
  const options   = _requestHeader('GET');
  const res       = await fetch(targetURL + '/report/pdf/' + data, options);
  const blob      = await res.blob();
  const file_name = 'employee_report.pdf';
  download(blob, file_name);
  return [{'status':true}];
};


function get_employee_report_html_000(data) {
  const options = _requestHeader('GET');
  return fetch(targetURL + '/report/hrml/r000', options)
    .then(_handleResponse)
    .then(_returnResponse);
};


async function get_employee_report_excel_001(data) {
  const options = _requestHeader('GET');
  const res = await fetch(targetURL + '/report/excel/r001' , options)
  const blob = await res.blob();
  const today = new Date();

  const file_date = [
    today.getDate().toString().padStart(2,'0'),
    (today.getMonth() + 1).toString().padStart(2,'0'),
    today.getFullYear(),
  ].join('_');

  const file_name = 'employee_' + file_date + '.xlsx';
  download(blob, file_name);
  return [{'status':true}];
};


async function get_employee_report_pdf_001(data) {
  const options   = _requestHeader('GET');
  const res       = await fetch(targetURL + '/report/pdf/' + data, options);
  const blob      = await res.blob();
  const file_name = 'employee_report.pdf';
  download(blob, file_name);
  return [{'status':true}];
};


function get_employee_report_html_001(data) {
  const options = _requestHeader('GET');
  return fetch(targetURL + '/report/hrml/r001', options)
    .then(_handleResponse)
    .then(_returnResponse);
};


function _remove(id) {
  const options = _requestHeader('DELETE');
  return fetch(targetURL  + '/' + id, options)
    .then(_handleResponse)
    .then(_returnResponse);
}


function _view(id) {
  const options = _requestHeader('GET');
  let path = targetURL + '/' + id;
  return fetch(path, options)
    .then(_handleResponse)
    .then(_returnResponse);
}

function _count() {
  const options = _requestHeader('GET');
  return fetch(targetURL +
      '?type=count', options)
  .then(_handleResponse)
  .then(_returnResponse);
}

function _list (data) {
  const options = _requestHeader('GET');
  let searchText = ''; //searchText.replace(/ /g,'_');
       console.log('employee.service.employee_id: ' + JSON.stringify(data.employee_id));
       console.log('employee.service.employee_name: ' + JSON.stringify(data.employee_name));
  return fetch(targetURL +
      '?type=list' +
      '&pagesize=' + data.page_size +
      '&currentpage=' + data.current_page +
      '&sortfield=' + data.sort_field +
      '&employee_id=' + data.employee_id + 
      '&employee_name=' + data.employee_name + 
      '&complex_id=' + data.complex_id + 
      '&sortorder=' + data.sort_order, options)
  .then(_handleResponse)
  .then(_returnResponse);
}

function _lookup() {
  const options = _requestHeader('GET');
  return fetch(targetURL + '_lookup', options)
    .then(_handleResponse)
    .then(_returnResponse);
  }

async function  service_to_export_employee(){
  const options = _requestHeader('GET');
  const res = await fetch(targetURL + '_export', options);
  const blob = await res.blob();
  const today = new Date();

  const file_date = [
    today.getDate().toString().padStart(2,'0'),
    (today.getMonth() + 1).toString().padStart(2,'0'),
    today.getFullYear(),
  ].join('_');

  const file_name = 'employee_' + file_date + '.xlsx';
  download(blob, file_name);
  return [{'status':true}];
}

function service_to_import_employee(data) {
  const options = {
    method    : 'POST',
    headers   : { ...authHeaderNoContentType()},
    body      : data
  };
  return fetch(targetURL + '_import', options)
    .then(_handleResponse)
    .then(_returnResponse);
}

async function service_to_get_sample_employee(){
  const options = _requestHeader('GET');
  const res = await fetch(targetURL + '_get_sample_employee', options);
  const blob = await res.blob();

  const file_name = 'employee.csv';
  download(blob, file_name);
  return [{'status':true}];
}

function _requestHeader(type) {
  const header = {
    method  : type,
    headers : { ...authHeader()},
  };

  return header;
}

function _handleResponse(response) {
  return response.json();
}

function _returnResponse(response) {
  if(response.code=='E.AUT.001'){
    console.log('[DEBUG] Login Expired');
    console.log('[DEBUG] Response: ' + JSON.stringify(response));
    authenticateActions.logout('Session Expired');
  } else {
    return response;
  }
}
