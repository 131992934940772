'use strict'
export default function formValidation (state) {
  if (
        !state.form.gpDataFields.company_id_has_error &&
        state.form.gpDataFields.company_name !== '' &&
        !state.form.gpDataFields.company_name_has_error &&
        state.form.gpDataFields.address !== '' &&
        !state.form.gpDataFields.address_has_error &&
        state.form.gpDataFields.phone !== '' &&
        !state.form.gpDataFields.phone_has_error &&
        state.form.gpDataFields.email !== '' &&
        !state.form.gpDataFields.email_has_error &&
        true
   )
  {
    return state.setIn(['form', 'gpCompanyIsValid'], true)
  } else {
    return state.setIn(['form', 'gpCompanyIsValid'], false)
  }
}
