const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        employee_id                               : '',
        employee_name                             : '',
        complex_id                                : '',
        complex_name                              : '',
        search_employee_id                        : '',
        search_employee_name                      : '',
        search_complex_id                         : '',
        complex_complex_id                        : '',
   }))(),
   gpEmployeeDataList                             : [],
   gpEmployeeDataListNumRows                      : 0,
   gpEmployeeLookupList                           : [],
   gpEmployeeIsDisabled                           : false,
   gpEmployeeError                                : null,
   gpEmployeeIsValid                              : true,
   gpEmployeeIsFetching                           : false,
   employee_count                                 : 0,
   employee_active_tab                            : 0,
   gpEmployeeReport000Path                        : '',
   gpEmployeeReport000IsFetching                  : false,
   gpEmployeeReport001Path                        : '',
   gpEmployeeReport001IsFetching                  : false,
   gpEmployeeChart0DataList                       : [],
   gpEmployeeChart0Error                          : null,
   gpEmployeeChart0IsFetching                     : false,
   gpEmployeeShowModal                            : 'hide',
   gpEmployeeModalParam                           : [],
   gpEmployeeLookupShowModal                      : 'hide',
   gpDataFields: new (Record({
        complex_id                                : '',
        complex_name                              : '',
        employee_id                               : '',
        employee_name                             : '',
        search_employee_id                        : '',
        search_employee_name                      : '',
        complex_complex_id                        : '',
        employee_id_has_error                     : false,
        employee_name_has_error                   : false,
        search_employee_id_has_error              : false,
        search_employee_name_has_error            : false,
        complex_complex_id_has_error              : false,
        employee_id_error_msg                     : false,
        employee_name_error_msg                   : false,
        search_employee_id_error_msg              : false,
        search_employee_name_error_msg            : false,
        complex_complex_id_error_msg              : false,
        complex_id_has_error                      : false,
        complex_id_error_msg                      : false,
        search_complex_id                         : '',
        complex_id                                : '',
   }))(),
 })
 var employeeInitialState = Record({
   form: new Form()
 })
export default employeeInitialState
