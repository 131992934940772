export const companyConstants = {
    ON_COMPANY_FORM_FIELD_CHANGE                  : 'ON_COMPANY_FORM_FIELD_CHANGE',
    CHANGE_COMPANY_FIELD                          : 'CHANGE_COMPANY_FIELD',
    ON_COMPANY_STATE_CHANGE                       : 'ON_COMPANY_STATE_CHANGE',

    COMPANY_GET_LIST_REQUEST                      : 'COMPANY_GET_LIST_REQUEST',
    COMPANY_GET_LIST_SUCCESS                      : 'COMPANY_GET_LIST_SUCCESS',
    COMPANY_GET_LIST_FAILURE                      : 'COMPANY_GET_LIST_FAILURE',

    COMPANY_VIEW_REQUEST                          : 'COMPANY_VIEW_REQUEST',
    COMPANY_VIEW_SUCCESS                          : 'COMPANY_VIEW_SUCCESS',
    COMPANY_VIEW_FAILURE                          : 'COMPANY_VIEW_FAILURE',

    COMPANY_ADD_REQUEST                           : 'COMPANY_ADD_REQUEST',
    COMPANY_ADD_SUCCESS                           : 'COMPANY_ADD_SUCCESS',
    COMPANY_ADD_FAILURE                           : 'COMPANY_ADD_FAILURE',

    COMPANY_CHANGE_REQUEST                        : 'COMPANY_CHANGE_REQUEST',
    COMPANY_CHANGE_SUCCESS                        : 'COMPANY_CHANGE_SUCCESS',
    COMPANY_CHANGE_FAILURE                        : 'COMPANY_CHANGE_FAILURE',

    COMPANY_FILTER_REQUEST                        : 'COMPANY_FILTER_REQUEST',
    COMPANY_FILTER_SUCCESS                        : 'COMPANY_FILTER_SUCCESS',
    COMPANY_FILTER_FAILURE                        : 'COMPANY_FILTER_FAILURE',

    COMPANY_REMOVE_REQUEST                        : 'COMPANY_REMOVE_REQUEST',
    COMPANY_REMOVE_SUCCESS                        : 'COMPANY_REMOVE_SUCCESS',
    COMPANY_REMOVE_FAILURE                        : 'COMPANY_REMOVE_FAILURE',

    COMPANY_LOOKUP_REQUEST                        : 'COMPANY_LOOKUP_REQUEST',
    COMPANY_LOOKUP_SUCCESS                        : 'COMPANY_LOOKUP_SUCCESS',
    COMPANY_LOOKUP_FAILURE                        : 'COMPANY_LOOKUP_FAILURE',

    COMPANY_SHOW_MODAL                            : 'COMPANY_SHOW_MODAL',
    COMPANY_SEND_PARAM                            : 'COMPANY_SEND_PARAM',
    COMPANY_INITIALIZE_DATA                       : 'COMPANY_INITIALIZE_DATA',
    COMPANY_SHOW_LOOKUP_MODAL                     : 'COMPANY_SHOW_LOOKUP_MODAL',
    COMPANY_INIT_SUCCESS                          : 'COMPANY_INIT_SUCCESS',

    COMPANY_IMPORT_REQUEST                        : 'COMPANY_IMPORT_REQUEST',
    COMPANY_IMPORT_SUCCESS                        : 'COMPANY_IMPORT_SUCCESS',
    COMPANY_IMPORT_FAILURE                        : 'COMPANY_IMPORT_FAILURE',

    COMPANY_EXPORT_REQUEST                        : 'COMPANY_EXPORT_REQUEST',
    COMPANY_EXPORT_SUCCESS                        : 'COMPANY_EXPORT_SUCCESS',
    COMPANY_EXPORT_FAILURE                        : 'COMPANY_EXPORT_FAILURE',

    COMPANY_COUNT_REQUEST                         : 'COMPANY_COUNT_REQUEST',
    COMPANY_COUNT_SUCCESS                         : 'COMPANY_COUNT_SUCCESS',
    COMPANY_COUNT_FAILURE                         : 'COMPANY_COUNT_FAILURE',

    COMPANY_GET_SAMPLE_FILE_REQUEST               : 'COMPANY_GET_SAMPLE_FILE_REQUEST',
    COMPANY_GET_SAMPLE_FILE_SUCCESS               : 'COMPANY_GET_SAMPLE_FILE_SUCCESS',
    COMPANY_GET_SAMPLE_FILE_FAILURE               : 'COMPANY_GET_SAMPLE_FILE_FAILURE',

};

