import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import TenantEdit                                      from './edit';
import LeaseList                                        from './../lease/simple_data_list';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  tenantActions,
  leaseActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...tenantActions,
      ...leaseActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    tenant: {
      gpDataFields                                : state.tenant.form.gpDataFields,
      gpTenantIsFetching                          : state.tenant.form.gpTenantIsFetching,
      tenant_active_tab                           : state.tenant.form.tenant_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
        this.props.actions._onLeaseFormFieldChange('tenant_id', this.props.match.params.tenant_id);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsTenantIsFetching                          : true,
      tenant_id                                   : '',
      tenant_name                                 : '',
      email                                       : '',
      phone                                       : '',
      ktp                                         : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        tenant_id                                 : nextProps.tenant.gpDataFields.tenant_id,
        tenant_name                               : nextProps.tenant.gpDataFields.tenant_name,
        email                                     : nextProps.tenant.gpDataFields.email,
        phone                                     : nextProps.tenant.gpDataFields.phone,
        ktp                                       : nextProps.tenant.gpDataFields.ktp,
        date_upd                                  : nextProps.tenant.gpDataFields.date_upd,
        date_add                                  : nextProps.tenant.gpDataFields.date_add,
        lsTenantIsFetching                        : nextProps.tenant.gpTenantIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewTenant(this.props.match.params.tenant_id);
      this.props.actions._onLeaseFormFieldChange('tenant_id', this.props.tenant.gpDataFields.tenant_id);
      this.setState({
        lsTenantIsFetching                        : this.props.tenant.gpTenantIsFetching,
        tenant_id                                 : this.props.tenant.gpDataFields.tenant_id,
        tenant_name                               : this.props.tenant.gpDataFields.tenant_name,
        email                                     : this.props.tenant.gpDataFields.email,
        phone                                     : this.props.tenant.gpDataFields.phone,
        ktp                                       : this.props.tenant.gpDataFields.ktp,
      });
    }


    _editTenant(id) {
      const data = [{
          tenant_id      : id,
      }];
      this.props.actions._activateEditTenant(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onTenantStateChange('tenant_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
        {
          menuItem: { key: 'lease', content: 'Sewa' },
          render: () => <Tab.Pane attached={false} ><LeaseList /></Tab.Pane>,
        },
      ]


      const {
        tenant_active_tab
      } = this.props.tenant;


      const {
        tenant_id,
        tenant_name,
        email,
        phone,
        ktp,
        date_add,
        date_upd
      } = this.props.tenant.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Penyewa
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/tenant/edit/' + tenant_id}
                onClick = {() => this._editTenant(tenant_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Penyewa</span><br />
                  {tenant_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Nama Tenant</span><br />
                  {tenant_name}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Email</span><br />
                  {email}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Phone</span><br />
                  {phone}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Nomor KTP</span><br />
                  {ktp}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
            <div className='spacer' />
            <div>
              <Tab menu={{borderless:true, pointing: true}} onTabChange={this.handleTabChange.bind(this)} activeIndex={tenant_active_tab} panes={panes} />
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

