var SERVER = 'http://ameemo.com/';
var DEBUG  = true;

export const IMAGE_PATH          = SERVER + 'images/';
export const URL                 = SERVER;


export const NOTIFICATION  = {
  'LOGIN_SUCCESS': true,
  'LOGIN_FAILED': 'Login Gagal.',
  '003': 'Registrasi berhasil.',
};


export function LOG(txt) {
  if (DEBUG){
    console.log('[DEBUG] - ' + txt);
  }
};


export function userAuthority() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.userlevel) {
        return user.userlevel
    } else {
        return [];
    }
}


export function getfullname() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.fullname) {
        return user.fullname
    } else {
        return [];
    }
}

export function getIsLoggedIn() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        return true
    } else {
        return false
    }
}

export function getMoney(number) {
  return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
}

export function getNumber(number) {
  return new Intl.NumberFormat('id-ID', { style: 'decimal'}).format(number);
}

export function getPercent(number) {
  return new Intl.NumberFormat('id-ID', { style: 'percent'}).format(number);
}

export function getBoolean(number) {
  let val = '-';
  if(number == 1) {
    val = 'Ya';
  } else {
    val = 'Tidak';
  }

  return val;
}

