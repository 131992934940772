    import React, { Component }                            from 'react';                                                                                            
    import { bindActionCreators }                          from 'redux';
    import { connect }                                     from 'react-redux';
    import { Link }                                        from 'react-router-dom';
    import {
      apartmentActions,
    }                                                      from './../../_actions';

    import {
      Button,
      Icon,
    }                                                      from 'semantic-ui-react';

    import {
      Row,
      Col,
    }                                                      from 'antd';

    import {
           FaHome,
    }                                                      from 'react-icons/fa';

    import ReportApartment000                                    from './reportapartment000';
    import ReactToPrint from 'react-to-print';

    function mapDispatchToProps (dispatch) {
      return {
        actions: bindActionCreators({
          ...apartmentActions,
          }
          , dispatch)
      }
    }

    function mapStateToProps(state) {
        return {
          apartment: {
              gpApartmentDataList        : state.apartment.form.gpApartmentDataList,
              gpApartmentDataListNumRows : state.apartment.form.gpApartmentDataListNumRows,
              gpApartmentIsFetching      : state.apartment.form.gpApartmentIsFetching,
              gpApartmentShowModal       : state.apartment.form.ggApartmenttShowModal,
            },
          global: {
            currentUser : state.global.currentUser
          }
        };
    }


    class ReportView extends Component {
        constructor(props) {
          super(props);
          this.goBack = this.goBack.bind(this);
          this._print = this._print.bind(this);
        }

        state = {
        search_apartment_id                       : '',
        search_apartment_name                     : '',
        search_total_bedroom                      : '',
        search_rent_cost                          : '',
        lsApartmentIsFetching                     : true,
        lsApartmentDataList                       : [],
        lsPagination                              : {},
        lsPageSize                                : 10,
        lsCurrentPage                             : 1,
        lsSortedBy                                : 'apartment_id',
        lsSortOrder                               : 'descend',
        lsTotalPage                               : 0,
        lsNumberOfRows                            : 0,
        lsSearchText                              : '',
        lsLoadingApartment                        : true,
        direction                                 : null,
        building_id                               : '',
        features                                  : {
          width                                   : 1200,
          height                                  : 800,
        }
        }


        componentWillReceiveProps (nextProps) {

          if (nextProps.apartment.gpApartmentDataList){
            if (nextProps.apartment.gpApartmentDataList !== this.props.apartment.gpApartmentDataList) {
              this.setState({
                lsApartmentDataList              : nextProps.apartment.gpApartmentDataList,
                lsNumberOfRows              : nextProps.apartment.gpApartmentDataListNumRows,
              });

              const lsPagination = { ...this.state.pagination };
              lsPagination.total = nextProps.apartment.gpApartmentDataListNumRows*1;
              this.setState({
                pagination: lsPagination,
              });
            };
          };

        };


        componentDidMount(){
          this._loadApartmentDataSets();
        }


        goBack(){
          const { history } = this.props;
          history.push('/report');
        }


        _loadApartmentDataSets() {
          const data = {
          apartment_id                            : this.state.search_apartment_id,
          apartment_name                          : this.state.search_apartment_name,
          total_bedroom                           : this.state.search_total_bedroom,
          rent_cost                               : this.state.search_rent_cost,
          building_id                             : this.state.building_id, 
          page_size                               : this.state.lsPageSize,
          current_page                            : 1,
          sort_field                              : this.state.lsSortedBy,
          sort_order                              : this.state.lsSortOrder,
        }

          this.setState({
            lsCurrentPage                         : 1,
          }, this.props.actions._listApartment(data));
          }


      _print(){
        // console.log('[report.view][_print][window]:' + JSON.stringify(window));
        window.print();
      }


      render() {
        var index = 1;
        // Object.keys(this.state.lsApartmentDataList).forEach(function(key) {
        //   arr.push(this.state.lsApartmentDataList[key]);
        // });


        return (
          <div>
             <div className='text-2xl font-bold text-blue-600' >
               Laporan
             </div>
             <br />
             <Row>
               <Col span={6}>
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'>
                   <Icon name='angle left' />
                   Back
                 </Button>
                 &nbsp;&nbsp;
                 <ReactToPrint
                   trigger={() =>
                     <a href='#'>
                       <Button
                         color='green'
                         size='mini'><Icon name='print' /> Print
                       </Button>
                     </a>
                    }
                   content={() => this.cmpReportApartment000}
                 />
               </Col>
               <Col span={18}>
               </Col>
             </Row>
             <br />
             <div style={{overflowY : 'auto', boxShadow: 'inset 6px 6px #929495', fontSize: '14px', height:'450px', padding:'40px', backgroundColor:'#97999a'}}>
               <div style={{padding:'40px', backgroundColor:'#fff', boxShadow: '6px 6px #929495', borderWidth: '1px'}}>
                 <ReportApartment000 ref={el => (this.cmpReportApartment000 = el)} />
               </div>
               <br /><br />
             </div>
          </div>

        );
      }
    }

    export default connect(mapStateToProps, mapDispatchToProps)(ReportView);
