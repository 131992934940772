/* -------------------------------------------------------------------------- */
/* RENT.COUNT                                                                 */
/* Auto Generated On: 2019/10/19 11:59                                        */
/* Changes:                                                                   */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/*                                                                            */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* Import definition start                                                    */
/* -------------------------------------------------------------------------- */
import React, { Component }                           from 'react';
import { bindActionCreators }                         from 'redux';
import { connect }                                    from 'react-redux';
import {
  rentActions,
}                                                      from '../_actions';
import {
  Table,
}                                                     from 'antd';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {

  return {
    actions: bindActionCreators({
      ...rentActions,
      }
      , dispatch)
  }

}

function mapStateToProps(state) {
  return {
    process: {
      rent_count                              : state.rent.form.rent_count,
    }
  }
}

class Count extends Component {

  constructor(props){
      super(props);
  }

  state = {
    rent_count                          : 0,
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      rent_count                  : nextProps.process.rent_count,
    });
  }
  componentDidMount(){
    this.props.actions.action_to_count_rent();
    this.setState({
      rent_count                  : this.props.process.rent_count,
    });
  }

  render() {
    return (
      <div>
        <div style={{fontSize:'20px', border: '#cccccc 1px solid', textAlign:'center', width:'50px', cellPadding:'100px',cellSpacing:'100px', fontWeight:'bold'}} >
          <div><small><small>Count</small></small></div>
          {this.state.rent_count}
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Count);
