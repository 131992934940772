export const complexConstants = {
    ON_COMPLEX_FORM_FIELD_CHANGE                  : 'ON_COMPLEX_FORM_FIELD_CHANGE',
    CHANGE_COMPLEX_FIELD                          : 'CHANGE_COMPLEX_FIELD',
    ON_COMPLEX_STATE_CHANGE                       : 'ON_COMPLEX_STATE_CHANGE',

    COMPLEX_GET_LIST_REQUEST                      : 'COMPLEX_GET_LIST_REQUEST',
    COMPLEX_GET_LIST_SUCCESS                      : 'COMPLEX_GET_LIST_SUCCESS',
    COMPLEX_GET_LIST_FAILURE                      : 'COMPLEX_GET_LIST_FAILURE',

    COMPLEX_VIEW_REQUEST                          : 'COMPLEX_VIEW_REQUEST',
    COMPLEX_VIEW_SUCCESS                          : 'COMPLEX_VIEW_SUCCESS',
    COMPLEX_VIEW_FAILURE                          : 'COMPLEX_VIEW_FAILURE',

    COMPLEX_ADD_REQUEST                           : 'COMPLEX_ADD_REQUEST',
    COMPLEX_ADD_SUCCESS                           : 'COMPLEX_ADD_SUCCESS',
    COMPLEX_ADD_FAILURE                           : 'COMPLEX_ADD_FAILURE',

    COMPLEX_CHANGE_REQUEST                        : 'COMPLEX_CHANGE_REQUEST',
    COMPLEX_CHANGE_SUCCESS                        : 'COMPLEX_CHANGE_SUCCESS',
    COMPLEX_CHANGE_FAILURE                        : 'COMPLEX_CHANGE_FAILURE',

    COMPLEX_FILTER_REQUEST                        : 'COMPLEX_FILTER_REQUEST',
    COMPLEX_FILTER_SUCCESS                        : 'COMPLEX_FILTER_SUCCESS',
    COMPLEX_FILTER_FAILURE                        : 'COMPLEX_FILTER_FAILURE',

    COMPLEX_REMOVE_REQUEST                        : 'COMPLEX_REMOVE_REQUEST',
    COMPLEX_REMOVE_SUCCESS                        : 'COMPLEX_REMOVE_SUCCESS',
    COMPLEX_REMOVE_FAILURE                        : 'COMPLEX_REMOVE_FAILURE',

    COMPLEX_LOOKUP_REQUEST                        : 'COMPLEX_LOOKUP_REQUEST',
    COMPLEX_LOOKUP_SUCCESS                        : 'COMPLEX_LOOKUP_SUCCESS',
    COMPLEX_LOOKUP_FAILURE                        : 'COMPLEX_LOOKUP_FAILURE',

    COMPLEX_SHOW_MODAL                            : 'COMPLEX_SHOW_MODAL',
    COMPLEX_SEND_PARAM                            : 'COMPLEX_SEND_PARAM',
    COMPLEX_INITIALIZE_DATA                       : 'COMPLEX_INITIALIZE_DATA',
    COMPLEX_SHOW_LOOKUP_MODAL                     : 'COMPLEX_SHOW_LOOKUP_MODAL',
    COMPLEX_INIT_SUCCESS                          : 'COMPLEX_INIT_SUCCESS',

    COMPLEX_IMPORT_REQUEST                        : 'COMPLEX_IMPORT_REQUEST',
    COMPLEX_IMPORT_SUCCESS                        : 'COMPLEX_IMPORT_SUCCESS',
    COMPLEX_IMPORT_FAILURE                        : 'COMPLEX_IMPORT_FAILURE',

    COMPLEX_EXPORT_REQUEST                        : 'COMPLEX_EXPORT_REQUEST',
    COMPLEX_EXPORT_SUCCESS                        : 'COMPLEX_EXPORT_SUCCESS',
    COMPLEX_EXPORT_FAILURE                        : 'COMPLEX_EXPORT_FAILURE',

    COMPLEX_COUNT_REQUEST                         : 'COMPLEX_COUNT_REQUEST',
    COMPLEX_COUNT_SUCCESS                         : 'COMPLEX_COUNT_SUCCESS',
    COMPLEX_COUNT_FAILURE                         : 'COMPLEX_COUNT_FAILURE',

    COMPLEX_GET_SAMPLE_FILE_REQUEST               : 'COMPLEX_GET_SAMPLE_FILE_REQUEST',
    COMPLEX_GET_SAMPLE_FILE_SUCCESS               : 'COMPLEX_GET_SAMPLE_FILE_SUCCESS',
    COMPLEX_GET_SAMPLE_FILE_FAILURE               : 'COMPLEX_GET_SAMPLE_FILE_FAILURE',

};

