    import React, { Component }                            from 'react';                                                                                            
    import { bindActionCreators }                          from 'redux';
    import { connect }                                     from 'react-redux';
    import { Link }                                        from 'react-router-dom';
    import {
      employeeActions,
    }                                                      from './../../_actions';

    import {
      Button,
      Icon,
    }                                                      from 'semantic-ui-react';

    import {
      Row,
      Col,
    }                                                      from 'antd';

    import {
           FaHome,
    }                                                      from 'react-icons/fa';

    import ReportEmployee001                                    from './reportemployee001';
    import ReactToPrint from 'react-to-print';

    function mapDispatchToProps (dispatch) {
      return {
        actions: bindActionCreators({
          ...employeeActions,
          }
          , dispatch)
      }
    }

    function mapStateToProps(state) {
        return {
          employee: {
              gpEmployeeDataList        : state.employee.form.gpEmployeeDataList,
              gpEmployeeDataListNumRows : state.employee.form.gpEmployeeDataListNumRows,
              gpEmployeeIsFetching      : state.employee.form.gpEmployeeIsFetching,
              gpEmployeeShowModal       : state.employee.form.ggEmployeetShowModal,
            },
          global: {
            currentUser : state.global.currentUser
          }
        };
    }


    class ReportView extends Component {
        constructor(props) {
          super(props);
          this.goBack = this.goBack.bind(this);
          this._print = this._print.bind(this);
        }

        state = {
        search_employee_id                        : '',
        search_employee_name                      : '',
        lsEmployeeIsFetching                      : true,
        lsEmployeeDataList                        : [],
        lsPagination                              : {},
        lsPageSize                                : 10,
        lsCurrentPage                             : 1,
        lsSortedBy                                : 'employee_id',
        lsSortOrder                               : 'descend',
        lsTotalPage                               : 0,
        lsNumberOfRows                            : 0,
        lsSearchText                              : '',
        lsLoadingEmployee                         : true,
        direction                                 : null,
        complex_id                                : '',
        features                                  : {
          width                                   : 1200,
          height                                  : 800,
        }
        }


        componentWillReceiveProps (nextProps) {

          if (nextProps.employee.gpEmployeeDataList){
            if (nextProps.employee.gpEmployeeDataList !== this.props.employee.gpEmployeeDataList) {
              this.setState({
                lsEmployeeDataList              : nextProps.employee.gpEmployeeDataList,
                lsNumberOfRows              : nextProps.employee.gpEmployeeDataListNumRows,
              });

              const lsPagination = { ...this.state.pagination };
              lsPagination.total = nextProps.employee.gpEmployeeDataListNumRows*1;
              this.setState({
                pagination: lsPagination,
              });
            };
          };

        };


        componentDidMount(){
          this._loadEmployeeDataSets();
        }


        goBack(){
          const { history } = this.props;
          history.push('/report');
        }


        _loadEmployeeDataSets() {
          const data = {
          employee_id                             : this.state.search_employee_id,
          employee_name                           : this.state.search_employee_name,
          complex_id                              : this.state.complex_id, 
          page_size                               : this.state.lsPageSize,
          current_page                            : 1,
          sort_field                              : this.state.lsSortedBy,
          sort_order                              : this.state.lsSortOrder,
        }

          this.setState({
            lsCurrentPage                         : 1,
          }, this.props.actions._listEmployee(data));
          }


      _print(){
        // console.log('[report.view][_print][window]:' + JSON.stringify(window));
        window.print();
      }


      render() {
        var index = 1;
        // Object.keys(this.state.lsEmployeeDataList).forEach(function(key) {
        //   arr.push(this.state.lsEmployeeDataList[key]);
        // });


        return (
          <div>
             <div className='text-2xl font-bold text-blue-600' >
               Laporan
             </div>
             <br />
             <Row>
               <Col span={6}>
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'>
                   <Icon name='angle left' />
                   Back
                 </Button>
                 &nbsp;&nbsp;
                 <ReactToPrint
                   trigger={() =>
                     <a href='#'>
                       <Button
                         color='green'
                         size='mini'><Icon name='print' /> Print
                       </Button>
                     </a>
                    }
                   content={() => this.cmpReportEmployee001}
                 />
               </Col>
               <Col span={18}>
               </Col>
             </Row>
             <br />
             <div style={{overflowY : 'auto', boxShadow: 'inset 6px 6px #929495', fontSize: '14px', height:'450px', padding:'40px', backgroundColor:'#97999a'}}>
               <div style={{padding:'40px', backgroundColor:'#fff', boxShadow: '6px 6px #929495', borderWidth: '1px'}}>
                 <ReportEmployee001 ref={el => (this.cmpReportEmployee001 = el)} />
               </div>
               <br /><br />
             </div>
          </div>

        );
      }
    }

    export default connect(mapStateToProps, mapDispatchToProps)(ReportView);
