import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import BuildingEdit                                    from './edit';
import ApartmentList                                    from './../apartment/simple_data_list';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  buildingActions,
  apartmentActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...buildingActions,
      ...apartmentActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    building: {
      gpDataFields                                : state.building.form.gpDataFields,
      gpBuildingIsFetching                        : state.building.form.gpBuildingIsFetching,
      building_active_tab                         : state.building.form.building_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
        this.props.actions._onApartmentFormFieldChange('building_id', this.props.match.params.building_id);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsBuildingIsFetching                        : true,
      building_id                                 : '',
      building_name                               : '',
      total_apartement                            : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        building_id                               : nextProps.building.gpDataFields.building_id,
        building_name                             : nextProps.building.gpDataFields.building_name,
        total_apartement                          : nextProps.building.gpDataFields.total_apartement,
        date_upd                                  : nextProps.building.gpDataFields.date_upd,
        date_add                                  : nextProps.building.gpDataFields.date_add,
        lsBuildingIsFetching                      : nextProps.building.gpBuildingIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewBuilding(this.props.match.params.building_id);
      this.props.actions._onApartmentFormFieldChange('building_id', this.props.building.gpDataFields.building_id);
      this.setState({
        lsBuildingIsFetching                      : this.props.building.gpBuildingIsFetching,
        building_id                               : this.props.building.gpDataFields.building_id,
        building_name                             : this.props.building.gpDataFields.building_name,
        total_apartement                          : this.props.building.gpDataFields.total_apartement,
      });
    }


    _editBuilding(id) {
      const data = [{
          building_id      : id,
      }];
      this.props.actions._activateEditBuilding(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onBuildingStateChange('building_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
        {
          menuItem: { key: 'apartment', content: 'Ruang Sewa' },
          render: () => <Tab.Pane attached={false} ><ApartmentList /></Tab.Pane>,
        },
      ]


      const {
        building_active_tab
      } = this.props.building;


      const {
        complex_id,
        complex_name,
        building_id,
        building_name,
        total_apartement,
        date_add,
        date_upd
      } = this.props.building.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Gedung
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/building/edit/' + building_id}
                onClick = {() => this._editBuilding(building_id)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID Komplek</span><br />
                  {complex_id} - {complex_name}</Col>

                  <Col md={8} className='p-2'><span className='font-bold'>ID Gedung</span><br />
                  {building_id}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Nama Gedung</span><br />
                  {building_name}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Jumlah Apartemen</span><br />
            <NumericFormat
              value={total_apartement}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
            <div className='spacer' />
            <div>
              <Tab menu={{borderless:true, pointing: true}} onTabChange={this.handleTabChange.bind(this)} activeIndex={building_active_tab} panes={panes} />
            </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

