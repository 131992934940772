import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import { Link }                                        from 'react-router-dom';
import UsersEdit                                       from './edit';
import {
       FaHome,
}                                                      from 'react-icons/fa';
import {
  usersActions,
}                                                      from '../_actions';
import {
  Row,
  Breadcrumb,
  Col,
  Spin,
}                                                      from 'antd';
import {
  Table,
  Button,
  Icon,
  Image,
  Label,
  Menu,
  Tab
}                                                      from 'semantic-ui-react';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';
import ContentLoader                                  from 'react-content-loader'

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...usersActions,
      }
      , dispatch)
  }
}

function mapStateToProps(state) {
  return {
    users: {
      gpDataFields                                : state.users.form.gpDataFields,
      gpUsersIsFetching                           : state.users.form.gpUsersIsFetching,
      users_active_tab                            : state.users.form.users_active_tab,
    },
      global: {
        currentUser : state.global.currentUser
      }
  }
}

class DataView extends Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    state = {
      lsUsersIsFetching                           : true,
      id_user                                     : '',
      fullname                                    : '',
      phone                                       : '',
      email                                       : '',
      passwd                                      : '',
      active                                      : '',
      administrator                               : '',
      id_userlevel                                : '',
      last_login_date                             : '',
      login_attempt                               : '',
      expired_date                                : '',
      date_add                                    : '',
      date_upd                                    : '',
    }


    componentWillReceiveProps (nextProps) {
      console.log('[*.view][componentWillReceiveProps][nextProps]: ' + JSON.stringify(nextProps))
      this.setState({
        id_user                                   : nextProps.users.gpDataFields.id_user,
        fullname                                  : nextProps.users.gpDataFields.fullname,
        phone                                     : nextProps.users.gpDataFields.phone,
        email                                     : nextProps.users.gpDataFields.email,
        passwd                                    : nextProps.users.gpDataFields.passwd,
        active                                    : nextProps.users.gpDataFields.active,
        administrator                             : nextProps.users.gpDataFields.administrator,
        id_userlevel                              : nextProps.users.gpDataFields.id_userlevel,
        last_login_date                           : nextProps.users.gpDataFields.last_login_date,
        login_attempt                             : nextProps.users.gpDataFields.login_attempt,
        expired_date                              : nextProps.users.gpDataFields.expired_date,
        date_upd                                  : nextProps.users.gpDataFields.date_upd,
        date_add                                  : nextProps.users.gpDataFields.date_add,
        lsUsersIsFetching                         : nextProps.users.gpUsersIsFetching,
      });
    }

    componentDidMount(){
      this.props.actions._viewUsers(this.props.match.params.id_user);
      this.setState({
        lsUsersIsFetching                         : this.props.users.gpUsersIsFetching,
        id_user                                   : this.props.users.gpDataFields.id_user,
        fullname                                  : this.props.users.gpDataFields.fullname,
        phone                                     : this.props.users.gpDataFields.phone,
        email                                     : this.props.users.gpDataFields.email,
        passwd                                    : this.props.users.gpDataFields.passwd,
        active                                    : this.props.users.gpDataFields.active,
        administrator                             : this.props.users.gpDataFields.administrator,
        id_userlevel                              : this.props.users.gpDataFields.id_userlevel,
        last_login_date                           : this.props.users.gpDataFields.last_login_date,
        login_attempt                             : this.props.users.gpDataFields.login_attempt,
        expired_date                              : this.props.users.gpDataFields.expired_date,
      });
    }


    _editUsers(id) {
      const data = [{
          id_user      : id,
      }];
      this.props.actions._activateEditUsers(data);
    }


    getJSONValue(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].value;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    getJSONLabel(jsonString) {
      var json = [{}];
      try {
          json = JSON.parse(jsonString);
          console.log('getJSONValue|string: ' + jsonString);
          return json[0].label;
      } catch (e) {
          console.log('getJSONValue|error: ' + JSON.stringify(e.message));
          return false;
      }
    }


    handleTabChange(e, val) {
      this.props.actions._onUsersStateChange('users_active_tab', val.activeIndex);
    }


    render() {

      const MyLoader = () => (
        <ContentLoader>
          <rect x='2' y='0'  rx='2' ry='2' width='80' height='5' />
          <rect x='2' y='10' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='20' rx='2' ry='2' width='150' height='5' />
          <rect x='2' y='30' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='40' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='50' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='60' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='70' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='80' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='90' rx='2' ry='2' width='140' height='5' />
          <rect x='2' y='100' rx='2' ry='2' width='160' height='5' />
          <rect x='2' y='110' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='120' rx='2' ry='2' width='120' height='5' />
          <rect x='2' y='130' rx='2' ry='2' width='120' height='5' />
        </ContentLoader>
      )


      const panes = [
      ]


      const {
        users_active_tab
      } = this.props.users;


      const {
        id_user,
        fullname,
        phone,
        email,
        passwd,
        active,
        administrator,
        id_userlevel,
        last_login_date,
        login_attempt,
        expired_date,
        date_add,
        date_upd
      } = this.props.users.gpDataFields;


    const path = APP.IMAGE_PATH;
    return (
      <div>
        <div className='content' >
        <div className='text-2xl font-bold text-blue-600' >
          Pengguna
        </div>
          <div>
              <Button
                onClick = {()=> this.goBack()}
                color='blue'
                size='mini'><Icon name='angle left' /> Back</Button>
              &nbsp;&nbsp;
              <Link
                to={'/users/edit/' + id_user}
                onClick = {() => this._editUsers(id_user)}
                >
                 <Button
                   onClick = {()=> this.goBack()}
                   color='blue'
                   size='mini'><Icon name='edit' /> Edit</Button>
              </Link>
          </div>
          <div className='spacer' />
          <div>
            <div className='spacer' />
              <div>
                <Row className='w-full bg-indigo-50 p-4 text-base rounded-md'>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>ID User</span><br />
                  {id_user}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Nama Lengkap</span><br />
                  {fullname}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>No. Telepon</span><br />
                  {phone}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Email</span><br />
                  {email}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Kata Kunci</span><br />
                  {passwd}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Aktif</span><br />
            <NumericFormat
              value={active}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Admin</span><br />
            <NumericFormat
              value={administrator}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>ID UserLevel</span><br />
                  {id_userlevel}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Tanggal Login Terakhir</span><br />
                  {last_login_date}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                <div className='md:hidden'><Row></Row></div>
                  <Col md={8} className='p-2'><span className='font-bold'>Percobaan Login</span><br />
            <NumericFormat
              value={login_attempt}
              thousandSeparator='.'
              decimalSeparator=','
              decimalScale={2}
              displayType='text'
              renderText={(value) => <span>{value}</span>}
            />
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                  <Col md={8} className='p-2'><span className='font-bold'>Tanggal Expired</span><br />
                  {expired_date}
                  </Col>
                  <div className='md:hidden'><Row></Row></div>

                </Row>
              </div>
            </div>

            <div className='spacer' />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);

