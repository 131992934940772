'use strict'
import {rentConstants, authenticateConstants}            from './../_constants';
import InitialState                                 from './../_initialstates/rent.initialstate'
import fieldValidation                              from './../_validation/rent.field.validation'
import formValidation                               from './../_validation/rent.form.validation'


const initialState = new InitialState()

export default function Reducer (state = initialState, action) {
  let nextRecordState = null
  if (!(state instanceof InitialState)) return initialState.mergeDeep(state)

  switch (action.type) {
    case rentConstants.RENT_GET_LIST_REQUEST:
    case rentConstants.RENT_LOOKUP_REQUEST:
    case rentConstants.RENT_ADD_REQUEST:
    case rentConstants.RENT_CHANGE_REQUEST:
    case rentConstants.RENT_FILTER_REQUEST:
    case rentConstants.RENT_VIEW_REQUEST:
    case rentConstants.RENT_COUNT_REQUEST:
      return state
        .setIn(['form', 'gpRentIsFetching'],  true)

    case rentConstants.RENT_CHANGE_SUCCESS:
    case rentConstants.RENT_ADD_SUCCESS:
      return state
          .setIn(['form', 'gpRentIsFetching'],  false)

    case rentConstants.RENT_COUNT_SUCCESS:
      return state
          .setIn(['form', 'gpRentIsFetching'],  false)
          .setIn(['form', 'rent_count'],  action.payload)

    case rentConstants.RENT_FILTER_SUCCESS:
    case rentConstants.RENT_GET_LIST_SUCCESS:
      const {data, page} = action.payload
      var results = state.getIn(['form', 'gpRentDataList']).concat(data.rows);
      return state
          .setIn(['form', 'gpRentDataList'], data.rows)
          .setIn(['form', 'gpRentDataListNumRows'],  data.num_rows)
          .setIn(['form', 'gpRentIsFetching'],  false)


  case rentConstants.RENT_LOOKUP_SUCCESS:
    console.log('[rent.reducer][lookup.success][payload]:' + JSON.stringify(action.payload));
    return state
        .setIn(['form', 'gpRentLookupList'], action.payload)
        .setIn(['form', 'gpRentIsFetching'], false)


    case rentConstants.RENT_INITIALIZE_DATA:
      return state
          .setIn(['form', 'gpDataFields',                                                           'rent_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'rent_description'],'')
          .setIn(['form', 'gpDataFields',                                                           'rent_fee'],'')
          .setIn(['form', 'gpDataFields',                                                           'late_fee'],'')
          .setIn(['form', 'gpDataFields',                                                           'due_date'],'')
          .setIn(['form', 'gpDataFields',                                                           'lease_id'],'')
          .setIn(['form', 'gpDataFields',                                                           'lease_description'],'')
          .setIn(['form', 'gpDataFields',                                                           'lease_lease_id'],'')

    case rentConstants.RENT_SHOW_MODAL:
      return state
        .setIn(['form', 'gpRentShowModal'],  action.payload)


    case rentConstants.RENT_SEND_PARAM:
      return state
        .setIn(['form', 'gpRentModalParam'],  action.payload[0])


    case rentConstants.RENT_VIEW_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields',     'rent_id'],                                           action.payload[0].rent_id)
          .setIn(['form', 'gpOriginalFields', 'rent_id'],                                           action.payload[0].rent_id)
          .setIn(['form', 'gpDataFields',     'rent_description'],                                  action.payload[0].rent_description)
          .setIn(['form', 'gpOriginalFields', 'rent_description'],                                  action.payload[0].rent_description)
          .setIn(['form', 'gpDataFields',     'rent_fee'],                                          action.payload[0].rent_fee)
          .setIn(['form', 'gpOriginalFields', 'rent_fee'],                                          action.payload[0].rent_fee)
          .setIn(['form', 'gpDataFields',     'late_fee'],                                          action.payload[0].late_fee)
          .setIn(['form', 'gpOriginalFields', 'late_fee'],                                          action.payload[0].late_fee)
          .setIn(['form', 'gpDataFields',     'due_date'],                                          action.payload[0].due_date)
          .setIn(['form', 'gpOriginalFields', 'due_date'],                                          action.payload[0].due_date)
          .setIn(['form', 'gpDataFields',     'lease_id'],                                          action.payload[0].lease_id)
          .setIn(['form', 'gpOriginalFields', 'lease_id'],                                          action.payload[0].lease_id)
          .setIn(['form', 'gpDataFields',     'lease_description'],                                 action.payload[0].lease_description)
          .setIn(['form', 'gpOriginalFields', 'lease_description'],                                 action.payload[0].lease_description)
          .setIn(['form', 'gpDataFields', 'lease_lease_id'],                                        action.payload[0].lease_lease_id)
          .setIn(['form', 'gpOriginalFields', 'lease_lease_id'],                                    action.payload[0].lease_lease_id)
          .setIn(['form', 'gpRentIsFetching'],                                                      false)
          return nextRecordState


    case rentConstants.RENT_INIT_SUCCESS:
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'rent_id'],                                               '')
          .setIn(['form', 'gpDataFields', 'rent_description'],                                      '')
          .setIn(['form', 'gpDataFields', 'rent_fee'],                                              '')
          .setIn(['form', 'gpDataFields', 'late_fee'],                                              '')
          .setIn(['form', 'gpDataFields', 'due_date'],                                              '')
          .setIn(['form', 'gpDataFields', 'rent_id_has_error'],                                     false)
          .setIn(['form', 'gpDataFields', 'rent_description_has_error'],                            false)
          .setIn(['form', 'gpDataFields', 'rent_fee_has_error'],                                    false)
          .setIn(['form', 'gpDataFields', 'late_fee_has_error'],                                    false)
          .setIn(['form', 'gpDataFields', 'due_date_has_error'],                                    false)

          .setIn(['form', 'gpRentIsValid'],                                                         false)
      return nextRecordState


    case authenticateConstants.LOGOUT_SUCCESS:
    console.log('rentReducer::LOGOUT_SUCCESS')
      nextRecordState = state
          .setIn(['form', 'gpDataFields', 'rent_id'],                                               '')
          .setIn(['form', 'gpDataFields', 'rent_description'],                                      '')
          .setIn(['form', 'gpDataFields', 'rent_fee'],                                              '')
          .setIn(['form', 'gpDataFields', 'late_fee'],                                              '')
          .setIn(['form', 'gpDataFields', 'due_date'],                                              '')
          .setIn(['form', 'gpDataFields', 'lease_id'],                                              '')
          .setIn(['form', 'gpDataFields', 'lease_description'],                                     '')
          .setIn(['form', 'gpDataFields', 'lease_id'],                                              '')
          .setIn(['form', 'gpRentError'],                                                           null)
          return formValidation(nextRecordState, action)


    case rentConstants.RENT_GET_LIST_FAILURE:
    case rentConstants.RENT_LOOKUP_FAILURE:
    case rentConstants.RENT_ADD_FAILURE:
    case rentConstants.RENT_CHANGE_FAILURE:
    case rentConstants.RENT_FILTER_FAILURE:
    case rentConstants.RENT_VIEW_FAILURE:
    case rentConstants.RENT_COUNT_FAILURE:
      console.log('rent.reducer.failure.error: ' + JSON.stringify(action.payload))
      return state
          .setIn(['form', 'gpRentIsFetching'], false)
          .setIn(['form', 'gpRentError'], action.payload)


   case rentConstants.ON_RENT_STATE_CHANGE:{
     const {field, value} = action.payload
     let nextState = state
         .setIn(['form', field], value)
         return nextState}


    case rentConstants.ON_RENT_FORM_FIELD_CHANGE:{
      const {field, value} = action.payload
      let nextState = state
          .setIn(['form', 'gpDataFields', field], value)
          return formValidation(fieldValidation(nextState, action), action)}

    case rentConstants.CHANGE_RENT_FIELD:{
      const {field, value} = action.payload
      let nextState = state.setIn(['form', 'gpDataFields', field], value)
          .setIn(['form', 'gpDataFields', field+'_has_error'], null)
          .setIn(['form', 'gpDataFields', field+'_error_msg'], null)
          .setIn(['form', 'gpRentError'], null)
      return nextState}

    case rentConstants.SET_STATE:
      var record = JSON.parse(action.payload).record.form
      var next = state
          .setIn(['form', 'gpDataFields', 'rent_id'],                                               record.rent_id)
          .setIn(['form', 'gpOriginalFields', 'rent_id'],                                           record.rent_id)
          .setIn(['form', 'gpDataFields', 'rent_description'],                                      record.rent_description)
          .setIn(['form', 'gpOriginalFields', 'rent_description'],                                  record.rent_description)
          .setIn(['form', 'gpDataFields', 'rent_fee'],                                              record.rent_fee)
          .setIn(['form', 'gpOriginalFields', 'rent_fee'],                                          record.rent_fee)
          .setIn(['form', 'gpDataFields', 'late_fee'],                                              record.late_fee)
          .setIn(['form', 'gpOriginalFields', 'late_fee'],                                          record.late_fee)
          .setIn(['form', 'gpDataFields', 'due_date'],                                              record.due_date)
          .setIn(['form', 'gpOriginalFields', 'due_date'],                                          record.due_date)
          .setIn(['form', 'gpDataFields', 'lease_id'],                                              record.lease_id)
          .setIn(['form', 'gpOriginalFields', 'lease_id'],                                          record.lease_id)
          .setIn(['form', 'gpDataFields', 'lease_id_has_error'],                                    record.lease_id_has_error)
          .setIn(['form', 'gpOriginalFields', 'lease_id_has_error'],                                record.lease_id_has_error)
          .setIn(['form', 'gpDataFields', 'lease_description'],                                     record.lease_description)
          .setIn(['form', 'gpOriginalFields', 'lease_description'],                                 record.lease_description)
          .setIn(['form', 'gpDataFields', 'lease_description_has_error'],                           record.lease_description_has_error)
          .setIn(['form', 'gpOriginalFields', 'lease_description_has_error'],                       record.lease_description_has_error)
          .setIn(['form', 'gpDataFields', 'lease_lease_id'],                                        record.lease_lease_id)
          .setIn(['form', 'gpOriginalFields', 'lease_lease_id'],                                    record.lease_lease_id)

          .setIn(['form', 'gpDataFields', 'rent_id_has_error'],                                     record.rent_id_has_error)
          .setIn(['form', 'gpDataFields', 'rent_description_has_error'],                            record.rent_description_has_error)
          .setIn(['form', 'gpDataFields', 'rent_fee_has_error'],                                    record.rent_fee_has_error)
          .setIn(['form', 'gpDataFields', 'late_fee_has_error'],                                    record.late_fee_has_error)
          .setIn(['form', 'gpDataFields', 'due_date_has_error'],                                    record.due_date_has_error)

          .setIn(['form', 'gpDataFields', 'lease_lease_id_has_error'],                              action.payload.lease_lease_id_has_error)


          .setIn(['form', 'gpRentDataList'],                                                        record.gpRentDataList)
          .setIn(['form', 'gpRentIsDisabled'],                                                      record.gpRentIsDisabled)
          .setIn(['form', 'gpRentError'],                                                           record.gpRentError)
          .setIn(['form', 'gpRentIsValid'],                                                         record.gpRentIsValid)
          .setIn(['form', 'gpRentIsFetching'],                                                      record.gpRentIsFetching)
          .setIn(['form', 'rent_count'],                                                            record.rent_count)
      return next
case rentConstants.RENT_SHOW_LOOKUP_MODAL:
  return state
    .setIn(['form', 'gpRentLookupShowModal'],  action.payload)


  }
  return state
}
