import React                                          from 'react';
import { Route }                                      from 'react-router-dom';
import { Layout }                                     from 'antd';

const { Content } = Layout;

export const PrivateContent = ({ ...props }) => (
  localStorage.getItem('user')
    ?<Content style={{backgroundColor:'white', margin:'85px 5px 5px 5px', padding:'5px'}}>{props.children}</Content>
    :<Content style={{backgroundColor:'white', margin:'5px 5px 5px 5px', padding:'5px'}}>{props.children}</Content>
)

