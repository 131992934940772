const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        id_userlevel                              : '',
        role_name                                 : '',
        search_id_userlevel                       : '',
        search_role_name                          : '',
   }))(),
   gpUserlevelDataList                            : [],
   gpUserlevelDataListNumRows                     : 0,
   gpUserlevelLookupList                          : [],
   gpUserlevelIsDisabled                          : false,
   gpUserlevelError                               : null,
   gpUserlevelIsValid                             : true,
   gpUserlevelIsFetching                          : false,
   userlevel_count                                : 0,
   userlevel_active_tab                           : 0,
   gpUserlevelShowModal                           : 'hide',
   gpUserlevelModalParam                          : [],
   gpUserlevelLookupShowModal                     : 'hide',
   gpDataFields: new (Record({
        id_userlevel                              : '',
        role_name                                 : '',
        search_id_userlevel                       : '',
        search_role_name                          : '',
        id_userlevel_has_error                    : false,
        role_name_has_error                       : false,
        search_id_userlevel_has_error             : false,
        search_role_name_has_error                : false,
        id_userlevel_error_msg                    : false,
        role_name_error_msg                       : false,
        search_id_userlevel_error_msg             : false,
        search_role_name_error_msg                : false,
   }))(),
 })
 var userlevelInitialState = Record({
   form: new Form()
 })
export default userlevelInitialState
