// -------------------------------------------------------------------------- 
// Import definition start                                                    
// -------------------------------------------------------------------------- 
import React, { Component }                            from 'react';
import { bindActionCreators }                          from 'redux';
import { connect }                                     from 'react-redux';
import _                                               from 'underscore';
import { Link, Redirect }                              from 'react-router-dom';
import * as companyActions                             from '../_actions/company.actions';
import {
  Row,
  Col,
  Input,
  InputNumber,
  Modal,
  Tabs,
  Upload,
  message,
  DatePicker,
}                                                      from 'antd';
import {
  Button,
  Header,
  Icon,
  Dropdown,
  Radio,
  Checkbox,
}                                                      from 'semantic-ui-react';
import moment                                          from 'moment';
import * as GF                                         from './../_applib/_helpers/global.function';
import * as APP                                        from './../_applib/_settings/config';
import { NumericFormat }                               from 'react-number-format';

/* -------------------------------------------------------------------------- */
/* Import definition End                                                      */
/* -------------------------------------------------------------------------- */

function mapStateToProps(state) {
    return {
     company: {
       gpCompanyShowModal                         : state.company.form.gpCompanyShowModal,
       gpCompanyModalParam                        : state.company.form.gpCompanyModalParam,
       gpCompanyIsFetching                        : state.company.form.gpCompanyIsFetching,
       gpDataFields                               : state.company.form.gpDataFields,
       gpCompanyIsValid                           : state.company.form.gpCompanyIsValid,
     },
      global: {
        currentUser : state.global.currentUser
      }
    };
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...companyActions,
      }, dispatch)
  }
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}
/* -------------------------------------------------------------------------- */
/* Public Constant Start                                                      */
/* -------------------------------------------------------------------------- */
const TextArea          = Input.TextArea;
const TabPane           = Tabs.TabPane;
const dateFormat        = 'DD.MM.YYYY';
/* -------------------------------------------------------------------------- */
/* Public Constant End                                                        */
/* -------------------------------------------------------------------------- */
  class DataEdit extends Component {
      state = {
          lsConfirmLoading                        : false,
          lsActionType                            : '',
          lsFormTitle                             : '',
          loading                                 : false,
          redirect                                : false,
          direct_access                           : false,
    }

    constructor(props){
       super(props);
       this.goBack = this.goBack.bind(this);
    }


    goBack(){
        this.props.history.goBack();
    }


    componentDidMount(){

      if(this.props.match.url.indexOf('/edit/') !== -1){
        console.log('Editing things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._editCompany(this.props.match.params.company_id));
      }

      if(this.props.match.url.indexOf('/add/') !== -1){
        console.log('Adding things: ' + JSON.stringify(this.props.match.url));
        this.setState({
          direct_access          : true,
        }, this._addCompany());
      }

    }


/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */
    componentWillReceiveProps (nextProps) {
/* -------------------------------------------------------------------------- */
/*  check data change from async request                                      */
/* -------------------------------------------------------------------------- */

      if(nextProps.company.gpCompanyModalParam) {
        if (nextProps.company.gpCompanyModalParam !== this.props.company.gpCompanyModalParam) {
          console.log('Information: gpCompanyModalParam props changed');
          if(nextProps.company.gpCompanyModalParam['trxType']==='edt') {
            this.setState({
              company_id      : nextProps.company.gpCompanyModalParam['company_id'],
              trxType          : nextProps.company.gpCompanyModalParam['trxType'],
            }, this._editCompany(nextProps.company.gpCompanyModalParam['company_id']));
          };

          if(nextProps.company.gpCompanyModalParam['trxType']==='add') {
            this.setState({
              trxType          : nextProps.company.gpCompanyModalParam['trxType'],
            }, this._addCompany());
          };

          if(nextProps.company.gpCompanyModalParam['trxType']==='filter') {
            this.setState({
              trxType          : nextProps.company.gpCompanyModalParam['trxType'],
            }, this._filterCompany());
          };

        };
      };

      if(nextProps.company.gpCompanyShowModal) {
        if (nextProps.company.gpCompanyShowModal !== this.props.company.gpCompanyShowModal) {
          console.log('Information: gpCompanyShowModal props changed');
          if(nextProps.company.gpCompanyShowModal==='show'){
            this.setState({
              lsCompanyShowModal: true,
            }, () => {
                 setTimeout(() => { this.firstInput && this.firstInput.focus() }, 1)
               }
            )
          }

          if(nextProps.company.gpCompanyShowModal==='hide'){
            this.setState({
              lsCompanyShowModal: false,
            });
          }
        };
      };

      this.setState({
        company_id                                : nextProps.company.gpDataFields.company_id,
        company_name                              : nextProps.company.gpDataFields.company_name,
        address                                   : nextProps.company.gpDataFields.address,
        phone                                     : nextProps.company.gpDataFields.phone,
        email                                     : nextProps.company.gpDataFields.email,
        date_add                                  : nextProps.company.gpDataFields.date_add,
        date_upd                                  : nextProps.company.gpDataFields.date_upd,
      });

}
_addCompanyOnServer() {
    var id1 = this.state.company_id.trim();
    var id2 = id1.length;
    var id3 = '';  
    
    if(id2 == 0){
      id3 = GF.create_id(10);
    } else {
      id3 = this.state.company_id;
    }
    const data = {
        company_id                                : id3,
        company_name                              : this.state.company_name,
        address                                   : this.state.address,
        phone                                     : this.state.phone,
        email                                     : this.state.email,
    }
    this.props.actions._addCompany(data);
    this.goBack();
}

_editCompanyOnServer() {
    const data = {
        company_id                                : this.state.company_id,
        company_name                              : this.state.company_name,
        address                                   : this.state.address,
        phone                                     : this.state.phone,
        email                                     : this.state.email,
    }
    this.props.actions._editCompany(data);
    this.goBack();
}

_filterCompanyOnServer() {
    const data = {
        company_id                                : this.state.company_id,
        company_name                              : this.state.company_name,
        address                                   : this.state.address,
        phone                                     : this.state.phone,
        email                                     : this.state.email,
    }
    this.props.actions._filterCompany(data);
    this.goBack();
}

/* -------------------------------------------------------------------------- */
/*  edit company                                                              */
/* -------------------------------------------------------------------------- */
_editCompany(id){
  this.setState({
     isIDDisabled                                 : true,
     lsActionType                                 : 'edt',
     action_button_text                           : 'Simpan',
     lsFormTitle                                  : 'Update',
  });
  this.props.actions._viewCompany(id);
}

/* -------------------------------------------------------------------------- */
/*  add new company                                     
/* -------------------------------------------------------------------------- */
_addCompany() {
    let today = GF.today();
    this.props.actions._onCompanyFormFieldChange('company_id', '')
    this.props.actions._onCompanyFormFieldChange('company_name', '')
    this.props.actions._onCompanyFormFieldChange('address', '')
    this.props.actions._onCompanyFormFieldChange('phone', '')
    this.props.actions._onCompanyFormFieldChange('email', '')
    this.props.actions._initCompany();

    this.setState({
         company_id                               : '',
         company_name                             : '',
         address                                  : '',
         phone                                    : '',
         email                                    : '',
         isIDDisabled                             : false,
         lsCompanyDetail                          : [],
         lsActionType                             : 'add',
         action_button_text                       : 'Tambah',
         lsFormTitle                              : 'Tambah',
    });
}

/* -------------------------------------------------------------------------- */
/*  filter company                                     
/* -------------------------------------------------------------------------- */
_filterCompany() {

    let today = GF.today();
    this.setState({
         company_id                               : '',
         company_name                             : '',
         address                                  : '',
         phone                                    : '',
         email                                    : '',
         lsCompanyDetail                          : [],
         lsActionType                             : 'filter',
         action_button_text                       : 'Filter',
         lsFormTitle                              : 'Cari',
  });
}

/* -------------------------------------------------------------------------- */
/*  handle modal form submit button                                           */
/* -------------------------------------------------------------------------- */
handleModalSubmit = () => {
  this.props.actions._showCompanyModalForm('hide');
  this.setState({
    lsConfirmLoading: false,
  });

  if (this.state.lsActionType==='add'){
    this._addCompanyOnServer();
  }

  if (this.state.lsActionType==='edt'){
    this._editCompanyOnServer();
  }

  if (this.state.lsActionType==='filter'){
    this._filterCompanyOnServer();
  }
}

/* -------------------------------------------------------------------------- */
/*  handle modal form cancel button                                           */
/* -------------------------------------------------------------------------- */
handleModalCancel = () => {
  this.props.actions._showCompanyModalForm('hide');
}

handleChange(e) {
  const value   = e.target.value;
  const field   = e.target.name;
       console.log('[company.edit][onChange][field:value]:' + JSON.stringify(field) + ':' + JSON.stringify(value));
       this.props.actions._onCompanyFormFieldChange(field, value)
}

/* -------------------------------------------------------------------------- */
/*  Start of render function                                                  */
/* -------------------------------------------------------------------------- */
render() {
    const {
      redirect,
      lsConfirmLoading,
      lsCompanyShowModal,
    } = this.state;


     const {
       company_id,
       company_name,
       address,
       phone,
       email,
       date_add,
       date_upd,
       company_id_has_error,
       company_name_has_error,
       address_has_error,
       phone_has_error,
       email_has_error,
       date_add_has_error,
       date_upd_has_error,
       company_id_error_msg,
       company_name_error_msg,
       address_error_msg,
       phone_error_msg,
       email_error_msg,
       date_add_error_msg,
       date_upd_error_msg
     } = this.props.company.gpDataFields;


const imageURL  = APP.URL + 'uploadimage';
const user = JSON.parse(localStorage.getItem('user'));


if (redirect) {
  console.log('redirected due to direct access');
  return <Redirect to='/Company'/>;
}

/* -------------------------------------------------------------------------- */
/*  Start of render return function                                           */
/* -------------------------------------------------------------------------- */
return (
/* -------------------------------------------------------------------------- */
/*  Start of render body function                                             */
/* -------------------------------------------------------------------------- */
<div>


{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add company start                                         */}
{/* ------------------------------------------------------------------------ */}
<div className='p-4 rounded-md'>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add company body start                                    */}
{/* ------------------------------------------------------------------------ */}
     <div className='text-2xl text-gray-600 px-4 pb-4 font-bold'>
       Perusahaan
     </div>
     <div className='w-full bg-gray-50 p-8 text-base rounded-md'>
         <Row gutter={16}>

         <Col lg={8}>
          <b>ID Perusahaan</b><br />
          <div className='spacer' />
          <Input
              ref={(input) => { this.firstInput = input; }}
              disabled={this.state.isIDDisabled}
              type='text'
              name='company_id'
              placeholder='[AUTO]'
              value={company_id}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {company_id_has_error?<div style={{color:'lightcoral'}}>{company_id_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Nama Perusahaan</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='company_name'
              placeholder='Masukan Nama Perusahaan'
              value={company_name}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {company_name_has_error?<div style={{color:'lightcoral'}}>{company_name_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Alamat</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='address'
              placeholder='Masukan Alamat'
              value={address}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {address_has_error?<div style={{color:'lightcoral'}}>{address_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Nomor Telepon</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='phone'
              placeholder='Masukan Nomor Telepon'
              value={phone}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {phone_has_error?<div style={{color:'lightcoral'}}>{phone_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>

         <Col lg={8}>
          <b>Email</b><br />
          <div className='spacer' />
          <Input
              disabled={false}
              type='text'
              name='email'
              placeholder='Masukan Email'
              value={email}
              onChange={this.handleChange.bind(this)}
              onPressEnter={this.handleModalSubmit.bind(this)}
          />
          {email_has_error?<div style={{color:'lightcoral'}}>{email_error_msg}</div>:<div></div>}
          <div className='spacer' />
          <br />
         </Col>

         <div className='md:hidden'><Row></Row></div>
        </Row>
        <div className='spacer' />
        <br />
        <div className='inline-flex rounded-md shadow-sm' role='group'>
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-base font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2'
            onClick = {()=> this.goBack()}>
            <Icon name='reply' />Batal
          </button>&nbsp;&nbsp;
          <button type='button' className='inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-base font-medium hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 disabled:bg-gray-200 text-gray-900'
            onClick={this.handleModalSubmit}
            disabled={!this.props.company.gpCompanyIsValid}>
            <Icon name='save' />{this.state.action_button_text}
          </button>
        </div>
      </div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add company body End                   */}
{/* ------------------------------------------------------------------------ */}
</div>
{/* ------------------------------------------------------------------------ */}
{/*  Modal form to add company End                        */}
{/* ------------------------------------------------------------------------ */}
</div>
/* -------------------------------------------------------------------------- */
/*  End of render body Function                                               */
/* -------------------------------------------------------------------------- */
);
/* -------------------------------------------------------------------------- */
/*  End of render return function                                             */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of render function                                                    */
/* -------------------------------------------------------------------------- */
}
/* -------------------------------------------------------------------------- */
/*  End of class function                                                     */
/* -------------------------------------------------------------------------- */
export default connect(mapStateToProps, mapDispatchToProps)(DataEdit);
