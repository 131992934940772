import React                                          from 'react';
import { Link }                                       from 'react-router-dom';
import { connect }                                    from 'react-redux';
import {Row, Col}                                     from 'antd';
import {Icon}                                          from 'semantic-ui-react';
import * as APP                                       from './../../_applib/_settings/config'; 



class Parameter extends React.Component {
    state = {}
    componentDidMount(){
      APP.LOG(APP.userAuthority());
      this.setState({
        userlevel_all: true,
        books_all: true,
      })
    }
    renderUserlevel() {
      if(this.state.userlevel_all) {
        return  <span>
          <Col span={2} className='home-square-menu'>
            <Link to='/userlevel'>
              <img src={require('./../../_applib/_static/_icons/quiz.png')} width='60px' height='60px'/><br />
              Userlevel
            </Link>
          </Col>
        </span>
      } else {
        return null
      }
    }

    renderBooks() {
      if(this.state.books_all) {
        return  <span>
          <Col span={2} className='home-square-menu'>
            <Link to='/books'>
              <img src={require('./../../_applib/_static/_icons/quiz.png')} width='60px' height='60px'/><br />
              Books
            </Link>
          </Col>
        </span>
      } else {
        return null
      }
    }


    render() {
        return (
          <div>
            <div className='header-bottom' >
            </div>
            <div className='content'>
              <Row>
             { this.renderUserlevel()}
             { this.renderBooks()}
              </Row>
            </div>
          </div>
        );
    }
}


function mapStateToProps(state) {
    const { users, authenticate } = state;
    const { user } = authenticate;
    return {
        user,
        users
    };
}

const connectedParameter = connect(mapStateToProps)(Parameter);
export { connectedParameter as Parameter };
