const {Record} = require('immutable')
const Form = Record({
   gpOriginalFields: new (Record({
        service_id                                : '',
        service_name                              : '',
        problem                                   : '',
        apartment_id                              : '',
        apartment_name                            : '',
        employee_id                               : '',
        employee_name                             : '',
        search_service_id                         : '',
        search_service_name                       : '',
        search_problem                            : '',
        search_apartment_id                       : '',
        search_employee_id                        : '',
        apartment_apartment_id                    : '',
        employee_employee_id                      : '',
   }))(),
   gpServiceDataList                              : [],
   gpServiceDataListNumRows                       : 0,
   gpServiceLookupList                            : [],
   gpServiceIsDisabled                            : false,
   gpServiceError                                 : null,
   gpServiceIsValid                               : true,
   gpServiceIsFetching                            : false,
   service_count                                  : 0,
   service_active_tab                             : 0,
   gpServiceShowModal                             : 'hide',
   gpServiceModalParam                            : [],
   gpServiceLookupShowModal                       : 'hide',
   gpDataFields: new (Record({
        apartment_id                              : '',
        apartment_name                            : '',
        employee_id                               : '',
        employee_name                             : '',
        service_id                                : '',
        service_name                              : '',
        problem                                   : '',
        search_service_id                         : '',
        search_service_name                       : '',
        search_problem                            : '',
        apartment_apartment_id                    : '',
        employee_employee_id                      : '',
        service_id_has_error                      : false,
        service_name_has_error                    : false,
        problem_has_error                         : false,
        search_service_id_has_error               : false,
        search_service_name_has_error             : false,
        search_problem_has_error                  : false,
        apartment_apartment_id_has_error          : false,
        employee_employee_id_has_error            : false,
        service_id_error_msg                      : false,
        service_name_error_msg                    : false,
        problem_error_msg                         : false,
        search_service_id_error_msg               : false,
        search_service_name_error_msg             : false,
        search_problem_error_msg                  : false,
        apartment_apartment_id_error_msg          : false,
        employee_employee_id_error_msg            : false,
        apartment_id_has_error                    : false,
        apartment_id_error_msg                    : false,
        search_apartment_id                       : '',
        apartment_id                              : '',
        employee_id_has_error                     : false,
        employee_id_error_msg                     : false,
        search_employee_id                        : '',
        employee_id                               : '',
   }))(),
 })
 var serviceInitialState = Record({
   form: new Form()
 })
export default serviceInitialState
