import React, { Component }                           from 'react';
import { connect }                                    from 'react-redux';
import { bindActionCreators }                         from 'redux';
import { Link }                                       from 'react-router-dom';
import { authenticateActions }                        from './../_actions';
import sha256                                         from 'crypto-js/sha256';
import { Input, Grid,  Button, Segment }              from 'semantic-ui-react';


function mapStateToProps (state) {
  return {
    authenticate: {
      form                      : state.authenticate.form,
      fields                    : state.authenticate.form.fields,
      isFetching                : state.authenticate.form.isFetching,
      isValid                   : state.authenticate.form.isValid,
    },
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
       ...authenticateActions },
       dispatch)
  }
}

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email  : '',
            password  : ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    handleChange(name, e) {
        this.props.actions.loginState()
        const { value } = e.target;
        console.log('name: ' + JSON.stringify(name));
        console.log('value: ' + JSON.stringify(value));
        this.props.actions._onAuthFormFieldChange(name, value)
    }

    _handleKeyDown=(e)=>{
        console.log('event.key: ' + e.key)
        if (e.key === 'Enter') {
          this.handleSubmit();
        }
    }

    handleSubmit(e) {
        const { email, password } = this.props.authenticate.fields;

        if (email && password) {
            let hash = sha256(password).toString();
            this.props.actions.login(email, hash);
        }
    }

    render() {
        const { loggingIn } = this.props;

        const {
          email,
          password,
          showPassword,
          icon,
          email_has_error,
          password_has_error,
          email_error_msg,
          password_error_msg
        } = this.props.authenticate.fields;


        return (
          <div className='flex justify-center text-center p-12 bg-blue-50'>
              <div class='w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-6 dark:bg-gray-800 dark:border-gray-700'>
                <div style={{textAlign:'left'}}>
                  <img src={require('./../_applib/_static/_images/logo.jpg')} width='140px'/>
                </div>
                <br /><br /><br />
                <div>
                  <div
                    style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}
                    >Email</div>
                  <Input
                    width={300}
                    autoFocus
                    size='large'
                    name='email'
                    value={email}
                    fluid
                    onChange={(e)=>this.handleChange('email', e)}
                    onKeyPress={this._handleKeyDown}
                  />
                  {email_has_error?<div style={{textAlign:'left', color: 'red'}}>{email_error_msg}</div>:null}
                </div>
                    <br />
                    <div style={{textAlign:'left', fontWeight: 'bold', fontSize: '16px'}}>Password</div>
                    <div className='login-input'>
                      <Input
                        type='password'
                        name='password'
                        size='large'
                        fluid
                        value={password}
                        onChange={(e)=>this.handleChange('password', e)}
                        onKeyPress={this._handleKeyDown}
                      />
                      {password_has_error?<div style={{textAlign:'left', color: 'red'}}>{password_error_msg}</div>:null}
                    </div>
                    <br /><br />
                    <div>
                      <Button primary
                        size='large'
                        style={{width:'100%'}}
                        loading={loggingIn}
                        onClick={this.handleSubmit}>
                        Sign In
                      </Button>
                      <br /><br /><br />
                      <Link to='/register' className='btn btn-link'>Buat Akun</Link>
                      &nbsp;&nbsp;<a className='ui gray empty circular label'></a>&nbsp;&nbsp;
                      <Link to='/forgotpassword' className='btn btn-link'>Lupa Password</Link>
                    </div>
              </div>
            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Login)
