'use strict'


import validate from 'validate.js'
import _ from 'underscore'


const emailConstraints = {
  from: {
    email: true
  }
}

const phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
const phoneConstraints = {
  phone: {
    format: {
      pattern: phonePattern,
      flags: 'i'
    }
  }
}

const fullnamePattern = /^[a-zA-Z ]*$/
const fullnameConstraints = {
  fullname: {
    format: {
      pattern: fullnamePattern,
      flags: 'i'
    }
  }
}

const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
const passwordConstraints = {
  password: {
    format: {
      pattern: passwordPattern,
      flags: 'i'
    }
  }
}

export default function fieldValidation (state, action) {
  const {field, value} = action.payload

  switch (field) {
    case ('fullname'): {
      let validfullname = _.isUndefined(validate({fullname: value},
                                                fullnameConstraints))
      if (validfullname) {
        return state.setIn(['form', 'fields', 'fullname_has_error'],
                         false)
        .setIn(['form', 'fields', 'fullname_error_msg'], '')
      } else {
        return state.setIn(['form', 'fields', 'fullname_has_error'], true)
        .setIn(['form', 'fields', 'fullname_error_msg'],
               'Nama tidak valid')
      }
    }

    case ('email'): {
      let validEmail = _.isUndefined(validate({from: value},
                                             emailConstraints))
      if (validEmail) {
        return state.setIn(['form', 'fields', 'email_has_error'], false)
      } else {
        return state.setIn(['form', 'fields', 'email_has_error'], true)
        .setIn(['form', 'fields', 'email_error_msg'],
                 'Email tidak valid')
      }
    }

    case ('phone'): {
      let validPhone = _.isUndefined(validate({phone: value},
                                             phoneConstraints))
      if (validPhone) {
        return state.setIn(['form', 'fields', 'phone_has_error'], false)
      } else {
        return state.setIn(['form', 'fields', 'phone_has_error'], true)
        .setIn(['form', 'fields', 'phone_error_msg'],
                 'Phone tidak valid')
      }
    }

    case ('password'): {
      let validPassword = _.isUndefined(validate({password: value},
                                               passwordConstraints))
      if (validPassword) {
        return state.setIn(['form', 'fields', 'password_has_error'],
                         false)
        .setIn(['form', 'fields', 'password_error_msg'],
               '')
      } else {
        return state.setIn(['form', 'fields', 'password_has_error'], true)
        .setIn(['form', 'fields', 'password_error_msg'],
          'Password Tidak Valid')
      }
    }

    case ('showPassword'):
      return state.setIn(['form', 'fields',
                                'showPassword'], value)

  }
  return state
}
