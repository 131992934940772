import { authenticateConstants }                       from './../_constants';
import { authenticateService }                         from './../_services';
import { store }                                       from './../_applib/_helpers';
import {
  alertActions,
}                                                      from './';
import { history }                                     from './../_applib/_helpers';
import * as APP                                        from './../_applib/_settings/config';
export var authenticateActions = {
    login,
    logout,
    forgot,
    register,
    getAll,
    _onAuthFormFieldChange,
    _changeField,
    logoutState,
    registerState,
    loginState,
    forgotPasswordState,
    delete: _delete
};

function login(email, password) {
    return dispatch => {
        dispatch(alertActions.clear());
        dispatch(request());
        authenticateService.login(email, password)
            .then(
                user => {
                    APP.LOG('src|_actions|authenticate.actions.js|login|success|user:' + JSON.stringify(user));
                    dispatch(success(user));
                    history.push('/home');
                    APP.LOG('Go to Home');
                },
                error => {
                    APP.LOG('src|_actions|authenticate.actions.js|login|error:' + JSON.stringify(error));
                    dispatch(failure());
                    dispatch(alertActions.error('Login Tidak Berhasil'));
                }
            );
    };

    function request()      { return { type:  authenticateConstants.LOGIN_REQUEST } }
    function success(user)  { return { type:  authenticateConstants.LOGIN_SUCCESS, user } }
    function failure() { return { type: authenticateConstants.LOGIN_FAILURE}}
}


function logoutAction(text) {
    return {
      type: 'LOGOUT_SUCCESS',
      text
    }
}

function alertClearAction() {
    return {
      type: 'ALERT_CLEAR'
    }
}

function alertSuccessAction(text) {
    return {
      type: 'ALERT_SUCCESS',
      message: text
    }
}

function logout(reason) {
  return dispatch => {
    APP.LOG('auth.act.logout');
    store.dispatch(logoutAction());
    store.dispatch(alertClearAction());
    store.dispatch(alertSuccessAction(reason));

    if (localStorage.getItem('user') === null){
      return false;
    };

    localStorage.removeItem('user');

    if (localStorage.getItem('user') === null){
      history.push('./login');
      return true;
    } else {
      return false;
    };
  }
}


function register(fullname, email, phone, passwd) {
    return dispatch => {
        dispatch(request());
        authenticateService.register({fullname, email, phone, passwd})
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error('Ups, ada kesalahan pada sistem'));
                }
            );
    };

    function request(user) { return { type: authenticateConstants.SIGNUP_REQUEST}}
    function success(user) { return { type: authenticateConstants.SIGNUP_SUCCESS,user}}
    function failure() { return { type: authenticateConstants.SIGNUP_FAILURE}}
}


function forgot(email) {
    return dispatch => {
        dispatch(request());
        authenticateService.forgot({email})
            .then(
                user => {
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Forgot password successful'));
                },
                error => {
                    dispatch(failure());
                    dispatch(alertActions.error('Ups, ada kesalahan pada sistem'));
                }
            );
    };

    function request() { return { type: authenticateConstants.RESET_PASSWORD_REQUEST}}
    function success() { return { type: authenticateConstants.RESET_PASSWORD_SUCCESS}}
    function failure(error) { return { type: authenticateConstants.RESET_PASSWORD_FAILURE, payload: error}}
}


function getAll() {
    return dispatch => {
        dispatch(request());
        authenticateService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure())
            );
    };

    function request() { return { type:      authenticateConstants.GETALL_REQUEST } }
    function success(users) { return { type: authenticateConstants.GETALL_SUCCESS, users } }
    function failure() { return { type: authenticateConstants.GETALL_FAILURE}}
}


// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));
        authenticateService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: authenticateConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: authenticateConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: authenticateConstants.DELETE_FAILURE, id}}
}

export function _onAuthFormFieldChange (field, value) {
  return {
    type: authenticateConstants.ON_AUTH_FORM_FIELD_CHANGE,
    payload: {field: field, value: value}
  }
}

export function _changeField (field, value) {
  return {
    type: authenticateConstants.CHANGE_FIELD,
    payload: {field: field, value: value}
  }
}

export function logoutState()           {return {type: authenticateConstants.LOGOUT }}
export function registerState()         {return {type: authenticateConstants.REGISTER }}
export function loginState()            {return {type: authenticateConstants.LOGIN }}
export function forgotPasswordState()  {return {type: authenticateConstants.FORGOT_PASSWORD }}

